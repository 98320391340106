import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { user__img } from "./style";
import {
  form__control,
  input__date,
  input__label,
  input__select,
  input__text,
  section__title,
  tab__switch__btn,
  tab__switch__btn__label,
} from "../style";
import { isMacOs, isSafari, browserVersion } from "react-device-detect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../datepicker.css";
import moment from "moment";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";

export default function TabInformasiPasien({
  form,
  medicalRecord,
  handleGeneralInputFormChange,
  isPasienBaru,
}) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [legacyBrowser, setLegacyBrowser] = useState(false);

  const generateMedicalRecordNumber = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records/generate-medical-record-number?clinic=${form.clinic_id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      handleGeneralInputFormChange({
        target: {
          name: "nomor_rekam_medis",
          value: response.data.nomor_rekam_medis,
          id: "nomor_rekam_medis",
          type: "text",
        },
      });
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message ?? error.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (isMacOs && isSafari) {
      let vrs = parseInt(browserVersion);
      if (!isNaN(vrs) && vrs < 14) {
        setLegacyBrowser(true);
      }
    }
  }, []);

  return (
    <>
      <Typography sx={section__title}>Informasi Pasien</Typography>
      <Grid container spacing={0}>
        <Grid item xs={6} lg={3}>
          <Paper
            elevation={0}
            sx={{
              background: "transparent",
            }}
          >
            <img
              src={
                medicalRecord?.user?.photo_profile
                  ? medicalRecord.user.photo_profile
                  : "/assets/icons/avatar.svg"
              }
              alt="user"
              style={user__img}
            ></img>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="nama">
                Nama
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="Nama"
                id="nama"
                name="nama"
                type="text"
                value={form.nama ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="nomor">
                Nomor HP
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="nomor"
                id="nomor"
                name="nomor"
                type="text"
                value={form.nomor ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="tanggal_lahir">
                Tanggal Lahir
              </InputLabel>
              {legacyBrowser ? (
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    form.tanggal_lahir
                      ? moment(form.tanggal_lahir).toDate()
                      : ""
                  }
                  onChange={(date) => {
                    handleGeneralInputFormChange({
                      target: {
                        name: "tanggal_lahir",
                        value: date,
                        id: "tanggal_lahir",
                        type: "date",
                      },
                    });
                  }}
                />
              ) : (
                <InputBase
                  sx={input__date}
                  placeholder="tanggal lahir"
                  id="tanggal_lahir"
                  name="tanggal_lahir"
                  type="date"
                  value={form.tanggal_lahir ?? ""}
                  onChange={handleGeneralInputFormChange}
                />
              )}
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel
                sx={{ ...input__label, marginBottom: "10px" }}
                shrink
                htmlFor="jenis_kelamin"
              >
                Jenis Kelamin
              </InputLabel>
              <Select
                id="jenis_kelamin"
                name="jenis_kelamin"
                value={form.jenis_kelamin ?? ""}
                onChange={handleGeneralInputFormChange}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                IconComponent={() => {
                  return <></>;
                }}
                sx={input__select}
              >
                <MenuItem value={"WANITA"}>Perempuan</MenuItem>
                <MenuItem value={"PRIA"}>Laki - laki</MenuItem>
              </Select>
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="alamat">
                Alamat
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                minRows={7}
                maxRows={7}
                placeholder="alamat"
                id="alamat"
                name="alamat"
                type="text"
                value={form.alamat ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={6} lg={9}>
          <Paper
            elevation={0}
            sx={{
              background: "transparent",
              paddingLeft: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <FormControl
                variant="standard"
                sx={{
                  ...form__control,
                  marginBottom: isPasienBaru ? "0px" : "10px",
                }}
              >
                <InputLabel
                  sx={input__label}
                  shrink
                  htmlFor="nomor_rekam_medis"
                >
                  Nomor Rekam Medis
                </InputLabel>
                <InputBase
                  sx={input__text}
                  placeholder="nomor rekam medis"
                  id="nomor_rekam_medis"
                  name="nomor_rekam_medis"
                  type="text"
                  value={form.nomor_rekam_medis ?? ""}
                  onChange={handleGeneralInputFormChange}
                />
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
              {isPasienBaru && (
                <Button
                  onClick={generateMedicalRecordNumber}
                  sx={{
                    ...tab__switch__btn,
                    marginBottom: "3px",
                    height: "36px",
                  }}
                  variant="contained"
                >
                  <Typography sx={tab__switch__btn__label}>Generate</Typography>
                </Button>
              )}
            </Box>

            {isPasienBaru && (
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                  ".MuiCheckbox-root": {
                    py: "0px",
                  },
                  marginBottom: "10px",
                }}
                control={
                  <Checkbox
                    checked={form.pasien_baru}
                    onChange={() => {
                      handleGeneralInputFormChange({
                        target: {
                          name: "pasien_baru",
                          value: !form.pasien_baru,
                        },
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        Pasien Baru
                      </Typography>
                      <Tooltip
                        title="Jika dicentang, nomor rekam medis akan dianggap unik dan akan ditolak jika nomor rekam medis sudah terpakai."
                        placement="right"
                      >
                        <IconButton>
                          <HelpOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </>
                }
              />
            )}

            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="usia">
                Usia dari tgl periksa (Otomatis Terisi)
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="usia"
                id="usia"
                name="usia"
                type="text"
                value={form.usia}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="ktp">
                Kartu Identitas
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="kartu indentitas"
                id="ktp"
                name="ktp"
                type="text"
                value={form.ktp ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="nama_keluarga">
                Nama Keluarga
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="nama keluarga"
                id="nama_keluarga"
                name="nama_keluarga"
                type="text"
                value={form.nama_keluarga ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="nomor_keluarga">
                Nomor HP Keluarga
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="nomor keluarga"
                id="nomor_keluarga"
                name="nomor_keluarga"
                type="text"
                value={form.nomor_keluarga ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="pendidikan">
                Pendidikan
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="pendidikan"
                id="pendidikan"
                name="pendidikan"
                type="text"
                value={form.pendidikan ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="agama">
                Agama
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="agama"
                id="agama"
                name="agama"
                type="text"
                value={form.agama ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="pekerjaan">
                Pekerjaan
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="pekerjaan"
                id="pekerjaan"
                name="pekerjaan"
                type="text"
                value={form.pekerjaan ?? ""}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
