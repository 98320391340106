import React, { useState } from "react";
import validator from "validator";
import { useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useAuth } from "../../../../../providers/AuthProvider";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import authHeader from "../../../../../services/authHeader";
import { input__label, input__text } from "../../rekam-medis/editor/style";
import * as Sentry from "@sentry/react";

export default function BatasAntrianModal({
  open,
  onClose,
  onSave,
  schedule = {},
  onChange,
}) {
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnSave = () => {
    if (typeof onSave === "function") {
      onSave();
    }
  };

  const [validationError, setValidationError] = useState({});

  const handleChange = (e) => {
    if (typeof onChange === "function") {
      onChange((prevForm) => ({
        ...prevForm,
        [e.target.name]: e.target.value,
      }));
    }

    setValidationError((prevError) => ({
      ...prevError,
      [e.target.name]: "",
    }));
  };

  const validate = () => {
    const newError = {};
    if (!schedule.limit) {
      newError.limit = "batas antrian wajib diisi";
      return newError;
    }
    let isNomor = validator.isNumeric(String(schedule.limit));
    if (!isNomor) {
      newError.limit = "input wajib numerik";
    }
    if (parseInt(schedule.limit) < 0) {
      newError.limit = "limit antrian minimal 0";
    }
    return newError;
  };

  const handleSubmit = async (e) => {
    const findValidationError = validate();
    if (Object.values(findValidationError).some((err) => err !== "")) {
      setValidationError(findValidationError);
    } else {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/schedules/${schedule.id}`,
          {
            ...schedule,
            timezone: mediplusHelper.getTimezoneOffset(),
          },
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        enqueueSnackbar("sukses menyimpan data", {
          variant: "success",
        });
        handleOnSave();
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.message, {
          variant: "error",
        });
        Sentry.captureException(e);
      } finally {
        handleModalClose();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Edit Batas Antrian
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <InputLabel sx={input__label} shrink htmlFor="limit">
              Batas Antrian
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              placeholder=""
              id="limit"
              name="limit"
              type="number"
              value={schedule?.limit ?? ""}
            />
            <FormHelperText error={true}>
              {validationError?.limit}
            </FormHelperText>
          </FormControl>

          <Button
            fullWidth
            sx={{
              backgroundColor: "blue",
              borderRadius: "8px",
              textTransform: "none",
              marginBottom: "15px",
              height: "38px",
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: 600,
                marginRight: "5px",
              }}
            >
              Simpan
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
