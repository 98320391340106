import React from "react";
import {
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { webkit__scrollbar } from "../../../../style";
import { useSelector } from "react-redux";

export default function ObatSubmenuModal({
  open = false,
  onClose = () => {},
  medicine,
  handleAddSubmenuMedicineItem,
}) {
  const invoice = useSelector((state) => state.invoice);

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleMedicineClick = (id) => {
    if (typeof handleAddSubmenuMedicineItem === "function")
      handleAddSubmenuMedicineItem(id);
  };

  const getItemCount = (id) => {
    let item = invoice?.invoice_items?.find((item) => item.medicine_id === id);
    if (!item) {
      return "";
    } else {
      return `${item.kuantitas}x`;
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Pilih Sub Obat
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <TableContainer
            sx={{
              ...webkit__scrollbar,
              maxHeight: "calc(100vh - 300px)",
              overflowY: "auto",
            }}
          >
            <Table aria-label="sticky table">
              <TableBody>
                {medicine &&
                  medicine.submenu?.length > 0 &&
                  medicine.submenu.map((item, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell
                          onClick={() => {
                            handleMedicineClick(item.id);
                          }}
                          sx={{
                            cursor: "pointer",
                            WebkitUserSelect: "none",
                            MsUserSelect: "none",
                            userSelect: "none",
                            "&:hover": {
                              background: "pink",
                            },
                          }}
                          align={"left"}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              {item.nama} | stok : {item.stok}
                            </span>
                            <span>{getItemCount(item.id)}</span>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Modal>
  );
}
