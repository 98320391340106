import { webkit__scrollbar } from "../../../../../style";

const invoice__item__list__container = {
  maxHeight: "250px",
  overflowY: "auto",
  borderBottom: "2px solid #F3F1F1",
  ...webkit__scrollbar,
};

const invoice__item__box = {
  display: "flex",
  boxShadow: "none",
  background: "#FAFBFD",
  position: "relative",
  justifyContent: "space-between",
  pr: 1,
};

const invoice__info__wrapper = {
  display: "flex",
  boxShadow: "none",
  background: "#FAFBFD",
  position: "relative",
  flexBasis: "60%",
  minWidth: "60%",
};

const invoice__img__container = {
  width: "44px",
  marginRight: "10px",
};

const invoice__info__text__container = {
  display: "flex",
  flexDirection: "column",
  paddingTop: "4px",
  my: "auto",
};

const invoice__info__text__name = {
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "19px",
};

const invoice__info__text__price = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "19px",
  display: "flex",
  flexDirection: "row",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const invoice__item__action__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
};

const qty__text = {
  pt: "5px",
  fontSize: "14px",
  mr: "4px",
  minWidth: "10px",
  textAlign: "center",
};

const qty__btn = {
  backgroundColor: "blue",
  height: "38px",
  color: "white",
  borderRadius: "8px",
  background: "none",
  px: 1,
  "&:hover": {
    background: "none",
  },
};

const discount__info__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
  my: 1,
  pr: 1,
};

const add__discount__btn = {
  fontSize: "12px",
  fontWeight: 400,
  color: "#00AEEF",
  cursor: "pointer",
};

const discount__info__text = {
  fontSize: "14px",
  fontWeight: 700,
  color: "red",
};

const submit__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  height: "38px",
};

const submit__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

const box__info__container = {
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "1px",
  maxWidth: "100%",
  py: "5px",
};

const box__info__label = {
  fontSize: "14px",
  fontWeight: 700,
  color: "#7D7D7D",
};

const box__info__value = {
  textAlign: "right",
  inlineSize: "300px",
  fontSize: "14px",
  fontWeight: 700,
  wordWrap: "break-word",
};

export {
  invoice__item__list__container,
  invoice__item__box,
  invoice__info__wrapper,
  invoice__img__container,
  invoice__info__text__container,
  invoice__info__text__name,
  invoice__info__text__price,
  invoice__item__action__container,
  qty__btn,
  qty__text,
  discount__info__container,
  add__discount__btn,
  discount__info__text,
  submit__btn,
  submit__btn__label,
  box__info__container,
  box__info__label,
  box__info__value,
};
