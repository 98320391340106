const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "15px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

const user__photo__opt__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignContent: "center",
  alignItems: "center",
  gap: "20px",
  marginBottom: "20px",
};

const user__photo = {
  width: "100px",
  height: "100px",
  borderRadius: "50px",
  objectFit: "cover",
};

const upload__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  height: "38px",
};

const upload__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

const avatar__btn = {
  color: "#00AEEF",
  fontSize: "14px",
  fontWeight: 700,
  cursor: "pointer",
};

const submit__btn = {
  backgroundColor: "#FF0091",
  mt: 1,
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  color: "white",
  "&:hover": {
    backgroundColor: "#FF0080",
  },
};

const submit__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
};

const modal__container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

const modal__header = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "12px",
  marginBottom: "10px",
};

const dropzone__container = {
  height: "95px",
  borderRadius: "8px",
  backgroundColor: "rgba(239, 241, 249, 1)",
  border: "1px solid #ced4da",
  position: "relative",
};

const dropzone__label = {
  margin: "0px",
  position: "absolute",
  left: "50%",
  top: "50%",
  WebkitTransform: "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
  display: "inline-block",
  maxWidth: "80%",
  fontSize: "14px",
};

export {
  header__container,
  header__h1,
  user__photo__opt__container,
  user__photo,
  upload__btn,
  upload__btn__label,
  avatar__btn,
  submit__btn,
  submit__btn__label,
  modal__container,
  modal__header,
  dropzone__container,
  dropzone__label,
};
