import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import {
  input__select,
  input__text,
  tab__switch__btn,
  tab__switch__btn__label,
} from "../style";
import {
  form__control,
  input,
  input__label,
  modal__title,
  modal__title__text,
  right__input__label,
} from "./style";
import ListObatModal from "./ListObatModal";
import { webkit__scrollbar } from "../../../../style";
import {
  snackbar__content__container,
  snackbar__content__text,
} from "../../main/table/style";

const satuan = [
  "ampoule",
  "capsule",
  "tab",
  "gr",
  "hisap",
  "kg",
  "kumur",
  "liter",
  "mg",
  "mcg",
  "ml",
  "ml/c",
  "emulsum",
  "pulveres",
  "naristillae",
  "oculentum",
  "pil",
  "potio",
  "serbuk",
  "larutan",
  "tinctura",
];

const rute_obat = [
  "oral",
  "parenteral",
  "rektal",
  "vaginal",
  "uretral",
  "lokal",
  "topikal",
  "transdermal",
];

function ResepRacikanModal({
  open,
  onClose,
  selectedResepRacik,
  setSelectedResepRacik,
  onSubmit,
}) {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const handleModalClose = (e, reason) => {
    if (reason !== "backdropClick") {
      if (typeof onClose === "function") {
        onClose();
      }
    }
  };

  const [listObatModalOpen, setListObatModalOpen] = useState(false);

  const handleInputChange = (e) => {
    setSelectedResepRacik((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleItemResepInputChange = (e, idx) => {
    setSelectedResepRacik((prev) => ({
      ...prev,
      item: prev.item?.map((i, iidx) =>
        idx === iidx ? { ...i, [e.target.name]: e.target.value } : i
      ),
    }));
  };

  const handleAddItemResep = () => {
    setSelectedResepRacik((prev) => ({
      ...prev,
      item: [
        {
          nama: "",
          jumlah_dosis: "",
          satuan_dosis: "",
          satuan_dosis_input_mode: "OPTION",
        },
        ...prev.item,
      ],
    }));
  };

  const handleSwitchInputModeSatuanDosis = () => {
    setSelectedResepRacik((prev) => {
      let temp = { ...prev };
      temp.satuan_dosis = "";
      if (temp.hasOwnProperty("satuan_dosis_input_mode")) {
        temp.satuan_dosis_input_mode =
          temp.satuan_dosis_input_mode === "OPTION" ? "TEXT" : "OPTION";
      } else {
        temp.satuan_dosis_input_mode = "OPTION";
      }
      return temp;
    });
  };

  const handleSwitchInputModeRuteObat = () => {
    setSelectedResepRacik((prev) => {
      let temp = { ...prev };
      temp.rute = "";
      if (temp.hasOwnProperty("rute_input_mode")) {
        temp.rute_input_mode =
          temp.rute_input_mode === "OPTION" ? "TEXT" : "OPTION";
      } else {
        temp.rute_input_mode = "OPTION";
      }
      return temp;
    });
  };

  const handleSwitchInputModeSatuanDosisItem = (idx) => {
    console.log(idx);
    setSelectedResepRacik((prev) => ({
      ...prev,
      item: prev.item.map((i, index) => {
        if (index === idx) {
          let temp = { ...i };
          console.log(temp);
          temp.satuan_dosis = "";
          if (temp.hasOwnProperty("satuan_dosis_input_mode")) {
            temp.satuan_dosis_input_mode =
              temp.satuan_dosis_input_mode === "OPTION" ? "TEXT" : "OPTION";
          } else {
            temp.satuan_dosis_input_mode = "OPTION";
          }
          return temp;
        } else {
          return i;
        }
      }),
    }));
  };

  const handleItemDeleteConfirmationOpen = (idx) => {
    setSelectedItemIndex(idx);
    setDeleteConfirmationOpen(true);
  };

  const handleItemDeleteConfirmationClose = () => {
    setSelectedItemIndex(null);
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteItem = () => {
    setSelectedResepRacik((prev) => ({
      ...prev,
      item: prev.item.filter((i, idx) => idx !== selectedItemIndex),
    }));
    setDeleteConfirmationOpen(false);
  };

  const handleListObatModalOpen = (idx) => {
    setSelectedItemIndex(idx);
    setListObatModalOpen(true);
  };

  const handleListObatModalClose = () => {
    setSelectedItemIndex(null);
    setListObatModalOpen(false);
  };

  const handleListObatOnChoose = (data) => {
    console.log(data);
    setListObatModalOpen(false);
    setSelectedResepRacik((prev) => ({
      ...prev,
      item: prev.item.map((i, idx) =>
        idx === selectedItemIndex
          ? {
              ...i,
              nama: data.parent
                ? `${data.parent.nama} (${data.nama})`
                : data.nama,
              satuan_dosis: data.parent ? data.parent.satuan : data.satuan,
              satuan_dosis_input_mode: "TEXT",
            }
          : i
      ),
    }));
    setSelectedItemIndex(null);
  };

  useEffect(() => {}, [selectedResepRacik]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={handleDeleteItem}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={handleItemDeleteConfirmationClose}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />
      <ListObatModal
        open={listObatModalOpen}
        onChoose={handleListObatOnChoose}
        onClose={handleListObatModalClose}
      ></ListObatModal>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
            borderRadius: "10px",
            marginBottom: "10px",
          }}
        >
          <Box
            id="modal-modal-description"
            sx={{
              maxHeight: "85vh",
              overflowY: "auto",
              ...webkit__scrollbar,
            }}
          >
            <Box sx={modal__title}>
              <Typography sx={modal__title__text}>Tambahkan Obat</Typography>
              <IconButton
                onClick={handleModalClose}
                color="inherit"
                aria-label="refresh"
                edge="start"
                type="button"
              >
                <img
                  width={25}
                  height={25}
                  effect="blur"
                  src="/assets/icons/close.svg"
                  alt="refresh"
                ></img>
              </IconButton>
            </Box>
            <Box
              sx={{
                px: 1,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <FormControl variant="standard" sx={form__control}>
                    <InputLabel sx={input__label} shrink>
                      Nama
                    </InputLabel>
                    <InputBase
                      autoComplete="false"
                      placeholder="Puyer"
                      name="nama_resep"
                      value={selectedResepRacik.nama_resep ?? ""}
                      type="text"
                      sx={input}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl variant="standard" sx={form__control}>
                    <InputLabel sx={input__label} shrink htmlFor="receiver">
                      Dosis (Jumlah)
                    </InputLabel>
                    <InputBase
                      autoComplete="false"
                      placeholder="e.g 50 , 1/4"
                      name="jumlah_dosis"
                      type="text"
                      value={selectedResepRacik.jumlah_dosis ?? ""}
                      sx={input}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" sx={form__control}>
                    <InputLabel sx={input__label} shrink>
                      Dosis (Satuan)
                    </InputLabel>
                    {selectedResepRacik.satuan_dosis_input_mode === "OPTION" ? (
                      <Select
                        name="satuan_dosis"
                        value={selectedResepRacik.satuan_dosis ?? ""}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        IconComponent={() => {
                          return <></>;
                        }}
                        sx={input__select}
                        onChange={handleInputChange}
                      >
                        <MenuItem value={""}>pilih satuan dosis</MenuItem>
                        {satuan.sort().map((i, idx) => (
                          <MenuItem key={idx} value={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <InputBase
                        autoComplete="false"
                        placeholder="e.g ml, mg"
                        name="satuan_dosis"
                        type="text"
                        value={selectedResepRacik.satuan_dosis ?? ""}
                        sx={input}
                        onChange={handleInputChange}
                      />
                    )}

                    <Typography
                      onClick={handleSwitchInputModeSatuanDosis}
                      sx={right__input__label}
                    >
                      {selectedResepRacik.satuan_dosis_input_mode === "OPTION"
                        ? "switch text"
                        : "switch opsi"}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="standard" sx={form__control}>
                    <InputLabel sx={input__label} shrink htmlFor="receiver">
                      Aturan Minum
                    </InputLabel>
                    <InputBase
                      autoComplete="false"
                      placeholder="3x1"
                      name="aturan_minum"
                      value={selectedResepRacik.aturan_minum ?? ""}
                      type="text"
                      onChange={handleInputChange}
                      sx={input}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="standard" sx={form__control}>
                    <InputLabel sx={input__label} shrink htmlFor="receiver">
                      Instruksi
                    </InputLabel>
                    <InputBase
                      autoComplete="false"
                      placeholder="e.g sesudah makan"
                      name="instruksi"
                      value={selectedResepRacik.instruksi ?? ""}
                      type="text"
                      sx={input}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="standard" sx={form__control}>
                    <InputLabel sx={input__label} shrink htmlFor="receiver">
                      Rute Obat
                    </InputLabel>
                    {selectedResepRacik.rute_input_mode === "OPTION" ? (
                      <Select
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        IconComponent={() => {
                          return <></>;
                        }}
                        sx={input__select}
                        name="rute"
                        value={selectedResepRacik.rute ?? ""}
                        onChange={handleInputChange}
                      >
                        <MenuItem value={""}>pilih rute obat</MenuItem>
                        {rute_obat.map((i, idx) => (
                          <MenuItem key={idx} value={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <InputBase
                        autoComplete="false"
                        placeholder="e.g oral"
                        name="rute"
                        type="text"
                        value={selectedResepRacik.rute ?? ""}
                        sx={input}
                        onChange={handleInputChange}
                      />
                    )}
                    <Typography
                      onClick={handleSwitchInputModeRuteObat}
                      sx={right__input__label}
                    >
                      {selectedResepRacik.rute_input_mode === "OPTION"
                        ? "switch text"
                        : "switch opsi"}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" sx={form__control}>
                    <InputLabel sx={input__label} shrink htmlFor="receiver">
                      Catatan
                    </InputLabel>
                    <InputBase
                      autoComplete="false"
                      placeholder="catatan dokter"
                      name="keterangan"
                      value={selectedResepRacik.keterangan ?? ""}
                      type="text"
                      sx={input}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" sx={form__control}>
                    <InputLabel sx={input__label} shrink htmlFor="receiver">
                      Jumlah (Qty)
                    </InputLabel>
                    <InputBase
                      autoComplete="false"
                      placeholder=""
                      name="qty"
                      value={selectedResepRacik.qty ?? ""}
                      type="number"
                      sx={input}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                p: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Item Resep
                </Typography>
                <Typography
                  onClick={handleAddItemResep}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  + Tambah Item
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {selectedResepRacik.item &&
                  Array.isArray(selectedResepRacik.item) &&
                  selectedResepRacik.item.length > 0 &&
                  selectedResepRacik.item.map((i, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          p: "3px",
                          pt: "10px",
                          pl: "10px",
                          border: "0.5px solid #ced4da",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4}>
                            <FormControl variant="standard" sx={form__control}>
                              <InputLabel sx={input__label} shrink>
                                Nama
                              </InputLabel>
                              <InputBase
                                autoComplete="false"
                                placeholder="salbutamol"
                                name="nama"
                                onChange={(e) => {
                                  handleItemResepInputChange(e, index);
                                }}
                                value={
                                  selectedResepRacik?.item[index]?.nama ?? ""
                                }
                                type="text"
                                sx={input}
                              />
                              <Typography
                                onClick={() => {
                                  handleListObatModalOpen(index);
                                }}
                                sx={right__input__label}
                              >
                                pilih dari obat
                              </Typography>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl variant="standard" sx={form__control}>
                              <InputLabel
                                sx={input__label}
                                shrink
                                htmlFor="receiver"
                              >
                                Dosis (Jumlah)
                              </InputLabel>
                              <InputBase
                                autoComplete="false"
                                placeholder="e.g 50 , 1/4"
                                onChange={(e) => {
                                  handleItemResepInputChange(e, index);
                                }}
                                value={
                                  selectedResepRacik?.item[index]
                                    ?.jumlah_dosis ?? ""
                                }
                                type="text"
                                name="jumlah_dosis"
                                sx={input}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl variant="standard" sx={form__control}>
                              <InputLabel sx={input__label} shrink>
                                Dosis (Satuan)
                              </InputLabel>
                              {i.satuan_dosis_input_mode === "OPTION" ? (
                                <Select
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  IconComponent={() => {
                                    return <></>;
                                  }}
                                  sx={input__select}
                                  onChange={(e) => {
                                    handleItemResepInputChange(e, index);
                                  }}
                                  value={
                                    selectedResepRacik?.item[index]
                                      ?.satuan_dosis ?? ""
                                  }
                                  name="satuan_dosis"
                                >
                                  <MenuItem value={""}>
                                    pilih satuan dosis
                                  </MenuItem>
                                  {satuan.sort().map((i, idx) => (
                                    <MenuItem key={idx} value={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                                </Select>
                              ) : (
                                <InputBase
                                  autoComplete="false"
                                  placeholder="e.g ml, mg"
                                  name="satuan_dosis"
                                  type="text"
                                  value={
                                    selectedResepRacik?.item[index]
                                      ?.satuan_dosis ?? ""
                                  }
                                  sx={input}
                                  onChange={(e) => {
                                    handleItemResepInputChange(e, index);
                                  }}
                                />
                              )}

                              <Typography
                                onClick={() => {
                                  handleSwitchInputModeSatuanDosisItem(index);
                                }}
                                sx={right__input__label}
                              >
                                {i.satuan_dosis_input_mode === "OPTION"
                                  ? "switch text"
                                  : "switch opsi"}
                              </Typography>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography
                            onClick={() => {
                              handleItemDeleteConfirmationOpen(index);
                            }}
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "red",
                              cursor: "pointer",
                              width: "fit-content",
                            }}
                          >
                            Hapus
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              pt: 2,
              textAlign: "right",
            }}
          >
            <Button
              onClick={onSubmit}
              sx={tab__switch__btn}
              variant="contained"
            >
              <Typography sx={tab__switch__btn__label}>Simpan</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

ResepRacikanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ResepRacikanModal;
