import { webkit__scrollbar } from "../../../../../style";

const select__2 = {
  "& .MuiInputBase-root": {
    p: 0,
    borderRadius: "8px",
    background: "rgba(239, 241, 249, 1)",
    fontSize: "12px",
    fontWeight: 600,
  },
  width: "200px",
};

const table__row = {
  border: "none",
  outline: "none",
};

const table__cell = {
  fontSize: "14px",
  fontWeight: 600,
  py: 0.5,
  border: "none",
  outline: "none",
  whiteSpace: "nowrap",
};

const odontogram__input__text = {
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "&.MuiInputBase-root": {
    paddingLeft: "0px",
    paddingRight: "10px",
    width: "100%",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "12px",
    paddingRight: "12px",
    height: "16px",
    fontSize: 12,
    fontWeight: 700,
    borderRadius: "8px",
    marginRight: "5px",
    minWidth: "100%",
    ...webkit__scrollbar,
  },
};

const option__container = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignContent: "center",
  gap: 4,
  justifyContent: "center",
  flexWrap: "wrap",
};

const option__item__container = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignContent: "center",
  gap: 2,
};

const disabled__tooth__index = {
  textDecoration: "line-through",
};

const triangle__teeth__orientation__helper = {
  position: "absolute",
  top: "40px",
  left: "643px",
  width: "20px",
  height: "20px",
};

export {
  select__2,
  table__cell,
  table__row,
  odontogram__input__text,
  option__container,
  option__item__container,
  disabled__tooth__index,
  triangle__teeth__orientation__helper,
};
