import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  Typography,
} from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import MediaList from "../media-list/MediaList";
import { input__label, input__text, section__title } from "../style";
import { dropzone__container, dropzone__label } from "./style";

export default function TabInternal({
  form,
  setForm,
  medicalRecord,
  handleGeneralInputFormChange,
}) {
  const handleFilInternalDelete = (index) => {
    setForm((prevForm) => ({
      ...prevForm,
      file_internal: prevForm.file_internal.filter((item, idx) => {
        return idx !== index;
      }),
    }));
  };

  const handleFileInternalChange = (file) => {
    console.log(
      mediplusHelper.getFileExtensionFromName(file[0].name.toLocaleLowerCase())
    );
    if (file.length > 0) {
      setForm((prevForm) => ({
        ...prevForm,
        file_internal: [...prevForm.file_internal, ...file].filter((i) => {
          return ["PNG", "JPG", "JPEG", "AVI", "MP4", "PDF"].includes(
            mediplusHelper.getFileExtensionFromName(i.name)
          );
        }),
      }));
    }
  };
  return (
    <>
      <Typography sx={section__title}>Informasi Internal Medis</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={input__label} shrink htmlFor="catatan">
                Catatan Internal
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="catatan internal"
                id="catatan"
                name="catatan"
                type="text"
                value={form.catatan}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={input__label} shrink htmlFor="catatan">
                File Catatan Internal
              </InputLabel>
            </FormControl>
            <Dropzone onDrop={handleFileInternalChange}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div style={dropzone__container} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p style={dropzone__label}>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {form?.file_internal.length > 0 &&
                form.file_internal.map((item, index) => {
                  return (
                    <Box key={`f_scan_box_${index}`}>
                      <Typography component={"span"} key={`f_scan_${index}`}>
                        {item.name} | {mediplusHelper.fileSizeIEC(item.size)}{" "}
                        <Typography
                          component={"span"}
                          sx={{
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleFilInternalDelete(index);
                          }}
                        >
                          delete
                        </Typography>
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Grid>
        </Grid>
        <MediaList medias={medicalRecord?.record_internal?.media} />
      </Box>
    </>
  );
}
