import React from "react";

export default function BottomLeftRotation() {
  return (
    <img
      src="/assets/odontogram/rotasi-bawah-kiri.png"
      alt="rotasi bawah kiri"
      width="50"
      height="15"
    ></img>
  );
}
