import { webkit__scrollbar } from "../../../style";

const scan__table__content__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "5px 15px 5px 15px",
};

const scan__table__title = {
  fontSize: "16px",
  fontWeight: 700,
  paddingTop: "7px",
};

const scan__table__container = {
  maxHeight: 330,
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  ...webkit__scrollbar,
};

const scan__user__img = {
  width: "44px",
  height: "44px",
  borderRadius: "50%",
};

const scan__status__pills = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
  padding: "5px 16px",
  gap: "10px",
  position: "relative",
  height: "30px",
  borderRadius: "32px",
  background: "#FFF3C9",
  color: "#F1BC00",
};

const scan__status__pills__label = {
  display: "inline-block",
  width: "100%",
  fontSize: "12px",
  fontWeight: 400,
};

const table__cell__text = {
  fontSize: "16px",
  fontWeight: 600,
};

export {
  scan__table__content__wrapper,
  scan__table__title,
  scan__table__container,
  scan__user__img,
  scan__status__pills,
  scan__status__pills__label,
  table__cell__text,
};
