import { FormControl, Grid, InputLabel, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Dropzone from "react-dropzone";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import MediaList from "../media-list/MediaList";
import { input__label, section__title } from "../style";
import { dropzone__container, dropzone__label } from "../tab-internal/style";

export default function TabHasilPemeriksaan({ form, setForm, medicalRecord }) {
  const handleFileMedisChange = (file) => {
    if (file.length > 0) {
      setForm((prevForm) => ({
        ...prevForm,
        file_scan: [...prevForm.file_scan, ...file].filter((i) => {
          return ["PNG", "JPG", "JPEG", "AVI", "MP4", "PDF"].includes(
            mediplusHelper.getFileExtensionFromName(i.name.toLocaleLowerCase())
          );
        }),
      }));
    }
  };

  const handleFileMedisDelete = (index) => {
    setForm((prevForm) => ({
      ...prevForm,
      file_scan: prevForm.file_scan.filter((item, idx) => {
        return idx !== index;
      }),
    }));
  };
  return (
    <>
      <Typography sx={section__title}>File Rekam Medis</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={input__label} shrink htmlFor="catatan">
                Hasil Pemeriksaan
              </InputLabel>
            </FormControl>
            <Dropzone onDrop={handleFileMedisChange}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div style={dropzone__container} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p style={dropzone__label}>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {form?.file_scan.length > 0 &&
                form.file_scan.map((item, index) => {
                  return (
                    <Box key={`f_scan_box_${index}`}>
                      <Typography component={"span"} key={`f_scan_${index}`}>
                        {item.name} | {mediplusHelper.fileSizeIEC(item.size)}{" "}
                        <Typography
                          component={"span"}
                          sx={{
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleFileMedisDelete(index);
                          }}
                        >
                          delete
                        </Typography>
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Grid>
        </Grid>
        <MediaList medias={medicalRecord?.media} />
      </Box>
    </>
  );
}
