import { webkit__scrollbar } from "../../style";

const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "15px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

const search__icon = {
  backgroundColor: "blue",
  borderRadius: "0px 8px 8px 0px",
  height: "38px",
};

const header__btn = {
  backgroundColor: "blue",
  height: "38px",
  color: "white",
  "&:hover": {
    backgroundColor: "blue",
  },
  borderRadius: "8px",
  marginLeft: "10px",
};

const header__btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "5px",
  marginLeft: "5px",
};

const table__container = {
  maxHeight: 520,
  ...webkit__scrollbar,
};

const table__cell__text = {
  fontSize: "14px",
  fontWeight: 600,
  py: 0,
};

const user__info__container = {
  display: "flex",
  minWidth: "150px",
  minHeight: "45px",
  boxShadow: "none",
  background: "transparent",
  width: "auto",
  cursor: "pointer",
  marginRight: "5px",
};

const user__info__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "10px",
  marginRight: "15px",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
};

const user__info__text = {
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "19px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export {
  header__container,
  header__h1,
  search__icon,
  header__btn,
  header__btn__label,
  table__container,
  table__cell__text,
  user__info__container,
  user__info__img,
  user__info__text,
};
