import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  box__img,
  box__media__container,
  box__pdf,
  box__pdf__icon,
  box__video,
  box__video__icon,
  media__list__wrapper,
} from "./style";
import MediaModal from "../../../../components/modal/media-modal/MediaModal";
import mediplusHelper from "../../../../../../utils/mediplusHelper";

export default function DetailPenyimpananContent(props) {
  const type = props.type;
  const detailPenyimpanan = props.detailPenyimpanan;
  const selectedMedia = props.selectedMedia;
  const setSelectedMedia = props.setSelectedMedia;

  const [mediaModal, setMediaModal] = React.useState(null);

  const handleMediaModalOpen = (media) => {
    if (
      mediplusHelper.isImageMime(media.mime_type) ||
      mediplusHelper.isVideoMime(media.mime_type)
    ) {
      setMediaModal(media);
    } else {
      window.open(media.s3_url, "_blank");
    }
  };

  const handleMediaModalClose = () => {
    setMediaModal(null);
  };

  const handleMediaClick = (media) => {
    let find = selectedMedia.find((item) => item.id === media.id);
    if (find === undefined) {
      setSelectedMedia((prev) => [...prev, media]);
    } else {
      setSelectedMedia((prev) => prev.filter((i) => i.id !== media.id));
    }
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={media__list__wrapper}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                rowGap: "20px",
                gap: "20px",
              }}
            >
              {detailPenyimpanan &&
                detailPenyimpanan.media &&
                detailPenyimpanan.media.length > 0 &&
                detailPenyimpanan.media
                  .filter((item) => {
                    if (type === "ALL") {
                      return true;
                    } else if (type === "IMAGE") {
                      return (
                        mediplusHelper.isImageMime(item.mime_type) === true
                      );
                    } else if (type === "VIDEO") {
                      return (
                        mediplusHelper.isVideoMime(item.mime_type) === true
                      );
                    } else {
                      return (
                        mediplusHelper.isVideoMime(item.mime_type) === false &&
                        mediplusHelper.isImageMime(item.mime_type) === false
                      );
                    }
                  })
                  .map((item) => {
                    return (
                      <Box
                        key={`box_${item.id}`}
                        onClick={() => {
                          handleMediaClick(item);
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        {mediplusHelper.isImageMime(item.mime_type) && (
                          <Paper
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMediaModalOpen(item);
                            }}
                            key={item.id}
                            sx={{
                              ...box__media__container,
                              border:
                                selectedMedia.find((i) => i.id === item.id) ===
                                undefined
                                  ? "none"
                                  : "5px solid rgba(0, 174, 239, 1)",
                            }}
                          >
                            <img
                              src={item.s3_url}
                              alt="media"
                              sttyle={box__img}
                            />
                          </Paper>
                        )}
                        {mediplusHelper.isVideoMime(item.mime_type) && (
                          <Paper
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMediaModalOpen(item);
                            }}
                            key={item.id}
                            sx={{
                              ...box__media__container,
                              border:
                                selectedMedia.find((i) => i.id === item.id) ===
                                undefined
                                  ? "none"
                                  : "5px solid rgba(0, 174, 239, 1)",
                            }}
                          >
                            <video style={box__video}>
                              <source
                                src={item.s3_url}
                                type="video/mp4"
                              ></source>
                            </video>
                            <LazyLoadImage
                              style={box__video__icon}
                              src="/assets/chat/play.png"
                            ></LazyLoadImage>
                          </Paper>
                        )}

                        {!mediplusHelper.isImageMime(item.mime_type) &&
                          !mediplusHelper.isVideoMime(item.mime_type) && (
                            <Paper
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMediaModalOpen(item);
                              }}
                              key={item.id}
                              sx={{
                                ...box__pdf,
                                border:
                                  selectedMedia.find(
                                    (i) => i.id === item.id
                                  ) === undefined
                                    ? "none"
                                    : "5px solid rgba(0, 174, 239, 1)",
                              }}
                            >
                              <img
                                style={box__pdf__icon}
                                src="/assets/icons/pdf.svg"
                                alt="doc"
                              ></img>
                            </Paper>
                          )}

                        <Typography
                          sx={{
                            marginTop: "5px",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {item.custom_properties?.original_filenamea ??
                            item.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#7D7D7D",
                          }}
                        >
                          {mediplusHelper.fileSizeIEC(item.size)}
                        </Typography>
                      </Box>
                    );
                  })}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <MediaModal media={mediaModal} onClose={handleMediaModalClose} />
    </>
  );
}
