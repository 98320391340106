import React from "react";

export default function BottomRightRotation() {
  return (
    <img
      src="/assets/odontogram/rotasi-bawah-kanan.png"
      alt="rotasi bawah kanan"
      width="50"
      height="15"
    ></img>
  );
}
