import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import validator from "validator";
import axios from "axios";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import {
  form__control,
  input,
  input__label,
  modal,
  modal__title,
  modal__title__text,
  submit__btn,
  submit__btn__label,
} from "./style";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";
import { updateScanFolder } from "../../../../../redux/slices/scanFolderUpdate";
import { isMacOs, isSafari, browserVersion } from "react-device-detect";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../datepicker.css";
import { input__date } from "../../../pages/rekam-medis/editor/style";

function SendDataModal({ open, onClose, data, type }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [labelKirim, setLabelKirim] = useState("Kirim");
  const [legacyBrowser, setLegacyBrowser] = useState(false);

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const [form, setForm] = useState({
    receiver: "",
    catatan: "",
    untuk_instansi: false,
    reminder: "",
  });

  const [validationError, setValidationError] = useState({
    receiver: "",
    catatan: "",
    reminder: "",
  });

  const handleChange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));

    setValidationError((prevError) => ({
      ...prevError,
      [e.target.name]: "",
    }));
  };

  const validate = () => {
    const newError = {};

    if (!form.receiver) {
      newError.receiver = "penerima wajib diisi";
      return newError;
    }

    let isEmail = validator.isEmail(form.receiver);
    let isNomor = form.receiver.match(/^(\+)?[0-9]/);

    if (!isEmail && !isNomor) {
      newError.receiver =
        "penerima terdeteksi bukan nomor telepon maupun email";
    }
    return newError;
  };

  const handleSubmit = async (e) => {
    const findValidationError = validate();
    if (Object.values(findValidationError).some((err) => err !== "")) {
      setValidationError(findValidationError);
    } else {
      try {
        let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/`;
        if (type === "SCAN_FOLDER") {
          url = url + `scan-folders/${data.id}/send`;
        } else if (type === "INVOICE") {
          url = url + `invoices/${data.id}/send`;
        } else {
          url = url + `medical-records/${data.id}/send`;
        }

        await axios.post(
          url,
          {
            ...form,
            via: validator.isEmail(form.receiver) ? "EMAIL" : "WHATSAPP",
            reminder: form.reminder
              ? moment(form.reminder).isValid()
                ? moment(form.reminder).format("YYYY-MM-DD")
                : null
              : null,
          },
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        enqueueSnackbar("request berhasil diterima server", {
          variant: "success",
        });

        if (type === "SCAN_FOLDER") {
          dispatch(updateScanFolder());
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.response?.data?.message ?? e.message, {
          variant: "error",
        });
        Sentry.captureException(e);
      } finally {
        handleModalClose();
      }
    }
  };

  useEffect(() => {
    if (validator.isEmail(form.receiver)) {
      setLabelKirim("Kirim Email");
    } else if (form.receiver.match(/^(08|62|\+62)/)) {
      setLabelKirim("Kirim Whatsapp");
    } else {
      setLabelKirim("Kirim");
    }
  }, [form.receiver]);

  useEffect(() => {
    if (isMacOs && isSafari) {
      let vrs = parseInt(browserVersion);
      if (!isNaN(vrs) && vrs < 14) {
        setLegacyBrowser(true);
      }
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal}>
        <Box sx={modal__title}>
          <Typography sx={modal__title__text}>
            Bagikan {type === "INVOICE" ? "Invoice" : "Hasil Pemeriksaan"} Ke
            Pasien / Dokter Instansi Lain
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="receiver">
              Email / Nomor Whatsapp / Telepon
            </InputLabel>
            <InputBase
              onChange={handleChange}
              placeholder=""
              id="receiver"
              name="receiver"
              type="text"
              sx={input}
            />
            <FormHelperText error={true}>
              {validationError.receiver}
            </FormHelperText>
          </FormControl>
          {type === "SCAN_FOLDER" && (
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="reminder">
                Reminder Pemeriksaan Selanjutnya (Opsional)
              </InputLabel>
              {legacyBrowser ? (
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={form.reminder ? moment(form.reminder).toDate() : ""}
                  onChange={(date) => {
                    handleChange({
                      target: {
                        name: "reminder",
                        value: date,
                        id: "reminder",
                        type: "date",
                      },
                    });
                  }}
                />
              ) : (
                <InputBase
                  sx={input__date}
                  placeholder=""
                  id="reminder"
                  name="reminder"
                  type="date"
                  value={form.reminder ?? ""}
                  onChange={handleChange}
                />
              )}
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          )}
          {type !== "INVOICE" && (
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
                marginBottom: "10px",
              }}
              control={
                <Checkbox
                  checked={form.untuk_instansi}
                  onChange={() => {
                    setForm((prevForm) => ({
                      ...prevForm,
                      untuk_instansi: !prevForm.untuk_instansi,
                    }));
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                <>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Kirim rekam medis ke instansi lain
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#D4D4D4",
                    }}
                  >
                    Menyetujui untuk menjaga kerahasiaan data
                  </Typography>
                </>
              }
            />
          )}
          <FormControl
            variant="standard"
            sx={{
              ...form__control,
              display: form.untuk_instansi === true ? "flex" : "none",
            }}
          >
            <InputLabel sx={input__label} shrink htmlFor="catatan">
              Keterangan
            </InputLabel>
            <InputBase
              onChange={handleChange}
              placeholder=""
              multiline
              minRows={3}
              maxRows={3}
              id="catatan"
              name="catatan"
              type="text"
              sx={input}
            />
            <FormHelperText error={true}></FormHelperText>
          </FormControl>
          <Button
            fullWidth
            sx={submit__btn}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography sx={submit__btn__label}>{labelKirim}</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

SendDataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  type: PropTypes.string.isRequired,
};

export default SendDataModal;
