import {
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import a11yProps from "../../../../components/tab/a11yProps";
import TabPanel from "../../../../components/tab/TabPanel";
import PencarianRiwayatModal from "./pencarian-riwayat-modal/PencarianRiwayatModal";
import {
  category__list__item__text,
  header__btn,
  header__btn__label,
  header__container,
  header__h1,
  search__input,
  tab__header__container,
  tab__header__label,
} from "./style";
import RekamMedisTable from "./table/RekamMedisTable";
import RekamMedisTimeline from "./timeline/RekamMedisTimeline";

export default function RekamMedis() {
  const history = useHistory();

  const [tabIndex, setTabIndex] = useState(1);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const btnSearch = useRef();
  const [searchLabel, setSearchLabel] = useState("Pilih Kategori");
  const [searchField, setSearchField] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [popperSearchOpen, setPopperSearchOpen] = useState(false);

  const [pencarianRiwayatModalOpen, setPencarianRiwayatModalOpen] =
    useState(false);

  const handlePencarianRiwayatModalOpen = () => {
    setPencarianRiwayatModalOpen(true);
  };

  const handlePencarianRiwayatModalClose = () => {
    setPencarianRiwayatModalOpen(false);
  };

  const handleSearchFieldChange = (field, label) => {
    setSearchField(field);
    setSearchLabel(label);
    setPopperSearchOpen(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <PencarianRiwayatModal
        open={pencarianRiwayatModalOpen}
        onClose={handlePencarianRiwayatModalClose}
      />

      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Rekam Medis
        </Typography>
        <Box>
          <IconButton
            ref={btnSearch}
            aria-label="search-user"
            onClick={() => {
              setPopperSearchOpen(!popperSearchOpen);
            }}
            sx={header__btn}
          >
            <Typography sx={{ ...header__btn__label, paddingLeft: "8px" }}>
              {searchLabel}
            </Typography>
            <img src="/assets/icons/arrow-bottom.svg" alt="kategori"></img>
          </IconButton>
          <InputBase
            sx={search__input}
            onChange={handleSearchInputChange}
            placeholder="Masukkan kata kunci"
          ></InputBase>
          <IconButton
            onClick={handlePencarianRiwayatModalOpen}
            aria-label="search-user"
            sx={{ ...header__btn, borderRadius: "8px" }}
          >
            <img src="/assets/icons/plus.svg" alt="create"></img>
            <Typography sx={header__btn__label}>Rekam Medis</Typography>
          </IconButton>
        </Box>
      </Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "rgba(0 0 0 / 0)",
        }}
      >
        <Box sx={{ width: "100%", position: "relative" }}>
          <Box sx={tab__header__container}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="tabk"
              sx={{
                ".MuiTabs-flexContainer": {
                  background: "transparent",
                },
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#FF0091",
                  height: "3px",
                },
              }}
            >
              <Tab
                sx={{
                  mr: 3,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__header__label,
                      color: tabIndex === 0 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Timeline
                  </Typography>
                }
                {...a11yProps(0)}
              ></Tab>
              <Tab
                sx={{
                  mx: 3,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__header__label,
                      color: tabIndex === 1 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Hari Ini
                  </Typography>
                }
                {...a11yProps(1)}
              />
              <Tab
                sx={{
                  mx: 3,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__header__label,
                      color: tabIndex === 2 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Belum Dikirim
                  </Typography>
                }
                {...a11yProps(2)}
              />
              <Tab
                sx={{
                  mx: 3,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__header__label,
                      color: tabIndex === 3 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Selesai
                  </Typography>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <TabPanel
            sx={{
              ".MuiBox-root": {
                paddingLeft: "0px",
                paddingRight: "0px",
              },
            }}
            value={tabIndex}
            index={0}
          >
            <RekamMedisTimeline
              searchField={searchField}
              searchValue={searchValue}
            />
          </TabPanel>
          <TabPanel
            sx={{
              "& .MuiBox-root": {
                padding: "0px",
              },
            }}
            value={tabIndex}
            index={1}
          >
            <RekamMedisTable
              searchField={searchField}
              searchValue={searchValue}
              type="TODAY"
            />
          </TabPanel>
          <TabPanel
            sx={{
              "& .MuiBox-root": {
                padding: "0px",
              },
            }}
            value={tabIndex}
            index={2}
          >
            <RekamMedisTable
              searchField={searchField}
              searchValue={searchValue}
              type="NOTSEND"
            />
          </TabPanel>
          <TabPanel
            value={tabIndex}
            sx={{
              "& .MuiBox-root": {
                padding: "0px",
              },
            }}
            index={3}
          >
            <RekamMedisTable
              searchField={searchField}
              searchValue={searchValue}
              type="DONE"
            />
          </TabPanel>
        </Box>
      </Paper>
      <Popper
        open={popperSearchOpen}
        anchorEl={btnSearch.current}
        placement={"bottom"}
        sx={{
          zIndex: 1500,
        }}
      >
        <Box>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("", "Pilih Kategori");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Pilih Kategori"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("nama", "Nama Pasien");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Nama Pasien"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange(
                      "nomor_rekam_medis",
                      "Nomor Rekam Medis"
                    );
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Nomor Rekam Medis"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("nomor", "Nomor HP");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Nomor HP"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("subjektif", "Subjektif");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Subjektif"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("objektif", "Objektif");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Objektif"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("assesment", "Penilaian");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Penilaian"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("plan", "Perencanaan");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Perencanaan"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("implementasi", "Implementasi");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Implemetasi"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("evaluasi", "Evaluasi");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Evaluasi"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleSearchFieldChange("revisi", "Revisi");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText
                    sx={category__list__item__text}
                    primary="Revisi"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>
      </Popper>
    </>
  );
}
