const text__date = {
  fontWeight: 600,
  fontSize: "12px",
  color: "rgba(125, 125, 125, 1)",
  marginBottom: "2px",
  marginLeft: "20px",
};

const text__subjektif = {
  fontWeight: 600,
  fontSize: "14px",
  display: "inline-block",
  marginBottom: "20px",
  marginLeft: "20px",
};

export { text__date, text__subjektif };
