const container = {
  display: "flex",
  boxShadow: "none",
  position: "relative",
  justifyContent: "space-between",
  background: "#EFF1F9",
  cursor: "pointer",
  mb: 0,
  padding: "7px 3px 3px 3px",
  borderRadius: "8px",
};

const conv__img__wrapper = {
  display: "flex",
  boxShadow: "none",
  position: "relative",
};

const conv__img__container = {
  width: "40px",
  marginRight: "10px",
  my: "auto",
};

const conv__img = {
  marginRight: "15px",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
};

const conv__name__container = {
  display: "flex",
  flexDirection: "column",
  paddingTop: "0px",
  my: "auto",
};

const conv__name = {
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "19px",
};

const conv__message = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "19px",
  display: "flex",
  flexDirection: "row",
};

const conv__time__container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignContent: "end",
  alignItems: "end",
};

const message__count = {
  fontSize: "12px",
  fontWeight: 600,
  color: "#FF0091",
  borderRadius: "3px",
  px: 0.5,
  background: "#FFCFEA",
};

export {
  container,
  conv__img__wrapper,
  conv__img__container,
  conv__img,
  conv__name__container,
  conv__name,
  conv__message,
  conv__time__container,
  message__count,
};
