import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useLiveQuery } from "dexie-react-hooks";
import { useAuth } from "../../../../../../providers/AuthProvider";
import { form__control, input__text } from "../../../rekam-medis/editor/style";
import { db } from "../../../../../../db/db";

const inputLabelStyle = {
  color: "rgba(4, 22, 21, 1)",
  fontSize: "16px",
  fontWeight: 600,
  paddingLeft: "2px",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function TemplateChatModal({ open = true, onClose = () => {} }) {
  const { user } = useAuth();
  const chat_templates = useLiveQuery(() =>
    db.chat_templates.where("doctor_id").equals(user.id).toArray()
  );
  const { enqueueSnackbar } = useSnackbar();
  const [input, setInput] = useState("");
  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleAddTemplate = async () => {
    if (!input) {
      enqueueSnackbar("silahkan ketik template pesan anda");
      return;
    }
    await db.chat_templates.add({
      value: input,
      doctor_id: user.id,
    });
    setInput("");
  };

  const handleDeleteTemplate = async (id) => {
    await db.chat_templates.where("id").equals(id).delete();
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...modalStyle, borderRadius: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Template Pesan
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <InputLabel sx={inputLabelStyle} shrink htmlFor="content">
              Pesan
            </InputLabel>
          </FormControl>
          <Grid container>
            <Grid item xs={9}>
              <FormControl variant="standard" sx={form__control}>
                <InputBase
                  sx={input__text}
                  value={input ?? ""}
                  multiline
                  maxRows={3}
                  type="text"
                  onChange={handleInputChange}
                ></InputBase>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                sx={{
                  backgroundColor: "blue",
                  borderRadius: "0px 8px 8px 0px",
                  textTransform: "none",
                  marginBottom: "15px",
                  height: "38px",
                }}
                variant="contained"
                onClick={handleAddTemplate}
              >
                <Typography
                  sx={{
                    marginLeft: "5px",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginRight: "5px",
                  }}
                >
                  Tambahkan
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <InputLabel sx={inputLabelStyle} shrink htmlFor="content">
              List Template Pesan
            </InputLabel>
          </FormControl>
          <Box
            sx={{
              background: "#EFF1F9",
              p: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {chat_templates &&
              chat_templates.map((i, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginRight: "10px",
                      alignContent: "center",
                      alignItems: "center",
                      background: "#fff",
                      borderRadius: "5px",
                      p: 0.5,
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        marginRight: "10px",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {i.value}
                    </Typography>
                    <IconButton
                      aria-label="delete-submenu"
                      sx={{
                        background: "#00AEEF",
                        borderRadius: "8px",
                        width: "20px",
                        height: "20px",
                        ":hover": {
                          background: "#00AEFF",
                        },
                      }}
                      onClick={() => {
                        handleDeleteTemplate(i.id);
                      }}
                    >
                      <img src="/assets/icons/cross.svg" alt="delete"></img>
                    </IconButton>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
