import { Box } from "@mui/material";
import React from "react";

export default function RootWhite() {
  return (
    <img
      src="/assets/odontogram/gigi-non-vital.png"
      alt="gigi-non-vital"
      width="50"
      height="15"
    ></img>
  );
}
