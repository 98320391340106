import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputBase,
  Modal,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { db } from "../../../../../../../db/db";
import { useAuth } from "../../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../../services/authHeader";
import { input__text } from "../../style";
import {
  diagnose__checkbox__container,
  diagnose__modal__container,
} from "./style";
import * as Sentry from "@sentry/react";

export default function DiagnoseModal({ open, onClose, form, onChoose }) {
  const { user } = useAuth();

  const [diagnoses, setDiagnoses] = useState([]);
  const [diagnoseLoading, setDiagnoseLoading] = useState(false);
  const [searchDiagnose, setSearchDiagnose] = useState("");

  const [bookmarkSearch, setBookmarkSearch] = useState("");
  const [icdbookmark, setIcdbookmark] = useState([]);
  const handleBookmarkChange = (value) => {
    setBookmarkSearch(value);
  };

  const loadIcdBookmark = async () => {
    let strRegExPattern = bookmarkSearch ? `${bookmarkSearch}` : "(.*?)";
    const bookmarks = await db.icd_bookmark
      .where("doctor_id")
      .equals(user.id)
      .filter(
        (x) =>
          x.icd10_code.match(new RegExp(strRegExPattern, "g")) ||
          x.who_full_desc.match(new RegExp(strRegExPattern, "g"))
      )
      .toArray();

    setIcdbookmark(bookmarks);
  };

  const loadDiagnoses = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/icd10`, {
        params: {
          search: searchDiagnose,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setDiagnoses(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setDiagnoseLoading(false);
      });
  };

  useEffect(() => {
    if (open === true) {
      loadDiagnoses();
    }
  }, [searchDiagnose, open]);

  useEffect(() => {
    if (open === true) {
      loadIcdBookmark();
    }
    return () => {
      setDiagnoses([]);
      setSearchDiagnose("");
      setBookmarkSearch("");
      setIcdbookmark([]);
    };
  }, [bookmarkSearch, open]);

  return (
    <Modal
      open={open}
      onClose={() => {
        if (typeof onClose === "function") {
          onClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={diagnose__modal__container}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={8}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
              }}
              id="modal-modal-title"
            >
              Diagnosa
            </Typography>
            <Box id="modal-modal-description">
              <InputBase
                placeholder="cari"
                type="text"
                onChange={(e) => {
                  setSearchDiagnose(e.target.value);
                }}
                sx={input__text}
              />
              <Box sx={diagnose__checkbox__container}>
                <Grid container spacing={0}>
                  {diagnoses &&
                    diagnoses.length > 0 &&
                    diagnoses.map((i) => {
                      return (
                        <Grid item xs={6} key={`grid_${i.icd10_code}`}>
                          <FormControlLabel
                            sx={{
                              ".MuiFormControlLabel-label": {
                                fontSize: "14px",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={
                                  form.diagnosa.find(
                                    (f) => f.icd10_code === i.icd10_code
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  if (typeof onChoose === "function")
                                    onChoose(i);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label={`${i.icd10_code} - ${i.who_full_desc}`}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
              }}
              id="modal-modal-title-2"
            >
              Bookmark
            </Typography>
            <Box id="modal-modal-description">
              <InputBase
                placeholder="cari"
                type="text"
                onChange={(e) => {
                  handleBookmarkChange(e.target.value);
                }}
                sx={input__text}
              />
              <Box sx={diagnose__checkbox__container}>
                <Grid container spacing={0}>
                  {icdbookmark &&
                    icdbookmark.length > 0 &&
                    icdbookmark.map((i) => {
                      return (
                        <Grid item xs={12} key={`grid_b_${i.icd10_code}`}>
                          <FormControlLabel
                            key={`form_b_${i.icd10_code}`}
                            sx={{
                              ".MuiFormControlLabel-label": {
                                fontSize: "14px",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={
                                  form.diagnosa.find(
                                    (f) => f.icd10_code === i.icd10_code
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  if (typeof onChoose === "function")
                                    onChoose(i);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label={`${i.icd10_code} - ${i.who_full_desc}`}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
