import axios from "axios";
import authHeader from "./authHeader";
import { db } from "../db/db";
import mediplusHelper from "../utils/mediplusHelper";

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/`;

const login = (email, password) => {
  const device_name = mediplusHelper.getDeviceUniqueId();
  return axios
    .post(API_URL + "login", {
      email,
      password,
      device_name,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem(
          "access_token",
          JSON.stringify(response.data.token)
        );
        localStorage.setItem("already_token_otp", false);
      }
      return { error: false, data: response.data, code: response.status };
    })
    .catch(function (error) {
      return {
        error: true,
        data: error.response.data,
        code: error.response.status,
      };
    });
};
const logout = async () => {
  try {
    await axios.post(
      API_URL + "logout",
      {},
      {
        headers: authHeader(),
      }
    );
  } catch (e) {
    console.log(e);
  } finally {
    await db.head_doctor.where("id").aboveOrEqual(0).delete();
    mediplusHelper.deleteLocalStorageItem("access_token");
    mediplusHelper.deleteLocalStorageItem("already_token_otp");
  }
};
const auth = async () => {
  try {
    const response = await axios.get(API_URL + "me", {
      params: {
        additional: "clinics,schedules,heads",
      },
      headers: authHeader(),
    });
    return {
      user: response.data,
      error: null,
      code: null,
    };
  } catch (error) {
    console.log(error);
    if (error.response?.status === 401) {
      mediplusHelper.deleteLocalStorageItem("access_token");
      mediplusHelper.deleteLocalStorageItem("already_token_otp");
    }
    return {
      user: null,
      error: error.message,
      code: error.response?.status,
    };
  }
};
const currentSchedule = async (clinicId = null, head = null) => {
  try {
    const response = await axios.get(API_URL + "schedules/current", {
      params: {
        clinic: clinicId ?? "",
        head: head,
      },
      headers: authHeader(),
    });
    return response.data;
  } catch (e) {
    console.log(e);
    return { schedule: null };
  }
};

const getUserAccessToken = () => {
  const accessToken = JSON.parse(localStorage.getItem("access_token"));
  return accessToken;
};

const AuthService = {
  auth,
  login,
  currentSchedule,
  logout,
  getUserAccessToken,
};
export default AuthService;
