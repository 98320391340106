const subscription__card = {
  p: 2,
  background: "transparent",
};

const subcription__header = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#7D7D7D",
  marginBottom: "10px",
  marginTop: "40px",
};

const clinic__usage__box = {
  background: "rgba(0, 174, 239, 0.08)",
  border: "1px solid rgba(0, 174, 239, 0.31)",
  p: 1,
  borderRadius: "8px",
  marginBottom: "15px",
};

const clinic__usage__box__header__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "5px",
};

const clinic__usage__box__header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const clinic__usage__box__header__clinic__name = {
  display: "block",
  paddingTop: "3px",
  marginLeft: "10px",
  fontSize: "14px",
  maxWidth: "170px",
  lineHeight: 1.3,
  fontWeight: 600,
};

const clinic__usage__box__header__clinic__usage = {
  fontWeight: 700,
  fontSize: "16px",
  color: "#00AEEF",
};

const clinic__usage__box__subscription__info__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  justifyItem: "center",
  marginBottom: "10px",
};

const subscription__name = {
  display: "block",
  paddingTop: "4px",
  marginLeft: "10px",
  fontSize: "14px",
  maxWidth: "170px",
  lineHeight: 1.3,
  fontWeight: 600,
};

const doctor__usage__box = {
  border: "1px solid #FF0091",
  p: 1,
  borderRadius: "8px",
};

const doctor__usage__box__header__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
  marginBottom: "10px",
};

const doctor__usage__box__header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const doctor__usage__box__header__label = {
  display: "block",
  paddingTop: "10px",
  marginLeft: "10px",
  fontSize: "14px",
  maxWidth: "170px",
  lineHeight: 1.3,
  fontWeight: 600,
};

const doctor__usage__box__header__value = {
  fontWeight: 700,
  fontSize: "16px",
  color: "#FF0091",
};

export {
  subscription__card,
  subcription__header,
  clinic__usage__box,
  clinic__usage__box__header__container,
  clinic__usage__box__header__wrapper,
  clinic__usage__box__header__clinic__name,
  clinic__usage__box__header__clinic__usage,
  clinic__usage__box__subscription__info__container,
  subscription__name,
  doctor__usage__box,
  doctor__usage__box__header__wrapper,
  doctor__usage__box__header__container,
  doctor__usage__box__header__label,
  doctor__usage__box__header__value,
};
