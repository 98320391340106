import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";

function PopperRefresh({ open, anchorEl, onClick }) {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={"bottom-end"}
      sx={{
        zIndex: 1500,
      }}
    >
      <Box>
        <Paper
          elevation={1}
          sx={{
            borderRadius: "10px",
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  py: "0px",
                }}
                onClick={onClick}
              >
                <ListItemText primary="refresh" />
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Popper>
  );
}

PopperRefresh.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  onClick: PropTypes.func.isRequired,
};

export default PopperRefresh;
