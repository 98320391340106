const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "15px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

const broadcast__btn = {
  backgroundColor: "blue",
  height: "38px",
  color: "white",
  borderRadius: "8px",
  marginLeft: "10px",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const broadcast__btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "5px",
  marginLeft: "5px",
};

export { header__container, header__h1, broadcast__btn, broadcast__btn__label };
