import { webkit__scrollbar } from "../../../style";

const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

const form__control = {
  width: "100%",
  marginBottom: "10px",
};

const input__label = {
  color: "rgba(4, 22, 21, 1)",
  fontSize: "16px",
  fontWeight: 600,
  paddingLeft: "2px",
};

const input = {
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    width: "100%",
    padding: "10px 12px",
    height: "16px",
    fontSize: 12,
    borderRadius: "8px",
    minWidth: "200px",
    "&:focus": {
      borderColor: "#1976d2",
    },
    ...webkit__scrollbar,
  },
};

const modal__title = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "12px",
  marginBottom: "10px",
};

const modal__title__text = {
  fontSize: "14px",
  fontWeight: 600,
};

const submit__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  height: "38px",
};

const submit__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

export {
  modal,
  input,
  modal__title,
  modal__title__text,
  input__label,
  form__control,
  submit__btn,
  submit__btn__label,
};
