import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Calendar from "react-calendar";
import "./calendar.css";
import moment from "moment";
import "moment/locale/id";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useSnackbar } from "notistack";
import { input__text, title__h1 } from "../../style";
import { useAuth } from "../../../../providers/AuthProvider";
import { updateScheduleData } from "../../../../redux/slices/schedule";
import authHeader from "../../../../services/authHeader";
import a11yProps from "../../../components/tab/a11yProps";
import TabPanel from "../../../components/tab/TabPanel";
import mediplusHelper from "../../../../utils/mediplusHelper";
import ScheduleBox from "./schedule-box/ScheduleBox";
import ScheduleChooserModal from "./schedule-chooser-modal/ScheduleChooserModal";
import {
  circular__container,
  home__widget__welcome__btn,
  home__widget__welcome__btn__label,
  home__widget__welcome__container,
  home__widget__welcome__illustration,
  home__widget__welcome__img,
  home__widget__welcome__img__container,
  home__widget__welcome__text__container,
  home__widget__welcome__text__details,
  home__widget__welcome__text__greeting,
  queue__stats__circular__inner__container,
  queue__stats__circular__inner__text__header,
  queue__stats__circular__inner__text__name,
  queue__stats__circular__inner__text__number,
  queue__stats__container,
  search__icon__img,
  tab__label,
  queue__stats__footer__container,
  queue__stats__footer__text__title,
  queue__stats__footer__text__value,
  call_queue__btn__label,
  call_queue__btn,
  schedule_switcher___container,
  schedule_switcher__header__container,
  schedule_switcher__header__text,
} from "./style";
import TabInvoice from "./tab-invoice/TabInvoice";
import TabQueue from "./tab-queue/TabQueue";
import ScanFolderTab from "./tab-scanfolder/TabScanfolder";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";

export default function Home() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  /**
   * === STATE ===
   */
  const { user } = useAuth();
  const dispatch = useDispatch();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const schedule_list = useSelector((state) => state.schedule.schedule_list);
  const schedule_date = useSelector((state) => state.schedule.scheduleDate);

  //table
  const [tabIndex, setTabIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const queue_update = useSelector((state) => state.queue_update.value);

  //misc
  const { enqueueSnackbar } = useSnackbar();
  const [queueStats, setQueueStats] = useState({
    current: null,
    next: null,
    limit: 0,
    progress_percent: 0,
  });

  //calendar
  const [selectedCalendarDate, setCalendardDate] = useState(
    new Date(schedule_date)
  );
  const [calendarShow, setCalendarShow] = useState(false);

  /**
   * === HANDLER ===
   */

  //table
  const handleSearchInput = (e) => {
    setSearch(e.target.value);
  };
  const handleTabChange = (event, newValue) => {
    setSearch("");
    setTabIndex(newValue);
  };

  //calendar
  const handleCalendarChange = (value) => {
    setCalendardDate(new Date(value));
  };
  const handleCalendarShow = () => {
    setCalendarShow(!calendarShow);
  };

  //schedule
  const handleScheduleSwitch = (id, date) => {
    let filterSchedule = schedule_list.filter((schedule) => {
      return schedule.id === id;
    });
    if (filterSchedule.length > 0) {
      dispatch(
        updateScheduleData({
          current_active_schedule: filterSchedule[0],
          scheduleDate: date,
        })
      );
      setCalendardDate(new Date(date));
    }
  };
  const handleScheduleModalOpen = () => {
    setScheduleModalOpen(true);
  };
  const handleScheduleModalClose = () => {
    setScheduleModalOpen(false);
  };

  //queue
  const updateQueueStats = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues/stats`, {
        params: {
          schedule: current_active_schedule.id,
          "booking-date": moment(schedule_date).format("YYYY-MM-DD"),
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setQueueStats((prev) => ({
          ...prev,
          ...response.data,
        }));
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  const callQueue = async (id) => {
    enqueueSnackbar("memproses panggilan..", {
      autoHideDuration: 1500,
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues/${id}`,
        {},
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      enqueueSnackbar("sukses memproses panggilan", {
        autoHideDuration: 1500,
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message, {
        variant: "error",
      });
    }
  };

  const anamnesisQueue = async (id) => {
    enqueueSnackbar("sedang memproses..", {
      autoHideDuration: 1500,
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues/${id}/anamnesis`,
        {},
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      enqueueSnackbar("sukses memproses request", {
        autoHideDuration: 1500,
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message, {
        variant: "error",
      });
    }
  };

  const headDoctorName = () => {
    const headDoctor = user.heads.find((i) => i.id === user.head_doctor_id);
    if (headDoctor !== undefined) {
      return headDoctor.nama;
    } else {
      return "";
    }
  };

  const handleNextQueueCall = async () => {
    enqueueSnackbar("memproses panggilan..", {
      autoHideDuration: 1500,
    });
    if (queueStats.next !== null) {
      let temp = queueStats.next;
      setQueueStats((prev) => ({
        ...prev,
        next: null,
      }));
      if (temp.status === "PERIKSA") {
        callQueue(temp.id);
      } else {
        anamnesisQueue(temp.id);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateQueueStats();
    }, 1000);
    const onFocus = () => {
      updateQueueStats();
    };
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [current_active_schedule, queue_update, schedule_date]);

  return (
    <>
      <Typography component="h1" sx={title__h1}>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={8}>
          <Paper elevation={0} sx={home__widget__welcome__container}>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <Paper elevation={0} sx={home__widget__welcome__img__container}>
                  <img
                    style={home__widget__welcome__img}
                    src={current_active_schedule.clinic?.photo_profile}
                    alt="clinic"
                  ></img>
                </Paper>
              </Grid>
              <Grid item xs={7}>
                <Paper
                  elevation={0}
                  sx={home__widget__welcome__text__container}
                >
                  <Typography sx={home__widget__welcome__text__greeting}>
                    Halo {user.nama}
                  </Typography>
                  <Typography sx={home__widget__welcome__text__details}>
                    Semoga anda sehat selalu, untuk jadwal praktek hari ini anda
                    sekarang ada pada “{current_active_schedule.clinic?.nama} -{" "}
                    {current_active_schedule.clinic_room?.nama}”{" "}
                    {user.type === "ASSISTANT" ? ` - ${headDoctorName()}` : ""}
                  </Typography>
                  <Button
                    onClick={handleScheduleModalOpen}
                    sx={home__widget__welcome__btn}
                    variant="contained"
                  >
                    <img
                      src="/assets/icons/logout.svg"
                      alt="ganti-tempat"
                    ></img>
                    <Typography sx={home__widget__welcome__btn__label}>
                      Ganti Tempat
                    </Typography>
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <img
                  src="/assets/doctor-woman-illustration.png"
                  alt="d-illustraion"
                  style={home__widget__welcome__illustration}
                ></img>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "rgba(0 0 0 / 0)",
            }}
          >
            <Box sx={{ width: "100%", position: "relative" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="tabk"
                  sx={{
                    ".MuiTabs-flexContainer": {
                      background: "transparent",
                    },
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FF0091",
                      height: "3px",
                    },
                  }}
                >
                  <Tab
                    label={
                      <Typography
                        sx={{
                          ...tab__label,
                          color: tabIndex === 0 ? "#041615" : "#7D7D7D",
                        }}
                      >
                        Info Antrian
                      </Typography>
                    }
                    {...a11yProps(0)}
                  ></Tab>
                  <Tab
                    label={
                      <Typography
                        sx={{
                          ...tab__label,
                          color: tabIndex === 1 ? "#041615" : "#7D7D7D",
                        }}
                      >
                        Hasil Pemeriksaan
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <Typography
                        sx={{
                          ...tab__label,
                          color: tabIndex === 2 ? "#041615" : "#7D7D7D",
                        }}
                      >
                        Pembayaran
                      </Typography>
                    }
                    {...a11yProps(2)}
                  />
                </Tabs>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <InputBase
                    size="18"
                    placeholder="Cari"
                    value={search}
                    onChange={handleSearchInput}
                    sx={{
                      ...input__text,
                      "& .MuiInputBase-input": {
                        ...input__text["& .MuiInputBase-input"],
                        height: "16px",
                        fontSize: 12,
                        borderRadius: "8px 0px 0px 8px",
                      },
                    }}
                  ></InputBase>
                  <IconButton aria-label="search-user" sx={search__icon__img}>
                    <img src="/assets/icons/search.svg" alt="search-user"></img>
                  </IconButton>
                </Box>
              </Box>
              <TabPanel
                sx={{
                  ".MuiBox-root": {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  },
                }}
                value={tabIndex}
                index={0}
              >
                <TabQueue />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <ScanFolderTab />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <TabInvoice />
              </TabPanel>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Paper
            elevation={0}
            sx={{
              ...queue__stats__container,
              marginTop: isDesktop ? "0px" : "50px",
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Paper elevation={0} component="div" sx={circular__container}>
                  <CircularProgressbar
                    value={queueStats.progress_percent}
                    styles={buildStyles({
                      rotation: -0.25,
                      pathColor: "rgba(255, 255, 255, 0.85)",
                      trailColor: "rgba(142, 224, 255, 0.25)",
                      backgroundColor: "rgba(142, 224, 255, 0.25)",
                    })}
                  ></CircularProgressbar>
                  <Box sx={queue__stats__circular__inner__container}>
                    <Typography
                      sx={queue__stats__circular__inner__text__header}
                    >
                      Panggil Antrian
                    </Typography>
                    <Typography
                      sx={queue__stats__circular__inner__text__number}
                    >
                      {queueStats.next !== null
                        ? queueStats.next?.nomor_antrian
                        : "--"}
                    </Typography>
                    <Typography sx={queue__stats__circular__inner__text__name}>
                      {queueStats.next !== null
                        ? mediplusHelper.cropText(
                            queueStats.next?.user?.nama,
                            16
                          )
                        : "----"}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={0} sx={queue__stats__footer__container}>
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Grid item xs={6}>
                      <Paper elevation={0}>
                        <Typography sx={queue__stats__footer__text__title}>
                          Antrian Saat Ini
                        </Typography>
                        <Typography sx={queue__stats__footer__text__value}>
                          {queueStats.current === null
                            ? "--"
                            : queueStats.current.nomor_antrian}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper elevation={0}>
                        <Typography sx={queue__stats__footer__text__title}>
                          Batas Antrian Hari Ini
                        </Typography>
                        <Typography sx={queue__stats__footer__text__value}>
                          {current_active_schedule.limit}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
                <Box textAlign="center">
                  <Button
                    sx={call_queue__btn}
                    variant="contained"
                    disabled={queueStats.next === null ? true : false}
                    onClick={handleNextQueueCall}
                  >
                    <Typography sx={call_queue__btn__label}>
                      {queueStats.next === null
                        ? "Panggil Antrian"
                        : queueStats.next?.status === "ANTRIAN"
                        ? "Panggil Anamnesis"
                        : "Panggil Antrian"}
                    </Typography>
                    <img
                      src="/assets/icons/arrow-square-left.svg"
                      alt="next-antrian"
                    ></img>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={schedule_switcher___container}>
            <Box sx={schedule_switcher__header__container}>
              <Typography sx={schedule_switcher__header__text}>
                Jadwal
              </Typography>
              <IconButton
                color="inherit"
                aria-label="refresh"
                edge="start"
                type="button"
                onClick={handleCalendarShow}
              >
                <img
                  src="/assets/icons/vertical-options.svg"
                  alt="refresh"
                ></img>
              </IconButton>
            </Box>
            <Box
              sx={{
                display: calendarShow ? "flex" : "none",
              }}
            >
              <Calendar
                value={selectedCalendarDate}
                onClickDay={handleCalendarChange}
                locale="id-ID"
                formatDay={(locale, date) => {
                  let ndate = moment(date);
                  if (ndate.isoWeekday() === 7) {
                    let color = "red";
                    if (
                      ndate.format("YYYY-MM-DD") ===
                      moment(selectedCalendarDate).format("YYYY-MM-DD")
                    ) {
                      color = "white";
                    }
                    return (
                      <span
                        style={{
                          color: color,
                        }}
                      >
                        {ndate.format("D")}
                      </span>
                    );
                  } else {
                    return ndate.format("D");
                  }
                }}
              ></Calendar>
            </Box>

            <Paper
              elevation={0}
              sx={{
                marginTop: "20px",
              }}
            >
              {schedule_list &&
                schedule_list.length > 0 &&
                schedule_list
                  .filter((i) => selectedCalendarDate.getDay() === i.hari)
                  .map((item, idx) => {
                    return (
                      <ScheduleBox
                        key={idx}
                        schedule={item}
                        date={selectedCalendarDate}
                        handleScheduleSwitch={handleScheduleSwitch}
                      />
                    );
                  })}
            </Paper>
          </Paper>
        </Grid>
      </Grid>
      <ScheduleChooserModal
        open={scheduleModalOpen}
        onClose={handleScheduleModalClose}
        date={selectedCalendarDate}
        handleScheduleSwitch={handleScheduleSwitch}
      />
    </>
  );
}
