import { webkit__scrollbar } from "../../../../style";

const table__info__header = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
};

const delete__btn = {
  backgroundColor: "blue",
  color: "white",
  borderRadius: "8px",
  height: "30px",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const delete__btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "10px",
  marginLeft: "5px",
};

const table__container = {
  maxHeight: "450px",
  ...webkit__scrollbar,
};

const thead__checkbox__container = {
  borderBottom: "2px solid rgba(212, 212, 212, 1)",
  borderTop: "1px solid rgba(212, 212, 212, 1)",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "14px",
  fontWeight: 600,
  color: "#7D7D7D",
  cursor: "pointer",
  top: 0,
  left: 0,
  zIndex: 100,
  position: "sticky",
  background: "fff",
};

const folder__info__container = {
  display: "flex",
  minWidth: "150px",
  maxWidth: "350px",
  minHeight: "45px",
  boxShadow: "none",
  background: "transparent",
  width: "auto",
  cursor: "pointer",
  marginRight: "5px",
  alignItems: "center",
};

const folder__info__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "10px",
  marginRight: "15px",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
};

const folder__info__text = {
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "19px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const table__cell__text = {
  fontSize: "14px",
  fontWeight: 600,
  paddingTop: "0px",
  paddingBottom: "0px",
};

const detail__btn = {
  p: 0.5,
  borderRadius: "8px",
  background: "#EFF1F9",
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  alignItems: "center",
};

const detail__btn__label = {
  fontSize: "14px",
  fontWeight: "600",
  marginLeft: "5px",
};

export {
  table__info__header,
  delete__btn,
  delete__btn__label,
  table__container,
  thead__checkbox__container,
  folder__info__container,
  folder__info__img,
  folder__info__text,
  table__cell__text,
  detail__btn,
  detail__btn__label,
};
