import React from "react";

export default function TopLeftRotation() {
  return (
    <img
      src="/assets/odontogram/rotasi-atas-kiri.png"
      alt="rotasi atas kiri"
      width="50"
      height="15"
    ></img>
  );
}
