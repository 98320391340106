import { createSlice } from "@reduxjs/toolkit";

export const medicine = createSlice({
  name: "medicine",
  initialState: {
    medicines: [],
    selected_medicine: {},
  },
  reducers: {
    updateListMedicine: (state, data) => {
      return { ...state, medicines: [...data.payload] };
    },
    updateSelectedMedicine: (state, data) => {
      return { ...state, selected_medicine: { ...data.payload } };
    },
  },
});

export const { updateListMedicine, updateSelectedMedicine } = medicine.actions;
export default medicine.reducer;
