import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import MediaModal from "../../../../components/modal/media-modal/MediaModal";
import {
  box__video__icon,
  box__img,
  box__media__container,
  box__pdf,
  box__pdf__icon,
  box__video,
} from "./style";

export default function MediaList({ medias }) {
  const [media, setMedia] = useState(null);

  const handleMediaClose = () => {
    setMedia(null);
  };

  const handleMediaModalOpen = (media) => {
    if (
      mediplusHelper.isImageMime(media.mime_type) ||
      mediplusHelper.isVideoMime(media.mime_type)
    ) {
      setMedia(media);
    } else {
      window.open(media.s3_url, "_blank");
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: 1,
        }}
      >
        {medias &&
          medias.length > 0 &&
          medias.map((item, index) => {
            if (mediplusHelper.isImageMime(item.mime_type)) {
              return (
                <Paper
                  onClick={() => {
                    handleMediaModalOpen(item);
                  }}
                  key={item.id}
                  sx={box__media__container}
                >
                  <img src={item.s3_url} style={box__img} alt="media" />
                </Paper>
              );
            } else if (mediplusHelper.isVideoMime(item.mime_type)) {
              return (
                <Paper
                  onClick={() => {
                    handleMediaModalOpen(item);
                  }}
                  key={item.id}
                  sx={box__media__container}
                >
                  <video style={box__video}>
                    <source src={item.s3_url} type="video/mp4"></source>
                  </video>
                  <LazyLoadImage
                    style={box__video__icon}
                    src="/assets/chat/play.png"
                  ></LazyLoadImage>
                </Paper>
              );
            } else {
              return (
                <Paper
                  onClick={() => {
                    handleMediaModalOpen(item);
                  }}
                  key={item.id}
                  sx={box__pdf}
                >
                  <img
                    style={box__pdf__icon}
                    src="/assets/icons/pdf.svg"
                    alt="doc"
                  ></img>
                </Paper>
              );
            }
          })}
      </Box>
      <MediaModal media={media} onClose={handleMediaClose} />
    </>
  );
}
