import { webkit__scrollbar } from "../../../style";

const search__input = {
  "&.MuiInputBase-root": {
    paddingLeft: "0px",
    position: "relative",
    width: "100%",
  },
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    padding: "10px 12px",
    height: "16px",
    fontSize: 12,
    borderRadius: "8px",
    marginRight: "1px",
  },
};

const tab__label = {
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "none",
  px: 1,
};

const search__input__container = {
  position: "relative",
  mb: "15px",
  mt: "15px",
  px: 1,
};

const search__input__icon = {
  position: "absolute",
  right: "5%",
  top: "23%",
};

const conversation__list__container = {
  px: 1,
  maxHeight: "calc(100vh - 270px)",
  overflowY: "auto",
  ...webkit__scrollbar,
};

export {
  search__input,
  tab__label,
  search__input__container,
  search__input__icon,
  conversation__list__container,
};
