import { Box } from "@mui/system";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  ClickAwayListener,
  IconButton,
  InputBase,
  Popper,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import _ from "lodash";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import EmojiPicker from "emoji-picker-react";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import uuid from "react-uuid";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import MediaModal from "../../../components/modal/media-modal/MediaModal";
import {
  chat__box__container,
  chat__box__wrapper,
  chat__input__container,
  chat__input__wrapper,
  input__btn,
  input__temlate__btn,
  message__input,
  submit__btn,
} from "./style";
import TextMessage from "./text-message/TextMessage";
import DateSeparator from "./date-separator/DateSeparator";
import ImageMessage from "./image-message/ImageMessage";
import VideoMessage from "./video-message/VideoMessage";
import DocumentMessage from "./document-message/DocumentMessage";
import TemplateChatPopper from "./popper/TemplateChatPopper";
import TemplateChatModal from "./modal/TemplateChatModal";
import UploadFileModal from "./modal/UploadFileModal";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";

export default function Messages() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const chat_conversation = useSelector((state) => state.chat_conversation);
  const chat_update = useSelector((state) => state.chat_update.value);
  const chat_read = useSelector((state) => state.chat_read.value);

  const [messages, setMessages] = useState([]);
  const [hasMoreData, setHasMoreData] = React.useState(false);
  const [nextUrl, setNextUrl] = React.useState(null);
  const [firstLoad, setFirstLoad] = React.useState(true);

  const [fileUploadModalOpen, setFileUploadModalOpen] = React.useState(false);
  const [popupEmojiOpen, setPopupEmojiOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const emojiPopperRef = useRef();
  const textInputRef = useRef();
  const [media, setMedia] = useState(null);

  const templateChatRef = useRef();
  const [templateChatPopperOpen, setTemplateChatPopperOpen] = useState(false);
  const [templateChatModalOpen, setTemplateChatModalOpen] = useState(false);

  const readAllMessage = async () => {
    if (!_.isEmpty(chat_conversation)) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/chat/conversations/${chat_conversation.id}/read`,
          {},
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
      }
    }
  };

  const fetchData = (url = null, type = "DEFAULT", cancelToken) => {
    if (!_.isEmpty(chat_conversation)) {
      axios
        .get(
          url
            ? url
            : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/chat/conversations/${chat_conversation.id}`,
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
            cancelToken: cancelToken,
          }
        )
        .then((response) => {
          if (url) {
            setMessages((prev) => [...prev, ...response.data.data]);
          } else {
            if (type === "DEFAULT") {
              setMessages(() => [...response.data.data]);
            } else {
              response.data.data.forEach((i) => {
                setMessages((prev) => {
                  let fduplicate = prev.find((message) => message.id === i.id);
                  if (fduplicate === undefined) {
                    return [i, ...prev];
                  } else {
                    return prev.map((message) =>
                      message.id === i.id ? { ...i } : message
                    );
                  }
                });
              });
            }
          }
          if (response.data.next_page_url !== null) {
            setHasMoreData(true);
            setNextUrl(response.data.next_page_url);
          } else {
            setHasMoreData(false);
            setNextUrl(null);
          }
        })
        .catch(function (error) {
          console.log(error);
          Sentry.captureException(error);
        })
        .finally(() => {
          setFirstLoad(false);
        });
    }
  };

  const sendMessage = async (file = null) => {
    setTemplateChatPopperOpen(false);
    if (!file) {
      if (textInputRef.current.value.length < 1) {
        enqueueSnackbar("silahkan isi pesan anda");
        return;
      }
    }
    let tempData = {
      id: null,
      temp_id: uuid().toString(),
      attachment: null,
      created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      is_seen: false,
      senderable_id: user.type === "ASSISTANT" ? user.head_doctor_id : user.id,
      senderable_type: user.model,
      temporary: true,
      failed: false,
      senderable: {
        photo_profile: user.photo_profile,
      },
    };
    if (file) {
      tempData = {
        ...tempData,
        content: "",
        attachment: {
          file_name: file.name,
          size: file.size,
          mime_type: file.type,
        },
      };
    } else {
      tempData = {
        ...tempData,
        content: textInputRef.current.value ? textInputRef.current.value : "  ",
      };
    }

    textInputRef.current.value = "";
    try {
      setMessages((prev) => [tempData, ...prev]);
      let formData;
      if (file) {
        formData = new FormData();
        formData.append("attachment", file);
        formData.append("content", "   ");
        formData.append("conversation_id", chat_conversation.id);
      } else {
        formData = {
          conversation_id: chat_conversation.id,
          content: tempData.content,
        };
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/chat/messages`,
        formData,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      setMessages((prev) => {
        let isAlreadyLoaded = prev.find((i) => i.id === response.data.id);
        if (isAlreadyLoaded === undefined) {
          return prev.map((i) =>
            i.temp_id === tempData.temp_id ? { ...response.data } : i
          );
        } else {
          return prev.filter((i) => i.temp_id !== tempData.temp_id);
        }
      });
    } catch (e) {
      console.log(e);
      setMessages((prev) =>
        prev.map((i) =>
          i.temp_id === tempData.temp_id ? { ...i, failed: true } : i
        )
      );
      Sentry.captureException(e);
    }
  };

  const isSenderMe = (senderable_id, senderable_type) => {
    if (user.type === "ASSISTANT") {
      if (
        senderable_id === user.head_doctor_id &&
        senderable_type === user.model
      ) {
        return true;
      }
      return false;
    } else {
      if (senderable_id === user.id && senderable_type === user.model) {
        return true;
      }
      return false;
    }
  };

  const displayPicture = (currentMessage, index, allMessage) => {
    if (index + 1 === allMessage.length) {
      return true;
    } else {
      if (
        currentMessage.senderable_id === allMessage[index + 1].senderable_id &&
        currentMessage.senderable_type ===
          allMessage[index + 1].senderable_type &&
        moment(currentMessage.created_at).format("YYYY-MM-DD") ===
          moment(allMessage[index + 1].created_at).format("YYYY-MM-DD")
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const displayDateSeparator = (currentMessage, index, allMessage) => {
    if (index + 1 === allMessage.length) {
      return true;
    } else {
      if (
        moment(currentMessage.created_at).format("YYYY-MM-DD") ===
        moment(allMessage[index + 1].created_at).format("YYYY-MM-DD")
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleEmojiClick = (emoji) => {
    textInputRef.current.value = `${textInputRef.current.value} ${emoji.emoji}`;
  };

  const handleTemplateClick = (template) => {
    textInputRef.current.value = `${textInputRef.current.value}${template}`;
  };

  const handleEmojiClickAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupEmojiOpen) setPopupEmojiOpen(false);
    }
  };

  const handleChatTemplatePopperAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (templateChatPopperOpen) setTemplateChatPopperOpen(false);
    }
  };

  const handleFileChange = (file) => {
    if (file.length > 0) {
      setFiles((prev) => [...file, ...prev]);
    }
  };

  const handleFileDelete = (index) => {
    setFiles((prev) =>
      prev.filter((item, idx) => {
        return idx !== index;
      })
    );
  };

  const handleFileUploadModalClose = () => {
    setFileUploadModalOpen(false);
  };

  const handleFileSend = () => {
    if (files.length < 1) {
      enqueueSnackbar("silahkan pilih file terlebih dahulu");
      return;
    }
    let tempFileData = [...files];
    setFiles([]);
    tempFileData.forEach((file) => {
      sendMessage(file);
    });
    setFileUploadModalOpen(false);
  };

  useEffect(() => {
    setFirstLoad(true);
    fetchData();
    readAllMessage();
  }, [chat_conversation]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    let myTimer = setTimeout(() => {
      if (!firstLoad) {
        fetchData(null, "PREVENTDUPLICATE", source.token);
        readAllMessage();
      }
    }, 1500);
    const onFocus = () => {
      if (!firstLoad) {
        fetchData(null, "PREVENTDUPLICATE", source.token);
        readAllMessage();
      }
    };
    window.addEventListener("focus", onFocus);
    //hanya render request terakhir
    return () => {
      source.cancel();
      clearInterval(myTimer);
      window.removeEventListener("focus", onFocus);
    };
  }, [chat_update, chat_read]);

  return (
    <>
      <Box
        sx={{
          display: _.isEmpty(chat_conversation) ? "none" : "block",
        }}
      >
        <Box
          sx={{
            ...chat__box__wrapper,
            minHeight: isDesktop
              ? "calc(100vh - 160px)"
              : "calc(100vh - 290px)",
            maxHeight: isDesktop
              ? "calc(100vh - 160px)"
              : "calc(100vh - 290px)",
          }}
        >
          <Box
            id="chatContainer"
            sx={{
              ...chat__box__container,
              minHeight: isDesktop
                ? "calc(100vh - 200px)"
                : "calc(100vh - 330px)",
              maxHeight: isDesktop
                ? "calc(100vh - 200px)"
                : "calc(100vh - 330px)",
            }}
          >
            <InfiniteScroll
              scrollableTarget="chatContainer"
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true}
              dataLength={messages.length}
              next={() => {
                fetchData(nextUrl);
              }}
              hasMore={hasMoreData}
            >
              {messages &&
                messages.length > 0 &&
                messages.map((i, idx, arr) => {
                  if (i.attachment === null) {
                    return (
                      <Fragment key={`ch_${idx}`}>
                        <TextMessage
                          text={i.content}
                          time={moment(i.created_at).format("HH:mm")}
                          align={
                            isSenderMe(i.senderable_id, i.senderable_type)
                              ? "right"
                              : "left"
                          }
                          avatarImageUrl={i.senderable?.photo_profile ?? null}
                          showAvatarImage={displayPicture(i, idx, arr)}
                          sending={i.temporary ? true : false}
                          failed={i.failed ? true : false}
                          isRead={i.is_seen ? true : false}
                        ></TextMessage>
                        {displayDateSeparator(i, idx, arr) ? (
                          <DateSeparator date={i.created_at} />
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    );
                  } else {
                    if (mediplusHelper.isImageMime(i.attachment.mime_type)) {
                      return (
                        <Fragment key={`ch_${idx}`}>
                          <ImageMessage
                            onClick={() => {
                              setMedia(i.attachment);
                            }}
                            align={
                              isSenderMe(i.senderable_id, i.senderable_type)
                                ? "right"
                                : "left"
                            }
                            media={i.attachment}
                            time={moment(i.created_at).format("HH:mm")}
                            avatarImageUrl={i.senderable?.photo_profile ?? null}
                            showAvatarImage={displayPicture(i, idx, arr)}
                            sending={i.temporary ? true : false}
                            failed={i.failed ? true : false}
                            isRead={i.is_seen ? true : false}
                          ></ImageMessage>
                          {displayDateSeparator(i, idx, arr) ? (
                            <DateSeparator date={i.created_at} />
                          ) : (
                            ""
                          )}
                        </Fragment>
                      );
                    } else if (
                      mediplusHelper.isVideoMime(i.attachment.mime_type)
                    ) {
                      return (
                        <Fragment key={`ch_${idx}`}>
                          <VideoMessage
                            onClick={() => {
                              setMedia(i.attachment);
                            }}
                            align={
                              isSenderMe(i.senderable_id, i.senderable_type)
                                ? "right"
                                : "left"
                            }
                            media={i.attachment}
                            time={moment(i.created_at).format("HH:mm")}
                            avatarImageUrl={i.senderable?.photo_profile ?? null}
                            showAvatarImage={displayPicture(i, idx, arr)}
                            sending={i.temporary ? true : false}
                            failed={i.failed ? true : false}
                            isRead={i.is_seen ? true : false}
                          ></VideoMessage>
                          {displayDateSeparator(i, idx, arr) ? (
                            <DateSeparator date={i.created_at} />
                          ) : (
                            ""
                          )}
                        </Fragment>
                      );
                    } else {
                      return (
                        <Fragment key={`ch_${idx}`}>
                          <DocumentMessage
                            align={
                              isSenderMe(i.senderable_id, i.senderable_type)
                                ? "right"
                                : "left"
                            }
                            media={i.attachment}
                            time={moment(i.created_at).format("HH:mm")}
                            avatarImageUrl={i.senderable?.photo_profile ?? null}
                            showAvatarImage={displayPicture(i, idx, arr)}
                            sending={i.temporary ? true : false}
                            failed={i.failed ? true : false}
                            isRead={i.is_seen ? true : false}
                          ></DocumentMessage>
                          {displayDateSeparator(i, idx, arr) ? (
                            <DateSeparator date={i.created_at} />
                          ) : (
                            ""
                          )}
                        </Fragment>
                      );
                    }
                  }
                })}
            </InfiniteScroll>
          </Box>

          <Box sx={chat__input__wrapper}>
            <Box sx={chat__input__container}>
              <InputBase
                sx={message__input}
                inputRef={textInputRef}
                placeholder="Masukkan pesan anda"
                multiline
                maxRows={3}
              ></InputBase>
              <IconButton
                data-group="popper-toggler"
                ref={templateChatRef}
                onClick={(e) => {
                  setTemplateChatPopperOpen(!templateChatPopperOpen);
                  setFileUploadModalOpen(false);
                  setPopupEmojiOpen(false);
                }}
                aria-label="clip"
                sx={input__temlate__btn}
              >
                <img
                  width={25}
                  height={25}
                  src="/assets/icons/quote-up-square.svg"
                  alt="template"
                ></img>
              </IconButton>
              <IconButton
                onClick={(e) => {
                  setFileUploadModalOpen(true);
                  setTemplateChatPopperOpen(false);
                  setPopupEmojiOpen(false);
                }}
                aria-label="clip"
                sx={input__temlate__btn}
              >
                <img
                  width={25}
                  height={25}
                  src="/assets/icons/paperclip.svg"
                  alt="attachment"
                ></img>
              </IconButton>
              <IconButton
                data-group="popper-toggler"
                ref={emojiPopperRef}
                onClick={(e) => {
                  setPopupEmojiOpen(!popupEmojiOpen);
                  setFileUploadModalOpen(false);
                  setTemplateChatPopperOpen(false);
                }}
                aria-label="clip"
                sx={input__btn}
              >
                <img
                  width={25}
                  height={25}
                  src="/assets/icons/emoji-happy.svg"
                  alt="emoji"
                ></img>
              </IconButton>
            </Box>
            <IconButton
              onClick={() => {
                sendMessage();
              }}
              aria-label="clip"
              sx={submit__btn}
            >
              <img
                width={23}
                height={23}
                src="/assets/icons/send-white.svg"
                alt="send"
              ></img>
            </IconButton>
          </Box>
        </Box>
        <MediaModal media={media} onClose={() => setMedia(null)} />
      </Box>

      <ClickAwayListener onClickAway={handleEmojiClickAway}>
        <Popper
          data-group="popper-element"
          open={popupEmojiOpen}
          anchorEl={emojiPopperRef.current}
          placement={"top"}
          sx={{
            zIndex: 1500,
          }}
        >
          <Box>
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </Box>
        </Popper>
      </ClickAwayListener>

      <TemplateChatPopper
        open={templateChatPopperOpen}
        setOpen={setTemplateChatPopperOpen}
        anchorEl={templateChatRef?.current ?? ""}
        setTemplateChatModalOpen={setTemplateChatModalOpen}
        handleClick={handleTemplateClick}
        handleClickAway={handleChatTemplatePopperAway}
      />

      <TemplateChatModal
        open={templateChatModalOpen}
        onClose={() => {
          setTemplateChatModalOpen(false);
        }}
      />

      <UploadFileModal
        open={fileUploadModalOpen}
        onClose={handleFileUploadModalClose}
        files={files}
        handleFileChange={handleFileChange}
        handleFileDelete={handleFileDelete}
        handleFileSend={handleFileSend}
      />
    </>
  );
}
