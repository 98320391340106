import { webkit__scrollbar } from "../../../../style";

const category__header__container = {
  marginTop: "20px",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
};

const category__header__text = {
  fontSize: "16px",
  fontWeight: 700,
};

const category__header__text__loadmore = {
  fontSize: "12px",
  fontWeight: 400,
  color: "rgba(0, 150, 255, 1)",
  cursor: "pointer",
};

const category__navigator__btn = {
  backgroundColor: "blue",
  height: "38px",
  color: "white",
  borderRadius: "50%",
  marginBottom: "5px",
  background: "none",
  border: "1px solid #C5C5C5",
  px: 0,
  "&:hover": {
    background: "none",
  },
};

const category__pills = {
  height: "38px",
  borderRadius: "30px",
  marginBottom: "5px",
  color: "black",
  backgroundColor: "white",
  border: "1px solid #C5C5C5",
  "&:hover": {
    backgroundColor: "rgba(0, 150, 255, 0.24)",
    color: "black",
  },
};

const category__pills__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "5px",
  marginRight: "0px",
  marginLeft: "5px",
};

const selected__category__container = {
  marginTop: "10px",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
};

const selected__category__text = {
  fontSize: "16px",
  fontWeight: 700,
};

const available__product__text = {
  fontSize: "12px",
  fontWeight: 400,
};

const product__list__container = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "start",

  maxHeight: "350px",
  overflowY: "auto",
  background: "#FFF",
  ...webkit__scrollbar,
};

const product__list__item__container = {
  flexBasis: "20%",
  background: "#FFF",
  marginBottom: "10px",
  cursor: "pointer",
  position: "relative",
  boxShadow: "4px 4px rgba(197, 197, 197, 0.2)",
};

const product__list__item__info__container = {
  mt: 2,
  mb: 1,
  px: 2,
};

const product__list__item__info__text = {
  fontSize: "14px",
  fontWeight: "600",
};

export {
  category__header__container,
  category__header__text,
  category__header__text__loadmore,
  category__navigator__btn,
  category__pills,
  category__pills__label,
  selected__category__container,
  selected__category__text,
  available__product__text,
  product__list__container,
  product__list__item__container,
  product__list__item__info__container,
  product__list__item__info__text,
};
