import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import {
  circular__progress__indicator__img,
  circular__progress__wrapper,
  redirect__btn,
  redirect__btn__label,
  redirect__btn__wrapper,
  usage__info__container,
} from "./style";
import * as Sentry from "@sentry/react";

export default function StorageUsage() {
  const history = useHistory();
  const { user } = useAuth();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const scan_folder_update = useSelector(
    (state) => state.scan_folder_update.value
  );

  const [usage, setUsage] = useState({
    usage_percent: 0,
    empty: 0,
    limit: 0,
  });

  const loadData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/storage/usage`, {
        params: {
          clinic: current_active_schedule.clinic_id,
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        let data = response.data;
        let find = data.find((i) => {
          return i.clinic_id === current_active_schedule.clinic_id;
        });
        if (find !== undefined) {
          let usagePercentage = (find.clinic_usage / find.limit) * 100;
          if (usagePercentage > 100) {
            usagePercentage = 100;
          }
          let emptyStorage = find.limit - find.clinic_usage;
          if (emptyStorage < 0) {
            emptyStorage = 0;
          }

          setUsage((prev) => ({
            ...prev,
            empty: emptyStorage,
            limit: find.limit,
            usage_percent: usagePercentage,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [current_active_schedule, scan_folder_update]);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          sx={{
            paddingLeft: "40px",
          }}
        >
          Penyimpanan Saya
        </Typography>
        <Box sx={circular__progress__wrapper}>
          <CircularProgressbar
            value={usage.usage_percent}
            circleRatio={0.5}
            strokeWidth={16}
            styles={buildStyles({
              strokeLinecap: "butt",
              rotation: -0.25,
              pathColor: "#FF0091",
              trailColor: "#F2F2F2",
              backgroundColor: "rgba(142, 224, 255, 0.25)",
            })}
          ></CircularProgressbar>
        </Box>
        <Box sx={usage__info__container}>
          <Box>
            <Typography
              component={"span"}
              sx={{
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              {mediplusHelper.fileSizeIEC(usage.empty)}
            </Typography>
            <Typography>Space Kosong</Typography>
          </Box>
          <Box>
            <Typography
              component={"span"}
              sx={{
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              {mediplusHelper.fileSizeIECRound(usage.limit)}
            </Typography>
            <Typography>Total Space</Typography>
          </Box>
        </Box>
        <img
          src="/assets/icons/indicator.svg"
          alt="indicator"
          style={{
            ...circular__progress__indicator__img,
            transform: `rotate(${-160 + (usage.usage_percent * 220) / 100}deg)`,
          }}
        ></img>
        <Box sx={redirect__btn__wrapper}>
          <Button
            onClick={() => {
              history.push("/penyimpanan");
            }}
            sx={redirect__btn}
            variant="contained"
          >
            <Typography sx={redirect__btn__label}>Penyimpanan</Typography>
          </Button>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Melihat jumlah berkas yang terpakai
          </Typography>
        </Box>
      </Paper>
    </>
  );
}
