import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import UserList from "./user-list/UserList";
import UserDetail from "./user-detail/UserDetail";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import UserRecordDetail from "./user-record-detail/UserRecordDetail";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

export default function RekamMedisTimeline({ searchField, searchValue }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const timeline_group_mode = useSelector((state) => state.timeline_group_mode);
  const [nextStepOpen, setNextStepOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userRecordDetail, setUserRecordDetail] = useState({});
  const [userListLoading, setUserListLoading] = useState(false);

  const loadUserList = () => {
    setUserListLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/timeline`, {
        params: {
          clinic: current_active_schedule.clinic_id,
          "search-field": searchField,
          "search-value": searchValue,
          head: user.head_doctor_id,
          "timeline-group-mode": timeline_group_mode,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setUserList(() => [...response.data]);
        setUserListLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
        setUserListLoading(false);
      });
  };

  const handleUserClick = (key) => {
    let usr = userList.find((item, k) => key === k);
    if (usr !== undefined) {
      setSelectedUser(usr);
    } else {
      enqueueSnackbar("data not found");
    }
  };

  const handleBack = () => {
    setNextStepOpen(false);
  };

  const handleTimelineItemClick = (medicalRecord) => {
    setNextStepOpen(true);
    setUserRecordDetail(medicalRecord);
  };

  useEffect(() => {
    loadUserList();
  }, [current_active_schedule, searchField, searchValue, timeline_group_mode]);

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          marginTop: "15px",
        }}
      >
        <Grid
          item
          xs={nextStepOpen ? 0 : 3}
          sx={{
            display: nextStepOpen ? "none" : "block",
          }}
        >
          <UserList
            list={userList}
            handleUserClick={handleUserClick}
            currentSelectedUser={selectedUser}
            userListLoading={userListLoading}
          ></UserList>
        </Grid>
        <Grid
          item
          xs={nextStepOpen ? 7 : 9}
          sx={{
            display: selectedUser ? "block" : "none",
          }}
        >
          <UserDetail
            currentSelectedUser={selectedUser}
            nextStepOpen={nextStepOpen}
            handleBack={handleBack}
            onTimelineItemClick={handleTimelineItemClick}
          />
        </Grid>
        <Grid
          item
          xs={nextStepOpen ? 5 : 0}
          sx={{
            display: nextStepOpen ? "block" : "none",
            paddingLeft: "5px",
          }}
        >
          <UserRecordDetail medicalRecord={userRecordDetail} />
        </Grid>
      </Grid>
    </>
  );
}
