import { webkit__scrollbar } from "../../../../../style";

const diagnose__modal__container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

const diagnose__checkbox__container = {
  maxHeight: "300px",
  minHeight: "300px",
  overflowY: "auto",
  marginTop: "10px",
  ...webkit__scrollbar,
};

export { diagnose__modal__container, diagnose__checkbox__container };
