const chat__container = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  minWidth: "100%",
  justifyContent: "space-between",
};

const avatar__container = {
  width: "44px",
  height: "44px",
  background: "none",
};

const avatar__img = {
  borderRadius: "50%",
  width: "44px",
  height: "44px",
};

const chat__text__wrapper = {
  width: "calc(100% - 88px)",
  background: "none",
};

const chat__text__container = {
  pt: 0.5,
  px: 1,
  display: "flex",
  justifyContent: "start",
};

const chat__text__bubble = {
  width: "fit-content",
  maxWidth: "80%",
  px: 1,
  background: "#EFF1F9",
  borderRadius: "0px 8px 8px 8px",
};

const chat__text = {
  fontSize: "14px",
  fontWeight: 500,
  pt: 0.5,
  pl: 0.5,
  minWidth: "40px",
  display: "inline-block",
  whiteSpace: "pre-wrap",
};

const chat__text__footer__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignContent: "center",
  alignItems: "center",
  gap: "2px",
  pl: 0.5,
};

const chat__text__footer__time = {
  fontSize: "10px",
  fontWeight: 400,
  textAlign: "right",
};

export {
  chat__container,
  avatar__container,
  avatar__img,
  chat__text__wrapper,
  chat__text__container,
  chat__text,
  chat__text__bubble,
  chat__text__footer__container,
  chat__text__footer__time,
};
