import { webkit__scrollbar } from "../../style";

const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "15px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

const search__btn = {
  backgroundColor: "blue",
  borderRadius: "0px 8px 8px 0px",
  height: "38px",
};

const table__container = {
  maxHeight: 560,
  ...webkit__scrollbar,
};

const table__cell__text = {
  fontSize: "14px",
  fontWeight: 600,
  paddingTop: "0px",
  paddingBottom: "0px",
};

const table__star__btn = {
  marginLeft: "5px",
  borderRadius: "8px",
};

const table__star__icon__wrapper = {
  background: "#EFF1F9",
  padding: "3px 3px 1px 3px",
  borderRadius: "8px",
};

const bookmark__header__text = {
  fontWeight: 700,
  fontSize: "16px",
  mb: 1,
  ml: 2,
  pt: 1,
};

const bookmark__list__container = {
  px: 2,
  maxHeight: "500px",
  overflowY: "auto",
  ...webkit__scrollbar,
};

const bookmark__list__item__box = {
  position: "relative",
  borderBottom: "1px solid #D5D5D5",
  height: "auto",
  pb: 1,
  mb: 1,
};

const bookmark__list__item__star__icon = {
  position: "absolute",
  right: 0,
  top: 0,
  cursor: "pointer",
};

export {
  header__container,
  header__h1,
  search__btn,
  table__container,
  table__cell__text,
  table__star__btn,
  table__star__icon__wrapper,
  bookmark__header__text,
  bookmark__list__container,
  bookmark__list__item__box,
  bookmark__list__item__star__icon,
};
