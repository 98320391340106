import { Box, Button, Snackbar, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  footer__container,
  header__container,
  header__h1,
  riwayat_medis__btn,
  riwayat_medis__btn__label,
  submit__btn,
  submit__btn__label,
  tab__label,
  tab__switch__btn,
  tab__switch__btn__label,
} from "./style";
import { useAuth } from "../../../../../providers/AuthProvider";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import axios from "axios";
import authHeader from "../../../../../services/authHeader";
import SectionLoading from "../../../../components/loading/SectionLoading";
import LoadError from "../../../../components/error/LoadError";
import a11yProps from "../../../../components/tab/a11yProps";
import RiwayatRekamMedisModal from "./riwayat-rekam-medis-modal/RiwayatRekamMedisModal";
import TabInformasiPasien from "./tab-informasi-pasien/TabInformasiPasien";
import TabDataMedis from "./tab-data-medis/TabDataMedis";
import TabDataSpesialis from "./tab-data-spesialis/TabDataSpesialis";
import TabSoap from "./tab-soap/TabSoap";
import TabResepIer from "./tab-resep-ier/TabResepIer";
import TabInternal from "./tab-internal/TabInternal";
import TabHasilPemeriksaan from "./tab-hasil-pemeriksaan/TabHasilPemeriksaan";
import Odontogram from "./odontogram/components/Odontogram";
import { useMemo } from "react";
import * as Sentry from "@sentry/react";
import odontogramHelper from "./odontogram/components/helper";
import _ from "lodash";
import {
  snackbar__content__container,
  snackbar__content__text,
} from "../main/table/style";
import { LegendToggleRounded } from "@mui/icons-material";
import LinkToScanFolderModal from "../link-to-scanfolder-modal/LinkToScanFolderModal";

export default function RekamMedisEditor(props) {
  const { user } = useAuth();
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const timeline_group_mode = useSelector((state) => state.timeline_group_mode);
  const history = useHistory();
  const location = useLocation();
  const route = useRouteMatch();
  const qs = mediplusHelper.parseQuery(location.search);

  const [loadingData, setLoadingData] = useState(true);
  const [loadDataError, setLoadDataError] = useState(false);
  const [loadDataErrorCode, setLoadDataErrorCode] = useState(0);
  const [referenceUser, setReferenceUser] = useState(
    qs?.ref ? parseInt(qs?.ref) : null
  );
  const [referenceType, setReferenceType] = useState(
    qs?.type && qs?.type === "MED" ? "MEDICALRECORD" : "USER"
  );

  const [selectedMedicalRecordId, setSelectedMedicalRecordId] = useState(null);
  const [riwayatRekamMedisOpen, setRiwayatRekamMedisOpen] = useState(false);

  const [tabIndex, setTabIndex] = useState(qs?.tab ? parseInt(qs?.tab) : 0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isPasienBaru, setIsPasienBaru] = useState(false);

  const [odontogramView, setOdontogramView] = useState(false);
  const [isLinkScanFolderModalOpen, setIsLinkScanFolderModalOpen] =
    useState(false);

  //snackbar
  const [createInvoiceConfirmationOpen, setCreateInvoiceConfirmationOpen] =
    useState(false);

  const [medicalRecord, setMedicalRecord] = useState({});
  const [form, setForm] = useState({
    clinic_id: "",
    user_id: "",
    tanggal_periksa: moment().format("YYYY-MM-DD"),
    reminder: "",
    nomor_rekam_medis: "",
    nama: "",
    ktp: "",
    alamat: "",
    jenis_kelamin: "WANITA",
    nomor: "",
    tanggal_lahir: "",
    nama_keluarga: "",
    nomor_keluarga: "",
    pendidikan: "",
    agama: "",
    pekerjaan: "",
    catatan_spesialis: "",
    tinggi_badan: "",
    berat_badan: "",
    lingkar_kepala: "",
    sistol: "",
    diastol: "",
    bpm: "",
    temp: "",
    spo2: "",
    rr: "",
    golongan_darah: "",
    riwayat_penyakit: "",
    riwayat_alergi: "",
    tanggal_menstruasi_terakhir: "",
    tanggal_perkiraan_lahir: "",
    usia_kehamilan: "",
    gravida: "",
    fetuces: "",
    para: "",
    aborta: "",
    ectopic: "",
    subjektif: "",
    objektif: "",
    assesment: "",
    plan: "",
    obat: "",
    resep: [],
    resep_racikan: [],
    implementasi: "",
    evaluasi: "",
    revisi: "",
    diagnosa: [],
    catatan: "",
    file_internal: [],
    file_scan: [],
    bmi: 0,
    bsa: 0,
    odontogram: null,
    occlusi: "NORMALBITE",
    torus_palatinus: "TIDAKADA",
    torus_mandibularis: "TIDAKADA",
    palatum: "DALAM",
    diastema: "",
    gigi_anomali: "",
    catatan_odontogram: "",
    ekstraksi_odontogram: "",
    jumlah_foto_odontogram: "",
    jumlah_foto_rontgen_odontogram: "",
    foto_opsi_odontogram: odontogramHelper.parseOpsiFoto("", 2),
    rontgen_opsi_odontogram: odontogramHelper.parseOpsiFoto("", 4),
    kategori_bmi: "", //tidak disave di db
    usia: "- Tahun", //tidak disave di db
    pasien_baru: false,
  });

  const memoizedOdontogramData = useMemo(
    () => ({
      occlusi: form.occlusi,
      torus_palatinus: form.torus_palatinus,
      torus_mandibularis: form.torus_mandibularis,
      palatum: form.palatum,
      diastema: form.diastema,
      gigi_anomali: form.gigi_anomali,
      catatan_odontogram: form.catatan_odontogram,
      ekstraksi_odontogram: form.ekstraksi_odontogram,
      jumlah_foto_odontogram: form.jumlah_foto_odontogram,
      jumlah_foto_rontgen_odontogram: form.jumlah_foto_rontgen_odontogram,
      foto_opsi_odontogram: form.foto_opsi_odontogram,
      rontgen_opsi_odontogram: form.rontgen_opsi_odontogram,
    }),
    [
      form.occlusi,
      form.torus_palatinus,
      form.torus_mandibularis,
      form.palatum,
      form.diastema,
      form.gigi_anomali,
      form.catatan_odontogram,
      form.ekstraksi_odontogram,
      form.jumlah_foto_odontogram,
      form.jumlah_foto_rontgen_odontogram,
      form.foto_opsi_odontogram,
      form.rontgen_opsi_odontogram,
    ]
  );

  const parseData = (medicalRecord) => {
    return {
      pasien_baru: false,
      clinic_id: medicalRecord?.clinic_id ?? "",
      user_id: medicalRecord?.user?.id ?? "",
      tanggal_periksa:
        medicalRecord?.tanggal_periksa ?? moment().format("YYYY-MM-DD"),
      reminder: medicalRecord?.reminder ?? "",
      nomor_rekam_medis: medicalRecord?.nomor_rekam_medis ?? "",

      nama: medicalRecord?.user?.nama
        ? medicalRecord?.user?.nama
        : medicalRecord?.record_temporary_patient_data?.nama ?? "",
      ktp: medicalRecord?.user?.ktp
        ? medicalRecord?.user?.ktp
        : medicalRecord?.ktp ?? "",
      alamat: medicalRecord?.user?.alamat
        ? medicalRecord?.user?.alamat
        : medicalRecord?.record_temporary_patient_data?.alamat ?? "",
      jenis_kelamin: medicalRecord?.user?.jenis_kelamin
        ? medicalRecord?.user?.jenis_kelamin
        : medicalRecord?.record_temporary_patient_data?.jenis_kelamin ?? "",
      nomor: medicalRecord?.user?.nomor
        ? medicalRecord?.user?.nomor
        : medicalRecord?.record_temporary_patient_data?.nomor ?? "",
      tanggal_lahir: medicalRecord?.user?.tanggal_lahir
        ? medicalRecord?.user?.tanggal_lahir
        : medicalRecord?.record_temporary_patient_data?.tanggal_lahir ?? "",

      nama_keluarga: medicalRecord?.nama_keluarga ?? "",
      nomor_keluarga: medicalRecord?.nomor_keluarga ?? "",
      pendidikan: medicalRecord?.pendidikan ?? "",
      agama: medicalRecord?.agama ?? "",
      pekerjaan: medicalRecord?.pekerjaan ?? "",
      catatan_spesialis: medicalRecord?.catatan_spesialis ?? "",
      tinggi_badan: medicalRecord?.record_physical?.tinggi_badan ?? 0,
      berat_badan: medicalRecord?.record_physical?.berat_badan ?? 0,
      lingkar_kepala: medicalRecord?.record_physical?.lingkar_kepala ?? 0,
      sistol: medicalRecord?.record_blood_pressure?.sistol ?? 0,
      diastol: medicalRecord?.record_blood_pressure?.diastol ?? 0,
      bpm: medicalRecord?.record_blood_pressure?.bpm ?? 0,
      temp: medicalRecord?.record_vital_sign?.temp ?? 0,
      spo2: medicalRecord?.record_vital_sign?.spo2 ?? 0,
      rr: medicalRecord?.record_vital_sign?.rr ?? 0,
      golongan_darah:
        medicalRecord?.record_medical_history?.golongan_darah ?? "",
      riwayat_penyakit:
        medicalRecord?.record_medical_history?.riwayat_penyakit ?? "",
      riwayat_alergi:
        medicalRecord?.record_medical_history?.riwayat_alergi ?? "",
      tanggal_menstruasi_terakhir:
        medicalRecord?.record_obgyn?.tanggal_menstruasi_terakhir ?? "",
      tanggal_perkiraan_lahir: medicalRecord?.record_obgyn
        ?.tanggal_menstruasi_terakhir
        ? mediplusHelper.estimasiTanggalKelahiran(
            medicalRecord?.record_obgyn?.tanggal_menstruasi_terakhir
          )
        : "",
      usia_kehamilan: mediplusHelper.usiaKehamilan(
        medicalRecord?.record_obgyn?.tanggal_menstruasi_terakhir ?? "",
        medicalRecord?.tanggal_periksa ?? moment().format("YYYY-MM-DD")
      ),
      gravida: medicalRecord?.record_gestational?.gravida ?? 0,
      fetuces: medicalRecord?.record_gestational?.fetuces ?? 0,
      para: medicalRecord?.record_gestational?.para ?? 0,
      aborta: medicalRecord?.record_gestational?.aborta ?? 0,
      ectopic: medicalRecord?.record_gestational?.ectopic ?? 0,
      subjektif: medicalRecord?.record_soap?.subjektif ?? "",
      objektif: medicalRecord?.record_soap?.objektif ?? "",
      assesment: medicalRecord?.record_soap?.assesment ?? "",
      plan: medicalRecord?.record_soap?.plan ?? "",
      obat: medicalRecord?.record_soap?.obat ?? "",
      resep: mediplusHelper.hasJsonStructure(
        medicalRecord?.record_recipe?.resep
      )
        ? JSON.parse(medicalRecord?.record_recipe?.resep)
        : medicalRecord?.record_recipe?.resep?.length > 0
        ? medicalRecord?.record_recipe?.resep
        : [],
      resep_racikan: mediplusHelper.hasJsonStructure(
        medicalRecord?.record_recipe?.resep_racikan
      )
        ? JSON.parse(medicalRecord?.record_recipe?.resep_racikan)
        : [],
      implementasi: medicalRecord?.record_recipe?.implementasi ?? "",
      evaluasi: medicalRecord?.record_recipe?.evaluasi ?? "",
      revisi: medicalRecord?.record_recipe?.revisi ?? "",
      diagnosa: medicalRecord?.record_diagnose?.diagnosa ?? [],
      catatan: medicalRecord?.record_internal?.catatan ?? "",
      file_internal: [],
      file_scan: [],
      bmi: mediplusHelper.calculateBMI(
        medicalRecord?.record_physical?.tinggi_badan ?? 0,
        medicalRecord?.record_physical?.berat_badan ?? 0
      ),
      bsa: mediplusHelper.calculateBSA(
        medicalRecord?.record_physical?.tinggi_badan ?? 0,
        medicalRecord?.record_physical?.berat_badan ?? 0
      ),
      kategori_bmi: mediplusHelper.BMICategory(
        mediplusHelper.calculateBMI(
          medicalRecord.record_physical?.tinggi_badan ?? 0,
          medicalRecord.record_physical?.berat_badan ?? 0
        )
      ),
      usia: mediplusHelper.getAge(
        medicalRecord?.user?.tanggal_lahir
          ? medicalRecord?.user?.tanggal_lahir
          : medicalRecord?.record_temporary_patient_data?.tanggal_lahir ?? "",
        medicalRecord?.tanggal_periksa
          ? medicalRecord?.tanggal_periksa
          : moment().format("YYYY-MM-DD")
      ),
      odontogram: medicalRecord?.record_odontogram?.odontogram ?? null,
      jumlah_foto_odontogram:
        medicalRecord?.record_odontogram?.jumlah_foto_odontogram ?? 0,
      jumlah_foto_rontgen_odontogram:
        medicalRecord?.record_odontogram?.jumlah_foto_rontgen_odontogram ?? 0,
      foto_opsi_odontogram: odontogramHelper.parseOpsiFoto(
        medicalRecord?.record_odontogram?.foto_opsi_odontogram ?? "",
        2
      ),
      rontgen_opsi_odontogram: odontogramHelper.parseOpsiFoto(
        medicalRecord?.record_odontogram?.rontgen_opsi_odontogram ?? "",
        4
      ),
    };
  };

  const isHPHTOver52Week = () => {
    if (form.tanggal_periksa && form.tanggal_menstruasi_terakhir) {
      let parseTanggalPeriksa = moment(form.tanggal_periksa);
      let parseTanggalMenstruasiTerakhir = moment(
        form.tanggal_menstruasi_terakhir
      );
      let diffWeek = Math.abs(
        parseTanggalPeriksa.diff(parseTanggalMenstruasiTerakhir, "week")
      );
      if (diffWeek > 52) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const loadData = async (id) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records/${id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      setMedicalRecord(response.data);
      let parsed = parseData(response.data);
      if (response.data.user?.id) {
        setReferenceUser(response.data.user?.id);
        setReferenceType("USER");
      } else {
        setReferenceUser(response.data.id);
        setReferenceType("MEDICALRECORD");
      }
      setForm((prev) => ({
        ...prev,
        ...parsed,
      }));
    } catch (error) {
      console.log(error);
      setLoadDataError(true);
      setLoadDataErrorCode(error.request.status);
      enqueueSnackbar(error.response?.data?.message ?? error.message, {
        variant: "error",
      });
      Sentry.captureException(error);
    } finally {
      setLoadingData(false);
    }
  };

  const saveData = async (odontogram = {}) => {
    let myForm = { ...form, ...odontogram };
    let checkHPHTOver52Week = isHPHTOver52Week();
    if (checkHPHTOver52Week) {
      enqueueSnackbar(
        "Tanggal Menstruasi Terakhir tidak boleh lebih dari 52 Minggu dari tanggal periksa"
      );
      return;
    }
    if (submitting === false) {
      setSubmitting(true);
      enqueueSnackbar(
        "memproses simpan data..., tunggu hingga notifikasi selanjutnya",
        {
          autoHideDuration: 3000,
        }
      );
      const formData = new FormData();
      console.log(myForm);
      Object.entries(myForm).forEach(([key, value]) => {
        if (
          key !== "file_internal" &&
          key !== "file_internal" &&
          key !== "diagnosa" &&
          key !== "resep" &&
          key !== "resep_racikan" &&
          key !== "odontogram" &&
          key !== "rontgen_opsi_odontogram" &&
          key !== "foto_opsi_odontogram" &&
          key !== "pasien_baru"
        ) {
          let dateInput = [
            "tanggal_lahir",
            "tanggal_periksa",
            "tanggal_menstruasi_terakhir",
            "tanggal_perkiraan_lahir",
            "reminder",
          ];
          if (dateInput.includes(key)) {
            formData.append(
              key,
              value === undefined || value === null || value === ""
                ? ""
                : moment(value).format("YYYY-MM-DD")
            );
          } else {
            formData.append(
              key,
              value === undefined || value === null ? "" : value
            );
          }
        }
      });

      if (myForm.pasien_baru) {
        formData.append("pasien_baru", 1);
      } else {
        formData.append("pasien_baru", 0);
      }

      if (myForm.file_internal.length > 0) {
        myForm.file_internal.forEach((i) => {
          formData.append("file_internal[]", i);
        });
      }

      if (myForm.file_scan.length > 0) {
        myForm.file_scan.forEach((i) => {
          formData.append("file_scan[]", i);
        });
      }

      if (Array.isArray(myForm.diagnosa) && myForm.diagnosa.length > 0) {
        let formatted = "";
        let n = 1;
        myForm.diagnosa.forEach((i) => {
          if (n < myForm.diagnosa.length) {
            formatted = formatted + `${i.icd10_code}\n`;
          } else {
            formatted = formatted + `${i.icd10_code}`;
          }
          n++;
        });
        formData.append("diagnosa", formatted);
      }

      if (
        Array.isArray(myForm.resep_racikan) &&
        myForm.resep_racikan.length > 0
      ) {
        formData.append(
          "resep_racikan",
          JSON.stringify(
            mediplusHelper.resepObatDataFixer(myForm.resep_racikan)
          )
        );
      } else {
        formData.append("resep_racikan", "");
      }

      if (Array.isArray(myForm.resep)) {
        const resep = myForm.resep.reduce((prev, val) => {
          let newData = { ...val };
          if (newData.hasOwnProperty("unsaved")) {
            delete newData.unsaved;
          }
          prev.push(newData);
          return prev;
        }, []);
        formData.append("resep", JSON.stringify(resep));
      } else {
        formData.append("resep", myForm.resep);
      }

      if (odontogram && odontogram.length > 0) {
        formData.append("odontogram", odontogram);
      } else {
        formData.append("odontogram", myForm.odontogram);
      }

      if (
        Array.isArray(myForm.foto_opsi_odontogram) &&
        myForm.foto_opsi_odontogram.length > 0
      ) {
        formData.append(
          "foto_opsi_odontogram",
          myForm.foto_opsi_odontogram.join("|")
        );
      } else {
        formData.append("foto_opsi_odontogram", "0|0");
      }

      if (
        Array.isArray(myForm.rontgen_opsi_odontogram) &&
        myForm.rontgen_opsi_odontogram.length > 0
      ) {
        formData.append(
          "rontgen_opsi_odontogram",
          myForm.rontgen_opsi_odontogram.join("|")
        );
      } else {
        formData.append("rontgen_opsi_odontogram", "0|0|0|0");
      }

      let res = {
        success: true,
        medical_record_id: null,
      };

      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records${
            medicalRecord?.id ? "/" + medicalRecord?.id : ""
          }`,
          formData,
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        enqueueSnackbar("sukses menyimpan data", {
          variant: "success",
        });
        res = {
          success: true,
          medical_record_id: medicalRecord?.id
            ? medicalRecord?.id
            : result.data.medical_record_id,
        };
        if (medicalRecord?.id) {
          setLoadingData(true);
          await loadData(medicalRecord.id);
        } else {
          history.push(`/rekam-medis/editor/${result.data.medical_record_id}`);
          setLoadingData(true);
          await loadData(result.data.medical_record_id);
        }
      } catch (e) {
        enqueueSnackbar(e.response?.data?.message ?? "Gagal menyimpan data", {
          variant: "error",
        });
        console.log(e);
        res = {
          success: false,
          medical_record_id: null,
        };
        Sentry.captureException(e);
      } finally {
        setSubmitting(false);
      }
      return res;
    } else {
      enqueueSnackbar("tunggu hingga proses menyimpan sebelumnya selesai", {
        variant: "warning",
      });
      return {
        success: false,
        medical_record_id: null,
      };
    }
  };

  const loadReferenceUserData = (userId) => {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-ref-user/${userId}`;
    if (referenceType === "MEDICALRECORD") {
      url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-ref/${userId}`;
    }
    axios
      .get(url, {
        params: {
          clinic: current_active_schedule.clinic.id,
          head: user.head_doctor_id,
          "timeline-group-mode": timeline_group_mode,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setMedicalRecord(response.data);
        let parsed = parseData(response.data);
        setForm((prev) => ({
          ...prev,
          ...parsed,
          user_id: referenceType === "MEDICALRECORD" ? null : referenceUser,
          pasien_baru: false,
        }));
      })
      .catch(function (error) {
        console.log(error);
        setLoadDataError(true);
        setLoadDataErrorCode(error.request.status);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const handleGeneralInputFormChange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNextTab = () => {
    if (tabIndex + 1 <= 6) {
      setTabIndex(tabIndex + 1);
    }
  };

  const handlePrevTab = () => {
    console.log(form);
    if (tabIndex - 1 >= 0) {
      setTabIndex(tabIndex - 1);
    }
  };

  const handleRiwayatRekamMedisOpen = () => {
    if (referenceUser !== null && referenceUser !== undefined) {
      setRiwayatRekamMedisOpen(true);
    } else {
      enqueueSnackbar("data riwayat tidak ditemukan");
    }
  };

  const handleRiwayatRekamMedisClose = () => {
    setRiwayatRekamMedisOpen(false);
  };

  const handleSaveOdontogram = (odontogram) => {
    setOdontogramView(false);
    setForm((prevForm) => ({
      ...prevForm,
      ...odontogram,
    }));
    saveData(odontogram);
  };

  const handlePrintOdontogram = async (odontogram) => {
    setForm((prevForm) => ({
      ...prevForm,
      ...odontogram,
    }));
    let save = await saveData(odontogram);
    if (save.success === true) {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records/${save.medical_record_id}/generate-odontogram-link`,
          {},
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        window.open(response.data.link, "_blank");
      } catch (error) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      }
    }
  };

  const generateInvoice = async () => {
    setCreateInvoiceConfirmationOpen(false);
    let save = await saveData();
    enqueueSnackbar("memproses invoice...", {
      variant: "default",
    });
    if (save.success === true) {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records/${save.medical_record_id}/invoice`,
          {},
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        history.push(`/pembayaran/editor/${response.data.invoice_id}`);
        enqueueSnackbar("sukses membuat invoice", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      }
    }
  };

  const handleOdontogramViewOpen = () => {
    setOdontogramView(true);
  };

  const handleOdontogramViewClose = () => {
    setOdontogramView(false);
  };

  const handleLinkScanFolderModalOpen = () => {
    if (!medicalRecord?.id) {
      enqueueSnackbar("harap simpan rekam medis terlebih dahulu");
      return;
    }
    setIsLinkScanFolderModalOpen(true);
  };

  const handleLinkScanFolderModalClose = () => {
    setIsLinkScanFolderModalOpen(false);
  };

  const handleOpenInvoice = () => {
    if (medicalRecord?.invoice?.id) {
      history.push(`/pembayaran/editor/${medicalRecord.invoice.id}`);
    } else {
      enqueueSnackbar("invoice tidak ditemukan", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let bmi = mediplusHelper.calculateBMI(
      parseInt(form.tinggi_badan),
      parseInt(form.berat_badan)
    );
    let bsa = mediplusHelper.calculateBSA(
      parseInt(form.tinggi_badan),
      parseInt(form.berat_badan)
    );

    setForm((prev) => ({
      ...prev,
      bmi,
      bsa,
      kategori_bmi: mediplusHelper.BMICategory(bmi),
    }));
  }, [form.tinggi_badan, form.berat_badan]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      usia_kehamilan:
        form.tanggal_menstruasi_terakhir && form.tanggal_periksa
          ? mediplusHelper.usiaKehamilan(
              form.tanggal_menstruasi_terakhir,
              form.tanggal_periksa
            )
          : "",
      tanggal_perkiraan_lahir: form.tanggal_menstruasi_terakhir
        ? mediplusHelper.estimasiTanggalKelahiran(
            form.tanggal_menstruasi_terakhir
          )
        : "",
    }));
  }, [form.tanggal_menstruasi_terakhir, form.tanggal_periksa]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      usia: mediplusHelper.getAge(form.tanggal_lahir, form.tanggal_periksa),
    }));
  }, [form.tanggal_lahir, form.tanggal_periksa]);

  useEffect(() => {
    if (route?.params?.id && !isNaN(route?.params?.id)) {
      setForm((prevForm) => ({
        ...prevForm,
        pasien_baru: false,
      }));
      loadData(route.params.id);
      setIsPasienBaru(false);
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        clinic_id: current_active_schedule.clinic.id,
      }));
      if (referenceUser) {
        setForm((prevForm) => ({
          ...prevForm,
          pasien_baru: false,
        }));
        loadReferenceUserData(referenceUser);
        setIsPasienBaru(false);
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          pasien_baru: true,
        }));
        setIsPasienBaru(true);
        setLoadingData(false);
      }
    }
  }, [location.pathname, location.search]);

  if (loadingData) {
    return <SectionLoading />;
  }

  if (loadDataError) {
    return <LoadError title={loadDataErrorCode} message="Failed Fetch Data" />;
  }

  return (
    <>
      <LinkToScanFolderModal
        medicalRecordId={medicalRecord?.id}
        open={isLinkScanFolderModalOpen}
        onClose={handleLinkScanFolderModalClose}
        onChoose={() => {
          handleLinkScanFolderModalClose();
          saveData();
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={createInvoiceConfirmationOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>Konfirmasi</Typography>
              <Box>
                <Button
                  onClick={generateInvoice}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setCreateInvoiceConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />
      <Box sx={header__container}>
        <Box>
          <Typography
            onClick={() => {
              console.log(form);
            }}
            sx={{
              ...header__h1,
              color: "rgba(0, 174, 239, 1)",
            }}
          >
            Rekam Medis
          </Typography>
          <Typography sx={header__h1}>
            {" "}
            {"|"} {medicalRecord?.id ? "Edit Rekam Medis" : "Buat Rekam Medis"}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "600",
            }}
          >
            {form?.nama ? form?.nama : "------"} ({" "}
            {form?.usia ? form?.usia : "- Tahun"} )
          </Typography>
          {!medicalRecord?.id && (
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              [{current_active_schedule?.clinic?.nama ?? ""}]
            </Typography>
          )}
        </Box>
        <Box>
          {odontogramView === false && medicalRecord?.invoice?.id ? (
            <Button
              sx={riwayat_medis__btn}
              variant="contained"
              onClick={handleOpenInvoice}
            >
              <Typography sx={riwayat_medis__btn__label}>
                {medicalRecord.invoice.invoice_number}
              </Typography>
            </Button>
          ) : (
            <Button
              sx={riwayat_medis__btn}
              variant="contained"
              onClick={() => {
                setCreateInvoiceConfirmationOpen(true);
              }}
            >
              <Typography sx={riwayat_medis__btn__label}>
                Buat Invoice
              </Typography>
            </Button>
          )}
          <Button
            sx={riwayat_medis__btn}
            variant="contained"
            onClick={handleLinkScanFolderModalOpen}
          >
            <Typography sx={riwayat_medis__btn__label}>
              Tautkan Hasil Pemeriksaan
            </Typography>
          </Button>
          {odontogramView === false && (
            <Button
              sx={riwayat_medis__btn}
              variant="contained"
              onClick={handleRiwayatRekamMedisOpen}
            >
              <Typography sx={riwayat_medis__btn__label}>
                Riwayat Rekam Medis
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      {odontogramView ? (
        <Odontogram
          encodedOdontogram={form.odontogram}
          odontogramData={memoizedOdontogramData}
          setForm={setForm}
          handleOdontogramViewOpen={handleOdontogramViewOpen}
          handleOdontogramViewClose={handleOdontogramViewClose}
          handleSaveOdontogram={handleSaveOdontogram}
          submitting={submitting}
          handlePrintOdontogram={handlePrintOdontogram}
        />
      ) : (
        <>
          <Box sx={{ width: "100%", position: "relative" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderTop: 1,
                borderColor: "divider",
                p: "10px 2",
              }}
            >
              <Tabs
                value={tabIndex}
                variant="scrollable"
                onChange={handleTabChange}
                aria-label="tabk"
                sx={{
                  ".MuiTabs-flexContainer": {
                    background: "transparent",
                  },
                  overflowX: "auto",
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#FF0091",
                    height: "3px",
                  },
                }}
              >
                <Tab
                  sx={{
                    mr: 1,
                  }}
                  label={
                    <Typography
                      sx={{
                        ...tab__label,
                        color: tabIndex === 0 ? "#041615" : "#7D7D7D",
                      }}
                    >
                      Informasi Pasien
                    </Typography>
                  }
                  {...a11yProps(0)}
                ></Tab>
                <Tab
                  sx={{
                    mx: 1,
                  }}
                  label={
                    <Typography
                      sx={{
                        ...tab__label,
                        color: tabIndex === 1 ? "#041615" : "#7D7D7D",
                      }}
                    >
                      Data Medis
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    mx: 1,
                  }}
                  label={
                    <Typography
                      sx={{
                        ...tab__label,
                        color: tabIndex === 2 ? "#041615" : "#7D7D7D",
                      }}
                    >
                      Data Spesialis
                    </Typography>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  sx={{
                    mx: 1,
                  }}
                  label={
                    <Typography
                      sx={{
                        ...tab__label,
                        color: tabIndex === 3 ? "#041615" : "#7D7D7D",
                      }}
                    >
                      SOAP
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
                <Tab
                  sx={{
                    mx: 1,
                  }}
                  label={
                    <Typography
                      sx={{
                        ...tab__label,
                        color: tabIndex === 4 ? "#041615" : "#7D7D7D",
                      }}
                    >
                      Resep & IER
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
                <Tab
                  sx={{
                    mx: 1,
                  }}
                  label={
                    <Typography
                      sx={{
                        ...tab__label,
                        color: tabIndex === 5 ? "#041615" : "#7D7D7D",
                      }}
                    >
                      Internal
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
                <Tab
                  sx={{
                    mx: 1,
                  }}
                  label={
                    <Typography
                      sx={{
                        ...tab__label,
                        color: tabIndex === 6 ? "#041615" : "#7D7D7D",
                      }}
                    >
                      Hasil Pemeriksaan
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>
            <Box
              id="form-container"
              sx={{
                paddingTop: "10px",
                paddingLeft: "10px",
              }}
            >
              <Box
                id="informasi-pasien"
                sx={{
                  display: tabIndex === 0 ? "block" : "none",
                }}
              >
                <TabInformasiPasien
                  form={form}
                  handleGeneralInputFormChange={handleGeneralInputFormChange}
                  medicalRecord={medicalRecord}
                  isPasienBaru={isPasienBaru}
                  setIsPasienBaru={setIsPasienBaru}
                />
              </Box>
              <Box
                id="data-medis"
                sx={{
                  display: tabIndex === 1 ? "block" : "none",
                }}
              >
                <TabDataMedis
                  form={form}
                  handleGeneralInputFormChange={handleGeneralInputFormChange}
                />
              </Box>
              <Box
                id="data-spesialis"
                sx={{
                  display: tabIndex === 2 ? "block" : "none",
                }}
              >
                <TabDataSpesialis
                  form={form}
                  handleGeneralInputFormChange={handleGeneralInputFormChange}
                  handleOdontogramViewOpen={handleOdontogramViewOpen}
                />
              </Box>
              <Box
                id="data-soap"
                sx={{
                  display: tabIndex === 3 ? "block" : "none",
                }}
              >
                <TabSoap
                  form={form}
                  setForm={setForm}
                  handleGeneralInputFormChange={handleGeneralInputFormChange}
                />
              </Box>
              <Box
                id="data-resep"
                sx={{
                  display: tabIndex === 4 ? "block" : "none",
                }}
              >
                <TabResepIer
                  form={form}
                  setForm={setForm}
                  handleGeneralInputFormChange={handleGeneralInputFormChange}
                />
              </Box>
              <Box
                id="data-internal"
                sx={{
                  display: tabIndex === 5 ? "block" : "none",
                }}
              >
                <TabInternal
                  form={form}
                  setForm={setForm}
                  handleGeneralInputFormChange={handleGeneralInputFormChange}
                  medicalRecord={medicalRecord}
                />
              </Box>
              <Box
                id="data-file"
                sx={{
                  display: tabIndex === 6 ? "block" : "none",
                }}
              >
                <TabHasilPemeriksaan
                  form={form}
                  setForm={setForm}
                  medicalRecord={medicalRecord}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={footer__container}>
            <Button
              onClick={() => {
                saveData(null);
              }}
              sx={submit__btn}
              variant="contained"
            >
              <img src="/assets/icons/save.svg" alt="save"></img>
              <Typography sx={submit__btn__label}>
                {submitting ? "Menyimpan..." : "Simpan"}
              </Typography>
            </Button>
            <Box>
              <Button
                onClick={handlePrevTab}
                sx={tab__switch__btn}
                variant="contained"
              >
                <Typography sx={tab__switch__btn__label}>Sebelumnya</Typography>
              </Button>
              <Button
                onClick={handleNextTab}
                sx={tab__switch__btn}
                variant="contained"
              >
                <Typography sx={tab__switch__btn__label}>
                  Selanjutnya
                </Typography>
              </Button>
            </Box>
          </Box>
          <RiwayatRekamMedisModal
            userId={referenceUser}
            referenceType={referenceType}
            open={riwayatRekamMedisOpen}
            onClose={handleRiwayatRekamMedisClose}
            selectedMedicalRecordId={selectedMedicalRecordId}
            setSelectedMedicalRecordId={setSelectedMedicalRecordId}
          />
        </>
      )}
    </>
  );
}
