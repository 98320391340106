import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";
import {
  avatar__btn,
  header__container,
  header__h1,
  submit__btn,
  submit__btn__label,
  upload__btn,
  upload__btn__label,
  user__photo,
  user__photo__opt__container,
} from "./style";
import { useAuth } from "../../../../providers/AuthProvider";
import mediplusHelper from "../../../../utils/mediplusHelper";
import authHeader from "../../../../services/authHeader";
import {
  form__control,
  input__label,
  input__text,
} from "../rekam-medis/editor/style";
import UploadFileModal from "./UploadFileModal";
import AvatarModal from "./AvatarModal";
import * as Sentry from "@sentry/react";

export default function Profile() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState({
    email: user.email,
    nomor: user.nomor,
    nama: user.nama,
    sip: user.sip,
    alamat: user.alamat,
  });

  const [submitting, setSubmitting] = useState(false);
  const [fileSubmitting, setFileSubmitting] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [files, setFiles] = useState(false);

  const handleInputChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = (file) => {
    if (file.length > 0) {
      if (mediplusHelper.isImageMime(file[0].type)) {
        setFiles([file[0]]);
      } else {
        enqueueSnackbar("silahkan pilih file bertipe image");
      }
    }
  };

  const handleFileDelete = (index) => {
    setFiles([]);
  };

  const handleProfileSave = async () => {
    try {
      setSubmitting(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/me`,
        {
          ...form,
        },
        {
          headers: authHeader(),
        }
      );
      window.location.reload();
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleAvatarClick = async (avatarName) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/me/photo-profile`,
        {
          avatar_name: avatarName,
        },
        {
          headers: authHeader(),
        }
      );
      window.location.reload();
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const handleFileUpload = async () => {
    if (files.length < 1) {
      enqueueSnackbar("silahkan pilih file terlebih dahulu");
      return;
    }
    try {
      setFileSubmitting(true);
      const formData = new FormData();
      formData.append("photo_profile", files[0]);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/me/photo-profile`,
        formData,
        {
          headers: authHeader(),
        }
      );
      window.location.reload();
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    } finally {
      setFileSubmitting(false);
    }
  };

  return (
    <>
      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Edit Profil
        </Typography>
      </Box>
      <Box>
        <Box sx={user__photo__opt__container}>
          <img src={user.photo_profile} alt="profile" style={user__photo}></img>
          <Button
            sx={upload__btn}
            variant="contained"
            onClick={() => {
              setUploadFileModalOpen(true);
            }}
          >
            <Typography sx={upload__btn__label}>Unggah Foto</Typography>
          </Button>
          <Typography
            onClick={() => {
              setAvatarModalOpen(true);
            }}
            sx={avatar__btn}
          >
            Gunakan Avatar
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel sx={input__label} shrink htmlFor="nama">
                  Nama
                </InputLabel>
                <InputBase
                  sx={input__text}
                  onChange={handleInputChange}
                  placeholder="nama"
                  id="nama"
                  name="nama"
                  type="text"
                  value={form.nama ?? ""}
                />
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel sx={input__label} shrink htmlFor="email">
                  Email
                </InputLabel>
                <InputBase
                  sx={input__text}
                  onChange={handleInputChange}
                  value={form.email ?? ""}
                  placeholder="email"
                  id="email"
                  name="email"
                  type="text"
                />
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel sx={input__label} shrink htmlFor="nomor">
                  Nomor Telepon
                </InputLabel>
                <InputBase
                  sx={input__text}
                  onChange={handleInputChange}
                  value={form.nomor ?? ""}
                  placeholder="nomor"
                  id="nomor"
                  name="nomor"
                  type="text"
                />
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel sx={input__label} shrink htmlFor="sip">
                  No SIP (Surat Izin Praktek)
                </InputLabel>
                <InputBase
                  sx={input__text}
                  onChange={handleInputChange}
                  value={form.sip ?? ""}
                  placeholder="sip"
                  id="sip"
                  name="sip"
                  type="text"
                />
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel sx={input__label} shrink htmlFor="alamat">
                  Alamat
                </InputLabel>
                <InputBase
                  sx={input__text}
                  onChange={handleInputChange}
                  value={form.alamat ?? ""}
                  placeholder="alamat"
                  id="alamat"
                  name="alamat"
                  type="text"
                />
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            onClick={handleProfileSave}
            sx={submit__btn}
            variant="contained"
          >
            <img src="/assets/icons/save.svg" alt="save"></img>
            <Typography sx={submit__btn__label}>
              {submitting ? "Menyimpan..." : "Simpan"}
            </Typography>
          </Button>
        </Box>
      </Box>
      <UploadFileModal
        open={uploadFileModalOpen}
        onClose={() => setUploadFileModalOpen(false)}
        files={files}
        handleFileChange={handleFileChange}
        fileSubmitting={fileSubmitting}
        handleFileUpload={handleFileUpload}
        handleFileDelete={handleFileDelete}
      />
      <AvatarModal
        open={avatarModalOpen}
        onClose={() => setAvatarModalOpen(false)}
        handleAvatarClick={handleAvatarClick}
      />
    </>
  );
}
