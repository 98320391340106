import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import {
  Button,
  ClickAwayListener,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import {
  folder__info__container,
  folder__info__img,
  folder__info__text,
  table__cell__text,
  table__container,
} from "./style";
import TheadCell from "../../../../components/table/thead-cell/TheadCell";
import SendAntrianModal from "../../../../components/modal/send-antrian-modal/SendAntrianModal";
import QRModal from "../../../../components/modal/qr-modal/QRModal";
import SendDataModal from "../../../../components/modal/send-data-modal/SendDataModal";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import HasilPemeriksaanTablePopper from "./HasilPemeriksaanTablePopper";
import _ from "lodash";
import * as Sentry from "@sentry/react";

export default function HasilPemeriksaanTable({ search, type }) {
  const history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const scan_folder_update = useSelector(
    (state) => state.scan_folder_update.value
  );

  //table
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState("created_at");
  const [sortType, setSortType] = React.useState("DESC");

  //popper
  const [selectedScan, setSelectedScan] = React.useState(null);
  const [popperScanEl, setPopperScanEl] = React.useState(null);
  const [popupScanOpen, setPopupScanOpen] = React.useState(false);

  //modal
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);
  const [sendAntrianModalOpen, setSendAntrianModalOpen] = React.useState(false);
  const [qrModalOpen, setQRModalOpen] = React.useState(false);
  const [sendModalOpen, setSendModalOpen] = React.useState(false);

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/scan-folders`,
        {
          params: {
            type: type,
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: 6,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        Sentry.captureException(error);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      });
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const handlePopupScanOpen = (event) => {
    let scanId = parseInt(event.currentTarget.getAttribute("data-scan-id"));
    let scan = rows.find((item) => {
      return item.id === scanId;
    });
    if (scan !== undefined) {
      setSelectedScan(scan);
      if (event.currentTarget === popperScanEl) {
        setPopupScanOpen(!popupScanOpen);
      } else {
        setPopperScanEl(event.currentTarget);
        setPopupScanOpen(true);
      }
    } else {
      enqueueSnackbar("scan folder record not found", {
        type: "error",
      });
    }
  };

  const handlePopupScanAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupScanOpen) setPopupScanOpen(false);
    }
  };

  const handleSortChange = (field) => {
    setPopupScanOpen(false);
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handleRedirectView = (id) => {
    let scanFolderdata = rows.find((i) => i.id === id);
    let selectedScanId = selectedScan?.id;
    if (scanFolderdata) {
      if (scanFolderdata.medical_record?.id) {
        history.push(
          `/rekam-medis/editor/${scanFolderdata.medical_record?.id}`
        );
      } else {
        history.push(
          `/hasil-pemeriksaan/${
            _.isString(id) || _.isNumber(id) ? id : selectedScanId
          }`
        );
      }
    } else {
      if (selectedScan?.medical_record?.id) {
        history.push(`/rekam-medis/editor/${selectedScan?.medical_record?.id}`);
      } else {
        history.push(
          `/hasil-pemeriksaan/${
            _.isString(id) || _.isNumber(id) ? id : selectedScanId
          }`
        );
      }
    }
  };

  const handleQrModalOpen = () => {
    setQRModalOpen(true);
    setPopupScanOpen(false);
  };

  const handleSendAntrianModalOpen = () => {
    setSendAntrianModalOpen(true);
    setPopupScanOpen(false);
  };

  const handleSendDataModalOpen = () => {
    setSendModalOpen(true);
    setPopupScanOpen(false);
  };

  React.useEffect(() => {
    setLoading(true);
    loadData();
    return () => {
      setPopupScanOpen(false);
      setPopperScanEl(null);
    };
  }, [
    current_active_schedule,
    page,
    sortBy,
    sortType,
    search,
    scan_folder_update,
  ]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "5px",
                  marginRight: "20px",
                }}
              >
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />

      <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={table__container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TheadCell
                  name="Nama"
                  imageColumn={true}
                  column_name="name"
                  useSort={true}
                  currentSortColumnName={sortBy}
                  currentSortType={sortType}
                  onClick={handleSortChange}
                />
                <TheadCell name="Ruang" imageColumn={false} useSort={false} />
                <TheadCell
                  name="Tanggal"
                  column_name="created_at"
                  useSort={true}
                  currentSortColumnName={sortBy}
                  currentSortType={sortType}
                  onClick={handleSortChange}
                />
                <TheadCell
                  name="Berkas"
                  column_name="file_count"
                  useSort={true}
                  currentSortColumnName={sortBy}
                  currentSortType={sortType}
                  onClick={handleSortChange}
                />
                <TheadCell name="Action" useSort={false} />
              </TableRow>
            </TableHead>

            {rows.length > 0 && (
              <TableBody>
                {rows.map((row, idx) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={idx}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleRedirectView(row.id);
                    }}
                  >
                    <TableCell align={"left"}>
                      <Paper sx={folder__info__container}>
                        <img
                          data-user-id={1}
                          src={"/assets/icons/folder.svg"}
                          alt="sfolder"
                          style={folder__info__img}
                        ></img>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingRight: "10px",
                            width: "100%",
                          }}
                        >
                          <Typography
                            data-user-id={1}
                            component={"div"}
                            sx={folder__info__text}
                          >
                            {row.name ?? "-"}
                          </Typography>

                          {row.medical_record?.user?.nama ? (
                            <Typography
                              data-user-id={1}
                              component={"div"}
                              sx={{
                                ...folder__info__text,
                                fontSize: "12px",
                                paddingY: "0px",
                              }}
                            >
                              Diterima :{" "}
                              {mediplusHelper.cropText(
                                row.medical_record?.user?.nama,
                                18,
                                true
                              )}
                            </Typography>
                          ) : (
                            <>
                              {(row.status_kirim === "FAILED" ||
                                row.status_kirim === "SENT") && (
                                <Typography
                                  data-user-id={1}
                                  component={"div"}
                                  sx={{
                                    ...folder__info__text,
                                    fontSize: "12px",
                                    paddingY: "0px",
                                  }}
                                >
                                  {row.detail_status?.replace(
                                    "Pengiriman Gagal, ",
                                    ""
                                  )}
                                </Typography>
                              )}
                            </>
                          )}
                        </Box>
                        {row.status_kirim === "PENDING" &&
                        Boolean(row.already_sent) === false ? (
                          <></>
                        ) : (
                          <>
                            <Typography
                              data-user-id={1}
                              component={"div"}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "5px 16px",
                                gap: "10px",
                                position: "relative",
                                height: "20px",
                                width: "80px",
                                borderRadius: "8px",
                                color:
                                  row.status_kirim === "FAILED"
                                    ? "rgba(255, 0, 0, 1)"
                                    : "rgba(0, 160, 73, 1)",
                                background:
                                  row.status_kirim === "FAILED"
                                    ? "rgba(255, 222, 222, 1)"
                                    : "rgba(211, 255, 231, 1)",
                                fontSize: "12px",
                              }}
                            >
                              {row.status_kirim === "READ"
                                ? "Dibuka"
                                : row.status_kirim === "SENT"
                                ? "Terkirim"
                                : row.status_kirim === "FAILED"
                                ? "Gagal"
                                : "Dikirim"}
                            </Typography>
                          </>
                        )}
                      </Paper>
                    </TableCell>
                    <TableCell
                      align={"left"}
                      sx={table__cell__text}
                      width="15%"
                    >
                      {row.hardware?.clinic_room?.nama ?? ""}
                    </TableCell>
                    <TableCell
                      align={"left"}
                      sx={table__cell__text}
                      width="18%"
                    >
                      {moment(row.created_at).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                    <TableCell
                      align={"left"}
                      sx={table__cell__text}
                      width="10%"
                    >
                      {row.file_count ?? "0"}
                    </TableCell>
                    <TableCell
                      align={"center"}
                      sx={table__cell__text}
                      width="7%"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <ClickAwayListener
                        onClickAway={(e) => {
                          e.stopPropagation();
                          handlePopupScanAway(e);
                        }}
                      >
                        <IconButton
                          data-group="popper-toggler"
                          data-scan-id={row.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePopupScanOpen(e);
                          }}
                          color="inherit"
                          aria-label="refresh"
                          edge="start"
                          type="button"
                          sx={{
                            marginLeft: "0px",
                          }}
                        >
                          <LazyLoadImage
                            effect="blur"
                            src="/assets/icons/table-action.svg"
                            alt="refresh"
                          ></LazyLoadImage>
                        </IconButton>
                      </ClickAwayListener>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            {rows.length < 1 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Tidak ada data
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {loading && (
            <img src="/roll.svg" alt="roll" width={30} height={30}></img>
          )}

          <Stack alignItems="end">
            <Pagination
              shape="rounded"
              sx={{
                p: 2,
                ".Mui-selected": {
                  backgroundColor: "rgba(0, 174, 239, 1)!important",
                  color: "white",
                },
              }}
              count={lastPage}
              showFirstButton
              showLastButton
              onChange={handlePageChange}
            />
          </Stack>
        </Box>
      </Paper>

      <HasilPemeriksaanTablePopper
        open={popupScanOpen}
        anchorEl={popperScanEl}
        handlePopupAway={handlePopupScanAway}
        handleRedirectView={handleRedirectView}
        handleQrModalOpen={handleQrModalOpen}
        handleSendAntrianModalOpen={handleSendAntrianModalOpen}
        handleSendDataModalOpen={handleSendDataModalOpen}
      />

      <SendAntrianModal
        open={sendAntrianModalOpen}
        data={selectedScan}
        onChoose={() => {
          setSendAntrianModalOpen(false);
          loadData();
        }}
        onClose={() => {
          setSendAntrianModalOpen(false);
        }}
      />
      <QRModal
        open={qrModalOpen}
        data={selectedScan}
        onClose={() => {
          setQRModalOpen(false);
        }}
        type="SCAN_FOLDER"
      />
      <SendDataModal
        open={sendModalOpen}
        data={selectedScan}
        onClose={() => {
          setSendModalOpen(false);
        }}
        type="SCAN_FOLDER"
      />
    </>
  );
}
