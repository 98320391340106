const login__text__h1 = {
  fontSize: "32px",
  fontWeight: "700",
};

const login__text__h2 = {
  marginBottom: "14px",
};

const login__button__submit = {
  backgroundColor: "blue",
  color: "white",
  textTransform: "none",
  width: "100%",
  marginBottom: "140px",
  marginTop: "10px",
};

const login__forgot_password__link = {
  display: "inline-block",
  width: "100%",
  color: "black",
  position: "absolute",
  right: "0px",
  textAlign: "right",
  fontSize: "0.75rem",
  fontWeight: "400",
  lineHeight: "1.4375em",
  textDecoration: "underline",
  cursor: "pointer",
};

const eye__icon = {
  position: "absolute",
  right: "12px",
  top: "34px",
  cursor: "pointer",
};

export {
  login__text__h1,
  login__text__h2,
  login__button__submit,
  login__forgot_password__link,
  eye__icon,
};
