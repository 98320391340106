const media__modal = {
  position: "absolute",
  maxHeight: "99vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  overflow: "hidden",
};

export { media__modal };
