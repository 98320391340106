import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import SectionLoading from "../../../../components/loading/SectionLoading";
import LoadError from "../../../../components/error/LoadError";
import {
  add__submenu__btn,
  delete__submenu__btn,
  footer__container,
  header__container,
  header__h1,
  header__h1__blue,
  submenu__header,
  submit__btn,
  submit__btn__label,
} from "./style";
import {
  form__control,
  input__label,
  input__text,
} from "../../rekam-medis/editor/style";
import * as Sentry from "@sentry/react";

export default function ObatEditor() {
  const history = useHistory();
  const { user } = useAuth();
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const route = useRouteMatch();
  const [loadingData, setLoadingData] = useState(true);
  const [loadDataError, setLoadDataError] = useState(false);
  const [loadDataErrorCode, setLoadDataErrorCode] = useState(0);
  const [isHasSubmenu, setIsHasSubmenu] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [medicine, setMedicine] = useState({
    id: "",
    clinic_id: "",
    nama: "",
    stok: 0,
    harga: 0,
    satuan: "",
    submenu: [],
  });

  const loadData = async (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/medicines/${id}`, {
        params: {
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setMedicine((prev) => ({
          ...prev,
          id: response.data?.id ?? "",
          clinic_id: response.data?.clinic_id ?? "",
          nama: response.data?.nama ?? "",
          stok: response.data?.stok ?? 0,
          harga: response.data?.harga ?? 0,
          satuan: response.data?.satuan ?? "",
          submenu: response.data?.submenu ?? [],
        }));
        if (response.data.submenu.length > 0) {
          setIsHasSubmenu(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoadDataError(true);
        setLoadDataErrorCode(error.request.status);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
        Sentry.captureException(error);
        history.push("/obat");
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const saveData = async () => {
    if (submitting === false) {
      setSubmitting(true);
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medicines${
            medicine?.id ? "/" + medicine.id : ""
          }`,
          medicine,
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        enqueueSnackbar("sukses menyimpan data", {
          variant: "success",
        });
        history.push("/obat");
        return;
      } catch (e) {
        if (e.request?.status === 422) {
          enqueueSnackbar("harap isi form yang disediakan dengan sesuai", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(e.response?.data?.message ?? "Gagal menyimpan data", {
            variant: "error",
          });
        }

        console.log(e);
        Sentry.captureException(e);
      } finally {
        setSubmitting(false);
      }
    } else {
      enqueueSnackbar("tunggu hingga proses menyimpan sebelumnya selesai", {
        variant: "warning",
      });
    }
  };

  const handleAddSubmenu = () => {
    setMedicine((prev) => ({
      ...prev,
      harga: 0,
      stok: 0,
      submenu: [
        ...prev.submenu,
        {
          id: "",
          nama: "",
          harga: 0,
          stok: 0,
        },
      ],
    }));
    setIsHasSubmenu(true);
  };

  useEffect(() => {
    if (route?.params?.id && !isNaN(route?.params?.id)) {
      loadData(route.params.id);
    } else {
      setMedicine((prev) => ({
        ...prev,
        clinic_id: current_active_schedule.clinic.id,
      }));

      setLoadingData(false);
    }
  }, []);

  if (loadingData) {
    return <SectionLoading />;
  }

  if (loadDataError) {
    return <LoadError title={loadDataErrorCode} message="Failed Fectch Data" />;
  }

  const handleChange = (e) => {
    setMedicine((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmenuChange = (e, index) => {
    setMedicine((prev) => ({
      ...prev,
      submenu: prev.submenu.map((submenu, i) =>
        i === index ? { ...submenu, [e.target.name]: e.target.value } : submenu
      ),
    }));
  };

  const handleSubmenuDelete = (index) => {
    setMedicine((prev) => ({
      ...prev,
      submenu: prev.submenu.filter((item, i) => i !== index),
    }));
    if (medicine.submenu.length - 1 <= 0) {
      setIsHasSubmenu(false);
    }
  };

  return (
    <>
      <Box sx={header__container}>
        <Box>
          <Typography sx={header__h1__blue}>Obat</Typography>
          <Typography sx={header__h1}>
            {" "}
            {"|"} {medicine?.id ? "Edit Obat" : "Buat Obat"}
          </Typography>
          {!medicine?.id && (
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              [{current_active_schedule.clinic.nama}]
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="nama">
                Nama
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="Nama"
                id="nama"
                name="nama"
                type="text"
                value={medicine.nama}
                onChange={handleChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="satuan">
                Satuan
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="Satuan"
                id="satuan"
                name="satuan"
                type="text"
                value={medicine.satuan}
                onChange={handleChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="stok">
                stok
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="Stok"
                id="stok"
                name="stok"
                type="number"
                disabled={isHasSubmenu ? true : false}
                value={medicine.stok}
                onChange={handleChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="harga">
                Harga
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="Stok"
                id="harga"
                name="harga"
                type="number"
                disabled={isHasSubmenu ? true : false}
                value={medicine.harga}
                onChange={handleChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <Box sx={submenu__header}>
                <Typography>Dosis dan jenis obat</Typography>
                <IconButton
                  aria-label="add"
                  sx={add__submenu__btn}
                  onClick={handleAddSubmenu}
                >
                  <img src="/assets/icons/plus.svg" alt="plus"></img>
                </IconButton>
              </Box>
              <Box
                sx={{
                  paddingLeft: "7px",
                }}
              >
                {medicine.submenu &&
                  medicine.submenu.map((submenu, index) => {
                    return (
                      <Grid container spacing={1} key={`grid_${index}`}>
                        <Grid item xs={3}>
                          <FormControl variant="standard" sx={form__control}>
                            <InputLabel sx={input__label} shrink htmlFor="stok">
                              Nama
                            </InputLabel>
                            <InputBase
                              sx={input__text}
                              placeholder="nama"
                              id={`nama_${index}`}
                              name={`nama`}
                              type="text"
                              value={medicine.submenu[index]?.nama}
                              onChange={(e) => {
                                handleSubmenuChange(e, index);
                              }}
                            />
                            <FormHelperText error={true}></FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                          <FormControl variant="standard" sx={form__control}>
                            <InputLabel sx={input__label} shrink htmlFor="stok">
                              Stok
                            </InputLabel>
                            <InputBase
                              sx={input__text}
                              placeholder="stok"
                              id={`stok_${index}`}
                              name={`stok`}
                              type="text"
                              value={medicine.submenu[index]?.stok}
                              onChange={(e) => {
                                handleSubmenuChange(e, index);
                              }}
                            />
                            <FormHelperText error={true}></FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl variant="standard" sx={form__control}>
                            <InputLabel
                              sx={input__label}
                              shrink
                              htmlFor="harga"
                            >
                              Harga
                            </InputLabel>
                            <InputBase
                              sx={input__text}
                              placeholder="harga"
                              id={`harga_${index}`}
                              name={`harga`}
                              type="text"
                              value={medicine.submenu[index]?.harga}
                              onChange={(e) => {
                                handleSubmenuChange(e, index);
                              }}
                            />
                            <FormHelperText error={true}></FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="delete-submenu"
                            sx={delete__submenu__btn}
                            onClick={() => {
                              handleSubmenuDelete(index);
                            }}
                          >
                            <img
                              src="/assets/icons/cross.svg"
                              alt="delete"
                            ></img>
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={footer__container}>
        <Button onClick={saveData} sx={submit__btn} variant="contained">
          <img src="/assets/icons/save.svg" alt="save"></img>
          <Typography sx={submit__btn__label}>
            {submitting ? "Menyimpan..." : "Simpan"}
          </Typography>
        </Button>
      </Box>
    </>
  );
}
