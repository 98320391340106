const chat__container = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  minWidth: "100%",
  justifyContent: "space-between",
};

const avatar__container = {
  width: "44px",
  height: "44px",
  background: "none",
};

const avatar__img = {
  borderRadius: "50%",
  width: "44px",
  height: "44px",
};

const chat__document__wrapper = {
  width: "calc(100% - 88px)",
  background: "none",
};

const chat__document__container = {
  pt: 0.5,
  px: 1,
  display: "flex",
  justifyContent: "start",
};

const chat__document__bubble = {
  width: "fit-content",
  maxWidth: "70%",
  maxHeight: "300px",
  px: 1,
  pt: 1,
  background: "#EFF1F9",
  borderRadius: "0px 8px 8px 8px",
  cursor: "pointer",
};

const doc__type__info__container = {
  minWidth: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
};

const doc__type__info__box = {
  width: "40px",
  height: "40px",
  background: "#FF0091",
  borderRadius: "8px",
  mx: "auto",
  position: "relative",
};

const doc__type__info__text = {
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: "14px",
  fontWeight: 700,
  color: "white",
};

const footer__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignContent: "center",
  alignItems: "center",
  gap: "2px",
  pl: 0.5,
};

export {
  chat__container,
  avatar__container,
  avatar__img,
  chat__document__wrapper,
  chat__document__container,
  chat__document__bubble,
  doc__type__info__container,
  doc__type__info__box,
  doc__type__info__text,
  footer__container,
};
