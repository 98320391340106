import { IconButton, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function TabLoading(props) {
  const loader = [0, 1, 2, 3];
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "5px 15px 5px 15px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            paddingTop: "7px",
          }}
        >
          {props.title}
        </Typography>
        <IconButton
          color="inherit"
          aria-label="refresh"
          edge="start"
          type="button"
        >
          <img src="/assets/icons/vertical-options.svg" alt="refresh"></img>
        </IconButton>
      </Box>
      <Box
        sx={{
          px: 1.8,
        }}
      >
        {loader.map((i) => {
          return (
            <Skeleton
              key={`skeletion-${i}`}
              sx={{ mb: 0.5 }}
              variant="rectangular"
              width={"100%"}
              height={40}
            />
          );
        })}
      </Box>
    </>
  );
}
