import React from "react";
import _ from "lodash";
import { Skeleton } from "@mui/material";
export default function ObatLoading() {
  return (
    <>
      {_.times(10, (i) => {
        return (
          <Skeleton
            key={`skeletion-${i}`}
            sx={{ mb: 0.5, borderRadius: "8px" }}
            variant="rectangular"
            width={160}
            height={166}
          />
        );
      })}
    </>
  );
}
