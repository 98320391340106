import {
  FormControl,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo } from "react";
import {
  modal__container,
  modal__header__container,
  modal__header__text,
  modal_btn,
  modal_btn__label,
  tab__cell__text,
} from "./style";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useAuth } from "../../../../../../providers/AuthProvider";
import debounce from "lodash.debounce";
import { input__text } from "../../editor/style";
import { webkit__scrollbar } from "../../../../style";
import { LazyLoadImage } from "react-lazy-load-image-component";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import authHeader from "../../../../../../services/authHeader";
import TheadCell from "../../../../components/table/thead-cell/TheadCell";
import UserDetailModal from "./UserDetailModal";

export default function PencarianRiwayatModal({ open, onClose }) {
  const history = useHistory();
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const timeline_group_mode = useSelector((state) => state.timeline_group_mode);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetailModalOpen, setUserDetailModalOpen] = useState(false);

  const handleSelectUser = (user) => {
    setSelected(user);
    setUserDetailModalOpen(true);
  };

  const handleUserDetailModalClose = () => {
    setUserDetailModalOpen(false);
  };

  const loadRiwayat = async () => {
    if (search.length < 1) {
      setRows([]);
      setLoading(false);
    }
    try {
      setLoading(true);
      setRows([]);
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/timeline-search`,
        {
          params: {
            clinic: current_active_schedule.clinic_id,
            head: user.head_doctor_id,
            "timeline-group-mode": timeline_group_mode,
            search: search,
          },
          headers: authHeader(),
        }
      );
      setRows(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Sentry.captureException(error);
      enqueueSnackbar(error.message);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePasienBaru = () => {
    history.push("/rekam-medis/editor");
  };

  const handleRedirect = (row) => {
    if (row?.user_id) {
      history.push(`/rekam-medis/editor?ref=${row.user_id}`);
    } else {
      history.push(`/rekam-medis/editor?ref=${row.medical_record_id}&type=MED`);
    }
  };

  const debouncedSearchChangeHandler = useMemo(
    () => debounce(handleSearchChange, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchChangeHandler.cancel();
    };
  }, []);

  useEffect(() => {
    loadRiwayat();
  }, [search]);

  return (
    <>
      <UserDetailModal
        open={userDetailModalOpen}
        onClose={handleUserDetailModalClose}
        selectedUser={selected}
      />
      <Modal
        open={open}
        onClose={(e) => {
          if (typeof onClose === "function") {
            onClose(e);
          }
        }}
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal__container}>
          <Box sx={modal__header__container}>
            <Typography sx={modal__header__text}>
              Cari Riwayat Rekam Medis
            </Typography>
            <IconButton
              onClick={(e) => {
                if (typeof onClose === "function") {
                  onClose(e);
                }
              }}
              color="inherit"
              aria-label="refresh"
              edge="start"
              type="button"
            >
              <img
                width={25}
                height={25}
                effect="blur"
                src="/assets/icons/close.svg"
                alt="refresh"
              ></img>
            </IconButton>
          </Box>
          <Box sx={{ minHeight: "300px" }}>
            <Box sx={{ px: "5%", position: "relative", mb: "10px" }}>
              <FormControl variant="standard" fullWidth>
                <InputBase
                  onChange={debouncedSearchChangeHandler}
                  sx={input__text}
                  placeholder="Nama / Nomor Rekam Medis / No HP / NIK"
                  type="text"
                />
              </FormControl>
              <IconButton
                aria-label="search-user"
                sx={{
                  ...modal_btn,
                  position: "absolute",
                  right: "6%",
                  top: "4px",
                  p: "5px",
                  height: "30px",
                }}
              >
                <Typography sx={modal_btn__label}>Cari Riwayat</Typography>
              </IconButton>
            </Box>

            {rows.length < 1 && loading === true && (
              <>
                <Box
                  sx={{
                    px: "5%",
                  }}
                >
                  {_.times(5, (i) => {
                    return (
                      <Skeleton
                        key={`skeletion-${i}`}
                        sx={{ mb: 0.5, borderRadius: "8px" }}
                        variant="rectangular"
                        width="100%"
                        height="40px"
                      />
                    );
                  })}
                </Box>
              </>
            )}

            {rows.length < 1 && loading === false && (
              <Box sx={{ width: "100%", textAlign: "center", padding: "10px" }}>
                Tidak Ada Data
              </Box>
            )}

            {rows.length > 0 && loading === false && (
              <Paper
                elevation={0}
                sx={{ width: "100%", overflow: "hidden", position: "relative" }}
              >
                <TableContainer
                  elevation={0}
                  sx={{
                    maxHeight: "300px",
                    px: "3%",
                    ...webkit__scrollbar,
                    position: "relative",
                  }}
                >
                  <Table
                    sx={{ minWidth: "100%" }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        <TheadCell name="" useSort={false} />
                        <TheadCell name="Nama" useSort={false} />
                        <TheadCell name="No R.Medis" useSort={false} />
                        <TheadCell name="NIK" useSort={false} />
                        <TheadCell name="Nomor HP" useSort={false} />
                        <TheadCell name="" useSort={false} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, indx) => (
                        <TableRow
                          key={indx}
                          onClick={() => {
                            handleRedirect(row);
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:last-child td, &:last-child th": { border: 0 },
                            py: "0px",
                          }}
                        >
                          <TableCell width="3%" sx={tab__cell__text}>
                            <LazyLoadImage
                              effect="blur"
                              src={
                                row.photo_profile
                                  ? row.photo_profile
                                  : "/assets/icons/avatar.svg"
                              }
                              alt="profile"
                              width="30"
                              height="30"
                              style={{
                                borderRadius: "50%",
                              }}
                            ></LazyLoadImage>
                          </TableCell>
                          <TableCell align="left" sx={tab__cell__text}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={tab__cell__text}>
                                {row.nama}
                              </Typography>
                              {row.user_id && (
                                <img
                                  src="/assets/icons/link.svg"
                                  width="13"
                                  height="13"
                                  alt="link"
                                  style={{
                                    marginLeft: "3px",
                                  }}
                                ></img>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="left" sx={tab__cell__text}>
                            {row.nomor_rekam_medis}
                          </TableCell>
                          <TableCell align="left" sx={tab__cell__text}>
                            {row.ktp}
                          </TableCell>
                          <TableCell align="left" sx={tab__cell__text}>
                            {row.nomor}
                          </TableCell>
                          <TableCell
                            width="10%"
                            align="right"
                            sx={tab__cell__text}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <IconButton
                              onClick={() => {
                                handleSelectUser(row);
                              }}
                              data-group="popper-toggler"
                              color="inherit"
                              aria-label="act"
                              edge="start"
                              type="button"
                            >
                              <img
                                src="/assets/icons/table-action.svg"
                                alt="act"
                              ></img>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Box>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={handlePasienBaru}
              aria-label="search-user"
              sx={modal_btn}
            >
              <img src="/assets/icons/plus.svg" alt="create"></img>
              <Typography sx={modal_btn__label}>Pasien Baru</Typography>
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
