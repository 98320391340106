import { createSlice } from "@reduxjs/toolkit";

/**
 * Fungsi hanya increment saja untuk trigger use effect agar reload data
 * Listener FCM hanya ada 1 di index dashboard, agar tidak ribet passing data, pakai redux
 */

export const hardwareUpdate = createSlice({
  name: "hardware_update",
  initialState: {
    value: 0,
  },
  reducers: {
    updateHardware: (state) => {
      state.value += 1;
    },
  },
});

export const { updateHardware } = hardwareUpdate.actions;

export default hardwareUpdate.reducer;
