import { Grid, IconButton, InputBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import HardwareUsageCard from "./hardware-usage-card/HardwareUsageCard";
import { search__input } from "../../hasil-pemeriksaan/main/style";
import { filter__btn, header__container, header__h1 } from "./style";
import PenyimpananTable from "./table/PenyimpananTable";
import { SubscriptionUsage } from "./subscription-usage/SubscriptionUsage";
import { useAuth } from "../../../../../providers/AuthProvider";
import HardwareUsageCardLoading from "./hardware-usage-card/HardwareUsageCardLoading";
import authHeader from "../../../../../services/authHeader";
import "swiper/css";
import * as Sentry from "@sentry/react";

export default function Penyimpanan() {
  const { user } = useAuth();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  //table
  const [search, setSearch] = React.useState("");

  //widget
  const [widgetLoading, setWidgetLoading] = React.useState(true);
  const [usage, setUsage] = React.useState([]);

  //modal
  const [modalFilterOpen, setModalFilterOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const loadUsage = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/storage/usage`, {
        params: {
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        let find = response.data.find(
          (item) => item.clinic_id === current_active_schedule.clinic_id
        );
        if (find !== undefined) {
          setUsage(() => ({ ...find }));
        }
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      })
      .finally(() => {
        setWidgetLoading(false);
      });
  };

  useEffect(() => {
    loadUsage();
  }, []);

  return (
    <>
      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Penyimpanan
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InputBase
            sx={search__input}
            size="18"
            placeholder="Cari"
            value={search}
            onChange={handleSearchChange}
          ></InputBase>
          <IconButton
            aria-label="search"
            sx={{
              backgroundColor: "blue",
              borderRadius: "0px 8px 8px 0px",
              height: "38px",
            }}
          >
            <img src="/assets/icons/search.svg" alt="search"></img>
          </IconButton>
          <IconButton
            onClick={() => {
              setModalFilterOpen(!modalFilterOpen);
            }}
            aria-label="filter-btn"
            sx={filter__btn}
          >
            <img src="/assets/icons/sort.svg" alt="filter"></img>
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          marginBottom: "15px",
          width: "100%",
        }}
      >
        {widgetLoading ? (
          <HardwareUsageCardLoading />
        ) : (
          <Swiper
            spaceBetween={30}
            slidesPerView={"auto"}
            onSlideChange={() => {}}
            onSwiper={(swiper) => {}}
          >
            {usage &&
              usage.hardware_usage &&
              usage.hardware_usage.map((item, i) => {
                return (
                  <SwiperSlide
                    key={i}
                    style={{
                      width: "fit-content",
                      padding: "5px 0px",
                    }}
                  >
                    <HardwareUsageCard
                      hardwareName={item?.name ?? ""}
                      hardwareUsage={item?.usage?.clinic ?? 0}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        )}
      </Box>

      <Box>
        <Grid container spacing={1}>
          <Grid item lg={9} md={12} sm={12}>
            <PenyimpananTable
              search={search}
              modalFilterOpen={modalFilterOpen}
              setModalFilterOpen={setModalFilterOpen}
              loadUsage={loadUsage}
            />
          </Grid>
          <Grid item lg={3} md={12} sm={12}>
            <SubscriptionUsage usage={usage} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
