import { createSlice } from "@reduxjs/toolkit";

/**
 * Fungsi hanya increment saja untuk trigger use effect agar reload data
 * Listener FCM hanya ada 1 di index dashboard, agar tidak ribet passing data, pakai redux
 */

export const scanFolderUpdate = createSlice({
  name: "scan_folder_update",
  initialState: {
    value: 0,
  },
  reducers: {
    updateScanFolder: (state) => {
      state.value += 1;
    },
  },
});

export const { updateScanFolder } = scanFolderUpdate.actions;
export default scanFolderUpdate.reducer;
