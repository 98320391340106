import { Grid, IconButton, Modal, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  modal__container,
  modal__header__container,
  modal__header__text,
  tab__label,
} from "./style";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import TimelineList from "../../main/timeline/user-detail/timeline-list/TimelineList";
import a11yProps from "../../../../../components/tab/a11yProps";
import UserRecordDetailContent from "../../main/timeline/user-record-detail/content/UserRecordDetailContent";
import * as Sentry from "@sentry/react";

export default function RiwayatRekamMedisModal({
  open,
  onClose,
  userId,
  referenceType,
  selectedMedicalRecordId,
  setSelectedMedicalRecordId,
}) {
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const timeline_group_mode = useSelector((state) => state.timeline_group_mode);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [tab, setTab] = useState("Data Medis");
  const [tabNum, setTabNum] = useState(0);

  const [medicalRecord, setMedicalRecord] = useState({});
  const [userDetail, setUserDetail] = useState({});

  const loadUserDetail = async () => {
    setMedicalRecord({});
    setUserDetail({});
    if (userId !== null && userId !== undefined) {
      let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/timeline/${userId}`;
      if (referenceType === "MEDICALRECORD") {
        url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/unlinked-timeline/${userId}`;
      }
      axios
        .get(url, {
          params: {
            clinic: current_active_schedule.clinic_id,
            head: user.head_doctor_id,
            "timeline-group-mode": timeline_group_mode,
          },
          headers: authHeader(),
        })
        .then((response) => {
          setUserDetail({
            ...response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
          Sentry.captureException(error);
        });
    } else {
      enqueueSnackbar("data riwayat tidak ditemukan", {
        variant: "error",
      });
    }
  };

  const handleTimelineItemClick = (medicalRecordId) => {
    setSelectedMedicalRecordId(medicalRecordId);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records/${medicalRecordId}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setMedicalRecord(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  const handleTabChange = (e, value) => {
    let tab = "Data Medis";
    if (value === 1) {
      tab = "Data Spesialis";
    }
    if (value === 2) {
      tab = "Data Soap";
    }
    if (value === 3) {
      tab = "Data Resep";
    }
    if (value === 4) {
      tab = "Data Internal";
    }
    if (value === 5) {
      tab = "Data Pemeriksaan";
    }
    setTabNum(value);
    setTab(tab);
  };

  useEffect(() => {
    if (userId !== null && userId !== undefined) {
      loadUserDetail();
    } else {
      setMedicalRecord({});
      setUserDetail({});
    }
  }, [userId]);

  useEffect(() => {
    if (selectedMedicalRecordId)
      handleTimelineItemClick(selectedMedicalRecordId);
  }, [selectedMedicalRecordId]);

  return (
    <Modal
      open={open}
      onClose={(e) => {
        if (typeof onClose === "function") {
          onClose(e);
        }
      }}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal__container}>
        <Box sx={modal__header__container}>
          <Typography sx={modal__header__text}>Riwayat Rekam Medis</Typography>
          <IconButton
            onClick={(e) => {
              if (typeof onClose === "function") {
                onClose(e);
              }
            }}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Box
              sx={{
                paddingTop: "20px,",
              }}
            >
              <TimelineList
                timelines={userDetail?.timeline ?? null}
                maxHeight="430px"
                showInvoicePrice={false}
                onClick={handleTimelineItemClick}
                currentSelectedTimelineId={selectedMedicalRecordId}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Tabs
              value={tabNum}
              variant="scrollable"
              onChange={handleTabChange}
              aria-label="tabk"
              sx={{
                ".MuiTabs-flexContainer": {
                  background: "transparent",
                },
                overflowX: "auto",
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#FF0091",
                  height: "3px",
                },
              }}
            >
              <Tab
                sx={{
                  mx: 0.1,
                  pl: 0.5,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      px: 0.1,
                      color: tabNum === 0 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Data Medis
                  </Typography>
                }
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  mx: 0.1,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      px: 0.1,
                      color: tabNum === 1 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Data Spesialis
                  </Typography>
                }
                {...a11yProps(1)}
              />
              <Tab
                sx={{
                  mx: 0.1,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      px: 0.1,
                      color: tabNum === 2 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    SOAP
                  </Typography>
                }
                {...a11yProps(2)}
              />
              <Tab
                sx={{
                  mx: 0.1,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      px: 0.1,
                      color: tabNum === 3 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Resep & IER
                  </Typography>
                }
                {...a11yProps(3)}
              />
              <Tab
                sx={{
                  mx: 0.1,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      px: 0.1,
                      color: tabNum === 4 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Internal
                  </Typography>
                }
                {...a11yProps(4)}
              />
              <Tab
                sx={{
                  mx: 0.1,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      px: 0.1,
                      color: tabNum === 5 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Hasil Pemeriksaan
                  </Typography>
                }
                {...a11yProps(5)}
              />
            </Tabs>
            <UserRecordDetailContent
              medicalRecord={medicalRecord}
              tab={tab}
              maxHeight={"400px"}
              handleTabClose={onClose}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
