import {
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  user__info__container,
  search__container,
  search__icon__img,
  user__list__container,
  user__info__img,
  user__info__text,
} from "./style";
import PropTypes from "prop-types";
import { search__input } from "../../../../../components/modal/send-antrian-modal/style";
import { input__select } from "../../../editor/style";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { changeTimelineGroupMode } from "../../../../../../../redux/slices/timelineGroupMode";
import * as Sentry from "@sentry/react";
import axios from "axios";
import authHeader from "../../../../../../../services/authHeader";
import _ from "lodash";
import { useAuth } from "../../../../../../../providers/AuthProvider";

function UserList({
  list,
  handleUserClick,
  currentSelectedUser,
  userListLoading,
}) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const timeline_group_mode = useSelector((state) => state.timeline_group_mode);
  const [internalSearch, setInternalSearch] = useState("");

  const isUserChoosed = (currentSelectedUser, user) => {
    // console.log(currentSelectedUser, user);
    if (!currentSelectedUser || !user) {
      return false;
    } else {
      if (currentSelectedUser.type === "UNLINKED") {
        if (currentSelectedUser.medical_record_id === user.medical_record_id) {
          return true;
        } else {
          return false;
        }
      } else {
        if (currentSelectedUser.user_id === user.user_id) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const saveTimelineGroupMode = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/me/timeline-grouping-mode`,
        {
          timeline_group_mode: timeline_group_mode,
        },
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleModeGroupChange = (e) => {
    dispatch(changeTimelineGroupMode(e.target.value));
  };

  useEffect(() => {
    saveTimelineGroupMode();
  }, [timeline_group_mode]);

  return (
    <Paper elevation={0}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
          marginBottom: "10px",
          paddingLeft: "10px",
        }}
      >
        <FormControl
          variant="standard"
          sx={{
            width: "100%",
            marginBottom: "0px",
          }}
        >
          <Select
            id="mode_timeline"
            name="mode_timeline"
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
            }}
            IconComponent={() => {
              return <></>;
            }}
            value={timeline_group_mode}
            sx={{
              ...input__select,
              background: "rgba(0, 174, 239, 1)",
              color: "white",
              fontWeight: "normal",
            }}
            onChange={handleModeGroupChange}
          >
            <MenuItem value={""} selected={true}>
              Pilih Mode Grouping
            </MenuItem>
            vr.
            <MenuItem value={"ktp"}>Grouping : NIK/Kartu Indentitas</MenuItem>
            <MenuItem value={"nomor"}>Grouping : Nomor HP</MenuItem>
            <MenuItem value={"nomor_rekam_medis"}>
              Grouping : Nomor Rekam Medis
            </MenuItem>
          </Select>
        </FormControl>
        <Tooltip
          title="Data rekam medis yang tidak terasosiasi dengan pengguna mediplus akan dikelompokkan berdasarkan data yang dipilih"
          placement="right"
        >
          <IconButton>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={search__container}>
        <InputBase
          sx={{
            ...search__input,
            "&.MuiInputBase-root": {
              paddingRight: "10px",
              width: "100%",
            },
            "& .MuiInputBase-input": {
              ...search__input["& .MuiInputBase-input"],
              borderRadius: "8px",
            },
          }}
          onChange={(e) => {
            setInternalSearch(e.target.value);
          }}
        ></InputBase>
        <img
          src="/assets/icons/search-black.svg"
          alt="search"
          style={search__icon__img}
        ></img>
      </Box>
      <Box sx={user__list__container}>
        {userListLoading ? (
          <Box
            sx={{
              px: "10px",
            }}
          >
            {_.times(6, (i) => {
              return (
                <Skeleton
                  key={i}
                  sx={{ mb: 0.5, borderRadius: "8px" }}
                  variant="rectangular"
                  width={"100%"}
                  height={"60px"}
                />
              );
            })}
          </Box>
        ) : (
          <>
            {list &&
              list.length > 0 &&
              list.map((item, key) => {
                if (internalSearch) {
                  if (
                    !item.nama
                      .toLocaleLowerCase()
                      .includes(internalSearch.toLocaleLowerCase())
                  ) {
                    return false;
                  }
                }
                return (
                  <Paper
                    onClick={(e) => {
                      if (typeof handleUserClick === "function") {
                        handleUserClick(key, e);
                      }
                    }}
                    key={`timeline_${key}`}
                    sx={{
                      ...user__info__container,
                      border: isUserChoosed(currentSelectedUser, item)
                        ? "1px solid rgba(0, 174, 239, 1)"
                        : "none",
                    }}
                  >
                    <img
                      src={
                        item.photo_profile
                          ? item.photo_profile
                          : "/assets/icons/avatar.svg"
                      }
                      alt="doctor"
                      style={user__info__img}
                    ></img>
                    <Typography
                      data-user-id={item.user_id}
                      component={"span"}
                      sx={user__info__text}
                    >
                      {item.nama}
                    </Typography>
                    {item.user_id && (
                      <img
                        src="/assets/icons/link.svg"
                        width="13"
                        height="13"
                        alt="link"
                      ></img>
                    )}
                  </Paper>
                );
              })}
          </>
        )}
      </Box>
    </Paper>
  );
}

UserList.propTypes = {
  list: PropTypes.array,
  handleUserClick: PropTypes.func,
  currentSelectedUserId: PropTypes.any,
};

export default UserList;
