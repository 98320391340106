import React from "react";

export default function BridgeEndReverse() {
  return (
    <img
      src="/assets/odontogram/bridge-end.svg"
      alt="brigde-end"
      style={{
        WebkitTransform: "scaleY(-1)",
        transform: "scaleY(-1)",
      }}
    ></img>
  );
}
