import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { header__tab__btn, header__tab__btn__label } from "./style";
import PropTypes from "prop-types";

function UserRecordDetailHeader({ tab, setTab }) {
  const handleTabChange = (tabName) => {
    if (typeof setTab === "function") {
      setTab(tabName);
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            ...header__tab__btn,
            backgroundColor:
              tab === "Data Medis" ? "rgba(0, 174, 239, 0.15)" : "white",
            border: tab === "Data Medis" ? "1px solid #00AEEF" : "none",
          }}
          onClick={() => {
            handleTabChange("Data Medis");
          }}
        >
          <img
            src="/assets/icons/medis.svg"
            width="30"
            height="30"
            alt="medis"
          ></img>
          <Typography sx={header__tab__btn__label}>Data Medis</Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            ...header__tab__btn,
            backgroundColor:
              tab === "Data Spesialis" ? "rgba(0, 174, 239, 0.15)" : "white",
            border: tab === "Data Spesialis" ? "1px solid #00AEEF" : "none",
          }}
          onClick={() => {
            handleTabChange("Data Spesialis");
          }}
        >
          <img
            src="/assets/icons/spesialis.svg"
            width="30"
            height="30"
            alt="spesialis"
          ></img>
          <Typography sx={header__tab__btn__label}>Data Spesialis</Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            ...header__tab__btn,
            backgroundColor:
              tab === "Data Soap" ? "rgba(0, 174, 239, 0.15)" : "white",
            border: tab === "Data Soap" ? "1px solid #00AEEF" : "none",
          }}
          onClick={() => {
            handleTabChange("Data Soap");
          }}
        >
          <img
            src="/assets/icons/soap.svg"
            width="30"
            height="30"
            alt="soap"
          ></img>
          <Typography sx={header__tab__btn__label}>SOAP</Typography>
        </Paper>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            ...header__tab__btn,
            backgroundColor:
              tab === "Data Resep" ? "rgba(0, 174, 239, 0.15)" : "white",
            border: tab === "Data Resep" ? "1px solid #00AEEF" : "none",
          }}
          onClick={() => {
            handleTabChange("Data Resep");
          }}
        >
          <img
            src="/assets/icons/resep.svg"
            width="30"
            height="30"
            alt="medis"
          ></img>
          <Typography sx={header__tab__btn__label}>Resep & IER</Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            ...header__tab__btn,
            backgroundColor:
              tab === "Data Internal" ? "rgba(0, 174, 239, 0.15)" : "white",
            border: tab === "Data Internal" ? "1px solid #00AEEF" : "none",
          }}
          onClick={() => {
            handleTabChange("Data Internal");
          }}
        >
          <img
            src="/assets/icons/internal.svg"
            width="30"
            height="30"
            alt="medis"
          ></img>
          <Typography sx={header__tab__btn__label}>Internal</Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            ...header__tab__btn,
            backgroundColor:
              tab === "Data Pemeriksaan" ? "rgba(0, 174, 239, 0.15)" : "white",
            border: tab === "Data Pemeriksaan" ? "1px solid #00AEEF" : "none",
          }}
          onClick={() => {
            handleTabChange("Data Pemeriksaan");
          }}
        >
          <img
            src="/assets/icons/pemeriksaan.svg"
            width="30"
            height="30"
            alt="medis"
          ></img>
          <Typography sx={header__tab__btn__label}>Pemeriksaan</Typography>
        </Paper>
      </Box>
    </Box>
  );
}

UserRecordDetailHeader.propTypes = {
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func,
};

export default UserRecordDetailHeader;
