import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import {
  hardware__list__container,
  hardware__list__item,
  hardware__list__item__img,
  hardware__list__item__info__container,
  hardware__list__item__info__hwname,
  hardware__list__item__info__status__container,
  hardware__list__item__info__status__text__label,
  hardware__list__item__info__status__text__value,
} from "./style";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import * as Sentry from "@sentry/react";

export default function HardwareStatus() {
  const { user } = useAuth();
  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const hardware_update = useSelector((state) => state.hardware_update.value);

  //misc
  const [hardwares, setHardwares] = useState([]);

  const loadData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/hardwares`, {
        params: {
          clinic: current_active_schedule.clinic_id,
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setHardwares((prev) => [...response.data]);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [current_active_schedule, hardware_update]);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          marginBottom: "20px",
        }}
      >
        <Box sx={hardware__list__container}>
          {hardwares &&
            hardwares.length > 0 &&
            hardwares.map((item, idx) => {
              return (
                <Paper key={idx} sx={hardware__list__item}>
                  <img
                    src={
                      item.photo_profile
                        ? item.photo_profile
                        : "/assets/icons/clinic.svg"
                    }
                    alt="doctor"
                    style={hardware__list__item__img}
                  ></img>
                  <Box
                    component={"div"}
                    sx={hardware__list__item__info__container}
                  >
                    <Typography
                      component={"span"}
                      sx={hardware__list__item__info__hwname}
                    >
                      {item.hardware}
                    </Typography>
                    <Box sx={hardware__list__item__info__status__container}>
                      <Typography
                        component={"span"}
                        sx={hardware__list__item__info__status__text__label}
                      >
                        Status
                      </Typography>
                      <Box
                        sx={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "50%",
                          background:
                            item.status === "ONLINE"
                              ? "#00A049"
                              : item.status === "SENDING"
                              ? "#00AEEF"
                              : "#FF0000",
                          mx: 1,
                        }}
                      ></Box>
                      <Typography
                        component={"span"}
                        sx={hardware__list__item__info__status__text__value}
                      >
                        {mediplusHelper.ucwords(item.status?.toLowerCase())}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              );
            })}
        </Box>
      </Paper>
    </>
  );
}
