import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import mediplusHelper from "../../../../../../../../utils/mediplusHelper";
import MediaModal from "../../../../../../components/modal/media-modal/MediaModal";
import {
  record__box__header_text,
  record__box__header__container,
  record__box__img,
  record__box__media__container,
  record__box__pdf,
  record__box__pdf__icon,
  record__box__video,
  record__box__video__icon,
  record__container,
  record__section__divider,
  record__text__label,
  record__text__value,
} from "./style";

import PropTypes from "prop-types";
import { useSnackbar } from "notistack";

function UserRecordDetailContent({
  medicalRecord,
  tab,
  maxHeight,
  handleTabClose,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [media, setMedia] = useState(null);

  const handleMediaClose = () => {
    setMedia(null);
  };

  const handleMediaOpen = (media) => {
    if (
      mediplusHelper.isImageMime(media.mime_type) ||
      mediplusHelper.isVideoMime(media.mime_type)
    ) {
      setMedia(media);
    } else {
      window.open(media.s3_url, "_blank");
      setMedia(null);
    }
  };

  const handleRedirectEdit = () => {
    if (!medicalRecord?.id) {
      enqueueSnackbar("silahkan pilih rekam medis terlebuh dahulu");
      return;
    }
    let tabNum = 1;
    if (tab === "Data Spesialis") {
      tabNum = 2;
    } else if (tab === "Data Soap") {
      tabNum = 3;
    } else if (tab === "Data Resep") {
      tabNum = 4;
    } else if (tab === "Data Internal") {
      tabNum = 5;
    } else if (tab === "Data Pemeriksaan") {
      tabNum = 6;
    }
    if (typeof handleTabClose === "function") {
      handleTabClose();
    }
    history.push(`/rekam-medis/editor/${medicalRecord.id}?tab=${tabNum}`);
    enqueueSnackbar("data di load", {
      variant: "success",
      autoHideDuration: 1500,
    });
    return;
  };
  return (
    <>
      <MediaModal media={media} onClose={handleMediaClose} />
      <Box
        sx={{
          ...record__container,
          maxHeight: maxHeight ? maxHeight : "270px",
        }}
      >
        <Paper elevation={0}>
          <Box sx={record__box__header__container}>
            <Typography sx={record__box__header_text}>{tab}</Typography>

            <IconButton
              onClick={handleRedirectEdit}
              aria-label="daaa"
              edge="start"
              type="button"
            >
              <img src="/assets/icons/pencil2.svg" alt="opt"></img>
            </IconButton>
          </Box>
        </Paper>
        <Box
          sx={{
            paddingLeft: "10px",
          }}
        >
          <Box
            id="data-medis"
            sx={{
              display: tab === "Data Medis" ? "block" : "none",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>
                Tanggal Pemeriksaan
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>
                    Tanggal periksa
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.tanggal_periksa
                      ? moment(medicalRecord?.tanggal_periksa).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>
                    Usia dari tanggal periksa
                  </Typography>
                  <Typography sx={record__text__value}>
                    {mediplusHelper.getAge(
                      medicalRecord?.user?.tanggal_lahir
                        ? medicalRecord?.user?.tanggal_lahir
                        : medicalRecord?.record_temporary_patient_data
                            ?.tanggal_lahir
                        ? medicalRecord?.record_temporary_patient_data
                            ?.tanggal_lahir
                        : null,
                      medicalRecord?.tanggal_periksa
                        ? medicalRecord?.tanggal_periksa
                        : medicalRecord?.created_at ?? null
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>Fisik</Typography>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>Tinggi</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_physical?.tinggi_badan} cm
                  </Typography>

                  <Typography sx={record__text__label}>BSA</Typography>
                  <Typography sx={record__text__value}>
                    {mediplusHelper.calculateBSA(
                      medicalRecord?.record_physical?.tinggi_badan,
                      medicalRecord?.record_physical?.berat_badan
                    )}
                  </Typography>

                  <Typography sx={record__text__label}>Kategori</Typography>
                  <Typography sx={record__text__value}>
                    {mediplusHelper.BMICategory(
                      mediplusHelper.calculateBMI(
                        medicalRecord?.record_physical?.tinggi_badan,
                        medicalRecord?.record_physical?.berat_badan
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>Berat</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_physical?.berat_badan} kg
                  </Typography>

                  <Typography sx={record__text__label}>BMI</Typography>
                  <Typography sx={record__text__value}>
                    {mediplusHelper.calculateBMI(
                      medicalRecord?.record_physical?.tinggi_badan,
                      medicalRecord?.record_physical?.berat_badan
                    )}
                  </Typography>

                  <Typography sx={record__text__label}>
                    Lingkar Kepala
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_physical?.lingkar_kepala} cm
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>
                Tekanan Darah
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>Systole</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_blood_pressure?.sistol ?? "-"} mmHg
                  </Typography>

                  <Typography sx={record__text__label}>Heart Rate</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_blood_pressure?.bpm ?? "-"} /Min
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>Diastole</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_blood_pressure?.diastol ?? "-"} mmHg
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>Tanda Vital</Typography>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>Suhu</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_vital_sign?.temp ?? "-"} C
                  </Typography>

                  <Typography sx={record__text__label}>
                    Respiration Rate
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_vital_sign?.rr ?? "-"} /Min
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>SPO2</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_vital_sign?.spo2 ?? "-"} %
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>
                Riwayat Medis
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography sx={record__text__label}>
                    Golongan Darah
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_medical_history?.golongan_darah ??
                      "-"}
                  </Typography>
                  <Typography sx={record__text__label}>
                    Riwayat Penyakit
                  </Typography>

                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_medical_history?.riwayat_penyakit ??
                      "-"}
                  </Typography>
                  <Typography sx={record__text__label}>
                    Riwayat Alergi
                  </Typography>

                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_medical_history?.riwayat_alergi ??
                      "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            id="data-spesialis"
            sx={{
              display: tab === "Data Spesialis" ? "block" : "none",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>HPHT</Typography>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>
                    Tanggal Terakhir Menstruasi
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_obgyn?.tanggal_menstruasi_terakhir
                      ? moment(
                          medicalRecord?.record_obgyn
                            ?.tanggal_menstruasi_terakhir
                        ).format("DD/MM/YYYY")
                      : "-"}
                  </Typography>

                  <Typography sx={record__text__label}>
                    Usia Kandungan
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_obgyn?.usia_kehamilan ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>
                    Estimasi Kelahiran
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_obgyn?.tanggal_perkiraan_lahir
                      ? moment(
                          medicalRecord?.record_obgyn?.tanggal_perkiraan_lahir
                        ).format("DD/MM/YYYY")
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>Kandungan</Typography>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>Gravida</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_gestational?.gravida ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>Para</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_gestational?.para ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>Ectopic</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_gestational?.ectopic ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={record__text__label}>Fetuces</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_gestational?.fetuces ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>Aborta</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_gestational?.aborta ?? "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            id="data-soap"
            sx={{
              display: tab === "Data Soap" ? "block" : "none",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>SOAP</Typography>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography sx={record__text__label}>Subjektif</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_soap?.subjektif ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>Objektif</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_soap?.objektif ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>Penilaian</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_soap?.assesment ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>Perencanaan</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_soap?.plan ?? "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>Diagnosa</Typography>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography sx={record__text__label}>Diagnosa</Typography>
                  <Typography sx={record__text__value} component="span">
                    {medicalRecord?.record_diagnose?.diagnosa &&
                      Array.isArray(medicalRecord?.record_diagnose?.diagnosa) &&
                      medicalRecord?.record_diagnose?.diagnosa.length > 0 &&
                      medicalRecord?.record_diagnose?.diagnosa.map(
                        (item, idx) => {
                          return (
                            <Typography
                              key={idx}
                              component="span"
                              sx={{
                                fontSize: "12px",
                              }}
                            >
                              {item.icd10_code} - {item.who_full_desc}
                            </Typography>
                          );
                        }
                      )}
                    {typeof medicalRecord?.record_diagnose?.diagnosa ===
                    "string"
                      ? medicalRecord?.record_diagnose?.diagnosa
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            id="data-resep"
            sx={{
              display: tab === "Data Resep" ? "block" : "none",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>Resep</Typography>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography sx={record__text__label}>Obat</Typography>
                  <Typography
                    sx={{
                      ...record__text__value,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    component={"span"}
                  >
                    {medicalRecord?.record_recipe?.resep ? (
                      <>
                        {mediplusHelper.hasJsonStructure(
                          medicalRecord.record_recipe.resep
                        )
                          ? JSON.parse(medicalRecord.record_recipe.resep).map(
                              (item, index) => {
                                return (
                                  <Typography
                                    sx={{
                                      ...record__text__value,
                                      marginBottom: "3px",
                                    }}
                                    key={index}
                                    component={"span"}
                                  >
                                    {item.nama} | {item.aturan_minum} |{" "}
                                    {item.keterangan}
                                  </Typography>
                                );
                              }
                            )
                          : medicalRecord.record_recipe.resep}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>

                  <Typography sx={record__text__label}>
                    Impelementasi
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_recipe?.implementasi ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>Evaluasi</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_recipe?.evaluasi ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>Revisi</Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_recipe?.revisi ?? "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            id="data-internal"
            sx={{
              display: tab === "Data Internal" ? "block" : "none",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>Internal</Typography>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography sx={record__text__label}>
                    Catatan Internal
                  </Typography>
                  <Typography sx={record__text__value}>
                    {medicalRecord?.record_internal?.catatan ?? "-"}
                  </Typography>

                  <Typography sx={record__text__label}>File</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      gap: 4,
                    }}
                  >
                    {medicalRecord?.record_internal?.media &&
                      medicalRecord?.record_internal?.media.map((item) => {
                        if (mediplusHelper.isImageMime(item.mime_type)) {
                          return (
                            <Paper
                              onClick={() => {
                                handleMediaOpen(item);
                              }}
                              key={item.id}
                              sx={record__box__media__container}
                            >
                              <img
                                src={item.s3_url}
                                alt="media"
                                style={record__box__img}
                              />
                            </Paper>
                          );
                        } else if (mediplusHelper.isVideoMime(item.mime_type)) {
                          return (
                            <Paper
                              onClick={() => {
                                handleMediaOpen(item);
                              }}
                              key={item.id}
                              sx={record__box__media__container}
                            >
                              <video style={record__box__video}>
                                <source
                                  src={item.s3_url}
                                  type="video/mp4"
                                ></source>
                              </video>
                              <LazyLoadImage
                                style={record__box__video__icon}
                                src="/assets/chat/play.png"
                              ></LazyLoadImage>
                            </Paper>
                          );
                        } else {
                          return (
                            <Paper
                              onClick={() => {
                                handleMediaOpen(item);
                              }}
                              key={item.id}
                              sx={record__box__pdf}
                            >
                              <img
                                style={record__box__pdf__icon}
                                src="/assets/icons/document-app.svg"
                                alt="doc"
                              ></img>
                            </Paper>
                          );
                        }
                      })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            id="data-pemeriksaan"
            sx={{
              display: tab === "Data Pemeriksaan" ? "block" : "none",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid rgba(212, 212, 212, 1)",
                marginBottom: "10px",
              }}
            >
              <Typography sx={record__section__divider}>
                Hasil Pemeriksaan
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography sx={record__text__label}>
                    File Pemeriksaan
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      gap: 4,
                    }}
                  >
                    {medicalRecord?.media &&
                      medicalRecord?.media.map((item, index) => {
                        if (mediplusHelper.isImageMime(item.mime_type)) {
                          return (
                            <Paper
                              onClick={() => {
                                handleMediaOpen(item);
                              }}
                              key={item.id}
                              sx={record__box__media__container}
                            >
                              <img
                                src={item.s3_url}
                                alt="media"
                                style={record__box__img}
                              />
                            </Paper>
                          );
                        } else if (mediplusHelper.isVideoMime(item.mime_type)) {
                          return (
                            <Paper
                              onClick={() => {
                                handleMediaOpen(item);
                              }}
                              key={item.id}
                              sx={record__box__media__container}
                            >
                              <video style={record__box__video}>
                                <source
                                  src={item.s3_url}
                                  type="video/mp4"
                                ></source>
                              </video>
                              <LazyLoadImage
                                style={record__box__video__icon}
                                src="/assets/chat/play.png"
                              ></LazyLoadImage>
                            </Paper>
                          );
                        } else {
                          return (
                            <Paper
                              onClick={() => {
                                handleMediaOpen(item);
                              }}
                              key={item.id}
                              sx={record__box__pdf}
                            >
                              <img
                                style={record__box__pdf__icon}
                                src="/assets/icons/document-app.svg"
                                alt="doc"
                              ></img>
                            </Paper>
                          );
                        }
                      })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

UserRecordDetailContent.propTypes = {
  medicalRecord: PropTypes.object,
  tab: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default UserRecordDetailContent;
