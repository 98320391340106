const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "30px",
};
const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

const header__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  marginLeft: "10px",
  height: "38px",
  color: "white",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const header__btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "10px",
};

const clinic__selector__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
  marginBottom: "10px",
};

const clinic__selector__select = {
  borderRadius: "8px",
  backgroundColor: "rgba(239, 241, 249, 1)",
  border: "1px solid #ced4da",
  paddingLeft: "12px",
  py: 0.5,
  fontSize: "12px",
  ":hover:not(.Mui-disabled):before": {
    border: "none",
  },
  ":hover": {
    border: "1px solid #ced4da",
  },
  ":before": {
    border: "none",
  },
  "label + &": {
    marginTop: "24px",
  },
};

const calendar__now__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const calendar__now__hour__text = {
  fontSize: "18px",
  fontWeight: 600,
  textAlign: "right",
};

const calendar__now__tz__text = {
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "right",
};

const year__selector__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
  p: 1.5,
  borderTop: "1px solid #D5D5D5",
  borderBottom: "1px solid #D5D5D5",
  borderRight: "1px solid #D5D5D5",
};

const year__selector__selected__text = {
  fontSize: "16px",
  fontWeight: 700,
};

const year__selector__btn__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
};

const month__selector__container = {
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  borderTop: "1px solid #D5D5D5",
  borderBottom: "1px solid #D5D5D5",
};

const month__btn = {
  fontSize: "16px",
  fontWeight: 600,
  borderRadius: "5px",
  px: 1,
  cursor: "pointer",
};

const month__btn__selected = {
  ...month__btn,
  background: "#FF0091",
  color: "white",
};

const date__selector__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
  p: 1.5,
};

const selected__date__text = {
  fontSize: "16px",
  fontWeight: 700,
  marginLeft: "40px",
  marginBottom: "10px",
};

export {
  header__container,
  header__h1,
  header__btn,
  header__btn__label,
  clinic__selector__container,
  clinic__selector__select,
  calendar__now__container,
  calendar__now__hour__text,
  calendar__now__tz__text,
  year__selector__container,
  year__selector__selected__text,
  year__selector__btn__container,
  month__selector__container,
  month__btn,
  month__btn__selected,
  date__selector__container,
  selected__date__text,
};
