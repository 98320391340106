import {
  IconButton,
  InputBase,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";

import { input, modal__title, modal__title__text } from "./style";
import { useAuth } from "../../../../../../providers/AuthProvider";
import axios from "axios";
import { useSelector } from "react-redux";
import authHeader from "../../../../../../services/authHeader";
import * as Sentry from "@sentry/react";
import TheadCell from "../../../../components/table/thead-cell/TheadCell";
import CustomObatRow from "./CustomObatRow";
import { table__container } from "../../../pembayaran/main/style";
import { webkit__scrollbar } from "../../../../style";

function ListObatModal({ open, onClose, onChoose }) {
  const { user } = useAuth();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  //table
  const [rows, setRows] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("nama");
  const [sortType, setSortType] = React.useState("ASC");
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [lastPage, setLastPage] = React.useState(1);

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medicines`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: 5,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleDataChoose = (data) => {
    if (typeof onChoose === "function") {
      onChoose(data);
    }
  };

  React.useEffect(() => {
    loadData();
    return () => {};
  }, [current_active_schedule, page, sortBy, sortType]);

  React.useEffect(() => {
    setPage(1);
    loadData();
  }, [search]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1,
          borderRadius: "10px",
          marginBottom: "10px",
          maxHeight: "95vh",
          ...webkit__scrollbar,
        }}
      >
        <Box id="modal-modal-description">
          <Box sx={modal__title}>
            <Typography sx={modal__title__text}>List Obat</Typography>
            <IconButton
              onClick={handleModalClose}
              color="inherit"
              aria-label="refresh"
              edge="start"
              type="button"
            >
              <img
                width={25}
                height={25}
                effect="blur"
                src="/assets/icons/close.svg"
                alt="refresh"
              ></img>
            </IconButton>
          </Box>
          <Box
            sx={{
              px: 1,
            }}
          >
            <InputBase
              autoComplete="false"
              placeholder="Cari"
              value={search}
              type="text"
              onChange={handleSearchChange}
              sx={input}
              fullWidth
            />
          </Box>
        </Box>
        <Box>
          <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{
                ...table__container,
                minHeight: "370px",
                maxHeight: "370px",
              }}
            >
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TheadCell
                      name="Nama"
                      useSort={true}
                      column_name="nama"
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    />
                    <TheadCell
                      name="Satuan"
                      useSort={true}
                      column_name="satuan"
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    />

                    <TheadCell name="Action" useSort={false} align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <CustomObatRow
                      key={row.id}
                      row={row}
                      handleDataChoose={handleDataChoose}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Stack alignItems="end">
            <Pagination
              shape="rounded"
              sx={{
                p: 2,
                ".Mui-selected": {
                  backgroundColor: "rgba(0, 174, 239, 1)!important",
                  color: "white",
                },
              }}
              count={lastPage}
              page={page}
              showFirstButton
              showLastButton
              onChange={handlePageChange}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}

ListObatModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onChoose: PropTypes.func,
};

export default ListObatModal;
