const chat__container = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  minWidth: "100%",
  justifyContent: "space-between",
};

const avatar__container = {
  width: "44px",
  height: "44px",
  background: "none",
};

const avatar__img = {
  borderRadius: "50%",
  width: "44px",
  height: "44px",
};

const chat__video__wrapper = {
  width: "calc(100% - 88px)",
  background: "none",
};

const chat__video__container = {
  pt: 0.5,
  px: 1,
  display: "flex",
  justifyContent: "start",
};

const chat__video__bubble = {
  width: "fit-content",
  maxWidth: "50%",
  px: 1,
  pt: 1,
  background: "#EFF1F9",
  borderRadius: "0px 8px 8px 8px",
};

const chat__video__img__box = {
  maxHeight: "170px",
  position: "relative",
  overflow: "hidden",
  borderRadius: "8px",
  mb: 0.5,
};

const img__container = {
  position: "relative",
  minHeight: "100px",
  minWidth: "100px",
  cursor: "pointer",
};

const img = {
  display: "block",
  width: "100%",
  height: "105%",
  objectFit: "cover",
};

const play__icon = {
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const chat__video__footer__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignContent: "center",
  alignItems: "center",
  gap: "2px",
  pl: 0.5,
};

const chat__video__footer__time = {
  fontSize: "10px",
  fontWeight: 400,
  textAlign: "right",
};

export {
  chat__container,
  avatar__container,
  avatar__img,
  chat__video__wrapper,
  chat__video__container,
  chat__video__bubble,
  chat__video__img__box,
  img__container,
  img,
  play__icon,
  chat__video__footer__container,
  chat__video__footer__time,
};
