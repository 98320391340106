import React from "react";
import { Grid, Skeleton } from "@mui/material";

export default function HardwareUsageCardLoading() {
  return (
    <Grid container spacing={3}>
      <Grid item lg={3} md={6} sm={6}>
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: "8px",
          }}
          width={"100%"}
          height={97}
        />
      </Grid>
      <Grid item lg={3} md={6} sm={6}>
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: "8px",
          }}
          width={"100%"}
          height={97}
        />
      </Grid>
    </Grid>
  );
}
