import { useTheme } from "@emotion/react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../../providers/AuthProvider";
import {
  input__date,
  input__label,
  input__text,
  input__text__date__disabled,
  section__title,
  tab__switch__btn,
  tab__switch__btn__label,
} from "../style";
import { isMacOs, isSafari, browserVersion } from "react-device-detect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../datepicker.css";
import moment from "moment";

export default function TabDataSpesialis({
  form,
  handleGeneralInputFormChange,
  handleOdontogramViewOpen,
}) {
  const { user } = useAuth();
  const [legacyBrowser, setLegacyBrowser] = useState(false);

  const handleWheel = (event) => {
    event.target.blur();
    event.stopPropagation();
  };

  useEffect(() => {
    if (isMacOs && isSafari) {
      let vrs = parseInt(browserVersion);
      if (!isNaN(vrs) && vrs < 14) {
        setLegacyBrowser(true);
      }
    }
  }, []);

  return (
    <>
      {user.specialist_id === 2 && (
        <>
          <Typography sx={section__title}>Odontogram</Typography>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={6} lg={3}>
                <Button
                  onClick={handleOdontogramViewOpen}
                  sx={{
                    backgroundColor: "blue",
                    borderRadius: "8px",
                    textTransform: "none",
                    marginBottom: "15px",
                    color: "white",
                    width: "100%",
                  }}
                  variant="contained"
                >
                  <Typography sx={tab__switch__btn__label}>
                    Buka Form Odontogram
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {user.specialist_id === 1 && (
        <>
          <Typography sx={section__title}>HPHT</Typography>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={6} lg={3}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel
                    sx={input__label}
                    shrink
                    htmlFor="tanggal_terakhir_menstruasi"
                  >
                    Tanggal Terakhir Menstruasi
                  </InputLabel>
                  {legacyBrowser ? (
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={
                        form.tanggal_menstruasi_terakhir
                          ? moment(form.tanggal_menstruasi_terakhir).toDate()
                          : ""
                      }
                      onChange={(date) => {
                        handleGeneralInputFormChange({
                          target: {
                            name: "tanggal_menstruasi_terakhir",
                            value: date,
                            id: "tanggal_menstruasi_terakhir",
                            type: "date",
                          },
                        });
                      }}
                    />
                  ) : (
                    <InputBase
                      sx={input__date}
                      placeholder="tanggal terakhir menstruasi"
                      id="tanggal_menstruasi_terakhir"
                      name="tanggal_menstruasi_terakhir"
                      type="date"
                      value={form.tanggal_menstruasi_terakhir}
                      onChange={handleGeneralInputFormChange}
                    />
                  )}
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6} lg={3}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel
                    sx={input__label}
                    shrink
                    htmlFor="tanggal_perkiraan_lahir"
                  >
                    Estimasi Kelahiran (Otomatis terisi)
                  </InputLabel>
                  <InputBase
                    sx={input__text__date__disabled}
                    placeholder="estimasi kelahiran"
                    id="tanggal_perkiraan_lahir"
                    name="tanggal_perkiraan_lahir"
                    type="date"
                    value={form.tanggal_perkiraan_lahir}
                    onChange={() => {}}
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={3}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel sx={input__label} shrink htmlFor="usia_kehamilan">
                    Usia Kandungan dari tgl periksa (Otomatis terisi)
                  </InputLabel>
                  <InputBase
                    sx={input__text}
                    placeholder="usia_kehamilan"
                    id="usia_kehamilan"
                    name="usia_kehamilan"
                    type="text"
                    value={form.usia_kehamilan}
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Typography sx={section__title}>Kandungan</Typography>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={6} lg={3}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel sx={input__label} shrink htmlFor="gravida">
                    Gravida
                  </InputLabel>
                  <InputBase
                    sx={input__text}
                    placeholder="gravida"
                    id="gravida"
                    name="gravida"
                    type="number"
                    value={form.gravida}
                    onChange={handleGeneralInputFormChange}
                    onWheel={handleWheel}
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={3}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel sx={input__label} shrink htmlFor="fetuces">
                    Fetuces
                  </InputLabel>
                  <InputBase
                    sx={input__text}
                    placeholder="fetuces"
                    id="fetuces"
                    name="fetuces"
                    type="number"
                    value={form.fetuces}
                    onChange={handleGeneralInputFormChange}
                    onWheel={handleWheel}
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={3}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel sx={input__label} shrink htmlFor="para">
                    Para
                  </InputLabel>
                  <InputBase
                    sx={input__text}
                    placeholder="para"
                    id="para"
                    name="para"
                    type="number"
                    value={form.para}
                    onChange={handleGeneralInputFormChange}
                    onWheel={handleWheel}
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={3}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel sx={input__label} shrink htmlFor="ectopic">
                    Ectopic
                  </InputLabel>
                  <InputBase
                    sx={input__text}
                    placeholder="ectopic"
                    id="ectopic"
                    name="ectopic"
                    type="number"
                    value={form.ectopic}
                    onChange={handleGeneralInputFormChange}
                    onWheel={handleWheel}
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={3}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel sx={input__label} shrink htmlFor="aborta">
                    Aborta
                  </InputLabel>
                  <InputBase
                    sx={input__text}
                    placeholder="aborta"
                    id="aborta"
                    name="aborta"
                    type="number"
                    value={form.aborta}
                    onChange={handleGeneralInputFormChange}
                    onWheel={handleWheel}
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
