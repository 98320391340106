import { Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function SidebarDivider({ text, isSidebarOpen }) {
  return (
    <Typography
      component={"span"}
      sx={{
        fontSize: "12px",
        fontWeight: 600,
        paddingLeft: "20px",
        display: isSidebarOpen ? "block" : "none",
      }}
    >
      {text}
    </Typography>
  );
}

SidebarDivider.propTypes = {
  text: PropTypes.string.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
};

export default SidebarDivider;
