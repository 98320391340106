import { webkit__scrollbar } from "../../../../style";

const modal__container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  minHeight: "400px",
  width: "900px",
};

const modal__header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "12px",
  paddingRight: "3px",
  pb: "20px",
  background: "#fff",
};

const modal__header__text = {
  fontSize: "16px",
  fontWeight: 700,
  textAlign: "center",
  flexGrow: 1,
};

const tab__label = {
  fontWeight: 600,
  textTransform: "none",
  px: 0.5,
  fontSize: "14px",
};

const modal_btn = {
  backgroundColor: "blue",
  height: "38px",
  color: "white",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const modal_btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  px: "10px",
};

const tab__cell__text = {
  fontSize: "14px",
  fontWeight: 600,
  py: 0,
};

export {
  modal__container,
  modal__header__container,
  modal__header__text,
  tab__label,
  modal_btn,
  modal_btn__label,
  tab__cell__text,
};
