const tab__container = {
  borderBottom: 1,
  borderTop: 1,
  borderColor: "divider",
  marginBottom: "10px",
};

const tab__label = {
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "none",
  color: "#041615",
  px: 1,
};



export { tab__container, tab__label };
