import Dexie from "dexie";

export const db = new Dexie("mediplusdb");
db.version(12).stores({
  template_subjektif: "++id, doctor_id, value",
  template_objektif: "++id, doctor_id, value",
  template_penilaian: "++id, doctor_id, value",
  template_perencanaan: "++id, doctor_id, value",
  template_implementasi: "++id, doctor_id, value",
  template_revisi: "++id, doctor_id, value",
  template_evaluasi: "++id, doctor_id, value",
  icd_bookmark: "++id, doctor_id, icd10_code, who_full_desc",
  notifications: "++id, doctor_id, title, message, is_read, created_at",
  head_doctor: "++id, doctor_id",
  chat_templates: "++id, doctor_id, value",
  template_aturan_minum: "++id, doctor_id, value",
  template_catatan_obat: "++id, doctor_id, value",
  current_login_id: "++id, doctor_id", //belum digunakan
});
