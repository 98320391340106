import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { Route } from "react-router-dom";
import { useAuth } from "../../../../providers/AuthProvider";
import AuthService from "../../../../services/authService";
import mediplusHelper from "../../../../utils/mediplusHelper";
import {
  Button,
  FormControl,
  InputBase,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  eye__icon,
  login__button__submit,
  login__forgot_password__link,
  login__text__h1,
  login__text__h2,
} from "./style";
import {
  auth__form_control,
  auth__form_label,
  auth__input__text,
} from "../../style";
import * as Sentry from "@sentry/react";

export default function Login() {
  const { user, setAuthUpdate } = useAuth();

  const [isInputTypePassword, setIsInputTypePassword] = useState(true);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [isSubmitting, setIsSubmiting] = useState(false);

  const [validationError, setValidationError] = useState({
    email: "",
    password: "",
  });

  const handlePasswordInputTypeChange = () => {
    setIsInputTypePassword(!isInputTypePassword);
  };

  const handleChange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));

    setValidationError((prevError) => ({
      ...prevError,
      [e.target.name]: "",
    }));
  };

  const validate = () => {
    const newError = {};

    if (!form.email) {
      newError.email = "email wajib diisi";
    }

    if (!form.password) {
      newError.password = "password wajib diisi";
    }
    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findValidationError = validate();
    if (Object.values(findValidationError).some((err) => err !== "")) {
      setValidationError(findValidationError);
    } else {
      try {
        setIsSubmiting(true);
        let data = await AuthService.login(form.email, form.password);
        if (data.error === true) {
          const newError = {};
          if (data.code === 422) {
            newError.password = "email / password salah";
          } else {
            newError.email = JSON.stringify(data);
          }
          setValidationError(newError);
          setIsSubmiting(false);
        } else {
          setAuthUpdate(true);
        }
      } catch (e) {
        console.log(e);
        const newError = {};
        newError.email = e.message;
        setValidationError(newError);
        setIsSubmiting(false);
        Sentry.captureException(e);
      }
    }
  };

  if (user) {
    let redirectTo = "/otp";
    let isOtpVerified =
      mediplusHelper.findLocalStorageItems("already_token_otp");
    if (isOtpVerified.length > 0) {
      if (isOtpVerified[0].val === "true") {
        redirectTo = "/";
      }
    }
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <Typography variant="h4" component="h1" sx={login__text__h1}>
        Selamat Datang !
      </Typography>
      <Typography sx={login__text__h2}>
        Masukkan alamat email dan kata sandi Anda untuk mengakses akun
      </Typography>
      <FormControl variant="standard" sx={auth__form_control}>
        <InputLabel sx={auth__form_label} shrink htmlFor="email">
          Email / Username
        </InputLabel>
        <InputBase
          placeholder="email@mediplus.co.id"
          id="email"
          name="email"
          type="text"
          value={form.email}
          onChange={handleChange}
          disable={isSubmitting.toString()}
          error={validationError.email ? true : false}
          sx={auth__input__text}
        />
        <FormHelperText error={true}>{validationError.email}</FormHelperText>
      </FormControl>
      <FormControl variant="standard" sx={auth__form_control}>
        <InputLabel sx={auth__form_label} shrink htmlFor="">
          Password
        </InputLabel>
        <InputBase
          placeholder="password"
          id="password"
          name="password"
          type={isInputTypePassword ? "password" : "text"}
          value={form.password}
          onChange={handleChange}
          error={validationError.password ? true : false}
          disable={isSubmitting.toString()}
          sx={auth__input__text}
        />
        <Route
          path="/forgot-password"
          exact
          children={({ history }) => {
            return (
              <Typography
                sx={login__forgot_password__link}
                onClick={() => {
                  history.push("/forgot-password");
                }}
              >
                Lupa Password ?
              </Typography>
            );
          }}
        />
        <FormHelperText error={true}>{validationError.password}</FormHelperText>
        <img
          style={eye__icon}
          onClick={handlePasswordInputTypeChange}
          src={
            isInputTypePassword
              ? "/assets/icons/eye-close.svg"
              : "/assets/icons/eye-open.svg"
          }
          alt="eye"
        ></img>
      </FormControl>

      <Button
        variant="contained"
        sx={login__button__submit}
        onClick={handleSubmit}
      >
        Masuk Sekarang
      </Button>
    </>
  );
}
