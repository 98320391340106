const container = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  minWidth: "100%",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
};

const avatar__container = {
  width: "44px",
  height: "44px",
  background: "none",
};

const box__wrapper = {
  width: "calc(100% - 88px)",
  background: "none",
};

const box = {
  pt: 0.5,
  px: 1,
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
};

const date__text = {
  fontSize: "12px",
  fontWeight: 600,
  py: 0.3,
  px: 1,
  borderRadius: "8px",
  border: "1px solid #D5D5D5",
};

export { container, avatar__container, box__wrapper, box, date__text };
