import { createSlice } from "@reduxjs/toolkit";

/**
 * Fungsi hanya increment saja untuk trigger use effect agar reload data
 */

export const newMedicalRecordUpdate = createSlice({
  name: "new_medical_record_update",
  initialState: {
    value: 0,
  },
  reducers: {
    updateNewMedicalRecord: (state) => {
      state.value += 1;
    },
  },
});

export const { updateNewMedicalRecord } = newMedicalRecordUpdate.actions;
export default newMedicalRecordUpdate.reducer;
