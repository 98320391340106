import {
  Box,
  ClickAwayListener,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import TheadCell from "../../../../components/table/thead-cell/TheadCell";
import { table__cell } from "./style";

export default function ObatRow({
  row,
  handlePopupMedicineOpen,
  handlePopupMedicineAway,
}) {
  const [open, setOpen] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("nama");
  const [sortType, setSortType] = React.useState("ASC");
  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };
  const [submenuRow, setSubmenuRow] = React.useState(row.submenu);

  React.useEffect(() => {
    let submenuRowSort = _.orderBy(
      submenuRow,
      [sortBy],
      [sortType.toLocaleLowerCase()]
    );
    setSubmenuRow(submenuRowSort);
  }, [sortBy, sortType]);

  return (
    <React.Fragment key={`row_${row.id}`}>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={`row_${row.id}`}
      >
        <TableCell width="7%">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={!row?.submenu || row.submenu.length < 1 ? true : false}
          >
            {!row?.submenu || row.submenu.length < 1 ? (
              <img
                src="/assets/icons/arrow-square-down-disabled.svg"
                alt="up"
              ></img>
            ) : open ? (
              <img src="/assets/icons/arrow-square-up.svg" alt="up"></img>
            ) : (
              <img src="/assets/icons/arrow-square-down.svg" alt="up"></img>
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={table__cell} component="th" scope="row">
          {row.nama}
        </TableCell>
        <TableCell sx={table__cell} align="left">
          {row.satuan}
        </TableCell>
        <TableCell sx={table__cell} align="left" width="13%">
          {!row?.submenu || row.submenu.length < 1 ? row.stok : ""}
        </TableCell>
        <TableCell sx={table__cell} align="left">
          {!row?.submenu || row.submenu.length < 1
            ? mediplusHelper.formatRupiah(row.harga)
            : ""}
        </TableCell>
        <TableCell sx={table__cell} align="center">
          <ClickAwayListener onClickAway={handlePopupMedicineAway}>
            <IconButton
              data-group="popper-toggler"
              data-medicine-id={row.id}
              onClick={handlePopupMedicineOpen}
              color="inherit"
              aria-label="refresh"
              edge="start"
              type="button"
            >
              <LazyLoadImage
                effect="blur"
                src="/assets/icons/table-action.svg"
                alt="action"
              ></LazyLoadImage>
            </IconButton>
          </ClickAwayListener>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width: "50%", marginLeft: "80px" }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TheadCell
                      name="Nama Tambahan"
                      column_name="nama"
                      useSort={true}
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    ></TheadCell>

                    <TheadCell
                      name="Stok"
                      column_name="stok"
                      useSort={true}
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    ></TheadCell>

                    <TheadCell
                      name="Harga"
                      column_name="harga"
                      useSort={true}
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    ></TheadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submenuRow &&
                    submenuRow.map((submenuRow) => (
                      <TableRow key={submenuRow.id}>
                        <TableCell component="th" scope="row">
                          {submenuRow.nama}
                        </TableCell>
                        <TableCell align="left">{submenuRow.stok}</TableCell>
                        <TableCell align="left">
                          {mediplusHelper.formatRupiah(submenuRow.harga)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
