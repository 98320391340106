import { createSlice } from "@reduxjs/toolkit";

/**
 * Fungsi untuk menyimpan mode group di timeline rekam medis
 *
 */

export const timelineGroupMode = createSlice({
  name: "timeline_group_mode",
  initialState: "ktp",
  reducers: {
    changeTimelineGroupMode: (state, data) => {
      if (["ktp", "nomor", "nomor_rekam_medis"].includes(data.payload)) {
        return data.payload;
      }
      return state;
    },
  },
});

export const { changeTimelineGroupMode } = timelineGroupMode.actions;

export default timelineGroupMode.reducer;
