import React from "react";

import { Route, Redirect } from "react-router-dom";
import AppLoading from "../pages/components/loading/AppLoading";
import { useAuth } from "../providers/AuthProvider";
import mediplusHelper from "../utils/mediplusHelper";

function PrivateRoute({ component: Component, ...restProps }) {
  const { user, loading, mustSelectHead } = useAuth();
  if (loading) {
    return <AppLoading />;
  }

  if (user) {
    let isOtpVerified =
      mediplusHelper.findLocalStorageItems("already_token_otp");
    if (isOtpVerified.length > 0) {
      if (isOtpVerified[0].val !== "true") {
        return <Redirect to={"/otp"} />;
      }
    } else {
      return <Redirect to={"/otp"} />;
    }
  }

  return (
    <Route
      {...restProps}
      render={(props) => {
        return mustSelectHead ? (
          <Redirect
            to={{
              pathname: "/head-doctors",
              state: {
                from: props.location,
              },
            }}
          ></Redirect>
        ) : user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          ></Redirect>
        );
      }}
    />
  );
}

export default PrivateRoute;
