import React from "react";
import { Switch, Route } from "react-router-dom";
import ForgotPassword from "../components/forgot-password/ForgotPassword";
import Login from "../components/login/Login";
import Otp from "../components/otp/Otp";

export default function AuthRoute() {
  return (
    <Switch>
      <Route exact path="/login">
        <Login></Login>
      </Route>
      <Route exact path="/otp">
        <Otp></Otp>
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword></ForgotPassword>
      </Route>
    </Switch>
  );
}
