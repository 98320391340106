const cross__mark = {
  "&::after": {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    width: "50px",
    height: "50px",
    background:
      "linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#000 45%,#000 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%), linear-gradient(135deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 43%,#000 45%,#000 55%, rgba(0,0,0,0) 57%, rgba(0,0,0,0) 100%)",
    backgroundSize: "50px 50px",
    objectFit: "contain",
  },
};

const v__mark = {
  "&::after": {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    width: "50px",
    height: "50px",
    background: `url("/assets/icons/checkmark.svg") no-repeat`,
    backgroundSize: "50px 50px",
    objectFit: "contain",
  },
};

const hash__mark = {
  "&::after": {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    width: "50px",
    height: "50px",
    background: `url("/assets/odontogram/hash.svg") no-repeat`,
    backgroundSize: "50px 50px",
    objectFit: "contain",
  },
};

const stripe__mark = {
  "&::after": {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    width: "50px",
    height: "50px",
    backgroundImage:
      "repeating-linear-gradient(90deg,#000,#000 2px,rgba(0,0,0,0) 2px,rgba(0,0,0,0) 4px)",
    objectFit: "contain",
    backgroundSize: "50px 50px",
  },
};

const stripe__cross__mark = {
  "&::before": {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    width: "50px",
    height: "50px",
    backgroundImage:
      "repeating-linear-gradient(90deg,#000,#000 2px,rgba(0,0,0,0) 2px,rgba(0,0,0,0) 4px)",
    objectFit: "contain",
    backgroundSize: "50px 50px",
  },
  "&::after": {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    width: "50px",
    height: "50px",
    background:
      "linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#000 45%,#000 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%), linear-gradient(135deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 43%,#000 45%,#000 55%, rgba(0,0,0,0) 57%, rgba(0,0,0,0) 100%)",
    backgroundSize: "50px 50px",
    objectFit: "contain",
  },
};

const disabled__mark = {
  "&::after": {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    width: "50px",
    height: "50px",
    backgroundImage:
      "linear-gradient(0deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 35%,#000 40%,#000 60%,rgba(0,0,0,0) 65%,rgba(0,0,0,0) 100%)",
    objectFit: "contain",
    backgroundSize: "50px 50px",
  },
};

const oblique__stripe = {
  backgroundImage:
    "repeating-linear-gradient(45deg,#000,#000 2px,#ff0000 2px,#ff0000 4px)",
  backgroundSize: "30px 30px",
  objectFit: "contain",
};

const straight__stripe = {
  backgroundImage:
    "repeating-linear-gradient(90deg,#000,#000 2px,#32CD32 2px,#32CD32 4px)",
  backgroundSize: "30px 30px",
  objectFit: "contain",
};

const bold__outline__mark = {
  outline: "5px solid #000",
};

const gold__bold__outline__mark = {
  outline: "5px solid #FFD700",
};

const silver__bold__outline__mark = {
  outline: "5px solid #AAA9AD",
};

const sec__container = {
  position: "relative",
  outline: "1px solid #000",
  width: "50px",
  height: "50px",
};

const foursec__top = {
  left: "0px",
  top: "0px",
  position: "absolute",
  background: "black",
  clipPath: "polygon(0 0, 100% 0, 60% 100%, 40% 100%)",
  height: "25px",
  width: "50px",
  cursor: "pointer",
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    clipPath: "polygon(0 0, 100% 0, 60% 100%, 40% 100%)",
    height: "23px",
    width: "46px",
  },
};

const foursec__top__amf = {
  ...foursec__top,
  background: "white",
  "&::after": {
    ...foursec__top["&::after"],
    background: "black",
  },
};

const foursec__top__car = {
  ...foursec__top,
  "&::after": {
    ...foursec__top["&::after"],
    height: "15px",
    width: "30px",
  },
};

const foursec__top__cof = {
  ...foursec__top,
  "&::after": {
    ...foursec__top["&::after"],
    ...straight__stripe,
    backgroundSize: "50px 25px",
  },
};

const foursec__top__fis = {
  ...foursec__top,
  "&::after": {
    ...foursec__top["&::after"],
    ...oblique__stripe,
    backgroundSize: "50px 25px",
  },
};

const foursec__bottom = {
  left: "0px",
  bottom: "0px",
  position: "absolute",
  background: "black",
  clipPath: "polygon(40% 0, 60% 0, 100% 100%, 0 100%)",
  height: "25px",
  width: "50px",
  cursor: "pointer",
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    clipPath: "polygon(40% 0, 60% 0, 100% 100%, 0 100%)",
    height: "23px",
    width: "46px",
  },
};

const foursec__bottom__amf = {
  ...foursec__bottom,
  background: "white",
  "&::after": {
    ...foursec__bottom["&::after"],
    background: "black",
  },
};

const foursec__bottom__car = {
  ...foursec__bottom,
  "&::after": {
    ...foursec__bottom["&::after"],
    height: "15px",
    width: "30px",
  },
};

const foursec__bottom__cof = {
  ...foursec__bottom,
  "&::after": {
    ...foursec__bottom["&::after"],
    ...straight__stripe,
    backgroundSize: "50px 25px",
  },
};

const foursec__bottom__fis = {
  ...foursec__bottom,
  "&::after": {
    ...foursec__bottom["&::after"],
    ...oblique__stripe,
    backgroundSize: "50px 25px",
  },
};

const foursec__left = {
  left: "0px",
  position: "absolute",
  background: "black",
  clipPath: "polygon(100% 50%, 0 0, 0 100%)",
  height: "50px",
  width: "20px",
  cursor: "pointer",
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    clipPath: "polygon(100% 50%, 0 0, 0 100%)",
    height: "45px",
    width: "18px",
  },
};

const foursec__left__amf = {
  ...foursec__left,
  background: "white",
  "&::after": {
    ...foursec__left["&::after"],
    background: "black",
  },
};

const foursec__left__car = {
  ...foursec__left,
  "&::after": {
    ...foursec__left["&::after"],
    height: "37.5px",
    width: "15px",
  },
};

const foursec__left__cof = {
  ...foursec__left,
  "&::after": {
    ...foursec__left["&::after"],
    ...straight__stripe,
    backgroundSize: "20px 50px",
  },
};

const foursec__left__fis = {
  ...foursec__left,
  "&::after": {
    ...foursec__left["&::after"],
    ...oblique__stripe,
    backgroundSize: "20px 50px",
  },
};

const foursec__right = {
  right: "0px",
  position: "absolute",
  background: "black",
  clipPath: "polygon(100% 0, 0 50%, 100% 100%)",
  height: "50px",
  width: "20px",
  cursor: "pointer",
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    clipPath: "polygon(100% 0, 0 50%, 100% 100%)",
    height: "45px",
    width: "18px",
  },
};

const foursec__right__amf = {
  ...foursec__right,
  background: "white",
  "&::after": {
    ...foursec__right["&::after"],
    background: "black",
  },
};

const foursec__right__car = {
  ...foursec__right,
  "&::after": {
    ...foursec__right["&::after"],
    height: "37.5px",
    width: "15px",
  },
};

const foursec__right__cof = {
  ...foursec__right,
  "&::after": {
    ...foursec__right["&::after"],
    ...straight__stripe,
    backgroundSize: "20px 50px",
  },
};

const foursec__right__fis = {
  ...foursec__right,
  "&::after": {
    ...foursec__right["&::after"],
    ...oblique__stripe,
    backgroundSize: "20px 50px",
  },
};

const fivesec__center = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "black",
  height: "20px",
  width: "20px",
  cursor: "pointer",
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    height: "19px",
    width: "19px",
  },
};

const fivesec__center__amf = {
  ...fivesec__center,
  background: "white",
  "&::after": {
    ...fivesec__center["&::after"],
    background: "black",
  },
};

const fivesec__center__car = {
  ...fivesec__center,
  "&::after": {
    ...fivesec__center["&::after"],
    height: "10px",
    width: "10px",
  },
};

const fivesec__center__cof = {
  ...fivesec__center,
  "&::after": {
    ...fivesec__center["&::after"],
    ...straight__stripe,
    backgroundSize: "20px 20px",
  },
};

const fivesec__center__fis = {
  ...fivesec__center,
  "&::after": {
    ...fivesec__center["&::after"],
    ...oblique__stripe,
    backgroundSize: "20px 20px",
  },
};

const fivesec__top = {
  left: "0px",
  top: "0px",
  position: "absolute",
  background: "black",
  clipPath: "polygon(0 0, 100% 0, 70% 100%, 30% 100%)",
  height: "15px",
  width: "50px",
  cursor: "pointer",
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    clipPath: "polygon(0 0, 100% 0, 70% 100%, 30% 100%)",
    height: "14px",
    width: "46.7px",
  },
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
};

const fivesec__top__amf = {
  ...fivesec__top,
  background: "white",
  "&::after": {
    ...fivesec__top["&::after"],
    background: "black",
  },
};

const fivesec__top__car = {
  ...fivesec__top,
  "&::after": {
    ...fivesec__top["&::after"],
    height: "10px",
    width: "33.35px",
  },
};

const fivesec__top__cof = {
  ...fivesec__top,
  "&::after": {
    ...fivesec__top["&::after"],
    ...straight__stripe,
    backgroundSize: "50px 25px",
  },
};

const fivesec__top__fis = {
  ...fivesec__top,
  "&::after": {
    ...fivesec__top["&::after"],
    ...oblique__stripe,
    backgroundSize: "50px 25px",
  },
};

const fivesec__left = {
  left: "0px",
  position: "absolute",
  background: "black",
  clipPath: "polygon(0 0, 100% 30%, 100% 70%, 0 100%)",
  height: "50px",
  width: "15px",
  cursor: "pointer",
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    clipPath: "polygon(0 0, 100% 30%, 100% 70%, 0 100%)",
    height: "46.7px",
    width: "14px",
  },
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
};

const fivesec__left__amf = {
  ...fivesec__left,
  background: "white",
  "&::after": {
    ...fivesec__left["&::after"],
    background: "black",
  },
};

const fivesec__left__car = {
  ...fivesec__left,
  "&::after": {
    ...fivesec__left["&::after"],
    height: "33.35px",
    width: "10px",
  },
};

const fivesec__left__cof = {
  ...fivesec__left,
  "&::after": {
    ...fivesec__left["&::after"],
    ...straight__stripe,
    backgroundSize: "25px 50px",
  },
};

const fivesec__left__fis = {
  ...fivesec__left,
  "&::after": {
    ...fivesec__left["&::after"],
    ...oblique__stripe,
    backgroundSize: "25px 50px",
  },
};

const fivesec__bottom = {
  left: "0px",
  bottom: "0px",
  position: "absolute",
  background: "black",
  clipPath: "polygon(30% 0, 70% 0, 100% 100%, 0 100%)",
  height: "15px",
  width: "50px",
  cursor: "pointer",
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    clipPath: "polygon(30% 0, 70% 0, 100% 100%, 0 100%)",
    height: "14px",
    width: "46.7px",
  },
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
};

const fivesec__bottom__amf = {
  ...fivesec__bottom,
  background: "white",
  "&::after": {
    ...fivesec__bottom["&::after"],
    background: "black",
  },
};

const fivesec__bottom__car = {
  ...fivesec__bottom,
  "&::after": {
    ...fivesec__bottom["&::after"],
    height: "10px",
    width: "33.35px",
  },
};

const fivesec__bottom__cof = {
  ...fivesec__bottom,
  "&::after": {
    ...fivesec__bottom["&::after"],
    ...straight__stripe,
    backgroundSize: "50px 25px",
  },
};

const fivesec__bottom__fis = {
  ...fivesec__bottom,
  "&::after": {
    ...fivesec__bottom["&::after"],
    ...oblique__stripe,
    backgroundSize: "50px 25px",
  },
};

const fivesec__right = {
  right: "0px",
  position: "absolute",
  background: "black",
  clipPath: "polygon(0 30%, 100% 0, 100% 100%, 0 70%)",
  height: "50px",
  width: "15px",
  cursor: "pointer",
  "&::after": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    content: "''",
    background: "white",
    clipPath: "polygon(0 30%, 100% 0, 100% 100%, 0 70%)",
    height: "46.7px",
    width: "14px",
  },
  "&:hover": {
    background: "blue",
    "&::after": {
      background: "pink",
    },
  },
};

const fivesec__right__amf = {
  ...fivesec__right,
  background: "white",
  "&::after": {
    ...fivesec__right["&::after"],
    background: "black",
  },
};

const fivesec__right__car = {
  ...fivesec__right,
  "&::after": {
    ...fivesec__right["&::after"],
    height: "33.35px",
    width: "10px",
  },
};

const fivesec__right__cof = {
  ...fivesec__right,
  "&::after": {
    ...fivesec__right["&::after"],
    ...straight__stripe,
    backgroundSize: "25px 50px",
  },
};

const fivesec__right__fis = {
  ...fivesec__right,
  "&::after": {
    ...fivesec__right["&::after"],
    ...oblique__stripe,
    backgroundSize: "25px 50px",
  },
};

const legend__text = {
  display: "flex",
  justifyContent: "center",
  fontSize: "12px",
  fontWeight: 600,
  width: "100%",
  py: 0.5,
};

const index__text = {
  display: "flex",
  justifyContent: "center",
  fontSize: "16px",
  fontWeight: 700,
  width: "100%",
};

const legend__container = {
  width: "60px",
  height: "25px",
};

const rotation__container = {
  width: "60px",
  height: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const karang__container = {
  width: "60px",
  height: "12px",
};

const tooth__container = {
  width: "60px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignContent: "center",
};

export {
  sec__container,
  foursec__top,
  foursec__left,
  foursec__bottom,
  foursec__right,
  fivesec__center,
  fivesec__top,
  fivesec__left,
  fivesec__bottom,
  fivesec__right,
  oblique__stripe,
  straight__stripe,
  legend__text,
  legend__container,
  rotation__container,
  karang__container,
  index__text,
  tooth__container,
  disabled__mark,
  stripe__mark,
  hash__mark,
  v__mark,
  cross__mark,
  bold__outline__mark,
  gold__bold__outline__mark,
  silver__bold__outline__mark,
  fivesec__center__amf,
  fivesec__center__car,
  fivesec__center__cof,
  fivesec__center__fis,
  fivesec__top__amf,
  fivesec__top__car,
  fivesec__top__cof,
  fivesec__top__fis,
  fivesec__left__amf,
  fivesec__left__car,
  fivesec__left__cof,
  fivesec__left__fis,
  fivesec__bottom__amf,
  fivesec__bottom__car,
  fivesec__bottom__cof,
  fivesec__bottom__fis,
  fivesec__right__amf,
  fivesec__right__car,
  fivesec__right__cof,
  fivesec__right__fis,
  foursec__top__amf,
  foursec__top__car,
  foursec__top__cof,
  foursec__top__fis,
  foursec__bottom__amf,
  foursec__bottom__car,
  foursec__bottom__cof,
  foursec__bottom__fis,
  foursec__left__amf,
  foursec__left__car,
  foursec__left__cof,
  foursec__left__fis,
  foursec__right__amf,
  foursec__right__car,
  foursec__right__cof,
  foursec__right__fis,
};
