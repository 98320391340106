import React from "react";

import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function GeneratePDFLoadingModal({ open }) {
  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          pb: 4,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img alt="loading" src="/assets/loading.svg"></img>
          <Typography
            sx={{
              display: "block",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Generating PDF...
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}
