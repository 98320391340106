import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import odontogramHelper from "../helper";
import Anomali from "./legends/Anomali";
import BridgeCenter from "./legends/BridgeCenter";
import BridgeCenterReverse from "./legends/BridgeCenterReverse";
import BridgeEnd from "./legends/BridgeEnd";
import BridgeEndReverse from "./legends/BridgeEndReverse";
import BridgeStart from "./legends/BridgeStart";
import BridgeStartReverse from "./legends/BridgeStartReverse";
import FullDentureAcrylic from "./legends/FullDentureAcrylic";
import FullDentureMetal from "./legends/FullDentureMetal";
import ImplantPorcelainCrown from "./legends/ImplantPorcelainCrown";
import Karang from "./legends/Karang";
import None from "./legends/None";
import PartialDentureAcrylic from "./legends/PartialDentureAcrylic";
import PartialDentureMetal from "./legends/PartialDentureMetal";
import PartialErupt from "./legends/PartialErupt";
import RootBlack from "./legends/RootBlack";
import RootWhite from "./legends/RootWhite";
import UnErupted from "./legends/UnErupted";
import BottomLeftRotation from "./rotations/BottomLeftRotation";
import BottomRightRotation from "./rotations/BottomRightRotation";
import LeftMigration from "./rotations/LeftMigration";
import LeftRotation from "./rotations/LeftRotation";
import RightMigration from "./rotations/RightMigration";
import RightRotation from "./rotations/RightRotation";
import TopLeftRotation from "./rotations/TopLeftRotation";
import TopRightRotation from "./rotations/TopRightRotation";
import {
  fivesec__bottom,
  fivesec__center,
  fivesec__left,
  fivesec__right,
  fivesec__top,
  sec__container,
  legend__container,
  rotation__container,
  index__text,
  tooth__container,
  disabled__mark,
  cross__mark,
  hash__mark,
  stripe__mark,
  bold__outline__mark,
  gold__bold__outline__mark,
  silver__bold__outline__mark,
  fivesec__center__amf,
  fivesec__center__car,
  fivesec__center__cof,
  fivesec__center__fis,
  fivesec__top__amf,
  fivesec__top__car,
  fivesec__top__cof,
  fivesec__top__fis,
  fivesec__left__amf,
  fivesec__left__car,
  fivesec__left__cof,
  fivesec__left__fis,
  fivesec__bottom__amf,
  fivesec__bottom__car,
  fivesec__bottom__cof,
  fivesec__bottom__fis,
  fivesec__right__amf,
  fivesec__right__car,
  fivesec__right__cof,
  fivesec__right__fis,
  karang__container,
  v__mark,
} from "./style";

export default function FiveSectionTooth({
  data,
  teethMode,
  handleToothClick,
}) {
  const [style, setStyle] = useState({
    container: sec__container,
    top: fivesec__top,
    bottom: fivesec__bottom,
    center: fivesec__center,
    left: fivesec__left,
    right: fivesec__right,
  });

  const [rotation, setRotation] = useState({
    top: <></>,
    bottom: <></>,
  });

  const [legend, setLegend] = useState({
    top: <></>,
    bottom: <></>,
  });

  const [karang, setKarang] = useState("");

  const containerStyleBuilder = () => {
    const baseStyle = sec__container;
    if (odontogramHelper.isDisabled(data, teethMode)) {
      setStyle((prev) => ({
        ...prev,
        container: { ...baseStyle, ...disabled__mark },
      }));
      setLegend({
        top: <></>,
        bottom: <></>,
      });
      setRotation({
        top: <></>,
        bottom: <></>,
      });
    } else {
      let globalData = data[1] ?? [];
      if (globalData.length > 0 && globalData[0].length > 0) {
        let mode = globalData[0];
        let useDefaultStyle = true;
        if (mode === "FLMB" || mode === "ICLB") {
          useDefaultStyle = false;
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <BridgeStart />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <BridgeStartReverse />,
            });
          }

          setStyle((prev) => ({
            ...prev,
            container: { ...baseStyle, ...bold__outline__mark },
          }));
        } else if (mode === "IPCB") {
          useDefaultStyle = false;
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <BridgeStart />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <BridgeStartReverse />,
            });
          }

          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...bold__outline__mark,
              ...stripe__mark,
            },
          }));
        } else if (mode === "BWS") {
          useDefaultStyle = false;
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <BridgeCenter />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <BridgeCenterReverse />,
            });
          }

          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...bold__outline__mark,
              ...cross__mark,
            },
          }));
        } else if (mode === "BWE") {
          useDefaultStyle = false;
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <BridgeEnd />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <BridgeEndReverse />,
            });
          }
          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...bold__outline__mark,
            },
          }));
        } else if (mode === "BWPL") {
          useDefaultStyle = false;
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <BridgeCenter />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <BridgeCenterReverse />,
            });
          }
          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...bold__outline__mark,
              ...stripe__mark,
            },
          }));
        } else if (mode === "BWEPL") {
          useDefaultStyle = false;
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <BridgeEnd />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <BridgeEndReverse />,
            });
          }

          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...bold__outline__mark,
              ...stripe__mark,
            },
          }));
        } else if (mode === "SSAK") {
          useDefaultStyle = false;
          setLegend({
            top: "",
            bottom: "",
          });
          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...v__mark,
            },
          }));
        } else if (mode === "ANOM") {
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <Anomali />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <Anomali />,
            });
          }
        } else if (mode === "UERU") {
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <UnErupted />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <UnErupted />,
            });
          }
        } else if (mode === "PAERU") {
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <PartialErupt />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <PartialErupt />,
            });
          }
        } else if (mode === "GGTA") {
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: <None />,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: <None />,
            });
          }
        } else if (mode === "GGHG") {
          setLegend({
            top: "",
            bottom: "",
          });
          useDefaultStyle = false;
          setStyle((prev) => ({
            ...prev,
            container: { ...baseStyle, ...cross__mark },
          }));
        } else if (mode === "FRAK") {
          useDefaultStyle = false;
          setLegend({
            top: "",
            bottom: "",
          });
          setStyle((prev) => ({
            ...prev,
            container: { ...baseStyle, ...hash__mark },
          }));
        } else if (
          mode === "PLC" ||
          mode === "MPLC" ||
          mode === "GMC" ||
          mode === "IPLC"
        ) {
          useDefaultStyle = false;
          let outlineMark;
          if (mode === "PLC" || mode === "IPLC") {
            outlineMark = bold__outline__mark;
          } else if (mode === "MPLC") {
            outlineMark = silver__bold__outline__mark;
          } else {
            outlineMark = gold__bold__outline__mark;
          }
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: mode === "IPLC" ? <ImplantPorcelainCrown /> : "",
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: mode === "IPLC" ? <ImplantPorcelainCrown /> : "",
            });
          }

          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...stripe__mark,
              ...outlineMark,
            },
          }));
        } else if (mode === "FLMC") {
          useDefaultStyle = false;
          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...bold__outline__mark,
            },
          }));
        } else if (
          mode === "PADA" ||
          mode === "FLDA" ||
          mode === "PADM" ||
          mode === "FLDM"
        ) {
          useDefaultStyle = false;
          setStyle((prev) => ({
            ...prev,
            container: {
              ...baseStyle,
              ...cross__mark,
            },
          }));
          let legend;
          if (mode === "PADA") {
            legend = <PartialDentureAcrylic />;
          } else if (mode === "FLDA") {
            legend = <FullDentureAcrylic />;
          } else if (mode === "PADM") {
            legend = <PartialDentureMetal />;
          } else {
            legend = <FullDentureMetal />;
          }
          if (odontogramHelper.isTopGroupIndex(data[0])) {
            setLegend({
              top: legend,
              bottom: "",
            });
          } else {
            setLegend({
              top: "",
              bottom: legend,
            });
          }
        }
        if (useDefaultStyle === true) {
          setStyle((prev) => ({
            ...prev,
            container: baseStyle,
          }));
        }
      } else {
        setStyle((prev) => ({
          ...prev,
          container: { ...baseStyle },
        }));
        setLegend({
          top: "",
          bottom: "",
        });
      }
    }
  };

  const resetSectionStyle = (sectionName = null) => {
    if (sectionName === null) {
      setStyle((prev) => ({
        ...prev,
        top: fivesec__top,
        bottom: fivesec__bottom,
        center: fivesec__center,
        left: fivesec__left,
        right: fivesec__right,
      }));
    } else {
      if (sectionName === "top") {
        setStyle((prev) => ({
          ...prev,
          top: fivesec__top,
        }));
      } else if (sectionName === "bottom") {
        setStyle((prev) => ({
          ...prev,
          bottom: fivesec__bottom,
        }));
      } else if (sectionName === "center") {
        setStyle((prev) => ({
          ...prev,
          center: fivesec__center,
        }));
      } else if (sectionName === "left") {
        setStyle((prev) => ({
          ...prev,
          left: fivesec__left,
        }));
      } else if (sectionName === "right") {
        setStyle((prev) => ({
          ...prev,
          right: fivesec__right,
        }));
      }
    }
  };

  const sectionStyleBuilder = () => {
    if (
      odontogramHelper.isGlobalStyleApplied(data) ||
      odontogramHelper.isDisabled(data, teethMode)
    ) {
      resetSectionStyle();
    } else {
      /**
       * part
       * 5-part atas, kanan, bawah, kiri, tengah
       */
      let sectionNameList = ["top", "right", "bottom", "left", "center"];
      let sectionData = data[2];
      if (Array.isArray(sectionData)) {
        sectionData.forEach((sec, idx) => {
          let sectionName = sectionNameList[idx];
          if (sec.length < 1) {
            resetSectionStyle(sectionName);
          } else {
            let mode = sec;
            if (sectionName === "top") {
              if (mode === "TAA") {
                setStyle((prev) => ({
                  ...prev,
                  top: fivesec__top__amf,
                }));
              } else if (mode === "TAC") {
                setStyle((prev) => ({
                  ...prev,
                  top: fivesec__top__cof,
                }));
              } else if (mode === "PFS") {
                setStyle((prev) => ({
                  ...prev,
                  top: fivesec__top__fis,
                }));
              } else if (mode === "KAR") {
                setStyle((prev) => ({
                  ...prev,
                  top: fivesec__top__car,
                }));
              } else {
                resetSectionStyle(sectionName);
              }
            } else if (sectionName === "right") {
              if (mode === "TAA") {
                setStyle((prev) => ({
                  ...prev,
                  right: fivesec__right__amf,
                }));
              } else if (mode === "TAC") {
                setStyle((prev) => ({
                  ...prev,
                  right: fivesec__right__cof,
                }));
              } else if (mode === "PFS") {
                setStyle((prev) => ({
                  ...prev,
                  right: fivesec__right__fis,
                }));
              } else if (mode === "KAR") {
                setStyle((prev) => ({
                  ...prev,
                  right: fivesec__right__car,
                }));
              } else {
                resetSectionStyle(sectionName);
              }
            } else if (sectionName === "bottom") {
              if (mode === "TAA") {
                setStyle((prev) => ({
                  ...prev,
                  bottom: fivesec__bottom__amf,
                }));
              } else if (mode === "TAC") {
                setStyle((prev) => ({
                  ...prev,
                  bottom: fivesec__bottom__cof,
                }));
              } else if (mode === "PFS") {
                setStyle((prev) => ({
                  ...prev,
                  bottom: fivesec__bottom__fis,
                }));
              } else if (mode === "KAR") {
                setStyle((prev) => ({
                  ...prev,
                  bottom: fivesec__bottom__car,
                }));
              } else {
                resetSectionStyle(sectionName);
              }
            } else if (sectionName === "left") {
              if (mode === "TAA") {
                setStyle((prev) => ({
                  ...prev,
                  left: fivesec__left__amf,
                }));
              } else if (mode === "TAC") {
                setStyle((prev) => ({
                  ...prev,
                  left: fivesec__left__cof,
                }));
              } else if (mode === "PFS") {
                setStyle((prev) => ({
                  ...prev,
                  left: fivesec__left__fis,
                }));
              } else if (mode === "KAR") {
                setStyle((prev) => ({
                  ...prev,
                  left: fivesec__left__car,
                }));
              } else {
                resetSectionStyle(sectionName);
              }
            } else if (sectionName === "center") {
              if (mode === "TAA") {
                setStyle((prev) => ({
                  ...prev,
                  center: fivesec__center__amf,
                }));
              } else if (mode === "TAC") {
                setStyle((prev) => ({
                  ...prev,
                  center: fivesec__center__cof,
                }));
              } else if (mode === "PFS") {
                setStyle((prev) => ({
                  ...prev,
                  center: fivesec__center__fis,
                }));
              } else if (mode === "KAR") {
                setStyle((prev) => ({
                  ...prev,
                  center: fivesec__center__car,
                }));
              } else {
                resetSectionStyle(sectionName);
              }
            }
          }
        });
      } else {
        resetSectionStyle();
      }
    }
  };

  const rotationLegendHandler = () => {
    let validTop = ["MAK1", "MAK2", "RAK1", "RAK2"];
    if (
      data[3] !== undefined &&
      data[3].length > 0 &&
      validTop.includes(data[3])
    ) {
      let mode = data[3];
      if (mode === "MAK1") {
        setRotation((prev) => ({
          ...prev,
          top: <RightMigration />,
        }));
      } else if (mode === "MAK2") {
        setRotation((prev) => ({
          ...prev,
          top: <LeftMigration />,
        }));
      } else if (mode === "RAK1") {
        setRotation((prev) => ({
          ...prev,
          top: <TopRightRotation />,
        }));
      } else {
        setRotation((prev) => ({
          ...prev,
          top: <TopLeftRotation />,
        }));
      }
    } else {
      setRotation((prev) => ({
        ...prev,
        top: <></>,
      }));
    }

    let validBottom = ["MBK1", "MBK2", "RBK1", "RBK2", "GGNV", "PSA"];
    if (
      data[4] !== undefined &&
      data[4].length > 0 &&
      validBottom.includes(data[4])
    ) {
      let mode = data[4];

      if (mode === "MBK1") {
        setRotation((prev) => ({
          ...prev,
          bottom: <RightMigration />,
        }));
      } else if (mode === "MBK2") {
        setRotation((prev) => ({
          ...prev,
          bottom: <LeftMigration />,
        }));
      } else if (mode === "RBK1") {
        setRotation((prev) => ({
          ...prev,
          bottom: <BottomRightRotation />,
        }));
      } else if (mode === "RBK2") {
        setRotation((prev) => ({
          ...prev,
          bottom: <BottomLeftRotation />,
        }));
      } else if (mode === "GGNV") {
        setRotation((prev) => ({
          ...prev,
          bottom: <RootWhite />,
        }));
      } else if (mode === "PSA") {
        setRotation((prev) => ({
          ...prev,
          bottom: <RootBlack />,
        }));
      } else {
        setRotation((prev) => ({
          ...prev,
          bottom: <></>,
        }));
      }
    } else {
      setRotation((prev) => ({
        ...prev,
        bottom: <></>,
      }));
    }
  };

  const karangLegendHandler = () => {
    if (odontogramHelper.isDisabled(data, teethMode)) {
      setKarang("");
      return;
    }
    if (data[5] !== undefined && data[5].length > 0 && data[5] === "KARANG") {
      setKarang(<Karang />);
    } else {
      setKarang("");
    }
  };

  useEffect(() => {
    containerStyleBuilder();
    sectionStyleBuilder();
    rotationLegendHandler();
    karangLegendHandler();
  }, [data, teethMode]);

  return (
    <>
      <Box sx={tooth__container}>
        {odontogramHelper.isTopGroupIndex(data[0]) && (
          <Box>
            <Typography sx={index__text}>{data[0] ?? 0}</Typography>
          </Box>
        )}
        <Box data-name="karang" sx={karang__container}>
          {karang}
        </Box>
        <Box data-name="top-rotation" sx={rotation__container}>
          {rotation.top}
        </Box>
        <Box data-name="top-legends" sx={legend__container}>
          {legend.top}
        </Box>
        <Box
          sx={style.container}
          onClick={(e) => {
            handleToothClick(e, data);
          }}
          data-group="sec_container"
        >
          <Box sx={style.top} data-group="sec_top"></Box>
          <Box sx={style.bottom} data-group="sec_bottom"></Box>
          <Box sx={style.center} data-group="sec_center"></Box>
          <Box sx={style.left} data-group="sec_left"></Box>
          <Box sx={style.right} data-group="sec_right"></Box>
        </Box>
        <Box data-name="bottom-legends" sx={legend__container}>
          {legend.bottom}
        </Box>
        <Box data-name="bottom-rotation" sx={rotation__container}>
          {rotation.bottom}
        </Box>
        {odontogramHelper.isBottomGroupIndex(data[0]) && (
          <Box>
            <Typography sx={index__text}>{data[0] ?? 0}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
