import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import mediplusHelper from "../../../../utils/mediplusHelper";
import {
  dropzone__container,
  dropzone__label,
  modal__container,
  modal__header,
} from "./style";

export default function UploadFileModal({
  open,
  onClose,
  files,
  handleFileChange,
  fileSubmitting,
  handleFileUpload,
  handleFileDelete,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal__container}>
        <Box sx={modal__header}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Upload File
          </Typography>
          <IconButton
            onClick={onClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <Dropzone onDrop={handleFileChange}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div style={dropzone__container} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div style={dropzone__label}>
                    <div
                      style={{
                        padding: "10px 0px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src="/assets/icons/gallery-import.svg"
                        alt="import"
                      ></img>
                      <div>drag or upload files here</div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {files &&
              files.length > 0 &&
              files.map((item, index) => {
                return (
                  <Box key={`f_scan_box_${index}`}>
                    <Typography
                      component={"span"}
                      key={`f_scan_${index}`}
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {item.name} | {mediplusHelper.fileSizeIEC(item.size)}{" "}
                      <Typography
                        component={"span"}
                        sx={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFileDelete(index);
                        }}
                      >
                        delete
                      </Typography>
                    </Typography>
                  </Box>
                );
              })}
          </Box>

          <Button
            fullWidth
            sx={{
              backgroundColor: "blue",
              borderRadius: "8px",
              textTransform: "none",
              marginBottom: "15px",
              height: "38px",
            }}
            variant="contained"
            onClick={handleFileUpload}
            disabled={fileSubmitting ? true : false}
          >
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: 600,
                marginRight: "5px",
              }}
            >
              {fileSubmitting ? "Mengupload..." : "Upload"}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
