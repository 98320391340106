import moment from "moment";
import * as Sentry from "@sentry/react";

const mediplusHelper = {
  fileSizeIEC: (a, b, c, d, e) => {
    return (
      ((b = Math),
      (c = b.log),
      (d = 1024),
      (e = (c(a) / c(d)) | 0),
      a / b.pow(d, e)).toFixed(e ? 2 : 0) +
      " " +
      (e ? "KMGTPEZY"[--e] + "B" : "Bytes")
    );
  },
  fileSizeIECRound: (a, b, c, d, e) => {
    return (
      ((b = Math),
      (c = b.log),
      (d = 1024),
      (e = (c(a) / c(d)) | 0),
      a / b.pow(d, e)).toFixed(0) +
      " " +
      (e ? "KMGTPEZY"[--e] + "B" : "Bytes")
    );
  },
  cropText: (text, maxLength = 1, withTrailDots = true) => {
    if (text === undefined || text === null || !text) {
      return "";
    }
    if (text.length <= maxLength) {
      return text;
    }
    return (
      text.substr(0, maxLength - 3 < 0 ? maxLength : maxLength - 3) +
      (withTrailDots ? "..." : "")
    );
  },
  formatRupiah: (rupiah) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    })
      .format(rupiah)
      .replace(",00", "");
  },
  formatNumber: (num) => {
    return new Intl.NumberFormat(["id"]).format(num ?? 0);
  },
  convertIsoDayWeekToPHPSystem: (day) => {
    if (day === 7) {
      return 0;
    } else {
      return day;
    }
  },
  deleteLocalStorageItem: (key) => {
    let i,
      results = [];
    for (i in localStorage) {
      if (localStorage.hasOwnProperty(i)) {
        if (i.match(key) || (!key && typeof i === "string")) {
          let value = localStorage.getItem(i);
          results.push({ key: i, val: value });
        }
      }
    }
    if (results && results.length > 0) {
      results.forEach((k) => {
        localStorage.removeItem(k.key);
      });
    }
  },
  findLocalStorageItems: (key) => {
    let i,
      results = [];
    for (i in localStorage) {
      if (localStorage.hasOwnProperty(i)) {
        if (i.match(key) || (!key && typeof i === "string")) {
          let value = localStorage.getItem(i);
          results.push({ key: i, val: value });
        }
      }
    }
    return results;
  },
  getDeviceUniqueId: () => {
    let navigator_info = window.navigator;
    let screen_info = window.screen;
    let uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";
    return uid;
  },
  isImageMime: (mime) => {
    if (mime && mime.match(/image\/*/)) {
      return true;
    }
    return false;
  },
  isVideoMime: (mime) => {
    if (mime && mime.match(/video\/*/)) {
      return true;
    }
    return false;
  },
  isPdfMime: (mime) => {
    if (mime && mime.match(/application\/pdf/)) {
      return true;
    }
    return false;
  },
  parseQuery: (queryString) => {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  },
  ucwords: function (str) {
    let strVal = "";
    str = str.split(" ");
    for (var chr = 0; chr < str.length; chr++) {
      strVal +=
        str[chr].substring(0, 1).toUpperCase() +
        str[chr].substring(1, str[chr].length) +
        " ";
    }
    return strVal;
  },
  calculateBMI: (height = 0, weight = 0) => {
    try {
      if (isNaN(height) || isNaN(weight)) {
        return 0;
      }
      let a = weight / ((height * height) / 10000);
      if (isNaN(a)) {
        return 0;
      } else {
        return a.toFixed(2);
      }
    } catch (e) {
      return 0;
    }
  },
  BMICategory: (bmi = 0) => {
    if (bmi < 17) {
      return "Kurus Berat";
    } else if (bmi >= 17 && bmi <= 18.4) {
      return "Kurus Ringan";
    } else if (bmi >= 18.5 && bmi <= 25) {
      return "Ideal";
    } else if (bmi >= 25.1 && bmi <= 27) {
      return "Gemuk Ringan";
    } else {
      return "Gemuk Berat";
    }
  },
  calculateBSA: (height = 0, weight = 0) => {
    try {
      if (isNaN(height) || isNaN(weight)) {
        return 0;
      }
      let bsa = Math.sqrt((weight * height) / 3600);
      return bsa.toFixed(2);
    } catch (e) {
      return 0;
    }
  },
  usiaKehamilan: (start_date, current_date) => {
    try {
      let sd = moment(start_date);
      let cd = moment(current_date);
      let diff = Math.abs(sd.diff(cd, "days"));
      if (diff < 7) {
        return `0 Minggu ${diff} Hari`;
      } else {
        let days = diff % 7;
        let week = (diff - days) / 7;
        return `${week} Minggu ${days} Hari`;
      }
    } catch (error) {
      Sentry.captureException(error);
      return "0 Minggu 0 Hari";
    }
  },
  estimasiTanggalKelahiran: (date) => {
    try {
      return moment(date).add(280, "days").format("YYYY-MM-DD");
    } catch (error) {
      Sentry.captureException(error);
      return "";
    }
  },
  hasJsonStructure: (str) => {
    if (typeof str !== "string") return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === "[object Object]" || type === "[object Array]";
    } catch (err) {
      return false;
    }
  },
  dayToString: (day) => {
    const days = [
      "MINGGU",
      "SENIN",
      "SELASA",
      "RABU",
      "KAMIS",
      "JUMAT",
      "SABTU",
    ];
    return days[day];
  },
  getAge: (date, fromDate) => {
    if (!date) {
      return "- Tahun";
    }
    if (!fromDate) {
      return "- Tahun";
    }

    try {
      let fromDateMoment = moment(fromDate);
      let diffYears = fromDateMoment.diff(date, "years");
      if (diffYears >= 17) {
        return diffYears + " Tahun";
      }

      if (diffYears >= 1 && diffYears <= 16) {
        let diffMonth = fromDateMoment.diff(date, "months");
        let month = diffMonth % 12;
        let years = (diffMonth - month) / 12;
        return `${years} Tahun ${month} Bulan`;
      }

      let diffMonth = fromDateMoment.diff(date, "months");
      let diffDays = fromDateMoment.diff(date, "days");

      if (diffDays < 0) {
        return "- Tahun";
      }

      if (diffMonth >= 1 && diffMonth <= 11) {
        let dLeft = diffDays % 30;
        let month = (diffDays - dLeft) / 30;
        let week = Math.floor(dLeft / 7);
        return `${month} Bulan ${week} Minggu`;
      }

      let days = diffDays % 7;
      let weeks = (diffDays - days) / 7;

      return `${weeks} Minggu ${days} Hari`;
    } catch (error) {
      console.log(error);
      return "- Tahun";
    }
  },
  getTimezoneOffset: () => {
    let offset = Math.round((new Date().getTimezoneOffset() * -1) / 60);
    if (offset > 0) {
      return `+${offset}`;
    }
    return String(offset);
  },
  getInitials: (string) => {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  },
  getFileExtensionFromName: (filename) => {
    return /[.]/.exec(filename)
      ? /[^.]+$/.exec(filename.toUpperCase())[0]
      : "UNK";
  },
  unregisterServiceWorker: () => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  },
  isIOS: () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  },
  isPopperTogglerOrPopperElementClicked: function (event, args) {
    var path = event.path || (event.composedPath && event.composedPath());
    if (path) {
      let isPopperToggleOrElemet = Array.from(path).find((element) => {
        if (typeof element.getAttribute === "function") {
          return (
            element.getAttribute("data-group") === "popper-toggler" ||
            element.getAttribute("data-group") === "popper-element"
          );
        } else {
          return false;
        }
      });
      return Boolean(isPopperToggleOrElemet);
    }
  },
  abbreviateNumber: (value) => {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", " rb", " jt", " mil", " tril"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  },
  romanize: (num) => {
    if (isNaN(num)) return NaN;
    var digits = String(+num).split(""),
      key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
      ],
      roman = "",
      i = 3;
    while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  },
  resepObatDataFixer: (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.map((i) => {
      let temp = { ...i };
      let satuan = [
        "ampoule",
        "capsule",
        "tab",
        "gr",
        "hisap",
        "kg",
        "kumur",
        "liter",
        "mg",
        "mcg",
        "ml",
        "ml/c",
        "emulsum",
        "pulveres",
        "naristillae",
        "oculentum",
        "pil",
        "potio",
        "serbuk",
        "larutan",
        "tinctura",
      ];

      let rute_obat = [
        "oral",
        "parenteral",
        "rektal",
        "vaginal",
        "uretral",
        "lokal",
        "topikal",
        "transdermal",
      ];

      if (temp.hasOwnProperty("item")) {
        if (!Array.isArray(temp.item)) {
          temp.item = [];
        }
      } else {
        temp.item = [];
      }

      if (!satuan.includes(temp.satuan_dosis) && temp.satuan_dosis !== "") {
        temp.satuan_dosis_input_mode = "TEXT";
      } else {
        temp.satuan_dosis_input_mode = "OPTION";
      }

      if (!rute_obat.includes(temp.rute) && temp.rute !== "") {
        temp.rute_input_mode = "TEXT";
      } else {
        temp.rute_input_mode = "OPTION";
      }

      temp.item = temp.item.map((i) => {
        let t = { ...i };
        if (!satuan.includes(t.satuan_dosis) && t.satuan_dosis !== "") {
          t.satuan_dosis_input_mode = "TEXT";
        } else {
          t.satuan_dosis_input_mode = "OPTION";
        }

        return t;
      });
      return temp;
    });
  },
  formatScanFolderName: (str) => {
    if (!str) {
      return "";
    }
    if (str.lastIndexOf(".") !== -1) {
      str = str.substr(0, str.lastIndexOf("."));
    }
    if (str.lastIndexOf("_") !== -1) {
      str = str.substr(0, str.lastIndexOf("_"));
    }
    return str;
  },
};

export default mediplusHelper;
