import {
  IconButton,
  InputBase,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { webkit__scrollbar } from "../../../../style";
import { input__text } from "../../../rekam-medis/editor/style";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  minHeight: "calc(100vh - 150px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

function CategoryModal({
  open,
  onClose,
  categories,
  selectedCategory,
  onCategoryChange,
}) {
  const [filter, setFilter] = useState("");

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleCategoryClick = (category) => {
    if (typeof onCategoryChange === "function") {
      onCategoryChange(category);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                display: "block",
                minWidth: "150px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Semua Kategori
            </Typography>
            <InputBase
              sx={input__text}
              value={filter}
              onChange={handleFilterChange}
            ></InputBase>
          </Box>

          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={30}
              height={30}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <TableContainer
          sx={{
            ...webkit__scrollbar,
            maxHeight: "calc(100vh - 150px)",
            overflowY: "auto",
          }}
        >
          <Table aria-label="sticky table">
            <TableBody>
              {categories
                .filter((item) => {
                  if (filter.length < 1) {
                    return true;
                  } else {
                    return item.toLowerCase().includes(filter.toLowerCase());
                  }
                })
                .map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell
                        align={"left"}
                        onClick={() => {
                          handleCategoryClick(item);
                        }}
                        sx={{
                          cursor: "pointer",
                          background:
                            selectedCategory === item
                              ? "rgba(0, 150, 255, 0.24)"
                              : "none",
                          border:
                            selectedCategory === item
                              ? "1px solid #0096FF"
                              : "none",
                        }}
                      >
                        {item}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}

CategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.array,
  selectedCategory: PropTypes.string,
  onCategoryChange: PropTypes.func.isRequired,
};

export default CategoryModal;
