import {
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function TabQueueTablePopper({
  open,
  anchorEl,
  selectedQueue,
  handleQueueCall,
  handleToMedicalRecord,
  handleCancelQueueConfirmationOpen,
  handleRedirectWhatsapp,
  handlePopupQueueAway,
}) {
  return (
    <ClickAwayListener onClickAway={handlePopupQueueAway}>
      <Popper
        data-group="popper-element"
        open={open}
        anchorEl={anchorEl}
        placement={"left"}
        sx={{
          zIndex: 1500,
        }}
      >
        <Box>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    py: "0px",
                  }}
                  onClick={handleQueueCall}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img src="/assets/icons/call.svg" alt="call-queue"></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary={
                      selectedQueue?.status === "ANTRIAN"
                        ? "Panggil Anamnesis"
                        : "Panggil"
                    }
                  />
                </ListItemButton>
              </ListItem>
              {(selectedQueue.status === "TERPANGGIL" ||
                selectedQueue.status === "PERIKSA") && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToMedicalRecord(selectedQueue.id);
                    }}
                    sx={{
                      py: "0px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      <img
                        src="/assets/icons/document.svg"
                        alt="rekammedis-queue"
                      ></img>
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        paddingTop: "2px",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                      primary="Rekam Medis"
                    />
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem
                disablePadding
                onClick={handleCancelQueueConfirmationOpen}
              >
                <ListItemButton
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img
                      src="/assets/icons/user-remove.svg"
                      alt="cancel-queue"
                    ></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      paddingTop: "2px",
                    }}
                    primary="Batal"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={handleRedirectWhatsapp}>
                <ListItemButton
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img src="/assets/icons/call2.svg" alt="calll-queue"></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingTop: "2px",
                    }}
                    primary={selectedQueue.nomor}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}

TabQueueTablePopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  selectedQueue: PropTypes.object,
  handleQueueCall: PropTypes.func.isRequired,
  handleToMedicalRecord: PropTypes.func.isRequired,
  handleCancelQueueConfirmationOpen: PropTypes.func.isRequired,
  handleRedirectWhatsapp: PropTypes.func.isRequired,
  handlePopupQueueAway: PropTypes.func.isRequired,
};

export default TabQueueTablePopper;
