import React from "react";
import { Switch, Route } from "react-router-dom";
import Chat from "../pages/chat/Chat";
import Cuti from "../pages/cuti/Cuti";
import DesainPembayaran from "../pages/desain-pembayaran/DesainPembayaran";
import DetailHasilPemeriksaan from "../pages/hasil-pemeriksaan/detail/DetailHasilPemeriksaan";
import HasilPemeriksaan from "../pages/hasil-pemeriksaan/main/HasilPemeriksaan";
import Home from "../pages/home/Home";
import Icd from "../pages/icd/Icd";
import JadwalPraktek from "../pages/jadwal-praktek/JadwalPraktek";
import ObatEditor from "../pages/obat/editor/ObatEditor";
import Obat from "../pages/obat/main/Obat";
import PembayaranEditor from "../pages/pembayaran/editor/PembayaranEditor";
import Pembayaran from "../pages/pembayaran/main/Pembayaran";
import StatistikPembayaran from "../pages/pembayaran/statistik/StatistikPembayaran";
import DetailPenyimpanan from "../pages/penyimpanan/detail/DetailPenyimpanan";
import Penyimpanan from "../pages/penyimpanan/main/Penyimpanan";
import Profile from "../pages/profile/Profile";
import Odontogram from "../pages/rekam-medis/editor/odontogram/components/Odontogram";
import RekamMedisEditor from "../pages/rekam-medis/editor/RekamMedisEditor";
import RekamMedis from "../pages/rekam-medis/main/RekamMedis";

export default function DashboardRoute() {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home></Home>
        </Route>
        <Route exact path="/rekam-medis">
          <RekamMedis></RekamMedis>
        </Route>
        <Route exact path="/rekam-medis/editor">
          <RekamMedisEditor />
        </Route>
        <Route exact path="/rekam-medis/editor/:id">
          <RekamMedisEditor />
        </Route>
        <Route exact path="/hasil-pemeriksaan">
          <HasilPemeriksaan></HasilPemeriksaan>
        </Route>
        <Route exact path="/hasil-pemeriksaan/:id">
          <DetailHasilPemeriksaan></DetailHasilPemeriksaan>
        </Route>
        <Route exact path="/pembayaran">
          <Pembayaran></Pembayaran>
        </Route>
        <Route exact path="/pembayaran/statistik">
          <StatistikPembayaran></StatistikPembayaran>
        </Route>
        <Route exact path="/pembayaran/editor">
          <PembayaranEditor></PembayaranEditor>
        </Route>
        <Route exact path="/pembayaran/editor/:id">
          <PembayaranEditor></PembayaranEditor>
        </Route>
        <Route exact path="/jadwal-praktek">
          <JadwalPraktek></JadwalPraktek>
        </Route>
        <Route exact path="/obat">
          <Obat></Obat>
        </Route>
        <Route exact path="/obat/editor">
          <ObatEditor></ObatEditor>
        </Route>
        <Route exact path="/obat/editor/:id">
          <ObatEditor></ObatEditor>
        </Route>
        <Route exact path="/penyimpanan">
          <Penyimpanan></Penyimpanan>
        </Route>
        <Route exact path="/penyimpanan/:id">
          <DetailPenyimpanan></DetailPenyimpanan>
        </Route>
        <Route exact path="/cuti">
          <Cuti></Cuti>
        </Route>
        <Route exact path="/icd10">
          <Icd></Icd>
        </Route>
        <Route exact path="/chat">
          <Chat></Chat>
        </Route>
        <Route exact path="/profile">
          <Profile></Profile>
        </Route>
      </Switch>
    </>
  );
}
