import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import { updateChatRead } from "../../../../../../redux/slices/chatRead";
import {
  container,
  conv__img,
  conv__img__container,
  conv__img__wrapper,
  conv__message,
  conv__name,
  conv__name__container,
  conv__time__container,
  message__count,
} from "./style";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import * as Sentry from "@sentry/react";

export default function ConversationListItem({
  conversation,
  selected = false,
  handleConversationClick = () => {},
}) {
  const [hover, setHover] = useState(false);
  const { user } = useAuth();
  const dispatch = useDispatch();

  const handlePinUnpin = async () => {
    let baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/chat/conversations/${conversation.id}`;
    if (conversation.pinned === true) {
      baseUrl = `${baseUrl}/unpin`;
    } else {
      baseUrl = `${baseUrl}/pin`;
    }
    try {
      await axios.post(
        baseUrl,
        {},
        {
          headers: authHeader(),
          params: {
            head: user.head_doctor_id,
          },
        }
      );
      dispatch(updateChatRead());
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  return (
    <Paper
      onClick={() => {
        handleConversationClick(conversation.id);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      sx={{
        ...container,
        background: selected ? "#EFF1F9" : "transparent",
      }}
    >
      <Box sx={conv__img__wrapper}>
        <Box sx={conv__img__container}>
          <img
            src={
              conversation.conversation_photo
                ? conversation.conversation_photo
                : "/assets/icons/avatar.svg"
            }
            alt="doctor"
            style={conv__img}
          ></img>
        </Box>

        <Box component={"div"} sx={conv__name__container}>
          <Typography component={"span"} sx={conv__name}>
            {conversation.conversation_name}
          </Typography>
          <Typography component={"span"} sx={conv__message}>
            {conversation?.last_message && !_.isEmpty(conversation.last_message)
              ? conversation?.last_message.attachment === null
                ? mediplusHelper.cropText(
                    conversation?.last_message.content,
                    30
                  )
                : mediplusHelper.isImageMime(
                    conversation.last_message.attachment.mime_type
                  )
                ? "Image"
                : mediplusHelper.isVideoMime(
                    conversation.last_message.attachment.mime_type
                  )
                ? "Video"
                : "Document"
              : "Tidak ada pesan"}
          </Typography>
        </Box>
      </Box>
      <Box sx={conv__time__container}>
        <Typography
          sx={{
            fontSize: "10px",
            mb: "5px",
          }}
        >
          {conversation.last_message?.created_at
            ? moment(conversation.last_message?.created_at).format("HH:mm")
            : ""}
        </Typography>

        {hover ? (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handlePinUnpin();
            }}
            sx={{
              borderRadius: "3px",
              px: 0.5,
              background: "#FFCFEA",
              height: "auto",
            }}
          >
            <img
              src={
                conversation.pinned === true
                  ? "/assets/icons/unpin.svg"
                  : "/assets/icons/pin.svg"
              }
              style={{
                width: "10px",
                height: "10px",
              }}
              alt="pin"
            />
          </Box>
        ) : (
          <>
            {conversation.chat_message_count !== 0 ? (
              <Typography sx={message__count}>
                {conversation.chat_message_count}
              </Typography>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
}
