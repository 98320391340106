import React from "react";

export default function Karang() {
  return (
    <img
      src="/assets/odontogram/karang.svg"
      alt="karang"
      style={{
        marginLeft: "5px",
      }}
      
    ></img>
  );
}
