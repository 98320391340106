import { webkit__scrollbar } from "../../../../style";

const diagnose__box__container = {
  background: "rgba(239, 241, 249, 1)",
  marginRight: "10px",
  borderRadius: "8px",
  border: "1px solid #ced4da",
  padding: "5px",
};

const diagnose__box__header = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
};

const diagnose__box__header__text = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "10px",
};

const diagnose__box__header__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  height: "26px",
  color: "white",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const diagnose__list__container = {
  display: "flex",
  flexDirection: "column",
  gap: 0.5,
  minHeight: "120px",
  maxHeight: "120px",
  overflowY: "auto",
  paddingRight: "5px",
  paddingTop: "5px",
  ...webkit__scrollbar,
};

const diagnose__list__item = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  borderBottom: "1px solid black",
};

const diagnose__list__item__delete__btn = {
  color: "red",
  cursor: "pointer",
  fontSize: "14px",
};

export {
  diagnose__box__container,
  diagnose__box__header,
  diagnose__box__header__text,
  diagnose__box__header__btn,
  diagnose__list__container,
  diagnose__list__item,
  diagnose__list__item__delete__btn,
};
