import { Box, Button, Paper, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import {
  schedule__btn,
  schedule__btn__label,
  schedule__container,
  schedule__footer__container,
  schedule__footer__queue__info_container,
  schedule__footer__queue__info__title,
  schedule__footer__queue__info__value,
  schedule__footer__text,
  schedule__header__container,
  schedule__header__img,
  schedule__header__info__container,
  schedule__header__info__text__clinic,
  schedule__header__info__text__date,
} from "./style";
import PropTypes from "prop-types";

function ScheduleBox({ schedule, date, handleScheduleSwitch }) {
  const schedule_date = useSelector((state) => state.schedule.scheduleDate);
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  return (
    <Paper elevation={0} sx={schedule__container}>
      <Paper sx={schedule__header__container} elevation={0}>
        <img
          src={schedule.clinic?.photo_profile}
          alt="clinic"
          style={schedule__header__img}
        ></img>
        <Box component={"div"} sx={schedule__header__info__container}>
          <Typography
            component={"span"}
            sx={schedule__header__info__text__clinic}
          >
            {schedule.clinic?.nama}
          </Typography>
          <Typography
            component={"span"}
            sx={schedule__header__info__text__date}
          >
            {moment(date).locale("id").format("DD MMMM YYYY")}
          </Typography>
        </Box>
      </Paper>
      <Box sx={schedule__footer__container}>
        <Typography sx={schedule__footer__text}>
          {schedule.clinic_room?.nama}
        </Typography>
        <Typography sx={schedule__footer__text}>
          {schedule.jam_buka} - {schedule.jam_tutup}
        </Typography>
      </Box>
      <Box sx={schedule__footer__queue__info_container}>
        <Box>
          <Typography sx={schedule__footer__queue__info__title}>
            Jumlah Antrian
          </Typography>
          <Typography sx={schedule__footer__queue__info__value}>
            {schedule.antrian?.total ?? 0}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={schedule__btn}
          disabled={
            current_active_schedule.id === schedule.id &&
            moment(date).format("YYYY-MM-DD") ===
              moment(schedule_date).format("YYYY-MM-DD")
              ? true
              : false
          }
          onClick={() => {
            handleScheduleSwitch(
              schedule.id,
              moment(date).format("YYYY-MM-DD")
            );
          }}
        >
          <Typography sx={schedule__btn__label}>Pilih</Typography>
        </Button>
      </Box>
    </Paper>
  );
}

ScheduleBox.propTypes = {
  schedule: PropTypes.object.isRequired,
  date: PropTypes.any.isRequired,
  handleScheduleSwitch: PropTypes.func.isRequired,
};

export default ScheduleBox;
