import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  id: null,
  clinic_room_id: "",
  nama_pasien: "",
  harga_akhir: 0,
  tanggal_pemeriksaan: moment().format("YYYY-MM-DD HH:mm:ss"),
  medical_record_id: null,
  catatan: "",
  total_diskon: 0, //hanya untuk view tidak disimpan di DB
  invoice_items: [],
};

const calculateDiscountAndTotal = (invoice) => {
  let harga_akhir = 0;
  let total_diskon = 0;
  let invoice_items = [...invoice.invoice_items];
  if (invoice_items.length > 0) {
    for (let i = 0; i < invoice_items.length; i++) {
      let subtotalBeforeDiscount =
        invoice_items[i].harga * invoice_items[i].kuantitas;

      let diskon = 0;
      if (invoice_items[i].diskon_type === "NORMAL") {
        diskon = invoice_items[i].diskon_amount;
      } else {
        diskon =
          (invoice_items[i].diskon_amount * subtotalBeforeDiscount) / 100;
      }
      let subtotalAfterDiscount = subtotalBeforeDiscount - diskon;
      invoice_items[i].diskon = diskon;
      invoice_items[i].subtotal = subtotalAfterDiscount;
      harga_akhir += subtotalAfterDiscount;
      total_diskon += diskon;
    }
  }
  invoice.invoice_items = invoice_items;
  invoice.harga_akhir = harga_akhir;
  invoice.total_diskon = total_diskon;
  return invoice;
};

export const invoice = createSlice({
  name: "invoice",
  initialState: { ...initialState },
  reducers: {
    initInvoice: (state, data) => {
      const invoice_items = [];
      if (Array.isArray(data.payload?.invoice_items)) {
        data.payload.invoice_items.forEach((element) => {
          let tempInvItem = {
            ...element,
            photo: element?.product?.photo ?? null,
            originQty: element?.kuantitas,
          };
          if (tempInvItem.hasOwnProperty("product")) {
            delete tempInvItem.product;
          }
          if (tempInvItem.hasOwnProperty("medicine")) {
            delete tempInvItem.medicine;
          }
          invoice_items.push(tempInvItem);
        });
      }
      return calculateDiscountAndTotal({
        ...{ ...state, ...data.payload },
        invoice_items: invoice_items,
      });
    },
    updateInvoiceInfo: (state, data) => {
      return calculateDiscountAndTotal({
        ...state,
        ...data.payload,
      });
    },
    addInvoiceItem: (state, data) => {
      let product = data.payload;
      let find = state.invoice_items.find((i) => i.product_id === product.id);
      if (find !== undefined) {
        return calculateDiscountAndTotal({
          ...state,
          invoice_items: state.invoice_items.map((i) =>
            i.product_id === product.id
              ? { ...i, kuantitas: i.kuantitas + 1 }
              : i
          ),
        });
      } else {
        let newItem = {
          id: null,
          photo: product.photo,
          product_id: product.id,
          medicine_id: null,
          nama: product.nama,
          harga: product.harga,
          kuantitas: 1,
          pajak: 0,
          diskon_type: "NORMAL",
          diskon_amount: 0,
          originQty: 0,
          diskon: 0,
          subtotal: product.harga,
          tipe: "SERVICE",
        };
        return calculateDiscountAndTotal({
          ...state,
          invoice_items: [...state.invoice_items, newItem],
        });
      }
    },
    addMedicineInvoiceItem: (state, data) => {
      let medicine = data.payload;
      let find = state.invoice_items.find((i) => i.medicine_id === medicine.id);
      if (find !== undefined) {
        return calculateDiscountAndTotal({
          ...state,
          invoice_items: state.invoice_items.map((i) =>
            i.medicine_id === medicine.id
              ? {
                  ...i,
                  kuantitas: medicine.qty
                    ? i.kuantitas + medicine.qty
                    : i.kuantitas + 1,
                }
              : i
          ),
        });
      } else {
        let newItem = {
          id: null,
          photo: medicine.photo ?? null,
          product_id: null,
          medicine_id: medicine.id,
          nama: medicine.nama,
          harga: medicine.harga,
          kuantitas: medicine.qty ? medicine.qty : 0,
          pajak: 0,
          diskon_type: "NORMAL",
          diskon_amount: 0,
          diskon: 0,
          originQty: 0,
          subtotal: medicine.harga,
          tipe: "MEDICINE",
        };
        return calculateDiscountAndTotal({
          ...state,
          invoice_items: [...state.invoice_items, newItem],
        });
      }
    },
    addQtyInvoiceItem: (state, data) => {
      return calculateDiscountAndTotal({
        ...state,
        invoice_items: state.invoice_items.map((item, index) =>
          index === data.payload
            ? { ...item, kuantitas: item.kuantitas + 1 }
            : item
        ),
      });
    },
    subQtyInvoiceItem: (state, data) => {
      let index = data.payload;
      let item = state.invoice_items[index];
      if (item) {
        let minQty = 1;
        if (item.tipe !== "SERVICE") {
          minQty = 0;
        }
        if (item.kuantitas - 1 < minQty) {
          return calculateDiscountAndTotal({
            ...state,
            invoice_items: state.invoice_items.filter(
              (item, idx) => idx !== index
            ),
          });
        } else {
          return calculateDiscountAndTotal({
            ...state,
            invoice_items: state.invoice_items.map((item, idx) =>
              idx === index ? { ...item, kuantitas: item.kuantitas - 1 } : item
            ),
          });
        }
      }
    },
    editItemDiscount: (state, data) => {
      let index = data.payload.idx ?? -1;
      let diskon_amount = data.payload.diskon_amount ?? 0;
      let diskon_type = data.payload.diskon_type ?? "NORMAL";
      return calculateDiscountAndTotal({
        ...state,
        invoice_items: state.invoice_items.map((item, idx) =>
          idx === index ? { ...item, diskon_amount, diskon_type } : item
        ),
      });
    },
    resetInvoice: (state, data) => {
      return {
        ...initialState,
        clinic_room_id: data.payload?.clinic_room_id ?? "",
      };
    },
  },
});

export const {
  initInvoice,
  addInvoiceItem,
  resetInvoice,
  addQtyInvoiceItem,
  subQtyInvoiceItem,
  editItemDiscount,
  addMedicineInvoiceItem,
  updateInvoiceInfo,
} = invoice.actions;
export default invoice.reducer;
