import { webkit__scrollbar } from "../../../../style";

const template__text__wrapper = {
  marginBottom: "10px",
  display: "block",
};

const template__text__container = {
  background: "rgba(239, 241, 249, 1)",
  mr: "0px",
  borderRadius: "8px",
  border: "1px solid #ced4da",
  padding: "3px",
};

const template__text__header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "5px",
};

const template__text__header__h1 = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "10px",
};

const modal__container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  marginBottom: "10px",
};

const modal__submit__btn = {
  backgroundColor: "blue",
  height: "26px",
  color: "white",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const modal__submit__btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "3px",
};

const template__text__list__container = {
  display: "flex",
  justifyContent: "flex-start",
  gap: 0.5,
  minHeight: "85px",
  maxHeight: "200px",
  width: "100%",
  flexWrap: "wrap",
  overflowY: "auto",
  ...webkit__scrollbar,
};

const template__btn = {
  ...modal__submit__btn,
  borderRadius: "30px",
  color: "black",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#FF0091",
    color: "white",
  },
  maxWidth: "300px",
  height: "fit-content",
};

export {
  template__text__wrapper,
  template__text__container,
  template__text__header__container,
  template__text__header__h1,
  modal__container,
  modal__submit__btn,
  modal__submit__btn__label,
  template__text__list__container,
  template__btn,
};
