import { configureStore, combineReducers } from "@reduxjs/toolkit";
import chatConversation from "./slices/chatConversation";
import chatRead from "./slices/chatRead";
import chatUpdate from "./slices/chatUpdate";
import hardwareUpdate from "./slices/hardwareUpdate";
import invoice from "./slices/invoice";
import medicine from "./slices/medicine";
import medicineCache from "./slices/medicineCache";
import product from "./slices/product";
import queueUpdate from "./slices/queueUpdate";
import scanFolderUpdate from "./slices/scanFolderUpdate";
import schedule from "./slices/schedule";
import timelineGroupMode from "./slices/timelineGroupMode";
import newMedicalRecordUpdate from "./slices/newMedicalRecordUpdate";

const combinedReducer = combineReducers({
  chat_read: chatRead,
  chat_update: chatUpdate,
  hardware_update: hardwareUpdate,
  queue_update: queueUpdate,
  scan_folder_update: scanFolderUpdate,
  schedule: schedule,
  chat_conversation: chatConversation,
  invoice: invoice,
  product: product,
  medicine: medicine,
  medicine_cache: medicineCache,
  timeline_group_mode: timelineGroupMode,
  new_medical_record_update: newMedicalRecordUpdate,
});

const rootReducer = (state, action) => {
  /**
   * RESET ALL STATE ketika auth/logout reducer di dispatch
   */
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
