import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import { tab__container, tab__label } from "./style";
import a11yProps from "../../../../components/tab/a11yProps";
import TabPanel from "../../../../components/tab/TabPanel";
import SectionLoading from "../../../../components/loading/SectionLoading";
import DetailHasilPemeriksaanContent from "./content/DetailHasilPemeriksaanContent";
import * as Sentry from "@sentry/react";

export default function DetailHasilPemeriksaan() {
  const route = useRouteMatch();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [scanFolder, setScanFolder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const loadData = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/scan-folders/${id}`, {
        params: {
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setScanFolder(response.data);
      })
      .catch(function (error) {
        console.log(error);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
        Sentry.captureException(error);
        history.push("/hasil-pemeriksaan");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (route?.params?.id && !isNaN(route?.params?.id)) {
      loadData(route.params.id);
    } else {
      enqueueSnackbar("params id url invalid", {
        variant: "error",
      });
    }
  }, []);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Box sx={tab__container}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="tabk"
          sx={{
            ".MuiTabs-flexContainer": {
              background: "transparent",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#FF0091",
              height: "3px",
            },
          }}
        >
          <Tab
            label={
              <Typography
                sx={{
                  ...tab__label,
                  color: tabIndex === 0 ? "#041615" : "#7D7D7D",
                }}
              >
                Semua
              </Typography>
            }
            {...a11yProps(0)}
          ></Tab>
          <Tab
            label={
              <Typography
                sx={{
                  ...tab__label,
                  color: tabIndex === 1 ? "#041615" : "#7D7D7D",
                }}
              >
                Foto
              </Typography>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Typography
                sx={{
                  ...tab__label,
                  color: tabIndex === 2 ? "#041615" : "#7D7D7D",
                }}
              >
                Video
              </Typography>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Typography
                sx={{
                  ...tab__label,
                  color: tabIndex === 3 ? "#041615" : "#7D7D7D",
                }}
              >
                Lainnya
              </Typography>
            }
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel
        sx={{
          ".MuiBox-root": {
            paddingLeft: "0px",
            paddingRight: "0px",
          },
        }}
        value={tabIndex}
        index={0}
      >
        {loading ? (
          <SectionLoading height="calc(100vh - 200px)" />
        ) : (
          <DetailHasilPemeriksaanContent scanFolder={scanFolder} type="ALL" />
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {loading ? (
          <SectionLoading height="calc(100vh - 200px)" />
        ) : (
          <DetailHasilPemeriksaanContent scanFolder={scanFolder} type="IMAGE" />
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        {loading ? (
          <SectionLoading height="calc(100vh - 200px)" />
        ) : (
          <DetailHasilPemeriksaanContent scanFolder={scanFolder} type="VIDEO" />
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        {loading ? (
          <SectionLoading height="calc(100vh - 200px)" />
        ) : (
          <DetailHasilPemeriksaanContent scanFolder={scanFolder} type="OTHER" />
        )}
      </TabPanel>
    </Box>
  );
}
