const dropzone__container = {
  height: "95px",
  borderRadius: "8px",
  backgroundColor: "rgba(239, 241, 249, 1)",
  border: "1px solid #ced4da",
  position: "relative",
};

const dropzone__label = {
  margin: "0px",
  position: "absolute",
  left: "50%",
  top: "50%",
  WebkitTransform: "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
  display: "inline-block",
  maxWidth: "80%",
  fontSize: "14px",
};

export { dropzone__container, dropzone__label };
