const otp__text__h1 = {
  fontSize: "32px",
  fontWeight: "700",
};

const otp__text__h2 = {
  marginBottom: "14px",
};

const otp__button__submit = {
  backgroundColor: "blue",
  color: "white",
  textTransform: "none",
  width: "100%",
  marginBottom: "10px",
};

const otp__button__resend = {
  backgroundColor: "white",
  color: "blue",
  textTransform: "none",
  width: "100%",
  marginBottom: "10px",
};

export {
  otp__text__h1,
  otp__text__h2,
  otp__button__submit,
  otp__button__resend,
};
