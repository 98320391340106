const card__container = {
  borderRadius: "8px",
  position: "relative",
  overflow: "hidden",
  width: "305px",
};

const card__header = {
  display: "flex",
  minWidth: "150px",
  minHeight: "45px",
  boxShadow: "none",
  background: "transparent",
  width: "auto",
  cursor: "pointer",
  marginRight: "5px",
  marginBottom: "5px",
};

const hardware__illustration__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "10px",
  marginRight: "15px",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
};

const hardware__name = {
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "19px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const footer__container = {
  paddingLeft: "10px",
  marginBottom: "5px",
};

const usage__label = {
  fontSize: "12px",
  fontWeight: 400,
  color: "#7D7D7D",
};

const usage__value = {
  fontSize: "16px",
  fontWeight: 700,
  color: "#041615",
};

const background__img = {
  position: "absolute",
  right: "0px",
  bottom: "0px",
};

export {
  card__container,
  card__header,
  hardware__illustration__img,
  hardware__name,
  background__img,
  footer__container,
  usage__label,
  usage__value,
};
