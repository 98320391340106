import { Box, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Rnd } from "react-rnd";
import { accordion__item__text, header__container, header__h1 } from "./style";
import NamaPasien from "./components/NamaPasien";
import { useSnackbar } from "notistack";
import JenisPasien from "./components/JenisPasien";
import NamaDokter from "./components/NamaDokter";
import NamaPoli from "./components/NamaPoli";
import TanggalPeriksa from "./components/TanggalPeriksa";
import TanggalPembayaran from "./components/TanggalPembayaran";
import TableOne from "./components/TableOne";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row",
  justifyContent: "space-between",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function DesainPembayaran() {
  const { enqueueSnackbar } = useSnackbar();

  const [canvasDimension, setCanvasDimension] = useState({
    width: 400,
    height: 500,
  });

  const [elements, setElements] = useState([
    {
      id: "nama_pasien",
      border: "none",
      component: function () {
        return (
          <NamaPasien
            border={this.border ? this.border : "none"}
            helperBorder={this.helper}
          ></NamaPasien>
        );
      },
      x: 0,
      y: 0,
      w: 150,
      h: 20,
      display: "hidden",
      resize: {
        top: false,
        right: true,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    },
    {
      id: "nama_dokter",
      border: "none",
      component: function () {
        return (
          <NamaDokter
            border={this.border ? this.border : "none"}
            helperBorder={this.helper}
          ></NamaDokter>
        );
      },
      x: 0,
      y: 0,
      w: 150,
      h: 20,
      display: "hidden",
      resize: {
        top: false,
        right: true,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    },
    {
      id: "jenis_pasien",
      border: "none",
      component: function () {
        return (
          <JenisPasien
            border={this.border ? this.border : "none"}
            helperBorder={this.helper}
          ></JenisPasien>
        );
      },
      x: 0,
      y: 0,
      w: 150,
      h: 20,
      display: "hidden",
      resize: {
        top: false,
        right: true,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    },
    {
      id: "nama_poli",
      border: "none",
      component: function () {
        return (
          <NamaPoli
            border={this.border ? this.border : "none"}
            helperBorder={this.helper}
          ></NamaPoli>
        );
      },
      x: 0,
      y: 0,
      w: 150,
      h: 20,
      display: "hidden",
      resize: {
        top: false,
        right: true,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    },
    {
      id: "tanggal_periksa",
      border: "none",
      component: function () {
        return (
          <TanggalPeriksa
            border={this.border ? this.border : "none"}
            helperBorder={this.helper}
          ></TanggalPeriksa>
        );
      },
      x: 0,
      y: 0,
      w: 150,
      h: 40,
      display: "hidden",
      resize: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    },
    {
      id: "tanggal_pembayaran",
      border: "none",
      component: function () {
        return (
          <TanggalPembayaran
            border={this.border ? this.border : "none"}
            helperBorder={this.helper}
          ></TanggalPembayaran>
        );
      },
      x: 0,
      y: 0,
      w: 150,
      h: 40,
      display: "hidden",
      resize: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    },
    {
      id: "table_one",
      border: "none",
      component: function () {
        return (
          <TableOne
            border={this.border ? this.border : "none"}
            helperBorder={this.helper}
          ></TableOne>
        );
      },
      x: 0,
      y: 0,
      w: 350,
      h: 150,
      display: "hidden",
      resize: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    },
  ]);

  const [selectedAccordionElementId, setSelectedAccordionElementId] =
    useState(null);

  const canvasRef = useRef(null);

  const [useBorder, setUseBorder] = useState(false);
  const [isChooseElement, setIsChooseElement] = useState(true);
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCanvasClick = (e) => {
    let cursorX = e.pageX;
    let cursorY = e.pageY;
    let rect = canvasRef.current.getBoundingClientRect();
    let canvasX = rect.x;
    let canvasY = rect.y;
    let elPosX = cursorX - canvasX;
    let elPosY = cursorY - canvasY;
    if (selectedAccordionElementId !== null) {
      let el = elements.find((i) => i.id === selectedAccordionElementId);
      if (el) {
        if (el.display !== "hidden") {
          enqueueSnackbar("element sudah ada");
          setSelectedAccordionElementId(null);
          return;
        }
        setElements((prev) =>
          prev.map((el) =>
            el.id === selectedAccordionElementId
              ? { ...el, x: elPosX, y: elPosY, display: "show" }
              : el
          )
        );
        setSelectedAccordionElementId(null);
      }
    }
  };

  const handleElementPositionChange = (id, x, y) => {
    console.log(x, y);
    setElements((prev) =>
      prev.map((el, idx) => (el.id === id ? { ...el, x: x, y: y } : el))
    );
  };

  const handleElementSizeChange = (id, w, h) => {
    setElements((prev) =>
      prev.map((el, idx) => (el.id === id ? { ...el, w: w, h: h } : el))
    );
  };

  const handleAccordionElemetSelect = (id) => {
    setSelectedAccordionElementId(id);
  };

  const handleAddBorder = (id) => {
    setElements((prev) =>
      prev.map((el, idx) =>
        el.id === id ? { ...el, border: "1px solid rgba(0, 174, 239, 1)" } : el
      )
    );
  };

  const handleRemoveBorder = (id) => {
    setElements((prev) =>
      prev.map((el, idx) => (el.id === id ? { ...el, border: "none" } : el))
    );
  };

  const handleDragHelper = (id, x, y) => {
    let topHelper = false;
    let leftHelper = false;
    let elSameX = elements.find(
      (i) =>
        Math.round(i.x) - 5 <= Math.round(x) &&
        Math.round(i.x) + 5 >= Math.round(x) &&
        i.display !== "hidden" &&
        i.id !== id
    );
    if (elSameX) {
      leftHelper = true;
    }
    let elSameY = elements.find(
      (i) =>
        Math.round(i.y + 1) - 5 <= Math.round(y) &&
        Math.round(i.y + 1) + 5 >= Math.round(y) &&
        i.display !== "hidden" &&
        i.id !== id
    );
    if (elSameY) {
      topHelper = true;
    }
    setElements((prev) =>
      prev.map((el, idx) =>
        el.id === id
          ? {
              ...el,
              x: leftHelper ? elSameX.x : el.x,
              y: topHelper ? elSameY.y : el.y,
              helper: {
                top: topHelper,
                left: leftHelper,
              },
            }
          : el
      )
    );
  };

  const disableHelper = (id) => {
    setElements((prev) =>
      prev.map((el, idx) =>
        el.id === id
          ? {
              ...el,
              helper: {
                top: false,
                left: false,
              },
            }
          : el
      )
    );
  };

  return (
    <>
      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Desain Pembayaran
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Paper
            elevation={0}
            sx={{
              position: "relative",
              width: "100%",
              height: "calc(100vh - 150px)",
              background: "#EFF1F9",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Box
              ref={canvasRef}
              onClick={handleCanvasClick}
              component={"div"}
              id="template-canvas"
              sx={{
                background: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "hidden",
                height: canvasDimension.height,
                width: canvasDimension.width,
              }}
            >
              {elements &&
                elements
                  .filter((item) => item.display !== "hidden")
                  .map((el, idx) => {
                    return (
                      <Rnd
                        key={idx}
                        size={{
                          width: el.w,
                          height: el.h,
                        }}
                        position={{ x: el.x, y: el.y }}
                        onDragStart={(e, data) => {
                          handleAddBorder(el.id);
                        }}
                        onDrag={(e, data) => {
                          handleDragHelper(el.id, data.x, data.y);
                          handleElementPositionChange(el.id, data.x, data.y);
                        }}
                        onDragStop={(e, data) => {
                          handleRemoveBorder(el.id);
                          handleElementPositionChange(el.id, data.x, data.y);
                          handleDragHelper(el.id, data.x, data.y);
                          disableHelper(el.id);
                        }}
                        onResizeStart={() => {
                          handleAddBorder(el.id);
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          handleRemoveBorder(el.id);
                          handleElementSizeChange(
                            el.id,
                            ref.style.width,
                            ref.style.height
                          );
                        }}
                        bounds={"#template-canvas"}
                        enableResizing={el.resize}
                      >
                        {el.component()}
                      </Rnd>
                    );
                  })}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Data Pasien
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    onClick={() => {
                      handleAccordionElemetSelect("nama_pasien");
                    }}
                    sx={{
                      ...accordion__item__text,
                      background:
                        selectedAccordionElementId === "nama_pasien"
                          ? "rgba(239, 241, 249, 1)"
                          : "none",
                    }}
                  >
                    Nama Pasien
                  </Typography>
                  <Typography
                    onClick={() => {
                      handleAccordionElemetSelect("jenis_pasien");
                    }}
                    sx={{
                      ...accordion__item__text,
                      background:
                        selectedAccordionElementId === "jenis_pasien"
                          ? "rgba(239, 241, 249, 1)"
                          : "none",
                    }}
                  >
                    Jenis Pasien
                  </Typography>
                  <Typography
                    onClick={() => {
                      handleAccordionElemetSelect("nama_poli");
                    }}
                    sx={{
                      ...accordion__item__text,
                      background:
                        selectedAccordionElementId === "nama_poli"
                          ? "rgba(239, 241, 249, 1)"
                          : "none",
                    }}
                  >
                    Poli
                  </Typography>
                  <Typography
                    onClick={() => {
                      handleAccordionElemetSelect("nama_dokter");
                    }}
                    sx={{
                      ...accordion__item__text,
                      background:
                        selectedAccordionElementId === "nama_dokter"
                          ? "rgba(239, 241, 249, 1)"
                          : "none",
                    }}
                  >
                    Dokter
                  </Typography>
                  <Typography
                    onClick={() => {
                      handleAccordionElemetSelect("tanggal_pembayaran");
                    }}
                    sx={{
                      ...accordion__item__text,
                      background:
                        selectedAccordionElementId === "tanggal_pembayaran"
                          ? "rgba(239, 241, 249, 1)"
                          : "none",
                    }}
                  >
                    Tanggal Pembayaran
                  </Typography>
                  <Typography
                    onClick={() => {
                      handleAccordionElemetSelect("tanggal_periksa");
                    }}
                    sx={{
                      ...accordion__item__text,
                      background:
                        selectedAccordionElementId === "tanggal_periksa"
                          ? "rgba(239, 241, 249, 1)"
                          : "none",
                    }}
                  >
                    Tanggal Periksa
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Table
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    paddingLeft: "0px",
                  }}
                >
                  <Typography
                    onClick={() => {
                      handleAccordionElemetSelect("table_one");
                    }}
                    sx={{
                      ...accordion__item__text,
                      background:
                        selectedAccordionElementId === "table_one"
                          ? "rgba(239, 241, 249, 1)"
                          : "none",
                    }}
                  >
                    Table One
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
