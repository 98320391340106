import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  IconButton,
  Typography,
  ClickAwayListener,
  Button,
  Snackbar,
} from "@mui/material";
import { Box } from "@mui/system";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/id";
import { useSnackbar } from "notistack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../providers/AuthProvider";
import { updateQueue } from "../../../../../redux/slices/queueUpdate";
import authHeader from "../../../../../services/authHeader";
import TabLoading from "../tab-loading/TabLoading";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import TabQueueTablePopper from "./TabQueueTablePopper";
import PopperRefresh from "../popper-refresh/PopperRefresh";
import {
  queue__status__pills,
  queue__status__pills__label,
  queue__table__container,
  queue__table__content__wrapper,
  queue__table__title,
  queue__user__img,
  snackbar__content__container,
  snackbar__content__text,
  table__cell__text,
} from "./style";
import * as Sentry from "@sentry/react";

export default function TabQueue(props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useAuth();

  /**
   * === STATE ===
   */

  //table
  const { search } = props;
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [hasMoreData, setHasMoreData] = React.useState(false);
  const [nextUrl, setNextUrl] = React.useState(null);

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const schedule_date = useSelector((state) => state.schedule.scheduleDate);
  const queue_update = useSelector((state) => state.queue_update.value);

  //popper
  const [refreshButtonEl, setRefreshButtonEl] = React.useState(null);
  const [popupRefreshOpen, setPopupRefreshOpen] = React.useState(false);
  const [popperQueueEl, setPopperQueueEl] = React.useState(null);
  const [popupQueueOpen, setPopupQueueOpen] = React.useState(false);
  const [cancelQueueConfOpen, setCancelQueueConfOpen] = React.useState(false);
  const [selectedQueue, setSelectedQueue] = React.useState({
    id: null,
    nomor: null,
  });

  /**
   * === HANDLER ===
   */

  const handleRefresh = () => {
    dispatch(updateQueue());
    setPopupRefreshOpen(false);
    setLoading(true);
  };

  const handleCancelQueueConfirmationOpen = () => {
    setPopupQueueOpen(false);
    setCancelQueueConfOpen(true);
  };

  const handlePopupRefreshOpen = (event) => {
    setRefreshButtonEl(event.currentTarget);
    setPopupRefreshOpen(!popupRefreshOpen);
  };

  const handlePopupRefreshAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      setPopupRefreshOpen(false);
    }
  };

  const handlePopupQueueOpen = (event) => {
    setPopperQueueEl(event.currentTarget);
    let queueId = parseInt(event.currentTarget.getAttribute("data-queue-id"));
    let queue = rows.find((item) => {
      return item.id === queueId;
    });
    if (queue !== undefined) {
      setSelectedQueue({
        id: queue.id,
        nomor: queue.user?.nomor,
        status: queue.status,
      });
    }
    if (event.currentTarget === popperQueueEl) {
      setPopupQueueOpen(!popupQueueOpen);
    } else {
      setPopupQueueOpen(true);
    }
  };

  const handlePopupQueueAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupQueueOpen) setPopupQueueOpen(false);
    }
  };

  const callQueue = async (id) => {
    enqueueSnackbar("memproses panggilan..", {
      autoHideDuration: 1500,
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues/${id}`,
        {},
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      enqueueSnackbar("sukses memproses panggilan", {
        autoHideDuration: 1500,
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message, {
        variant: "error",
      });
    }
  };

  const anamnesisQueue = async (id) => {
    enqueueSnackbar("sedang memproses..", {
      autoHideDuration: 1500,
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues/${id}/anamnesis`,
        {},
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      enqueueSnackbar("sukses memproses request", {
        autoHideDuration: 1500,
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message, {
        variant: "error",
      });
    }
  };

  const handleQueueCall = async () => {
    setPopupQueueOpen(false);
    if (selectedQueue.id !== null) {
      if (selectedQueue.status === "PERIKSA") {
        await callQueue(selectedQueue.id);
      } else if (selectedQueue.status === "ANTRIAN") {
        await anamnesisQueue(selectedQueue.id);
      } else {
        enqueueSnackbar("Antrian sudah dipanggil / dibatalkan", {
          variant: "error",
        });
      }
    }
  };

  const handleQueueCancel = async () => {
    setPopupQueueOpen(false);
    try {
      enqueueSnackbar("membatalkan antrian...", {
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues/${selectedQueue.id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      enqueueSnackbar("sukses membatalkan antrian", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const handleRedirectWhatsapp = () => {
    const nomor = selectedQueue?.nomor?.replace(/^0/, "62");
    window.open(`https://api.whatsapp.com/send/?phone=${nomor}`, "_blank");
  };

  const handleToMedicalRecord = async (id = null) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues/${
          id ? id : selectedQueue.id
        }/medical-record`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      history.push(`/rekam-medis/editor/${response.data.id}`);
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
      });
    }
  };

  const loadData = (url = null) => {
    axios
      .get(url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues`, {
        params: {
          schedule: current_active_schedule.id,
          search: search,
          "booking-date": moment(schedule_date).format("YYYY-MM-DD"),
          pagination: 30,
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        if (url) {
          setRows((prev) => [...prev, ...response.data.data]);
        } else {
          setRows((prev) => [...response.data.data]);
        }
        if (response.data.next_page_url !== null) {
          setHasMoreData(true);
          setNextUrl(response.data.next_page_url);
        } else {
          setHasMoreData(false);
          setNextUrl(null);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        Sentry.captureException(error);
      });
  };

  React.useEffect(() => {
    setTimeout(() => {
      loadData(null);
    }, 1000);
    const onFocus = () => {
      dispatch(updateQueue());
    };
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
      setPopupQueueOpen(false);
      setPopperQueueEl(null);
    };
  }, [current_active_schedule, queue_update, schedule_date, search]);

  if (loading) {
    return (
      <>
        <TabLoading
          title={
            "Info Antrian " +
            (moment(schedule_date).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
              ? "Hari Ini"
              : moment(schedule_date).locale("id").format("DD MMMM YYYY"))
          }
        />
      </>
    );
  }

  return (
    <>
      <Paper elevation={0}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={cancelQueueConfOpen}
          message={
            <>
              <Box sx={snackbar__content__container}>
                <Typography sx={snackbar__content__text}>
                  Konfirmasi Pembatalan Antrian
                </Typography>
                <Box>
                  <Button
                    onClick={() => {
                      handleQueueCancel();
                      setCancelQueueConfOpen();
                    }}
                    variant="contained"
                    size="small"
                    sx={{
                      marginRight: "10px",
                    }}
                  >
                    YA
                  </Button>
                  <Button
                    onClick={() => {
                      setCancelQueueConfOpen(false);
                    }}
                    variant="contained"
                    size="small"
                  >
                    Batal
                  </Button>
                </Box>
              </Box>
            </>
          }
          key={"topright"}
        />
        <Box sx={queue__table__content__wrapper}>
          <Typography sx={queue__table__title}>
            Info Antrian{" "}
            {moment(schedule_date).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
              ? "Hari Ini"
              : moment(schedule_date).locale("id").format("DD MMMM YYYY")}
          </Typography>
          <ClickAwayListener onClickAway={handlePopupRefreshAway}>
            <IconButton
              onClick={handlePopupRefreshOpen}
              color="inherit"
              aria-label="refresh"
              edge="start"
              type="button"
            >
              <img src="/assets/icons/vertical-options.svg" alt="refresh"></img>
            </IconButton>
          </ClickAwayListener>
        </Box>

        {rows.length < 1 && (
          <Paper
            sx={{
              p: 2,
            }}
          >
            <Typography>Tidak ada data antrian</Typography>
          </Paper>
        )}

        {rows.length > 0 && (
          <TableContainer
            id="queueTable"
            component={Paper}
            elevation={0}
            sx={queue__table__container}
          >
            <InfiniteScroll
              dataLength={rows.length}
              next={() => {
                loadData(nextUrl);
              }}
              hasMore={hasMoreData}
              scrollableTarget="queueTable"
            >
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      onClick={() => {
                        handleToMedicalRecord(row.id);
                      }}
                      key={row.id}
                      sx={{
                        cursor: "pointer",
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell width="3%">
                        <LazyLoadImage
                          effect="blur"
                          src={row.user?.photo_profile}
                          alt="profile"
                          style={queue__user__img}
                        ></LazyLoadImage>
                      </TableCell>
                      <TableCell width="30%" align="left">
                        <Typography sx={table__cell__text}>
                          {row.user.nama}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {moment(row.created_at)
                            .locale("id")
                            .format("DD MMMM YYYY") +
                            " | " +
                            moment(row.created_at).format("HH:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell width="20%">
                        <Typography sx={table__cell__text}>
                          antrian no : {row.nomor_antrian}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {row.detail_status ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell width="20%">
                        <Box
                          sx={{
                            ...queue__status__pills,
                            background:
                              row.status === "ANTRIAN"
                                ? "#FFF3C9"
                                : row.status === "TERPANGGIL"
                                ? "#C0ECFD"
                                : row.status === "PERIKSA"
                                ? "#D3FFE7"
                                : "#FFDEDE",
                            color:
                              row.status === "ANTRIAN"
                                ? "#F1BC00"
                                : row.status === "TERPANGGIL"
                                ? "#0492C7"
                                : row.status === "PERIKSA"
                                ? "#00A049"
                                : "#FF0000",
                          }}
                        >
                          <Typography sx={queue__status__pills__label}>
                            {row.status === "ANTRIAN"
                              ? "dalam antrian"
                              : row.status === "TERPANGGIL"
                              ? "terpanggil"
                              : row.status === "PERIKSA"
                              ? "anamnesis"
                              : "dibatalkan"}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        width="10%"
                        align="right"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ClickAwayListener
                          onClickAway={(e) => {
                            e.stopPropagation();
                            handlePopupQueueAway(e);
                          }}
                        >
                          <IconButton
                            data-group="popper-toggler"
                            color="inherit"
                            aria-label="act"
                            edge="start"
                            type="button"
                            data-queue-id={row.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePopupQueueOpen(e);
                            }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/icons/table-action.svg"
                              alt="act"
                            ></LazyLoadImage>
                          </IconButton>
                        </ClickAwayListener>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        )}
      </Paper>
      <TabQueueTablePopper
        open={popupQueueOpen}
        anchorEl={popperQueueEl}
        selectedQueue={selectedQueue}
        handleQueueCall={handleQueueCall}
        handleToMedicalRecord={handleToMedicalRecord}
        handleCancelQueueConfirmationOpen={handleCancelQueueConfirmationOpen}
        handleRedirectWhatsapp={handleRedirectWhatsapp}
        handlePopupQueueAway={handlePopupQueueAway}
      />
      <PopperRefresh
        open={popupRefreshOpen}
        anchorEl={refreshButtonEl}
        onClick={handleRefresh}
      />
    </>
  );
}
