import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  avatar__container,
  avatar__img,
  chat__container,
  chat__document__bubble,
  chat__document__container,
  chat__document__wrapper,
  doc__type__info__box,
  doc__type__info__container,
  doc__type__info__text,
  footer__container,
} from "./style";
import mediplusHelper from "../../../../../../utils/mediplusHelper";

export default function DocumentMessage({
  align = "LEFT",
  avatarImageUrl = "",
  media = {},
  time = "",
  isRead = false,
  showAvatarImage = false,
  sending = false,
  failed = false,
}) {
  align = align
    ? ["LEFT", "RIGHT"].includes(align.toUpperCase())
      ? align.toUpperCase()
      : "LEFT"
    : "LEFT";

  const handleDocumentClick = () => {
    window.open(media.s3_url, "_blank");
  };

  return (
    <>
      <Box sx={chat__container}>
        <Box sx={avatar__container}>
          {align === "LEFT" && showAvatarImage === true ? (
            <img
              src={avatarImageUrl ? avatarImageUrl : "/assets/icons/avatar.svg"}
              alt="avatar"
              style={avatar__img}
            ></img>
          ) : (
            ""
          )}
        </Box>
        <Box sx={chat__document__wrapper}>
          <Box
            sx={{
              ...chat__document__container,
              justifyContent: align === "LEFT" ? "start" : "end",
            }}
          >
            <Box
              onClick={handleDocumentClick}
              sx={{
                ...chat__document__bubble,
                background: align === "LEFT" ? "#EFF1F9" : "#FFF1F9",
                borderRadius:
                  align === "LEFT" ? "0px 8px 8px 8px" : "8px 0px 8px 8px",
              }}
            >
              <Box sx={doc__type__info__container}>
                <Box sx={doc__type__info__box}>
                  <Typography sx={doc__type__info__text}>
                    {mediplusHelper.getFileExtensionFromName(media.file_name)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mx: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    {media.file_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#7D7D7D",
                    }}
                  >
                    {mediplusHelper.fileSizeIEC(media.size)}
                  </Typography>
                </Box>
                <img
                  src="/assets/icons/document-download.svg"
                  alt="download"
                ></img>
              </Box>
              <Box
                sx={{
                  ...footer__container,
                  pl: align === "LEFT" ? 0.5 : 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 400,
                    textAlign: "right",
                  }}
                >
                  {sending ? (failed ? "gagal mengirim" : "mengirim...") : time}
                </Typography>
                {sending ? (
                  <></>
                ) : align === "RIGHT" ? (
                  <LazyLoadImage
                    style={{
                      width: "12px",
                      height: "12px",
                    }}
                    src={
                      isRead
                        ? "/assets/chat/double-check-blue.png"
                        : "/assets/chat/double-check.png"
                    }
                  ></LazyLoadImage>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={avatar__container}>
          {align === "RIGHT" && showAvatarImage === true ? (
            <img
              src={avatarImageUrl ? avatarImageUrl : "/assets/icons/avatar.svg"}
              alt="avatar"
              style={avatar__img}
            ></img>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
}
