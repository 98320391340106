import { webkit__scrollbar } from "../../../../style";

const media__list__wrapper = {
  minHeight: "calc(100vh - 200px)",
  maxHeight: "calc(100vh - 200px)",
  overflowY: "auto",
  ...webkit__scrollbar,
};

const box__media__container = {
  width: "200px",
  height: "150px",
  position: "relative",
  overflow: "hidden",
  borderRadius: "10px",
};

const box__img = {
  width: "100%",
  height: "100%",
  display: "block",
  objectFit: "cover",
};

const box__video = {
  width: "100%",
  height: "100%",
  display: "block",
  objectFit: "cover",
};

const box__video__icon = {
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const box__pdf = {
  position: "relative",
  width: "200px",
  height: "150px",
  background: "gray",
  overflow: "hidden",
  borderRadius: "10px",
};

const box__pdf__icon = {
  width: "60%",
  height: "60%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "50%",
};

const footer__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "20px",
};

const footer__btn = {
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  height: "38px",
};

const footer__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

const media__info__container = {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #D5D5D5",
  paddingBottom: "1px",
  maxWidth: "100%",
  py: "5px",
};

const media__info__label = {
  fontSize: "14px",
  fontWeight: 700,
  color: "#7D7D7D",
};

const media__info__value = {
  inlineSize: "150px",
  fontSize: "14px",
  fontWeight: 700,
  wordWrap: "break-word",
};

export {
  media__list__wrapper,
  box__media__container,
  box__img,
  box__video,
  box__video__icon,
  box__pdf,
  box__pdf__icon,
  footer__container,
  footer__btn,
  footer__btn__label,
  media__info__container,
  media__info__label,
  media__info__value,
};
