import React, { useEffect, useState } from "react";
import { useSwiper, Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../../providers/AuthProvider";
import { addInvoiceItem } from "../../../../../../redux/slices/invoice";
import { updateListProduct } from "../../../../../../redux/slices/product";
import authHeader from "../../../../../../services/authHeader";
import ProdukLoading from "./ProductLoading";
import {
  available__product__text,
  category__header__container,
  category__header__text,
  category__header__text__loadmore,
  category__navigator__btn,
  category__pills,
  category__pills__label,
  product__list__container,
  product__list__item__container,
  product__list__item__info__container,
  product__list__item__info__text,
  selected__category__container,
  selected__category__text,
} from "./style";
import ImageLabel from "./ImageLabel";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import ProdukModal from "./modal/ProdukModal";
import SortModal from "./modal/SortModal";
import ProductPopper from "./ProductPopper";
import { useTheme } from "@emotion/react";
import CategoryModal from "./CategoryModal";
import * as Sentry from "@sentry/react";

export default function Produk({
  searchProduct,
  sortModalOpen,
  setSortModalOpen,
  layananModalOpen,
  setLayananModalOpen,
  selectedProductId,
  setSelectedProductId,
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const products = useSelector((state) => state.product.products);

  const [productLoading, setProductLoading] = useState(true);

  //table
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [sortBy, setSortBy] = useState("nama");
  const [sortType, setSortType] = React.useState("ASC");
  const [totalProduct, setTotalProduct] = React.useState(0);

  //popper
  const [popperProductEl, setPopperProductEl] = React.useState(null);
  const [popupProductOpen, setPopupProductOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState({});

  const [category, setSelectedCategory] = React.useState("semua");
  const [categories, setCategories] = React.useState(["semua"]);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  //swiper
  const [categorySwiper, setCategorySwiper] = useState(null);

  const [categoryModalOpen, setCategoryModalOpen] = useState(false);

  const handleAddInvoiceItem = (id) => {
    let product = products.find((product) => product.id === id);
    if (product !== undefined) {
      dispatch(addInvoiceItem(product));
    } else {
      enqueueSnackbar("produk tidak ditemukan");
    }
  };

  const handlePopupProductOpen = (event) => {
    setPopperProductEl(event.currentTarget);
    let productId = parseInt(
      event.currentTarget.getAttribute("data-product-id")
    );
    let product = products.find((item) => {
      return item.id === productId;
    });
    if (product !== undefined) {
      setSelectedProduct(product);
      if (event.currentTarget === popperProductEl) {
        setPopupProductOpen(!popupProductOpen);
      } else {
        setPopupProductOpen(true);
      }
    } else {
      enqueueSnackbar("product not found", {
        type: "error",
      });
    }
  };

  const handlePopupProductAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked) {
      if (popupProductOpen) setPopupProductOpen(false);
    }
  };

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/products`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: searchProduct,
            pagination: 10,
            kategori: category,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch(updateListProduct(response.data.data));
        setLastPage(response.data.last_page);
        setProductLoading(false);
        setTotalProduct(response.data.total);
      })
      .catch(function (error) {
        console.log(error);
        setProductLoading(false);
        Sentry.captureException(error);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      });
  };

  const loadProductCategories = (url) => {
    axios
      .get(
        url
          ? url
          : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/products/categories`,
        {
          params: {
            head: user.head_doctor_id,
            clinic: current_active_schedule.clinic_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setCategories([...response.data]);
      })
      .catch(function (error) {});
  };

  const deleteData = async () => {
    setDeleteConfirmationOpen(false);
    try {
      enqueueSnackbar("menghapus produk...", {
        variant: "default",
      });
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/products/${selectedProduct.id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      enqueueSnackbar("sukses menghapus produk", {
        variant: "success",
      });
      loadData();
      loadProductCategories();
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message, {
        variant: "error",
      });
    } finally {
    }
  };

  const handleProductEdit = () => {
    setSelectedProductId(selectedProduct.id);
    setLayananModalOpen(true);
    setPopupProductOpen(false);
  };

  const handleDeleteConfirmationOpen = () => {
    setDeleteConfirmationOpen(true);
    setPopupProductOpen(false);
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    loadProductCategories();
  }, []);

  useEffect(() => {
    loadData();
    return () => {
      setPopupProductOpen(false);
      setPopperProductEl(null);
    };
  }, [page, searchProduct, sortBy, sortType, category]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "5px",
                  marginRight: "20px",
                }}
              >
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={deleteData}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />

      <Box sx={category__header__container}>
        <Typography sx={category__header__text}>Kategori</Typography>
        <Typography
          onClick={() => {
            setCategoryModalOpen(true);
          }}
          sx={category__header__text__loadmore}
        >
          Lihat Semua
        </Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item lg={0.7} md={1.3} sm={1.3}>
            <IconButton
              onClick={(e) => {
                categorySwiper?.slidePrev();
              }}
              aria-label="add"
              sx={category__navigator__btn}
            >
              <img
                width={35}
                height={20}
                src="/assets/icons/arrow2-left-disable.svg"
                alt="prev"
              ></img>
            </IconButton>
          </Grid>
          <Grid item lg={10.6} md={9.4} sm={9.4}>
            <Swiper
              spaceBetween={3}
              slidesPerView={"auto"}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
              onInit={(swiper) => {
                setCategorySwiper(swiper);
              }}
            >
              {categories &&
                categories.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      style={{
                        width: "fit-content",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setSelectedCategory(item);
                        }}
                        aria-label="medicine"
                        sx={{
                          ...category__pills,
                          backgroundColor:
                            category === item
                              ? "rgba(0, 150, 255, 0.24)"
                              : "white",
                          fontWeight: category === item ? 700 : 500,
                          color: category === item ? "black" : "#C5C5C5",
                          border:
                            category === item
                              ? "1px solid #0096FF"
                              : "1px solid #C5C5C5",
                        }}
                      >
                        <Typography sx={category__pills__label}>
                          {item}
                        </Typography>
                      </IconButton>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Grid>
          <Grid item lg={0.7} md={1.3} sm={1.3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={(e) => {
                  categorySwiper?.slideNext();
                }}
                aria-label="add"
                sx={category__navigator__btn}
              >
                <img
                  width={35}
                  height={20}
                  src="/assets/icons/arrow2-right-disable.svg"
                  alt="next"
                ></img>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={selected__category__container}>
        <Typography sx={selected__category__text}>Semua</Typography>
        <Typography sx={available__product__text}>
          {totalProduct} produk tersedia
        </Typography>
      </Box>
      <Box sx={product__list__container}>
        {productLoading ? (
          <ProdukLoading />
        ) : (
          <>
            {products.map((item) => {
              return (
                <Paper
                  key={`product_${item.id}`}
                  onClick={() => {
                    handleAddInvoiceItem(item.id);
                  }}
                  elevation={0}
                  sx={{
                    ...product__list__item__container,
                    flexBasis: isDesktop ? "20%" : "33%",
                  }}
                >
                  <ImageLabel
                    width={80}
                    height={80}
                    text={item.nama}
                    photo={item.photo}
                  />
                  <Box
                    id={`box_product_${item.id}`}
                    sx={product__list__item__info__container}
                  >
                    <Typography sx={product__list__item__info__text}>
                      {item.nama}
                    </Typography>
                    <Typography sx={product__list__item__info__text}>
                      {mediplusHelper.formatRupiah(item.harga)}
                    </Typography>
                  </Box>
                  <ClickAwayListener onClickAway={handlePopupProductAway}>
                    <IconButton
                      data-group="popper-toggler"
                      data-product-id={item.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePopupProductOpen(e);
                      }}
                      aria-label="opt"
                      edge="start"
                      type="button"
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <img
                        width={18}
                        height={18}
                        src="/assets/icons/vertical-options.svg"
                        alt="opt"
                      ></img>
                    </IconButton>
                  </ClickAwayListener>
                </Paper>
              );
            })}
          </>
        )}
      </Box>
      <Stack alignItems="end">
        <Pagination
          shape="rounded"
          sx={{
            p: 2,
            ".Mui-selected": {
              backgroundColor: "rgba(0, 174, 239, 1)!important",
              color: "white",
            },
          }}
          count={lastPage}
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </Stack>
      <ProductPopper
        open={popupProductOpen}
        anchorEl={popperProductEl}
        handleProductEdit={handleProductEdit}
        handleDeleteConfirmationOpen={handleDeleteConfirmationOpen}
        handleClickAway={handlePopupProductAway}
      />
      <CategoryModal
        open={categoryModalOpen}
        onClose={() => {
          setCategoryModalOpen(false);
        }}
        categories={categories}
        selectedCategory={category}
        onCategoryChange={(category) => {
          setSelectedCategory(category);
        }}
      />
      <SortModal
        open={sortModalOpen}
        onClose={() => {
          setSortModalOpen(false);
        }}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortType={sortType}
        setSortType={setSortType}
      />
      <ProdukModal
        id={selectedProductId}
        open={layananModalOpen}
        onClose={() => {
          setLayananModalOpen(false);
        }}
        onSave={() => {
          loadData();
          loadProductCategories();
        }}
      />
    </>
  );
}
