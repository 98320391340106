import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  form__control,
  input__date,
  input__label,
  input__text,
} from "../../../rekam-medis/editor/style";
import { isMacOs, isSafari, browserVersion } from "react-device-detect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../datepicker.css";
import moment from "moment";
import { useSnackbar } from "notistack";

export default function FilterModal(props) {
  const open = Boolean(props.open);
  const onClose = props.onClose;
  const filter = props.filter;
  const setFilter = props.setFilter;
  const loadData = props.loadData;

  const [legacyBrowser, setLegacyBrowser] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleSubmit = () => {
    if (typeof loadData === "function") {
      loadData();
    }
    handleModalClose();
  };

  const handleFilterChange = (e) => {
    if (e.target.name === "pagination") {
      if (e.target.value > 100) {
        enqueueSnackbar("max 100 item per page");
        return;
      }
    }
    setFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  };

  useEffect(() => {
    if (isMacOs && isSafari) {
      let vrs = parseInt(browserVersion);
      if (!isNaN(vrs) && vrs < 14) {
        setLegacyBrowser(true);
      }
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Filter Data Berkas Penyimpanan
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel sx={input__label} shrink htmlFor="start_date">
                  Start Date
                </InputLabel>
                {legacyBrowser ? (
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={
                      filter.start_date
                        ? moment(filter.start_date).toDate()
                        : ""
                    }
                    onChange={(date) => {
                      handleFilterChange({
                        target: {
                          name: "start_date",
                          value: date,
                          id: "start_date",
                          type: "date",
                        },
                      });
                    }}
                  />
                ) : (
                  <InputBase
                    sx={input__date}
                    onChange={handleFilterChange}
                    value={filter.start_date ?? ""}
                    placeholder=""
                    id="start_date"
                    name="start_date"
                    type="date"
                  />
                )}
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel sx={input__label} shrink htmlFor="receiver">
                  End Date
                </InputLabel>
                {legacyBrowser ? (
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={
                      filter.end_date ? moment(filter.end_date).toDate() : ""
                    }
                    onChange={(date) => {
                      handleFilterChange({
                        target: {
                          name: "end_date",
                          value: date,
                          id: "end_date",
                          type: "date",
                        },
                      });
                    }}
                  />
                ) : (
                  <InputBase
                    sx={input__date}
                    onChange={handleFilterChange}
                    value={filter.end_date ?? ""}
                    placeholder=""
                    id="end_date"
                    name="end_date"
                    type="date"
                  />
                )}
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="pagination">
              Jumlah Data Tampil per Page
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleFilterChange}
              value={filter.pagination}
              placeholder=""
              id="pagination"
              name="pagination"
              type="number"
            />
            <FormHelperText error={true}></FormHelperText>
          </FormControl>
          <Button
            onClick={handleSubmit}
            fullWidth
            sx={{
              backgroundColor: "blue",
              borderRadius: "8px",
              textTransform: "none",
              marginBottom: "15px",
              height: "38px",
            }}
            variant="contained"
          >
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: 600,
                marginRight: "5px",
              }}
            >
              Terapkan
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
