import React from "react";

export default function LeftMigration() {
  return (
    <img
      src="/assets/odontogram/arrow-left-1.svg"
      alt="arrow-left"
      width="50"
      height="15"
    ></img>
  );
}
