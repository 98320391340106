import { createSlice } from "@reduxjs/toolkit";

export const schedule = createSlice({
  name: "schedule",
  initialState: {
    dateSelected: null, //ini untuk state kalender
    scheduleDate: null, //tanggal jadwal aktif saat ini
    current_active_schedule: {},
    schedule_list: [],
  },
  reducers: {
    updateScheduleData: (state, data) => {
      return { ...state, ...data.payload };
    },
    updateCurrentActiveSchedule: (state, data) => {
      return { ...state, current_active_schedule: data.payload };
    },
  },
});

export const { updateScheduleData, updateCurrentActiveSchedule } =
  schedule.actions;

export default schedule.reducer;
