import React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  clinic__usage__box,
  clinic__usage__box__header__clinic__name,
  clinic__usage__box__header__clinic__usage,
  clinic__usage__box__header__container,
  clinic__usage__box__header__wrapper,
  clinic__usage__box__subscription__info__container,
  doctor__usage__box,
  doctor__usage__box__header__container,
  doctor__usage__box__header__label,
  doctor__usage__box__header__value,
  doctor__usage__box__header__wrapper,
  subcription__header,
  subscription__card,
  subscription__name,
} from "./style";
import { useSelector } from "react-redux";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import _ from "lodash";

const BlueLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D5D5D5",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00AEEF",
  },
}));

const PinkLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: "100%",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D5D5D5",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FF0091",
  },
}));

export function SubscriptionUsage({ usage }) {
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  return (
    <Paper elevation={0} sx={subscription__card}>
      <Typography sx={subcription__header}>Penyimpanan Anda</Typography>
      <Box sx={clinic__usage__box}>
        <Box sx={clinic__usage__box__header__wrapper}>
          <Box sx={clinic__usage__box__header__container}>
            <img src="/assets/icons/storage.svg" alt="penyimpanan"></img>
            <Typography sx={clinic__usage__box__header__clinic__name}>
              {current_active_schedule?.clinic?.nama ?? "-"}
            </Typography>
          </Box>
          <Typography sx={clinic__usage__box__header__clinic__usage}>
            {!_.isEmpty(usage)
              ? mediplusHelper.fileSizeIECRound(usage.limit)
              : "- B"}
          </Typography>
        </Box>
        <Box sx={clinic__usage__box__subscription__info__container}>
          <img src="/assets/icons/star.svg" alt="star"></img>
          <Typography sx={subscription__name}>
            {!_.isEmpty(usage) ? usage.subscription_name : "--"}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "12px",
            color: "#7D7D7D",
            fontWeight: 700,
          }}
        >
          {!_.isEmpty(usage)
            ? mediplusHelper.fileSizeIECRound(usage.clinic_usage)
            : "- B"}{" "}
          Terpakai
        </Typography>
        <BlueLinearProgress
          sx={{
            marginBottom: "5px",
          }}
          variant="determinate"
          value={
            !_.isEmpty(usage)
              ? (usage.clinic_usage / usage.limit) * 100 > 100
                ? 100
                : (usage.clinic_usage / usage.limit) * 100
              : 0
          }
        />
      </Box>

      <Box sx={doctor__usage__box}>
        <Box sx={doctor__usage__box__header__wrapper}>
          <Box sx={doctor__usage__box__header__container}>
            <img src="/assets/icons/folder-pink.svg" alt="dokter"></img>
            <Typography sx={doctor__usage__box__header__label}>
              Pemakaian Dokter
            </Typography>
          </Box>
          <Typography sx={doctor__usage__box__header__value}>
            {!_.isEmpty(usage)
              ? mediplusHelper.fileSizeIECRound(usage.doctor_usage)
              : "- B"}
          </Typography>
        </Box>
        <PinkLinearProgress
          sx={{
            marginBottom: "5px",
          }}
          variant="determinate"
          value={
            !_.isEmpty(usage)
              ? (usage.doctor_usage / usage.limit) * 100 > 100
                ? 100
                : (usage.doctor_usage / usage.limit) * 100
              : 0
          }
        />
      </Box>
    </Paper>
  );
}
