const box__media__container = {
  width: "100px",
  height: "100px",
  position: "relative",
  overflow: "hidden",
  borderRadius: "10px",
};

const box__img = {
  width: "100%",
  height: "100%",
  display: "block",
  objectFit: "cover",
};

const box__video = {
  width: "100%",
  height: "100%",
  display: "block",
  objectFit: "cover",
};

const box__video__icon = {
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const box__pdf = {
  position: "relative",
  width: "200px",
  height: "150px",
  background: "gray",
  overflow: "hidden",
  borderRadius: "10px",
};

const box__pdf__icon = {
  width: "60%",
  height: "60%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "50%",
};

export {
  box__media__container,
  box__img,
  box__video,
  box__video__icon,
  box__pdf,
  box__pdf__icon,
};
