import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import {
  Button,
  ClickAwayListener,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import TheadCell from "../../../../components/table/thead-cell/TheadCell";
import {
  snackbar__content__container,
  snackbar__content__text,
  table__container,
  table__status__pills,
  table__status__pills__label,
  tab__cell__text,
  user__info__container,
  user__info__img,
  user__info__text,
} from "./style";
import QRModal from "../../../../components/modal/qr-modal/QRModal";
import SendDataModal from "../../../../components/modal/send-data-modal/SendDataModal";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import RekamMedisTablePopper from "./RekamMedisTablePopper";
import authHeader from "../../../../../../services/authHeader";
import { useAuth } from "../../../../../../providers/AuthProvider";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";
import LinkToScanFolderModal from "../../link-to-scanfolder-modal/LinkToScanFolderModal";

export default function RekamMedisTable({ type, searchField, searchValue }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const tableRef = React.useRef(null);

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const new_medical_record_update = useSelector(
    (state) => state.new_medical_record_update
  );

  //table
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState("created_at");
  const [sortType, setSortType] = React.useState("DESC");
  const [paginate, setPaginate] = React.useState(10);

  //popper
  const [popperRecordEl, setPopperRecordEl] = React.useState(null);
  const [popupRecordOpen, setPopupRecordOpen] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState({});

  //snackbar
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  //modal
  const [qrModalOpen, setQRModalOpen] = React.useState(false);
  const [sendModalOpen, setSendModalOpen] = React.useState(false);
  const [isLinkScanFolderModalOpen, setIsLinkScanFolderModalOpen] =
    React.useState(false);

  const handleLinkScanFolderModalOpen = () => {
    setPopupRecordOpen(false);
    setIsLinkScanFolderModalOpen(true);
  };

  const handleLinkScanFolderModalClose = () => {
    setIsLinkScanFolderModalOpen(false);
  };

  const fetchData = (url = null) => {
    setLoading(true);
    axios
      .get(
        url
          ? url
          : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records`,
        {
          params: {
            type: type,
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            "search-field": searchField,
            "search-value": searchValue,
            pagination: paginate,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
        setLoading(false);
        tableRef?.current?.scrollTo({
          top: 0,
          left: 0,
        });
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        Sentry.captureException(error);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      });
  };

  const deleteData = async () => {
    setDeleteConfirmationOpen(false);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records/${selectedRecord.id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      fetchData();
      enqueueSnackbar("data terhapus", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
      });
    } finally {
    }
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const handlePopupRecordOpen = (event) => {
    setPopperRecordEl(event.currentTarget);
    let recordId = parseInt(event.currentTarget.getAttribute("data-record-id"));
    let record = rows.find((item) => {
      return item.id === recordId;
    });
    if (record !== undefined) {
      setSelectedRecord(record);
      if (event.currentTarget === popperRecordEl) {
        setPopupRecordOpen(!popupRecordOpen);
      } else {
        setPopupRecordOpen(true);
      }
    } else {
      enqueueSnackbar("medical record not found", {
        type: "error",
      });
    }
  };

  const handlePopupRecordAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupRecordOpen) setPopupRecordOpen(false);
    }
  };

  const handleRedirectEdit = (id = null) => {
    let seletedRecordId = selectedRecord.id;
    history.push(`/rekam-medis/editor/${id !== null ? id : seletedRecordId}`);
  };

  const handleRedirectEditNewTab = () => {
    let seletedRecordId = selectedRecord.id;
    window.open(`/rekam-medis/editor/${seletedRecordId}`, "_blank");
  };

  const handleRedirectCreate = () => {
    history.push(`/rekam-medis/editor?ref=${selectedRecord.user.id}`);
  };

  const handleCreateInvoice = async () => {
    enqueueSnackbar("memproses invoice...", {
      variant: "default",
    });
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records/${selectedRecord.id}/invoice`,
        {},
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      history.push(`/pembayaran/editor/${response.data.invoice_id}`);
      enqueueSnackbar("sukses membuat invoice", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message ?? error.message, {
        variant: "error",
      });
    }
  };

  const handleOpenInvoice = () => {
    setPopupRecordOpen(false);
    if (selectedRecord?.invoice?.id) {
      history.push(`/pembayaran/editor/${selectedRecord.invoice.id}`);
    } else {
      handleCreateInvoice();
    }
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
    return () => {
      setPopupRecordOpen(false);
      setPopperRecordEl(null);
    };
  }, [current_active_schedule, page, sortBy, sortType, paginate]);

  React.useEffect(() => {
    let update = false;
    if (type === "TODAY") {
      update = true;
      setLoading(true);
      fetchData();
    }
    return () => {
      if (update) {
        setPopupRecordOpen(false);
        setPopperRecordEl(null);
      }
    };
  }, [new_medical_record_update, type]);

  React.useEffect(() => {
    setPage(1);
    setLoading(true);
    fetchData();
    return () => {
      setPopupRecordOpen(false);
      setPopperRecordEl(null);
    };
  }, [searchField, searchValue]);

  return (
    <>
      <LinkToScanFolderModal
        medicalRecordId={selectedRecord?.id}
        open={isLinkScanFolderModalOpen}
        onClose={handleLinkScanFolderModalClose}
        onChoose={handleLinkScanFolderModalClose}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={deleteData}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />

      <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          ref={tableRef}
          sx={{
            ...table__container,
            maxHeight: isDesktop ? 450 : "calc(100vh - 300px)",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TheadCell
                  name="Nama"
                  column_name="nama"
                  onClick={handleSortChange}
                  useSort={true}
                  currentSortColumnName={sortBy}
                  currentSortType={sortType}
                  imageColumn={true}
                />
                <TheadCell
                  name="Tanggal"
                  column_name="created_at"
                  onClick={handleSortChange}
                  useSort={true}
                  currentSortColumnName={sortBy}
                  currentSortType={sortType}
                />
                <TheadCell
                  name="No Antri"
                  column_name="nomor_antrian"
                  onClick={handleSortChange}
                  useSort={true}
                  currentSortColumnName={sortBy}
                  currentSortType={sortType}
                />
                <TheadCell
                  name="Subject"
                  column_name="subjektif"
                  onClick={handleSortChange}
                  useSort={true}
                  currentSortColumnName={sortBy}
                  currentSortType={sortType}
                />
                <TheadCell name="Blood Pressure" useSort={false} />
                <TheadCell name="Physical" useSort={false} />
                <TheadCell name="Vital Sign" useSort={false} />
                <TheadCell name="Status" useSort={false} />
                <TheadCell name="Action" useSort={false} />
              </TableRow>
            </TableHead>
            {rows.length > 0 && (
              <TableBody>
                {rows.map((row, idx) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={idx}
                    onClick={() => {
                      handleRedirectEdit(row.id);
                    }}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <TableCell align={"left"}>
                      <Paper sx={user__info__container}>
                        <img
                          data-user-id={1}
                          src={
                            row.user?.photo_profile
                              ? row.user?.photo_profile
                              : "/assets/icons/avatar.svg"
                          }
                          alt="doctor"
                          style={user__info__img}
                        ></img>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            data-user-id={1}
                            component={"span"}
                            sx={user__info__text}
                          >
                            {row.user?.nama
                              ? row.user?.nama
                              : row.record_temporary_patient_data?.nama
                              ? row.record_temporary_patient_data?.nama
                              : "-"}
                          </Typography>
                          <Typography
                            data-user-id={1}
                            component={"span"}
                            sx={{
                              ...user__info__text,
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                          >
                            {mediplusHelper.getAge(
                              row.user?.tanggal_lahir
                                ? row.user?.tanggal_lahir
                                : row.record_temporary_patient_data
                                    ?.tanggal_lahir
                                ? row.record_temporary_patient_data
                                    ?.tanggal_lahir
                                : null,
                              row.tanggal_periksa
                                ? row.tanggal_periksa
                                : row.created_at ?? null
                            )}
                          </Typography>
                        </Box>
                      </Paper>
                    </TableCell>
                    <TableCell align={"left"} sx={tab__cell__text}>
                      {moment(row.created_at).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                    <TableCell align={"left"} sx={tab__cell__text}>
                      {row.nomor_antrian}
                    </TableCell>
                    <TableCell
                      align={"left"}
                      sx={{ ...tab__cell__text, maxWidth: "300px" }}
                    >
                      {row.record_soap?.subjektif ?? "Tidak ada subjek"}
                    </TableCell>
                    <TableCell align={"left"} sx={tab__cell__text}>
                      SYS {row.record_blood_pressure?.sistol ?? 0}, Dia{" "}
                      {row.record_blood_pressure?.diastol ?? 0}, HR{" "}
                      {row.record_blood_pressure?.bpm ?? 0}
                    </TableCell>
                    <TableCell align={"left"} sx={tab__cell__text}>
                      {row.record_physical?.tinggi_badan ?? 0} cm,{" "}
                      {row.record_physical?.berat_badan ?? 0} kg
                    </TableCell>
                    <TableCell align={"left"} sx={tab__cell__text}>
                      RR {row.record_vital_sign?.rr ?? 0}, SPO2{" "}
                      {row.record_vital_sign?.spo2 ?? 0}, Temp{" "}
                      {row.record_vital_sign?.temp ?? 0}
                    </TableCell>
                    <TableCell align={"left"} sx={tab__cell__text}>
                      <Box
                        sx={{
                          ...table__status__pills,
                          background: row.user
                            ? "rgba(211, 255, 231, 1)"
                            : "rgba(255, 222, 222, 1)",
                          color: row.user
                            ? "rgba(0, 160, 73, 1)"
                            : "rgba(255, 0, 0, 1)",
                        }}
                      >
                        <Typography sx={table__status__pills__label}>
                          {row.user ? "Terkirim" : "Belum proses"}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell
                      align={"left"}
                      sx={tab__cell__text}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ClickAwayListener
                        onClickAway={(e) => {
                          e.stopPropagation();
                          handlePopupRecordAway(e);
                        }}
                      >
                        <IconButton
                          data-group="popper-toggler"
                          data-record-id={row.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePopupRecordOpen(e);
                          }}
                          color="inherit"
                          aria-label="refresh"
                          edge="start"
                          type="button"
                        >
                          <LazyLoadImage
                            effect="blur"
                            src="/assets/icons/table-action.svg"
                            alt="refresh"
                          ></LazyLoadImage>
                        </IconButton>
                      </ClickAwayListener>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            {rows.length < 1 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Tidak ada data
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {loading && (
            <img src="/roll.svg" alt="roll" width={30} height={30}></img>
          )}

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Tampilkan
          </Typography>
          <Box
            sx={{
              width: "75px",
            }}
          >
            <FormControl fullWidth>
              <Select
                sx={{
                  height: "30px",
                  fontSize: "14px",
                }}
                labelId="entry"
                value={paginate}
                inputProps={{ "aria-label": "Without label" }}
                onChange={(e) => {
                  setPaginate(e.target.value);
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Stack>
            <Pagination
              shape="rounded"
              sx={{
                p: 2,
                ".Mui-selected": {
                  backgroundColor: "rgba(0, 174, 239, 1)!important",
                  color: "white",
                },
              }}
              count={lastPage}
              showFirstButton
              showLastButton
              onChange={handlePageChange}
            />
          </Stack>
        </Box>
      </Paper>
      <RekamMedisTablePopper
        open={popupRecordOpen}
        anchorEl={popperRecordEl}
        handleRedirectEdit={handleRedirectEdit}
        selectedRecord={selectedRecord}
        handleRedirectCreate={handleRedirectCreate}
        handleDeleteConfirmationOpen={() => {
          setDeleteConfirmationOpen(true);
          setPopupRecordOpen(false);
        }}
        handleQrModalOpen={() => {
          setQRModalOpen(true);
          setPopupRecordOpen(false);
        }}
        handleSendDataModalOpen={() => {
          setSendModalOpen(true);
          setPopupRecordOpen(false);
        }}
        handlePopupRecordAway={handlePopupRecordAway}
        handleRedirectEditNewTab={handleRedirectEditNewTab}
        handleOpenInvoice={handleOpenInvoice}
        handleLinkScanFolderModalOpen={handleLinkScanFolderModalOpen}
      />
      <QRModal
        type={"MEDICAL_RECORD"}
        open={qrModalOpen}
        data={selectedRecord}
        onClose={() => {
          setQRModalOpen(false);
        }}
      />
      <SendDataModal
        type={"MEDICAL_RECORD"}
        open={sendModalOpen}
        data={selectedRecord}
        onClose={() => {
          setSendModalOpen(false);
        }}
      />
    </>
  );
}
