import { Modal, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import * as Sentry from "@sentry/react";

function QRModal({ data, open, onClose, type }) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [qrData, setQrData] = useState("");

  const loadQr = () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/`;
    if (type === "SCAN_FOLDER") {
      url = url + `scan-folders/${data.id}/send`;
    } else {
      url = url + `medical-records/${data.id}/send`;
    }
    axios
      .post(
        url,
        {
          via: "QRCODE",
        },
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setQrData(atob(response.data.image));
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 260,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  };

  useEffect(() => {
    setQrData("");
    if (open === true && data?.id) {
      loadQr();
    }
  }, [open, data]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box id="modal-modal-description">
          {loading ? (
            <Skeleton
              sx={{ mb: 0.5 }}
              variant="rectangular"
              width={"100%"}
              height={250}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: qrData }}></div>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

QRModal.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default QRModal;
