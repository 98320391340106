import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

function TabInvoiceTablePopper({
  open,
  anchorEl,
  selectedInvoice,
  handlePopupInvoiceAway,
}) {
  const history = useHistory();
  const handleRedirectEdit = (id) => {
    history.push(`/pembayaran/editor/${id}`);
  };
  return (
    <ClickAwayListener onClickAway={handlePopupInvoiceAway}>
      <Popper
        data-group="popper-element"
        open={open}
        anchorEl={anchorEl}
        placement={"left"}
        sx={{
          zIndex: 1500,
        }}
      >
        <Box>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleRedirectEdit(selectedInvoice.id);
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/pencil.svg"
                      alt="edit-invoice"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Edit"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/send.svg"
                      alt="send-invoice"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Bagikan"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img
                      src="/assets/icons/printer.svg"
                      alt="print-invoice"
                    ></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      paddingTop: "2px",
                    }}
                    primary="Print"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}

TabInvoiceTablePopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  selectedInvoice: PropTypes.object,
  handlePopupInvoiceAway: PropTypes.func.isRequired,
};

export default TabInvoiceTablePopper;
