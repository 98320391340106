const firebaseConfig = {
  apiKey: "AIzaSyCumDkNnrLtBHEWNjmhY3hLKxHJOVzD-b4",
  authDomain: "chatprototype-519b8.firebaseapp.com",
  projectId: "chatprototype-519b8",
  storageBucket: "chatprototype-519b8.appspot.com",
  messagingSenderId: "382173449046",
  appId: "1:382173449046:web:4d2cdd4d44a20c7361a4f7",
  measurementId: "G-G20KQR1E4D",
};

export default firebaseConfig;
