import { webkit__scrollbar } from "../../../style";

const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

const table__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "12px",
};

const table__title = {
  fontSize: "14px",
  fontWeight: 600,
};

const search__input__container = {
  width: "40%",
  display: "flex",
  flexDirection: "row",
  paddingLeft: "10px",
};

const search__input__btn = {
  backgroundColor: "blue",
  borderRadius: "0px 8px 8px 0px",
  height: "38px",
};

const table__contaner = {
  ...webkit__scrollbar,
  maxHeight: 540,
};

const user__profile__container = {
  display: "flex",
  minWidth: "150px",
  maxWidth: "350px",
  minHeight: "45px",
  boxShadow: "none",
  background: "transparent",
  width: "auto",
  cursor: "pointer",
  marginRight: "5px",
};

const user__profile__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "5px",
  marginRight: "15px",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
};

const user__profile__text = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "19px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const table__cell__text = {
  fontSize: "14px",
  fontWight: 500,
};

const action__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  height: "38px",
};

const action__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

const search__input = {
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    width: "100%",
    padding: "10px 12px",
    height: "16px",
    fontSize: 12,
    borderRadius: "8px 0px 0px 8px",
    "&:focus": {
      borderColor: "#1976d2",
    },
  },
};

export {
  modal,
  table__wrapper,
  table__title,
  search__input__container,
  search__input__btn,
  table__contaner,
  user__profile__container,
  user__profile__img,
  user__profile__text,
  table__cell__text,
  action__btn,
  action__btn__label,
  search__input,
};
