const sidebar__container = {
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  border: "none",
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgb(255 0 145 / 0.1)",
    borderRadius: "10px",
    border: "1px solid rgb(255 0 145 / 0.2)",
  },
};

const sidebar__logo = {
  width: "120px",
  height: "21px",
  marginLeft: "auto",
  marginRight: "auto",
};

export { sidebar__container, sidebar__logo };
