import React, { useEffect, useState } from "react";
import { Paper, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import _ from "lodash";
import {
  header__container,
  header__wrapper,
  text__date,
  text__invoice__total,
  text__subjektif,
  timeline__h1,
  timeline__list__container,
  user__img,
} from "./style";
import RiwayatRekamMedisModal from "../../rekam-medis/editor/riwayat-rekam-medis-modal/RiwayatRekamMedisModal";
import authHeader from "../../../../../services/authHeader";
import { useAuth } from "../../../../../providers/AuthProvider";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";

export default function Timeline() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { user } = useAuth();
  const chat_conversation = useSelector((state) => state.chat_conversation);
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const [timelineData, setTimelineData] = useState({});
  const [selectedTimelineUserId, setSelectedTimelineUserId] = useState(null);
  const [selectedMedicalRecordId, setSelectedMedicalRecordId] = useState(null);
  const [timelineModalOpen, setTimelineModalOpen] = useState(false);

  const loadTimeline = () => {
    let userId = null;
    if (
      chat_conversation?.chat_participants &&
      chat_conversation?.chat_participants.length > 0
    ) {
      let participant = chat_conversation.chat_participants.find(
        (i) => i.chat_participantable_type === "App\\Models\\User"
      );
      if (participant !== undefined) {
        userId = participant.chat_participantable_id;
      }
    }
    if (userId !== null) {
      setSelectedTimelineUserId(userId);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/timeline/${userId}`,
          {
            params: {
              clinic: current_active_schedule.clinic_id,
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        )
        .then((response) => {
          setTimelineData({
            ...response.data,
          });
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          Sentry.captureException(error);
        });
    }
  };

  const handleTimelineModalClose = () => {
    setTimelineModalOpen(false);
  };

  const handleRightSectionTimelineClick = (e) => {
    let medicalRecordId = e.target.getAttribute("data-record-id");
    setSelectedMedicalRecordId(parseInt(medicalRecordId));
    setTimelineModalOpen(true);
  };

  useEffect(() => {
    loadTimeline();
    return () => {
      setTimelineModalOpen(false);
      setSelectedMedicalRecordId(null);
    };
  }, [current_active_schedule, chat_conversation]);

  return (
    <>
      <Box
        sx={{
          display: _.isEmpty(chat_conversation) ? "none" : "block",
        }}
      >
        <Paper elevation={0} sx={header__wrapper}>
          <Box sx={header__container}>
            <img
              src={
                timelineData?.user?.photo_profile
                  ? timelineData?.user?.photo_profile
                  : "/assets/icons/avatar.svg"
              }
              alt="user"
              style={user__img}
            ></img>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              {timelineData?.user?.nama ? timelineData?.user?.nama : "--"}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "11px",
              }}
            >
              {timelineData?.user?.tanggal_lahir
                ? moment().diff(timelineData.user.tanggal_lahir, "years") +
                  " Tahun"
                : "- Tahun"}
            </Typography>
          </Box>
        </Paper>
        <Typography sx={timeline__h1}>Timeline</Typography>
        <Box
          sx={{
            ...timeline__list__container,
            maxHeight: isDesktop
              ? "calc(100vh - 360px)"
              : "calc(100vh - 490px)",
          }}
        >
          {timelineData &&
            timelineData.timeline &&
            timelineData.timeline.length > 0 &&
            timelineData.timeline.map((item, index, arr) => {
              return (
                <Paper
                  data-record-id={item.medical_record_id}
                  onClick={handleRightSectionTimelineClick}
                  key={`timeline_${index}`}
                  elevation={0}
                  sx={{
                    position: "relative",
                    borderLeft:
                      arr.length - 1 === index
                        ? "2px dashed rgba(255, 0, 145, 0)"
                        : "2px dashed rgba(255, 0, 145, 1)",
                    borderRadius: 0,
                    cursor: "pointer",
                  }}
                >
                  <Box
                    data-record-id={item.medical_record_id}
                    sx={{
                      paddingBottom: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <Box
                      data-record-id={item.medical_record_id}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: "8px",
                        position: "relative",
                        border: "none",
                      }}
                    >
                      <Box
                        data-record-id={item.medical_record_id}
                        sx={{
                          paddingLeft: "10px",
                        }}
                      >
                        <Typography
                          data-record-id={item.medical_record_id}
                          sx={text__date}
                        >
                          {moment(item.created_at).format(
                            "DD MMMM YYYY | HH:MM"
                          )}
                        </Typography>
                        <Typography
                          data-record-id={item.medical_record_id}
                          sx={text__subjektif}
                        >
                          {item.subjektif
                            ? item.subjektif
                            : "Tidak ada subjektif"}
                        </Typography>
                      </Box>
                      <Typography
                        data-record-id={item.medical_record_id}
                        sx={text__invoice__total}
                      >
                        {item.invoice
                          ? mediplusHelper.formatRupiah(item.invoice)
                          : "Rp. 0"}
                      </Typography>
                    </Box>
                  </Box>

                  <img
                    src="/assets/icons/timeline-dot.svg"
                    alt="dot"
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "-9px",
                    }}
                  ></img>
                </Paper>
              );
            })}
        </Box>
      </Box>
      <RiwayatRekamMedisModal
        open={timelineModalOpen}
        onClose={handleTimelineModalClose}
        userId={selectedTimelineUserId}
        selectedMedicalRecordId={selectedMedicalRecordId}
        setSelectedMedicalRecordId={setSelectedMedicalRecordId}
      />
    </>
  );
}
