import { createSlice } from "@reduxjs/toolkit";

/**
 * Fungsi untuk trigger update data di chat message
 */

const initialState = {};

export const chatConversation = createSlice({
  name: "chat_conversation",
  initialState: {},
  reducers: {
    setSelectedConversation: (state, data) => {
      return {
        ...data.payload,
      };
    },
    resetSelectedConversation: () => {
      return { ...initialState };
    },
  },
});

export const { setSelectedConversation, resetSelectedConversation } =
  chatConversation.actions;

export default chatConversation.reducer;
