import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import {
  background__img,
  card__container,
  card__header,
  footer__container,
  hardware__illustration__img,
  hardware__name,
  usage__label,
  usage__value,
} from "./style";

export default function HardwareUsageCard({ hardwareName, hardwareUsage }) {
  return (
    <Paper sx={card__container}>
      <Box sx={card__header}>
        <img
          src={"/assets/icons/robot.svg"}
          alt="doctor"
          style={hardware__illustration__img}
        ></img>
        <Typography component={"span"} sx={hardware__name}>
          {hardwareName}
        </Typography>
      </Box>
      <Box sx={footer__container}>
        <Typography sx={usage__label}>Terpakai</Typography>
        <Typography sx={usage__value}>
          {mediplusHelper.fileSizeIECRound(hardwareUsage)}
        </Typography>
      </Box>
      <img
        src={"/assets/icons/penyimpanan-widget.svg"}
        alt="doctor"
        style={background__img}
      ></img>
    </Paper>
  );
}
