import { webkit__scrollbar } from "../../../../style";

const resep__container = {
  background: "rgba(239, 241, 249, 1)",
  borderRadius: "8px",
  border: "1px solid #ced4da",
  padding: "5px",
};

const resep__list__container = {
  display: "flex",
  flexDirection: "column",
  gap: 0.5,
  minHeight: "120px",
  maxHeight: "120px",
  overflowY: "auto",
  paddingRight: "5px",
  paddingTop: "5px",
  ...webkit__scrollbar,
};

const resep__list__item = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  borderBottom: "1px solid black",
  alignContent: "center",
  alignItems: "center",
};

const resep__list__item__btn__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
};

const resep__list__item__btn = {
  backgroundColor: "blue",
  height: "26px",
  color: "white",
  borderRadius: "8px",
  marginBottom: "5px",
  background: "none",
  px: 1,
  "&:hover": {
    background: "none",
  },
};

const medicine__box__container = {
  background: "rgba(239, 241, 249, 1)",
  borderRadius: "8px",
  border: "1px solid #ced4da",
  padding: "3px",
};

const medicine__box__header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
};

const medicine__box__header__text = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "0px",
};

const medicine__box__header__btn = {
  backgroundColor: "blue",
  height: "26px",
  color: "white",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const medicine__list__container = {
  display: "flex",
  alignItems: "stretch",
  justifyContent: "flex-start",
  gap: 0.7,
  width: "100%",
  minHeight: "140px",
  maxHeight: "140px",
  overflowY: "auto",
  flexDirection: "row",
  flexWrap: "wrap",
  paddingTop: "10px",
  boxSizing: "content-box",
  ...webkit__scrollbar,
};

const medicne__list__item = {
  height: "26px",
  borderRadius: "30px",
  color: "black",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#FF0091",
    color: "white",
  },
  marginRight: "10px",
  marginBottom: "10px",
};

const medicne__list__item__label = {
  fontSize: "12px",
  fontWeight: 700,
  paddingRight: "3px",
};

const modal__container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

const modal__header = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "12px",
  marginBottom: "10px",
};

const form__control = {
  width: "100%",
  marginBottom: "10px",
  pr: "10px",
  position: "relative",
};

const input__label = {
  color: "rgba(4, 22, 21, 1)",
  fontSize: "16px",
  fontWeight: 600,
  paddingLeft: "2px",
};

const input = {
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    width: "100%",
    padding: "10px 12px",
    height: "16px",
    fontSize: 12,
    borderRadius: "8px",
    minWidth: "10px",
    "&:focus": {
      borderColor: "#1976d2",
    },
    ...webkit__scrollbar,
  },
};

const modal__title = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "12px",
  marginBottom: "10px",
};

const modal__title__text = {
  fontSize: "14px",
  fontWeight: 600,
};

const submit__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  height: "38px",
};

const submit__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

const table__cell = {
  fontSize: "14px",
  fontWeight: 600,
  paddingTop: "0px",
  paddingBottom: "0px",
};

const right__input__label = {
  fontSize: "12px",
  position: "absolute",
  right: "10px",
  top: 0,
  cursor: "pointer",
  color: "blue",
};

export {
  resep__container,
  resep__list__container,
  resep__list__item,
  resep__list__item__btn__container,
  resep__list__item__btn,
  medicine__box__container,
  medicine__box__header__container,
  medicine__box__header__text,
  medicine__box__header__btn,
  medicine__list__container,
  medicne__list__item,
  medicne__list__item__label,
  modal__container,
  modal__header,
  modal__title__text,
  submit__btn,
  submit__btn__label,
  modal__title,
  form__control,
  input__label,
  input,
  table__cell,
  right__input__label,
};
