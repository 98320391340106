const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  alignContent: "center",
  alignItems: "center",
  marginBottom: "30px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "150%",
  display: "inline",
};

const header__h1__blue = {
  ...header__h1,
  color: "rgba(0, 174, 239, 1)",
};

const submenu__header = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingLeft: "7px",
  marginBottom: "10px",
};

const add__submenu__btn = {
  background: "#00AEEF",
  borderRadius: "8px",
  width: "25px",
  height: "25px",
  ":hover": {
    background: "#00AEFF",
  },
};

const delete__submenu__btn = {
  background: "#00AEEF",
  borderRadius: "8px",
  width: "25px",
  height: "25px",
  marginTop: "30px",
  ":hover": {
    background: "#00AEFF",
  },
};

const footer__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingLeft: "0px",
  marginTop: "50px",
};

const submit__btn = {
  backgroundColor: "#FF0091",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  color: "white",
  "&:hover": {
    backgroundColor: "#FF0080",
  },
};

const submit__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
};

export {
  header__container,
  header__h1,
  header__h1__blue,
  submenu__header,
  add__submenu__btn,
  delete__submenu__btn,
  footer__container,
  submit__btn,
  submit__btn__label,
};
