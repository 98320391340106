import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import "moment/locale/id";
import React from "react";
import mediplusHelper from "../../../../../../../../utils/mediplusHelper";
import {
  dot__img,
  invoice__price,
  text__date,
  text__subjective,
  timeline__container,
  timeline__list__container,
  timeline__text__wrapper,
} from "./style";
import PropTypes from "prop-types";

function TimelineList({
  timelines,
  onClick,
  maxHeight,
  currentSelectedTimelineId,
  showInvoicePrice,
}) {
  return (
    <Box
      sx={{
        ...timeline__list__container,
        maxHeight: maxHeight ? maxHeight : "270px",
      }}
    >
      {timelines &&
        timelines.length > 0 &&
        timelines.map((item, i, arr) => {
          return (
            <Paper
              onClick={(e) => {
                if (typeof onClick === "function") {
                  onClick(item?.medical_record_id, e);
                }
              }}
              key={i}
              elevation={0}
              sx={{
                ...timeline__container,
                borderLeft:
                  arr.length - 1 === i
                    ? "2px dashed rgba(255, 0, 145, 0)"
                    : "2px dashed rgba(255, 0, 145, 1)",
              }}
            >
              <Box
                sx={{
                  paddingBottom: "20px",
                  marginLeft: "10px",
                }}
              >
                <Box
                  sx={{
                    ...timeline__text__wrapper,
                    border:
                      item?.medical_record_id === currentSelectedTimelineId
                        ? "1px solid rgba(0, 174, 239, 1)"
                        : "none",
                  }}
                >
                  <Box
                    data-record-id={item?.medical_record_id}
                    sx={{
                      paddingLeft: "10px",
                    }}
                  >
                    <Typography
                      data-record-id={item?.medical_record_id}
                      sx={text__date}
                    >
                      {moment(item.created_at).format("DD MMMM YYYY | HH:MM")}
                    </Typography>
                    <Typography
                      data-record-id={item?.medical_record_id}
                      sx={text__subjective}
                    >
                      {item.subjektif
                        ? mediplusHelper.cropText(item.subjektif, 70)
                        : "Tidak ada subjektif"}
                    </Typography>
                  </Box>

                  {showInvoicePrice && (
                    <Typography
                      data-record-id={item?.medical_record_id}
                      sx={invoice__price}
                    >
                      {mediplusHelper.formatRupiah(item.invoice)}
                    </Typography>
                  )}
                </Box>
              </Box>

              <img
                src="/assets/icons/timeline-dot.svg"
                alt="dot"
                style={dot__img}
              ></img>
            </Paper>
          );
        })}
    </Box>
  );
}

TimelineList.propTypes = {
  timelines: PropTypes.array,
  onClick: PropTypes.func,
  maxHeight: PropTypes.string,
  currentSelectedTimelineId: PropTypes.number,
  showInvoicePrice: PropTypes.bool,
};

export default TimelineList;
