const table__head = {
  borderBottom: "2px solid rgba(212, 212, 212, 1)",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "14px",
  fontWeight: 600,
  color: "#7D7D7D",
  cursor: "pointer",
  position: "relative",
};

const sort__img = {
  position: "absolute",
  top: "50%",
  right: "5%",
  transform: "translateY(-50%)",
};

export { table__head, sort__img };
