const form__container = {
  margin: "20% 15% 0 15%",
  paddingRight: "10px",
  boxShadow: "none",
};

const form__logo = {
  width: "244px",
  height: "43px",
  marginBottom: "40px",
};

const form__footer_text = {
  display: "block",
  marginBottom: "40px",
};

const form__text_link = {
  color: "blue",
  display: "inline",
  cursor: "pointer",
};

const illustration__container = {
  margin: 0,
  minHeight: "100vh",
  boxShadow: "none",
  backgroundImage: "url(/assets/bg-auth.svg)",
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
};

const illustration__img = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  width: "90%",
};

const auth__form_control = {
  width: "100%",
  marginBottom: "10px",
  position: "relative",
};

const auth__form_label = {
  color: "black",
};

const auth__input__text = {
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "&.MuiInputBase-root": {
    paddingLeft: "0px",
    width: "100%",
  },
  "& .MuiInputBase-input": {
    borderRadius: "5px",
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    fontSize: "16px",
    width: "100%",
    padding: "10px 12px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&:focus": {
      borderColor: "#1976d2",
    },
  },
};

const auth__input__number = {
  ...auth__input__text,
  MozAppearance: "textfield",
};
export {
  form__container,
  form__logo,
  form__footer_text,
  form__text_link,
  illustration__container,
  illustration__img,
  auth__input__text,
  auth__form_control,
  auth__form_label,
  auth__input__number,
};
