import { Box } from "@mui/system";
import React from "react";

export default function RootBlack() {
  return (
    <img
      src="/assets/odontogram/perawatan-saluran-akar.png"
      alt="perawatan-saluran-akar"
      width="50"
      height="15"
    ></img>
  );
}
