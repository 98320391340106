import { webkit__scrollbar } from "../../../../style";

const snackbar__content__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const snackbar__content__text = {
  paddingTop: "5px",
  marginRight: "20px",
};

const table__container = {
  maxHeight: 450,
  ...webkit__scrollbar,
};

const user__info__container = {
  display: "flex",
  minWidth: "150px",
  minHeight: "45px",
  boxShadow: "none",
  background: "transparent",
  width: "auto",
  cursor: "pointer",
  marginRight: "5px",
};

const user__info__img = {
  width: "40px",
  height: "40px",
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "10px",
  marginRight: "15px",
  borderRadius: "50%",
};

const user__info__text = {
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "19px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const tab__cell__text = {
  fontSize: "14px",
  fontWeight: 600,
  py: 0,
};

const table__status__pills = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
  padding: "5px 16px",
  gap: "10px",
  position: "relative",
  height: "30px",
  borderRadius: "32px",
};

const table__status__pills__label = {
  display: "inline-block",
  width: "100%",
  fontSize: "12px",
  fontWeight: 400,
};

export {
  snackbar__content__container,
  snackbar__content__text,
  table__container,
  user__info__container,
  user__info__img,
  user__info__text,
  tab__cell__text,
  table__status__pills,
  table__status__pills__label,
};
