import { Paper, Popper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useLiveQuery } from "dexie-react-hooks";
import moment from "moment";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { db } from "../../../../../../db/db";
import PropTypes from "prop-types";

function NotificationPopper({ open, anchorEl }) {
  const notifications = useLiveQuery(() =>
    db.notifications.reverse().toArray()
  );

  const handleNotificationDelete = (id) => {
    db.notifications.where("id").equals(id).delete();
  };

  const handleNotificationDeleteAll = async () => {
    await db.notifications.clear();
  };

  return (
    <Popper
      data-group="popper-element"
      open={anchorEl && open ? open : false}
      anchorEl={anchorEl}
      placement={"bottom-end"}
      sx={{
        zIndex: 1500,
      }}
    >
      <Paper
        elevation={1}
        sx={{
          borderRadius: "10px",
          minWidth: "200px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
            alignContent: "baseline",
            px: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            Notifikasi
          </Typography>
          <Typography
            onClick={handleNotificationDeleteAll}
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              cursor: "pointer",
            }}
          >
            Hapus semua
          </Typography>
        </Box>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "400px",
            overflowY: "auto",
            p: 1,
            "&::-webkit-scrollbar": {
              height: "8px",
              width: "6px",
              backgroundColor: "none",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(255 0 145 / 0.1)",
              borderRadius: "10px",
              border: "1px solid rgb(255 0 145 / 0.2)",
            },
          }}
        >
          {notifications &&
            notifications.map((notification, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    p: 1,
                    border: "1px solid #D5D5D5",
                    borderRadius: "8px",

                    position: "relative",
                    marginBottom: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                  >
                    {notification.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                    }}
                  >
                    {notification.message}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "11px",
                    }}
                  >
                    {moment(notification.created_at).format(
                      "DD MMMM YYYY HH:mm"
                    )}
                  </Typography>
                  <LazyLoadImage
                    onClick={() => {
                      handleNotificationDelete(notification.id);
                    }}
                    src="/assets/icons/close.svg"
                    style={{
                      position: "absolute",
                      top: "7px",
                      right: "5px",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  ></LazyLoadImage>
                </Box>
              );
            })}
        </Paper>
      </Paper>
    </Popper>
  );
}

NotificationPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
};

export default NotificationPopper;
