import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useFirebase } from "../../providers/FirebaseProvider";
import FirebaseError from "../components/error/FirebaseError";
import AppLoading from "../components/loading/AppLoading";
import AuthRoute from "./route/AuthRoute";
import {
  form__container,
  form__logo,
  form__text_link,
  illustration__container,
  illustration__img,
} from "./style";

export default function Auth() {
  const { loading } = useAuth();
  const { fcmLoading, fcmError, wsLoading, wsError } = useFirebase();

  if (loading || fcmLoading || wsLoading) {
    return <AppLoading />;
  }

  if (fcmError && wsError) {
    return <FirebaseError />;
  }

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={5}>
            <Paper sx={form__container}>
              <img
                src="/assets/logo-mediplus.svg"
                alt="logo-mediplus"
                style={form__logo}
              ></img>

              <AuthRoute />

              <Typography sx={{}} component={"span"}>
                Dengan masuk atau mendaftar anda menyetujui{" "}
                <Typography
                  component={"span"}
                  sx={form__text_link}
                  onClick={() => {
                    window.open(
                      "https://mediplusindonesia.co.id/sk_privasi",
                      "_blank"
                    );
                  }}
                >
                  ketentuan layanan
                </Typography>{" "}
                dan{" "}
                <Typography
                  component={"span"}
                  sx={form__text_link}
                  onClick={() => {
                    window.open(
                      "https://mediplusindonesia.co.id/sk_privasi",
                      "_blank"
                    );
                  }}
                >
                  kebijakan privasi
                </Typography>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={7}>
            <Paper sx={illustration__container}>
              <img
                src="/assets/auth-illustration.png"
                alt="auth-illustration"
                style={illustration__img}
              ></img>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
