import {
  Button,
  FormControl,
  InputBase,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { Route } from "react-router-dom";
import axios from "axios";
import authHeader from "../../../../services/authHeader";
import {
  forgot__btn__submit,
  forgot__btn__success,
  forgot__text__h1,
  forgot__text__h1__success,
  forgot__text__h2,
  forgot__text__h2__success,
} from "./style";
import { auth__input__text } from "../../style";
import * as Sentry from "@sentry/react";

export default function ForgotPassword() {
  const [form, setForm] = useState({
    email: "",
  });

  const [isSubmitting, setIsSubmiting] = useState(false);

  const [success, setSuccess] = useState(false);

  const [validationError, setValidationError] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));

    setValidationError((prevError) => ({
      ...prevError,
      [e.target.name]: "",
    }));
  };

  const validate = () => {
    const newError = {};

    if (!form.email) {
      newError.email = "email wajib diisi";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findValidationError = validate();
    if (Object.values(findValidationError).some((err) => err !== "")) {
      setValidationError(findValidationError);
    } else {
      try {
        setIsSubmiting(true);
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/forgot`,
          {
            email: form.email,
          },
          {
            headers: authHeader(),
          }
        );
        setSuccess(true);
        setIsSubmiting(false);
      } catch (e) {
        console.log(e);
        const newError = {};
        newError.email = e.message;
        setValidationError(newError);
        setIsSubmiting(false);
        Sentry.captureException(e);
      }
    }
  };

  return success ? (
    <>
      <Typography variant="h4" component="h1" sx={forgot__text__h1__success}>
        Kami telah mengirimkan link reset ke {form.email}
      </Typography>
      <Typography sx={forgot__text__h2__success}>
        Segera periksa dan klik link tersebut
      </Typography>
      <Route
        path="/login"
        exact
        children={({ history }) => {
          return (
            <Button
              variant="contained"
              sx={forgot__btn__success}
              onClick={() => {
                history.push("/login");
              }}
            >
              Kembali ke Login
            </Button>
          );
        }}
      />
    </>
  ) : (
    <>
      <Typography variant="h4" component="h1" sx={forgot__text__h1}>
        Lupa Password
      </Typography>
      <Typography sx={forgot__text__h2}>
        Masukkan alamat username/email/nomor whatsapp anda dan kami akan
        mengirimkan petunjuk untuk mengatur ulang kata sandi Anda.
      </Typography>
      <FormControl
        variant="standard"
        sx={{
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <InputLabel
          sx={{
            color: "black",
          }}
          shrink
          htmlFor="email"
        >
          Email / Nomor Hp
        </InputLabel>
        <InputBase
          placeholder="email@mediplus.co.id"
          id="email"
          name="email"
          type="text"
          value={form.email}
          onChange={handleChange}
          disable={isSubmitting.toString()}
          error={validationError.email ? true : false}
          sx={auth__input__text}
        />
        <FormHelperText error={true}>{validationError.email}</FormHelperText>
      </FormControl>

      <Button
        variant="contained"
        sx={forgot__btn__submit}
        onClick={handleSubmit}
      >
        Reset Sekarang
      </Button>
    </>
  );
}
