import {
  Box,
  ClickAwayListener,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { table__cell } from "./style";
import TheadCell from "../../../../components/table/thead-cell/TheadCell";

export default function CustomObatRow({ row, handleDataChoose }) {
  const [open, setOpen] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("nama");
  const [sortType, setSortType] = React.useState("ASC");
  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };
  const [submenuRow, setSubmenuRow] = React.useState(row.submenu);

  React.useEffect(() => {
    let submenuRowSort = _.orderBy(
      submenuRow,
      [sortBy],
      [sortType.toLocaleLowerCase()]
    );
    setSubmenuRow(submenuRowSort);
  }, [sortBy, sortType]);

  return (
    <React.Fragment key={`row_${row.id}`}>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={`row_${row.id}`}
      >
        <TableCell width="7%">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={!row?.submenu || row.submenu.length < 1 ? true : false}
          >
            {!row?.submenu || row.submenu.length < 1 ? (
              <img
                src="/assets/icons/arrow-square-down-disabled.svg"
                alt="up"
              ></img>
            ) : open ? (
              <img src="/assets/icons/arrow-square-up.svg" alt="up"></img>
            ) : (
              <img src="/assets/icons/arrow-square-down.svg" alt="up"></img>
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={table__cell} component="th" scope="row">
          {row.nama}
        </TableCell>
        <TableCell sx={table__cell} align="left">
          {row.satuan}
        </TableCell>
        <TableCell sx={table__cell} align="center">
          {!row?.submenu ||
            (row.submenu.length < 1 && (
              <IconButton
                color="inherit"
                aria-label="refresh"
                edge="start"
                type="button"
                onClick={() => {
                  handleDataChoose(row);
                }}
              >
                <Typography
                  sx={{
                    color: "blue",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Pilih
                </Typography>
              </IconButton>
            ))}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width: "50%", marginLeft: "80px" }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TheadCell
                      name="Nama"
                      column_name="nama"
                      useSort={true}
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    ></TheadCell>
                    <TheadCell name="Aksi" useSort={false}></TheadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submenuRow &&
                    submenuRow.map((submenuRow) => (
                      <TableRow key={submenuRow.id}>
                        <TableCell component="th" scope="row">
                          {submenuRow.nama}
                        </TableCell>
                        <TableCell sx={table__cell} align="left">
                          <IconButton
                            color="inherit"
                            aria-label="refresh"
                            edge="start"
                            type="button"
                            onClick={() => {
                              handleDataChoose({
                                ...submenuRow,
                                parent: row,
                              });
                            }}
                          >
                            <Typography
                              sx={{
                                color: "blue",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              Pilih
                            </Typography>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
