import { useTheme } from "@emotion/react";
import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { webkit__scrollbar } from "../../../../../style";
import odontogramHelper from "./helper";
import {
  table__cell,
  table__row,
  odontogram__input__text,
  disabled__tooth__index,
} from "./style";

export default function BottomDetail({
  odontogramLabel,
  note,
  handleNoteChange,
  teethMode,
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
          gap: 1,
          px: isDesktop ? 5 : 1,
          marginBottom: "30px",
        }}
      >
        <TableContainer sx={webkit__scrollbar}>
          <Table aria-label="sticky table">
            <TableBody>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(48, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    48
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["48"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 48,
                    }}
                    value={note[48] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(38, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    38
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["38"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 38,
                    }}
                    value={note[38] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(47, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    47
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["47"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 47,
                    }}
                    value={note[47] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(37, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    37
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["37"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 37,
                    }}
                    value={note[37] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(46, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    46
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["46"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 46,
                    }}
                    value={note[46] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(36, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    36
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["36"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 36,
                    }}
                    value={note[36] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(45, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    45
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(85, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    85
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["4585"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 45,
                    }}
                    value={note[45] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(75, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    75
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(35, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    35
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["7535"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 35,
                    }}
                    value={note[35] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(44, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    44
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(84, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    84
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["4484"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 44,
                    }}
                    value={note[44] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(74, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    74
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(34, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    34
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["7434"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 34,
                    }}
                    value={note[34] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(43, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    43
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(83, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    83
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["4383"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 43,
                    }}
                    value={note[43] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(73, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    73
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(33, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    33
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["7333"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 33,
                    }}
                    value={note[33] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(42, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    42
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(82, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    82
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["4282"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 42,
                    }}
                    value={note[42] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(72, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    72
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(32, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    32
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["7232"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 32,
                    }}
                    value={note[32] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(41, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    41
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(81, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    81
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["4181"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 41,
                    }}
                    value={note[41] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(71, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    71
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(31, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    31
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["7131"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 31,
                    }}
                    value={note[31] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
