import React from "react";
import DrawerHeader from "./components/drawer-header/DrawerHeader";
import Drawer from "./components/drawer/Drawer";
import SidebarDivider from "./components/sidebar-divider/SidebarDivider";
import SidebarListItem from "./components/sidebar-list-item/SidebarListItem";
import { sidebar__container, sidebar__logo } from "./style";
import PropTypes from "prop-types";

function Sidebar({ open }) {
  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: { ...sidebar__container },
      }}
    >
      <DrawerHeader>
        <img
          src="/assets/logo-mediplus.svg"
          alt="logo-mediplus"
          style={sidebar__logo}
        ></img>
      </DrawerHeader>
      <SidebarListItem
        isSidebarOpen={open}
        name={"Dashboard"}
        path={"/"}
        isExactPath={true}
        icon={"/assets/icons/dashboard.svg"}
        first={true}
      />
      <SidebarDivider
        isSidebarOpen={open}
        text={"Pengelolaan"}
      ></SidebarDivider>
      <SidebarListItem
        isSidebarOpen={open}
        name={"Rekam Medis"}
        path={"/rekam-medis"}
        icon={"/assets/icons/rekam-medis.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"Hasil Pemeriksaan"}
        path={"/hasil-pemeriksaan"}
        icon={"/assets/icons/hasil-pemeriksaan.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"Pembayaran"}
        path={"/pembayaran"}
        icon={"/assets/icons/pembayaran.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"Jadwal Praktek"}
        path={"/jadwal-praktek"}
        icon={"/assets/icons/jadwal-praktek.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"Obat"}
        path={"/obat"}
        icon={"/assets/icons/obat.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"Penyimpanan"}
        path={"/penyimpanan"}
        icon={"/assets/icons/penyimpanan.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"Cuti Dokter"}
        path={"/cuti"}
        icon={"/assets/icons/cuti.svg"}
      />
      <SidebarDivider isSidebarOpen={open} text={"Aplikasi"}></SidebarDivider>
      <SidebarListItem
        isSidebarOpen={open}
        name={"Chat"}
        path={"/chat"}
        icon={"/assets/icons/chat.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"Desain Pembayaran"}
        path={"/desain-pembayaran"}
        icon={"/assets/icons/desain-pembayaran.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"ICD 10"}
        path={"/icd10"}
        icon={"/assets/icons/icd10.svg"}
      />
      <SidebarListItem
        isSidebarOpen={open}
        name={"Help / Guide"}
        path={"/help"}
        icon={"/assets/icons/help.svg"}
      />
    </Drawer>
  );
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Sidebar;
