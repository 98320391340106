import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  avatar__container,
  avatar__img,
  chat__container,
  chat__img__bubble,
  chat__img__container,
  chat__img__wrapper,
  footer__container,
  footer__time,
  img,
  img__container,
} from "./style";

export default function ImageMessage({
  align = "LEFT",
  avatarImageUrl = "",
  media = {},
  time = "",
  showAvatarImage = false,
  isRead = false,
  sending = false,
  failed = false,
  onClick = () => {},
}) {
  align = align
    ? ["LEFT", "RIGHT"].includes(align.toUpperCase())
      ? align.toUpperCase()
      : "LEFT"
    : "LEFT";

  return (
    <>
      <Box sx={chat__container}>
        <Box sx={avatar__container}>
          {align === "LEFT" && showAvatarImage === true ? (
            <LazyLoadImage
              src={avatarImageUrl ? avatarImageUrl : "/assets/icons/avatar.svg"}
              alt="avatar"
              style={avatar__img}
            ></LazyLoadImage>
          ) : (
            ""
          )}
        </Box>
        <Box sx={chat__img__wrapper}>
          <Box
            sx={{
              ...chat__img__container,
              justifyContent: align === "LEFT" ? "start" : "end",
            }}
          >
            <Box
              sx={{
                ...chat__img__bubble,
                background: align === "LEFT" ? "#EFF1F9" : "#FFF1F9",
                borderRadius:
                  align === "LEFT" ? "0px 8px 8px 8px" : "8px 0px 8px 8px",
              }}
            >
              <Box sx={img__container}>
                {sending ? (
                  <LazyLoadImage
                    src={"/assets/rolling.svg"}
                    style={img}
                    alt="attachment"
                  ></LazyLoadImage>
                ) : (
                  <LazyLoadImage
                    onClick={onClick}
                    src={media?.s3_url ?? ""}
                    style={img}
                    alt="attachment"
                  ></LazyLoadImage>
                )}
              </Box>

              <Box
                sx={{
                  ...footer__container,
                  pl: align === "LEFT" ? 0.5 : 0,
                }}
              >
                <Typography sx={footer__time}>
                  {sending ? (failed ? "gagal mengirim" : "mengirim...") : time}
                </Typography>
                {sending ? (
                  <></>
                ) : align === "RIGHT" ? (
                  <LazyLoadImage
                    style={{
                      width: "12px",
                      height: "12px",
                    }}
                    src={
                      isRead
                        ? "/assets/chat/double-check-blue.png"
                        : "/assets/chat/double-check.png"
                    }
                  ></LazyLoadImage>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={avatar__container}>
          {align === "RIGHT" && showAvatarImage === true ? (
            <LazyLoadImage
              src={avatarImageUrl ? avatarImageUrl : "/assets/icons/avatar.svg"}
              alt="avatar"
              style={avatar__img}
            ></LazyLoadImage>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
}
