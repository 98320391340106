import { IconButton, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { modal, table__title, table__wrapper } from "./style";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import MediaList from "../editor/media-list/MediaList";
import { webkit__scrollbar } from "../../../style";

function PreviewModal({ open, onClose, scanFolder }) {
  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <Box id="modal-modal-description">
            <Box sx={table__wrapper}>
              <Typography sx={table__title}>Preview</Typography>
              <IconButton
                onClick={handleModalClose}
                color="inherit"
                aria-label="refresh"
                edge="start"
                type="button"
              >
                <img
                  width={25}
                  height={25}
                  effect="blur"
                  src="/assets/icons/close.svg"
                  alt="refresh"
                ></img>
              </IconButton>
            </Box>
            <Paper
              elevation={0}
              sx={{
                width: "100%",
                maxHeight: "85vh",
                overflowY: "auto",
                ...webkit__scrollbar,
              }}
            >
              <MediaList medias={scanFolder?.media ?? []} />
            </Paper>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

PreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scanFolder: PropTypes.object.isRequired,
};

export default PreviewModal;
