import { webkit__scrollbar } from "../../../style";

const modal__container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

const header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "12px",
  marginBottom: "10px",
};

const antrian___list__container = {
  maxHeight: "200px",
  minHeight: "100px",
  overflowY: "auto",
  ...webkit__scrollbar,
};

const submit__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  height: "38px",
};

const submit__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

export {
  modal__container,
  header__container,
  antrian___list__container,
  submit__btn,
  submit__btn__label,
};
