import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ObatTablePopper({
  open,
  anchorEl,
  handleRedirectEdit,
  handleStokModalOpen,
  handleDeleteConfirmationOpen,
}) {
  return (
    <Popper
      data-group="popper-element"
      open={open}
      anchorEl={anchorEl}
      placement={"left"}
      sx={{
        zIndex: 1500,
      }}
    >
      <Box>
        <Paper
          elevation={1}
          sx={{
            borderRadius: "10px",
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleRedirectEdit}
                sx={{
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "30px",
                  }}
                >
                  <LazyLoadImage
                    effect="blur"
                    src="/assets/icons/pencil.svg"
                    alt="edit-invoice"
                  ></LazyLoadImage>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    paddingTop: "2px",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                  primary="Edit"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleStokModalOpen}
                sx={{
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "30px",
                  }}
                >
                  <LazyLoadImage
                    effect="blur"
                    src="/assets/icons/pencil-rounded.svg"
                    alt="send-invoice"
                  ></LazyLoadImage>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    paddingTop: "2px",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                  primary="Edit Stok"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleDeleteConfirmationOpen}
                sx={{
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "30px",
                  }}
                >
                  <LazyLoadImage
                    effect="blur"
                    src="/assets/icons/trash.svg"
                    alt="delete"
                  ></LazyLoadImage>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    paddingTop: "2px",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                  primary="Hapus"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Popper>
  );
}

export default ObatTablePopper;
