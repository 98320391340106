import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import { Button, InputBase, Pagination, Snackbar } from "@mui/material";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import {
  create__obat__btn,
  create__obat__btn__label,
  header__container,
  header__h1,
} from "./style";
import TheadCell from "../../../components/table/thead-cell/TheadCell";
import ObatRow from "./custom-row/ObatRow";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import ObatTablePopper from "./popper/ObatTablePopper";
import StokModal from "./modal/StokModal";
import { search__input } from "../../hasil-pemeriksaan/main/style";
import * as Sentry from "@sentry/react";

export default function Obat() {
  const history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  //table
  const [rows, setRows] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("nama");
  const [sortType, setSortType] = React.useState("ASC");
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [lastPage, setLastPage] = React.useState(1);

  //popper
  const [selectedMedicine, setSelectedMedicine] = React.useState(null);
  const [popperMedicineEl, setPopperMedicineEl] = React.useState(null);
  const [popupMedicineOpen, setPopupMedicineOpen] = React.useState(false);
  const [stokModalOpen, setStokModalOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medicines`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: 7,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  const deleteData = async () => {
    setDeleteConfirmationOpen(false);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medicines/${selectedMedicine.id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      loadData();
      enqueueSnackbar("data terhapus", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
      });
    } finally {
    }
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePopupMedicineOpen = (event) => {
    setPopperMedicineEl(event.currentTarget);
    let medicineId = parseInt(
      event.currentTarget.getAttribute("data-medicine-id")
    );
    let medicine = rows.find((item) => {
      return item.id === medicineId;
    });
    if (medicine !== undefined) {
      setSelectedMedicine(medicine);
      if (event.currentTarget === popperMedicineEl) {
        setPopupMedicineOpen(!popupMedicineOpen);
      } else {
        setPopupMedicineOpen(true);
      }
    } else {
      enqueueSnackbar("medicine not found", {
        type: "error",
      });
    }
  };

  const handlePopupMedicineAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupMedicineOpen) setPopupMedicineOpen(false);
    }
  };

  const handleRedirectEdit = () => {
    history.push(`/obat/editor/${selectedMedicine.id}`);
    return;
  };

  const handleStokModalOpen = () => {
    setStokModalOpen(true);
    setPopupMedicineOpen(false);
  };

  const handleDeleteConfirmationOpen = () => {
    setDeleteConfirmationOpen(true);
    setPopupMedicineOpen(false);
  };

  React.useEffect(() => {
    loadData();
    return () => {
      setPopupMedicineOpen(false);
      setPopperMedicineEl(false);
    };
  }, [current_active_schedule, page, sortBy, sortType, search]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "5px",
                  marginRight: "20px",
                }}
              >
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={deleteData}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />

      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Obat
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InputBase
            sx={search__input}
            size="18"
            placeholder="Cari"
            value={search}
            onChange={handleSearchChange}
          ></InputBase>
          <IconButton
            aria-label="search"
            sx={{
              backgroundColor: "blue",
              borderRadius: "0px 8px 8px 0px",
              height: "38px",
            }}
          >
            <img src="/assets/icons/search.svg" alt="search"></img>
          </IconButton>
          <IconButton
            onClick={() => {
              history.push("/obat/editor");
            }}
            aria-label="search-user"
            sx={create__obat__btn}
          >
            <img src="/assets/icons/plus.svg" alt="create"></img>
            <Typography sx={create__obat__btn__label}>Data Obat</Typography>
          </IconButton>
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          minWidth: "calc(100vw - 300px)",
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TheadCell name="" useSort={false}></TheadCell>
              <TheadCell
                name="Nama"
                useSort={true}
                column_name="nama"
                currentSortColumnName={sortBy}
                currentSortType={sortType}
                onClick={handleSortChange}
              />
              <TheadCell
                name="Satuan"
                useSort={true}
                column_name="satuan"
                currentSortColumnName={sortBy}
                currentSortType={sortType}
                onClick={handleSortChange}
              />
              <TheadCell
                name="Jumlah Stok"
                useSort={true}
                column_name="stok"
                currentSortColumnName={sortBy}
                currentSortType={sortType}
                onClick={handleSortChange}
              />
              <TheadCell
                name="Harga"
                useSort={true}
                column_name="harga"
                currentSortColumnName={sortBy}
                currentSortType={sortType}
                onClick={handleSortChange}
              />
              <TheadCell name="Action" useSort={false} align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <ObatRow
                key={row.id}
                row={row}
                handlePopupMedicineOpen={handlePopupMedicineOpen}
                handlePopupMedicineAway={handlePopupMedicineAway}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack alignItems="end">
        <Pagination
          shape="rounded"
          sx={{
            p: 2,
            ".Mui-selected": {
              backgroundColor: "rgba(0, 174, 239, 1)!important",
              color: "white",
            },
          }}
          count={lastPage}
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </Stack>

      <ObatTablePopper
        open={popupMedicineOpen}
        anchorEl={popperMedicineEl}
        handleRedirectEdit={handleRedirectEdit}
        handleStokModalOpen={handleStokModalOpen}
        handleDeleteConfirmationOpen={handleDeleteConfirmationOpen}
      />

      <StokModal
        open={stokModalOpen}
        medicine={selectedMedicine}
        setMedicine={setSelectedMedicine}
        onSave={() => {
          loadData();
        }}
        onClose={() => {
          setStokModalOpen(false);
        }}
      />
    </>
  );
}
