import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  antrian___list__container,
  header__container,
  modal__container,
  submit__btn,
  submit__btn__label,
} from "./style";
import {
  form__control,
  input__label,
  input__select,
  input__text,
} from "../../rekam-medis/editor/style";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import * as Sentry from "@sentry/react";

export default function BroadcastModal({ open = false, onClose = () => {} }) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const [clinicRooms, setClinicRooms] = useState([]);
  const [patientQueues, setPatientQueues] = useState([]);

  const [form, setForm] = useState({
    title: "",
    content: "",
    user_id: [],
    clinic_room_id: "",
  });

  const [validationError, setValidationError] = useState({
    title: "",
    content: "",
  });

  const handleChange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));

    setValidationError((prevError) => ({
      ...prevError,
      [e.target.name]: "",
    }));
  };

  const handleQueueClick = (id) => {
    let isAdded = form.user_id.includes(id);
    if (isAdded) {
      setForm((prev) => ({
        ...prev,
        user_id: prev.user_id.filter((i) => i !== id),
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        user_id: [id, ...prev.user_id],
      }));
    }
  };

  const loadDataClinicRoom = async () => {
    try {
      const responseClinicRooms = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/me/clinic-rooms`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      setClinicRooms(responseClinicRooms.data);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
        autoHideDuration: "2000",
      });
      Sentry.captureException(e);
    } finally {
    }
  };

  const loadDataPasienAntrian = async () => {
    setPatientQueues([]);
    try {
      const clinicRoom = clinicRooms.find(
        (i) => i.id === parseInt(form.clinic_room_id)
      );
      if (!clinicRoom) {
        enqueueSnackbar("data ruang tidak ditemukan");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/broadcast/users`,
        {
          params: {
            clinic: clinicRoom.clinic_id,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      setPatientQueues([...response.data]);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
        autoHideDuration: "2000",
      });
      Sentry.captureException(e);
    } finally {
    }
  };

  const validate = () => {
    const newError = {};

    if (!form.title) {
      newError.title = "wajib diisi";
      return newError;
    }

    if (!form.content) {
      newError.content = "wajib diisi";
    }
    return newError;
  };

  const handleSubmit = async (e) => {
    const findValidationError = validate();
    if (Object.values(findValidationError).some((err) => err !== "")) {
      setValidationError(findValidationError);
    } else {
      if (!form.clinic_room_id) {
        enqueueSnackbar("silahkan pilih ruang terlebih dahulu");
        return;
      }
      try {
        let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/broadcast`;
        let newForm = {
          title: form.title,
          content: form.content,
        };
        if (form.user_id.length > 0) {
          newForm.user_id = form.user_id;
        } else {
          newForm.clinic_room_id = form.clinic_room_id;
        }
        await axios.post(
          url,
          {
            ...newForm,
          },
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        enqueueSnackbar("sukses mengirim pesan ke penerima", {
          variant: "success",
        });
        setForm({ title: "", content: "", user_id: [], clinic_room_id: "" });
        handleModalClose();
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.message, {
          variant: "error",
        });
        Sentry.captureException(e);
      } finally {
        handleModalClose();
      }
    }
  };

  useEffect(() => {
    loadDataClinicRoom();
  }, []);

  useEffect(() => {
    if (form.clinic_room_id) {
      loadDataPasienAntrian();
    } else {
      setPatientQueues([]);
    }
  }, [form.clinic_room_id]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal__container}>
        <Box sx={header__container}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Broadcast pasien antrian
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="clinic_room_id">
              Pilih Ruang
            </InputLabel>
            <Select
              id="clinic_room_id"
              name="clinic_room_id"
              value={form.clinic_room_id ?? ""}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              IconComponent={() => {
                return <></>;
              }}
              sx={input__select}
            >
              {clinicRooms &&
                clinicRooms.map((i, idx) => {
                  return (
                    <MenuItem key={idx} value={i.id}>
                      {i.nama}
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText error={true}></FormHelperText>
          </FormControl>
          <Box
            sx={{
              p: 1,
              background: "#EFF1F9",
              borderRadius: "8px",
              marginBottom: "10px",
              display: form.clinic_room_id ? "block" : "none",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "12px",
              }}
            >
              Pilih pasien tertentu (Jika tidak ada yang dipilih akan di
              broadcast ke semua)
            </Typography>
            <Box sx={antrian___list__container}>
              <Grid container spacing={0}>
                {patientQueues &&
                  patientQueues.length > 0 &&
                  patientQueues.map((i, idx) => {
                    return (
                      <Grid item xs={12} key={`grid_${i.idx}`}>
                        <FormControlLabel
                          sx={{
                            ".MuiFormControlLabel-label": {
                              fontSize: "12px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={
                                form.user_id.includes(i.id) ? true : false
                              }
                              onChange={() => {
                                handleQueueClick(i.id);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label={i.nama}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="title">
              Judul
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              placeholder="judul pesan"
              id="title"
              name="title"
              type="text"
              value={form.title ?? ""}
            />
            <FormHelperText error={true}>
              {validationError.title}
            </FormHelperText>
          </FormControl>

          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="content">
              Pesan
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              placeholder="isi pesan"
              multiline
              minRows={3}
              maxRows={3}
              value={form.content ?? ""}
              id="content"
              name="content"
              type="text"
            />
            <FormHelperText error={true}>
              {validationError.content}
            </FormHelperText>
          </FormControl>
          <Button
            fullWidth
            sx={submit__btn}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography sx={submit__btn__label}>Broadcast</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
