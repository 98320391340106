import {
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../../../../../db/db";
import { useAuth } from "../../../../../../providers/AuthProvider";
import mediplusHelper from "../../../../../../utils/mediplusHelper";

export default function TemplateChatPopper({
  open = false,
  setOpen = () => {},
  anchorEl = null,
  handleClick = () => {},
  setTemplateChatModalOpen = () => {},
  handleClickAway = () => {},
}) {
  const { user } = useAuth();
  const chat_templates = useLiveQuery(() =>
    db.chat_templates.where("doctor_id").equals(user.id).toArray()
  );

  const handleModalOpen = () => {
    if (typeof setTemplateChatModalOpen === "function") {
      setTemplateChatModalOpen();
    }
    if (typeof setOpen === "function") {
      setOpen(false);
    }
  };

  const handleTemplateClick = (template) => {
    if (typeof handleClick === "function") {
      handleClick(template);
    }
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper
        data-group="popper-element"
        open={open}
        anchorEl={anchorEl ?? ""}
        id={"template-picker"}
        placement={"top"}
        sx={{
          zIndex: 1500,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            minWidth: "300px",
            marginBottom: "20px",
            p: 2,
            background: "#EFF1F9",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              Template Pesan
            </Typography>
            <IconButton
              aria-label="add"
              sx={{
                background: "#00AEEF",
                borderRadius: "8px",
                width: "25px",
                height: "25px",
                ":hover": {
                  background: "#00AEFF",
                },
              }}
              onClick={handleModalOpen}
            >
              <img src="/assets/icons/plus.svg" alt="plus"></img>
            </IconButton>
          </Box>
          <Box
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {chat_templates &&
              chat_templates.map((i, index) => {
                return (
                  <Typography
                    onClick={() => {
                      handleTemplateClick(i.value);
                    }}
                    key={index}
                    sx={{
                      width: "fit-content",
                      p: 0.5,
                      borderRadius: "7px",
                      fontWeight: 700,
                      fontSize: "12px",
                      background: "#fff",
                      cursor: "pointer",
                      marginBottom: "7px",
                    }}
                  >
                    {mediplusHelper.cropText(i.value, 40)}
                  </Typography>
                );
              })}
          </Box>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
}
