import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  form__control,
  input__label,
  input__select,
  input__text,
} from "../../../../rekam-medis/editor/style";
import { editItemDiscount } from "../../../../../../../redux/slices/invoice";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function DiskonModal({
  open = false,
  invoiceItemIndex = -1,
  invoiceItem = {},
  setInvoiceItem = () => {},
  onClose = () => {},
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleChange = (e) => {
    setInvoiceItem((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    if (
      invoiceItem.diskon_type === "PERCENT" &&
      invoiceItem.diskon_amount > 100
    ) {
      enqueueSnackbar("tipe persen jumlah diskon tidak boleh lebih dari 100");
      return;
    }
    if (
      invoiceItem.diskon_type === "NORMAL" &&
      invoiceItem.diskon_amount > invoiceItem.harga * invoiceItem.kuantitas
    ) {
      enqueueSnackbar("jumlah diskon tidak boleh lebih dari harga");
      return;
    }
    dispatch(
      editItemDiscount({
        idx: invoiceItemIndex,
        diskon_type: invoiceItem.diskon_type,
        diskon_amount: parseInt(invoiceItem.diskon_amount),
      })
    );
    handleModalClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...modalStyle, borderRadius: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Diskon Item
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="diskon_type">
              Tipe Diskon
            </InputLabel>
            <Select
              id="diskon_type"
              name="diskon_type"
              value={invoiceItem.diskon_type ?? ""}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              IconComponent={() => {
                return <></>;
              }}
              sx={input__select}
            >
              <MenuItem value={"NORMAL"}>Normal</MenuItem>
              <MenuItem value={"PERCENT"}>Persentase</MenuItem>
            </Select>
            <FormHelperText error={true}></FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="diskon_amount">
              Jumlah Diskon
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              value={parseInt(invoiceItem.diskon_amount)}
              placeholder=""
              id="diskon_amount"
              name="diskon_amount"
              type="number"
            />
            <FormHelperText error={true}></FormHelperText>
          </FormControl>

          <Button
            fullWidth
            sx={{
              backgroundColor: "blue",
              borderRadius: "8px",
              textTransform: "none",
              marginBottom: "15px",
              height: "38px",
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: 600,
                marginRight: "5px",
              }}
            >
              Simpan
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
