import _ from "lodash";
import mediplusHelper from "../../../../../../../utils/mediplusHelper";

const odontogramHelper = {
  androidGroupingIndex: (index) => {
    index = parseInt(index);
    let groupIndex = {
      18: "0",
      17: "1",
      16: "2",
      15: "3",
      14: "4",
      13: "5",
      12: "6",
      11: "7",
      21: "8",
      22: "9",
      23: "10",
      24: "11",
      25: "12",
      26: "13",
      27: "14",
      28: "15",
      55: "0",
      54: "1",
      53: "2",
      52: "3",
      51: "4",
      61: "5",
      62: "6",
      63: "7",
      64: "8",
      65: "9",
      85: "0",
      84: "1",
      83: "2",
      82: "3",
      81: "4",
      71: "5",
      72: "6",
      73: "7",
      74: "8",
      75: "9",
      48: "0",
      47: "1",
      46: "2",
      45: "3",
      44: "4",
      43: "5",
      42: "6",
      41: "7",
      31: "8",
      32: "9",
      33: "10",
      34: "11",
      35: "12",
      36: "13",
      37: "14",
      38: "15",
    };
    return groupIndex[index] ?? 0;
  },
  isFiveSectionIndex: (index) => {
    index = parseInt(index);
    let teethGroup = [
      18, 17, 16, 15, 14, 24, 25, 26, 27, 28, 55, 54, 64, 65, 85, 84, 74, 75,
      48, 47, 46, 45, 44, 34, 35, 36, 37, 38,
    ];
    if (teethGroup.includes(index)) {
      return true;
    }
    return false;
  },
  isTopGroupIndex: (index) => {
    index = parseInt(index);
    let topGroupIndex = [
      18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 55, 54,
      53, 52, 51, 61, 62, 63, 64, 65,
    ];
    if (topGroupIndex.includes(index)) {
      return true;
    } else {
      return false;
    }
  },
  isBottomGroupIndex: (index) => {
    index = parseInt(index);
    let bottomGroupIndex = [
      85, 84, 83, 82, 81, 71, 72, 73, 74, 75, 48, 47, 46, 45, 44, 43, 42, 41,
      31, 32, 33, 34, 35, 36, 37, 38,
    ];
    if (bottomGroupIndex.includes(index)) {
      return true;
    } else {
      return false;
    }
  },
  isTopLeftGroupIndex: (index) => {
    let topLeftGroupIndex = [
      18, 17, 16, 15, 14, 13, 12, 11, 55, 54, 53, 52, 51,
    ];
    if (topLeftGroupIndex.includes(parseInt(index))) {
      return true;
    } else {
      return false;
    }
  },
  isTopRightGroupIndex: (index) => {
    let topRightGroupIndex = [
      21, 22, 23, 24, 25, 26, 27, 28, 61, 62, 63, 64, 65,
    ];
    if (topRightGroupIndex.includes(parseInt(index))) {
      return true;
    } else {
      return false;
    }
  },
  isBottomLeftGroupIndex: (index) => {
    let bottomLeftGroupIndex = [
      85, 84, 83, 82, 81, 48, 47, 46, 45, 44, 43, 42, 41,
    ];
    if (bottomLeftGroupIndex.includes(parseInt(index))) {
      return true;
    } else {
      return false;
    }
  },
  isBottomRightGroupIndex: (index) => {
    let bottomRightGroupIndex = [
      71, 72, 73, 74, 75, 1, 32, 33, 34, 35, 36, 37, 38,
    ];
    if (bottomRightGroupIndex.includes(parseInt(index))) {
      return true;
    } else {
      return false;
    }
  },
  findGroupTeeth: (index) => {
    index = parseInt(index);
    let teethGroup = [
      [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28],
      [55, 54, 53, 52, 51, 61, 62, 63, 64, 65],
      [85, 84, 83, 82, 81, 71, 72, 73, 74, 75],
      [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38],
    ];
    let myGroup;
    teethGroup.forEach((i) => {
      if (i.includes(index)) {
        myGroup = i;
      }
    });
    return myGroup;
  },
  getToothIndexNumber: (loopIdx) => {
    let teethIndexes = [
      18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 55, 54,
      53, 52, 51, 61, 62, 63, 64, 65, 85, 84, 83, 82, 81, 71, 72, 73, 74, 75,
      48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
    ];
    return String(teethIndexes[loopIdx]) ?? "18";
  },
  isRotateOrMigrateMode: (mode) => {
    let modes = [
      "MAK2",
      "MAK1",
      "MBK2",
      "MBK1",
      "RAK2",
      "RAK1",
      "RBK2",
      "RBK1",
      "GGNV",
      "PSA",
    ];
    if (modes.includes(mode)) {
      return true;
    }
    return false;
  },
  isTopRotateOrMigrateMode: (mode) => {
    let modes = ["MAK2", "MAK1", "RAK2", "RAK1"];
    if (modes.includes(mode)) {
      return true;
    }
    return false;
  },
  isBottomRotateOrMigrateMode: (mode) => {
    let modes = ["MBK2", "MBK1", "RBK2", "RBK1", "GGNV", "PSA"];
    if (modes.includes(mode)) {
      return true;
    }
    return false;
  },
  isSou: (data) => {
    if (data[1].length < 1 || data[1][0].length < 1) {
      let sectionDataFound = false;
      data[2]?.forEach((v) => {
        if (v?.length > 0) {
          sectionDataFound = true;
        }
      });
      if (sectionDataFound === true) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
  isCanBeGroupCandidate: function (mode, data, all) {
    if (this.isSou(data)) {
      return false;
    }
    if (data[1][0] !== mode) {
      return false;
    }
    let myGroup = this.findGroupTeeth(data[0]);
    if (!myGroup) {
      return false;
    }
    let groupMemberId = data[1][1] ?? 9999999999;
    let groupMember = 0;
    myGroup.forEach((v) => {
      let myGpData = all.find(
        (i) => i[0] === String(v) && i[1][1] === groupMemberId
      );
      if (myGpData) {
        groupMember = groupMember + 1;
      }
    });
    if (groupMember > 1) {
      return false;
    } else {
      return true;
    }
  },
  isBridgeMode: (mode) => {
    let listBridgeMode = ["ICLB", "FLMB", "IPCB"];
    if (listBridgeMode.includes(mode)) {
      return true;
    } else {
      return false;
    }
  },
  isBridgeData: (data) => {
    let listBridgeData = [
      "ICLB",
      "FLMB",
      "IPCB",
      "BWS",
      "BWE",
      "BWPL",
      "BWEPL",
    ];
    if (listBridgeData.includes(data[1][0])) {
      return true;
    }
    return false;
  },
  generateGroupId: (all) => {
    let groupId = 0;
    while (true) {
      let isReserved = false;
      all?.forEach((i) => {
        if (i[1][1] === groupId) {
          isReserved = true;
        }
      });
      if (isReserved) {
        groupId = groupId + 1;
      } else {
        break;
      }
    }
    return groupId;
  },
  getDefaultSectionData: (data) => {
    let sectionData = data[2] ?? [];
    let sectionLength = sectionData.length;
    if (sectionLength === 5) {
      return ["", "", "", "", ""];
    } else {
      return ["", "", "", ""];
    }
  },
  getDefaultSectionDataByIndex: function (index) {
    if (this.isFiveSectionIndex(index)) {
      return ["", "", "", "", ""];
    } else {
      return ["", "", "", ""];
    }
  },
  isChildToothIndex: (index) => {
    let childToothIndexes = [
      55, 54, 53, 52, 51, 61, 62, 63, 64, 65, 85, 84, 83, 82, 81, 71, 72, 73,
      74, 75,
    ];

    if (childToothIndexes.includes(parseInt(index))) {
      return true;
    }
    return false;
  },
  isDisabled: function (data, teethMode) {
    let currentToothIdex = parseInt(data[0]);
    if (teethMode === 1) {
      if (this.isChildToothIndex(currentToothIdex)) {
        return true;
      } else {
        return false;
      }
    } else if (teethMode === 2) {
      if (this.isChildToothIndex(currentToothIdex)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },

  isDisabledByIndex: function (currentToothIdex, teethMode) {
    if (teethMode === 1) {
      if (this.isChildToothIndex(currentToothIdex)) {
        return true;
      } else {
        return false;
      }
    } else if (teethMode === 2) {
      if (this.isChildToothIndex(currentToothIdex)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },

  isGlobalStyleApplied: (data) => {
    let globalStyle = data[1] ?? [];
    if (globalStyle.length > 0 && globalStyle[0].length > 0) {
      return true;
    } else {
      return false;
    }
  },
  isModeAffectingGlobalStyle: (mode) => {
    let listAffectingGlobalModes = [
      "FRAK",
      "FLMC",
      "PLC",
      "MPLC",
      "GMC",
      "IPLC",
      "PADA",
      "FLDA",
      "PADM",
      "FLDM",
      "GGHG",
      "GGTA",
      "UERU",
      "PAERU",
      "ANOM",
      "PSA",
      "FLMB",
      "ICLB",
      "IPCB",
      "SSAK",
    ];
    if (listAffectingGlobalModes.includes(mode)) {
      return true;
    } else {
      return false;
    }
  },
  isModeCanUsedToSection: (mode) => {
    let allowed = ["TAA", "TAC", "PFS", "KAR"];
    if (allowed.includes(mode)) {
      return true;
    }
    return false;
  },
  getSectionName: (event) => {
    if (typeof event.target.getAttribute === "function") {
      let validData = [
        "sec_top",
        "sec_bottom",
        "sec_center",
        "sec_left",
        "sec_right",
      ];
      let secName = event.target.getAttribute("data-group");
      if (validData.includes(secName)) {
        return secName;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  sectionToIndex: (sectionName) => {
    let a = {
      sec_top: 0,
      sec_right: 1,
      sec_bottom: 2,
      sec_left: 3,
      sec_center: 4,
    };
    return a[sectionName] ?? 0;
  },
  isContainerClicked: (event) => {
    if (typeof event.target.getAttribute === "function") {
      if (event.target.getAttribute("data-group") === "sec_container") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  sectionDataModeToCode: (mode) => {
    if (mode === "TAA") {
      return "amf";
    } else if (mode === "TAC") {
      return "cof";
    } else if (mode === "PFS") {
      return "fis";
    } else if (mode === "KAR") {
      return "car";
    } else {
      return "unk";
    }
  },
  detailLabelGenerator: function (data) {
    let index = data[0];
    if (this.isSou(data) && (!data[4] || data[4].length < 1)) {
      return "sou";
    } else {
      let isRct = data[4] === "PSA" ? true : false;
      let isNvt = data[4] === "GGNV" ? true : false;
      if (this.isGlobalStyleApplied(data)) {
        let mode = data[1][0];
        let tempLabel;
        if (mode === "FLMC") {
          //Full Metal Crown
          tempLabel = "fmc";
        } else if (mode === "PLC") {
          //Porcelain Crown
          tempLabel = "poc";
        } else if (mode === "MPLC") {
          //Metal Porcelain Crown
          tempLabel = "mpc";
        } else if (mode === "GMC") {
          //Gold Porcelain Crown
          tempLabel = "gmc";
        } else if (mode === "IPLC") {
          //Implant + Porcelain Crown
          tempLabel = "ipx - poc";
        } else if (mode === "PADA") {
          //Partial Denture + Akrilik
          tempLabel = "miss - prd - acr";
        } else if (mode === "FLDA") {
          //Full Denture + Akrilik
          tempLabel = "miss - fld - acr";
        } else if (mode === "PADM") {
          //Partial Denture - Metal
          tempLabel = "miss - prd - frm";
        } else if (mode === "FLDM") {
          //Full Denture - Metal
          tempLabel = "miss - fld - frm";
        } else if (mode === "FRAK") {
          //Fraktur
          tempLabel = "cfr";
        } else if (mode === "GGHG") {
          //Gigi Hilang (Mis)
          tempLabel = "mis";
        } else if (mode === "GGTA") {
          //Gigi Tidak Ada (Non)
          tempLabel = "non";
        } else if (mode === "UERU") {
          //Un-Erupted
          tempLabel = "une";
        } else if (mode === "PAERU") {
          //Partial Erupted
          tempLabel = "pre";
        } else if (mode === "ANOM") {
          //Anomali
          tempLabel = "ano";
        } else if (mode === "SSAK") {
          //Sisa Akar
          tempLabel = "rrx";
        } else if (mode === "FLMB") {
          //Sisa Akar
          tempLabel = "meb";
        } else if (mode === "FLMB" || mode === "ICLB" || mode === "BWE") {
          //Full Metal Bridge || Full Metal Cantilever Bridge
          tempLabel = "meb";
        } else if (mode === "BWS") {
          //Bridge Member || END Full Metal Cantilever Bridge
          tempLabel = "mis - pon";
        } else if (mode === "BWPL") {
          tempLabel = "mis - pon - pob";
        } else if (mode === "IPCB" || mode === "BWEPL") {
          //Porcelain Bridge || END Porcelain Bridge
          tempLabel = "pob";
        } else {
          tempLabel = "";
        }
        return `${tempLabel}${isRct ? " - rct" : isNvt ? " - nvt" : ""}`;
      } else {
        let tempData = { TAA: "", TAC: "", PFS: "", KAR: "" };
        //ordered MDVLO
        let sectionData = data[2] ?? [];
        let oderedSection;
        if (this.isTopLeftGroupIndex(index)) {
          //ordered MDVLO
          //5-part 0 atas, 1 kanan, 2 bawah, 3 kiri, 4tengah
          oderedSection = [
            sectionData[1] ?? "", //m
            sectionData[3] ?? "", //d
            sectionData[0] ?? "", //v
            sectionData[2] ?? "", //l
            sectionData[4] ?? "", //o
          ];
        } else if (this.isTopRightGroupIndex(index)) {
          //ordered MDVLO
          //5-part 0 atas, 1 kanan, 2 bawah, 3 kiri, 4tengah
          oderedSection = [
            sectionData[3] ?? "",
            sectionData[1] ?? "",
            sectionData[0] ?? "",
            sectionData[2] ?? "",
            sectionData[4] ?? "",
          ];
        } else if (this.isBottomLeftGroupIndex(index)) {
          //ordered MDVLO
          //5-part 0 atas, 1 kanan, 2 bawah, 3 kiri, 4tengah
          oderedSection = [
            sectionData[1] ?? "",
            sectionData[3] ?? "",
            sectionData[2] ?? "",
            sectionData[0] ?? "",
            sectionData[4] ?? "",
          ];
        } else {
          //ordered MDVLO
          //5-part 0 atas, 1 kanan, 2 bawah, 3 kiri, 4tengah
          oderedSection = [
            sectionData[3] ?? "",
            sectionData[1] ?? "",
            sectionData[2] ?? "",
            sectionData[0] ?? "",
            sectionData[4] ?? "",
          ];
        }
        Object.keys(tempData).forEach((v) => {
          //M
          if (oderedSection[0] === v) {
            tempData[v] = `${tempData[v]}${"M"}`;
          }
          //O
          if (oderedSection[4] === v) {
            tempData[v] = `${tempData[v]}${"O"}`;
          }
          //D
          if (oderedSection[1] === v) {
            tempData[v] = `${tempData[v]}${"D"}`;
          }
          //V
          if (oderedSection[2] === v) {
            tempData[v] = `${tempData[v]}${"V"}`;
          }
          //L
          if (oderedSection[3] === v) {
            tempData[v] = `${tempData[v]}${"L"}`;
          }
        });

        let tempRes = "";
        Object.entries(tempData)
          .filter(([key, value]) => value.length > 0)
          .forEach(([key, value], idx, arr) => {
            tempRes = `${tempRes}${value} ${this.sectionDataModeToCode(key)}${
              isRct ? " - rct" : isNvt ? " - nvt" : ""
            }${idx === arr.length - 2 ? " , " : ""}`;
          });

        if (tempRes.length < 1) {
          tempRes = `${isRct ? "rct" : isNvt ? "nvt" : ""}`;
        }
        return tempRes;
      }
    }
  },
  noteFixer: function (arr) {
    let temp;
    if (Array.isArray(arr)) {
      temp = [...arr];
    } else {
      temp = [];
    }
    if (temp.length < 52) {
      _.times(52 - temp.length, (i) => {
        temp.push([]);
      });
    }
    temp = temp.map((i, idx) => {
      if (!(0 in i)) {
        i.push("");
      }
      i[0] = parseInt(this.getToothIndexNumber(idx));

      if (!(1 in i)) {
        i.push("");
      } else {
        i[1] = _.isString(i[1]) ? i[1] : "";
      }
      return i;
    });
    return temp;
  },
  dataFixer: function (data) {
    let temp;
    if (Array.isArray(data)) {
      temp = [...data];
    } else {
      temp = [];
    }
    if (0 in temp) {
      if (!_.isString(temp[0])) {
        temp[0] = "0";
      }
    } else {
      temp.push("0");
    }

    if (1 in temp) {
      if (Array.isArray(temp[1])) {
        if (temp[1].length < 2) {
          _.times(2 - temp[1].length, (i) => {
            temp[1].push("");
          });
        }
        temp[1] = temp[1].map((i) => (_.isString(i) ? i : ""));
      } else {
        temp[1] = ["", ""];
      }
    } else {
      temp.push(["", ""]);
    }

    let defaultSectionLength;
    if (this.isFiveSectionIndex(temp[0])) {
      defaultSectionLength = 5;
    } else {
      defaultSectionLength = 4;
    }
    let d = [];
    _.times(defaultSectionLength, (i) => {
      d.push("");
    });
    if (2 in temp) {
      if (Array.isArray(temp[2])) {
        if (temp[2].length < defaultSectionLength) {
          _.times(defaultSectionLength - temp[2].length, (i) => {
            temp[2].push("");
          });
        }
        temp[2] = temp[2].map((i) => (_.isString(i) ? i : ""));
      } else {
        temp[2] = d;
      }
    } else {
      temp.push(d);
    }

    if (3 in temp) {
      if (!_.isString(temp[3])) {
        temp[3] = "";
      }
    } else {
      temp.push("");
    }

    if (4 in temp) {
      if (!_.isString(temp[4])) {
        temp[4] = "";
      }
    } else {
      temp.push("");
    }

    if (5 in temp) {
      if (!_.isString(temp[5])) {
        temp[5] = "";
      }
    } else {
      temp.push("");
    }

    return temp;
  },
  decodeData: function (str) {
    let temp;
    if (mediplusHelper.hasJsonStructure(str)) {
      temp = JSON.parse(str);
    }
    if (!Array.isArray(temp)) {
      temp = [];
    }

    if (0 in temp) {
      if (typeof temp[0] !== "number" || ![1, 2, 3].includes(temp[0])) {
        temp[0] = 3;
      }
    } else {
      temp.push(1);
    }
    let d = [];
    _.times(52, (i) => {
      d.push([]);
    });
    if (1 in temp) {
      if (Array.isArray(temp[1])) {
        if (temp[1].length < 52) {
          _.times(52 - temp[1].length, (i) => {
            temp[1].push("");
          });
        }
      } else {
        temp[1] = d;
      }
    } else {
      temp.push(d);
    }
    temp[1] = temp[1].map((i) =>
      i.map((j, idx) => {
        if (idx === 1 || idx === 2) {
          return j.split("|");
        } else {
          return j === "null" || j === null || j === undefined ? "" : j;
        }
      })
    );
    //fixing index
    temp[1] = temp[1].map((i, idx) => {
      if (!(0 in i)) {
        i.push("");
      }
      i[0] = this.getToothIndexNumber(idx);
      return i;
    });
    //fixing data
    temp[1] = temp[1].map((i) => this.dataFixer(i));
    //fixing note
    if (!(2 in temp)) {
      temp.push(this.noteFixer(null));
    } else {
      temp[2] = this.noteFixer(temp[2]);
    }
    return temp;
  },
  encodeData: function (arr) {
    let temp = arr
      .filter((i, ix) => ix !== 3)
      .map((i, idx) =>
        idx === 1
          ? i.map((j) =>
              j.map((k, ix) => {
                if (ix === 0) {
                  return [k, this.androidGroupingIndex(k)].join("|");
                } else if (ix === 1) {
                  if (0 in k) {
                    if (k[0].length < 1) {
                      return "";
                    } else {
                      return k.join("|");
                    }
                  } else {
                    return "";
                  }
                } else if (ix === 2) {
                  return k.join("|");
                } else {
                  return k;
                }
              })
            )
          : i
      );
    return JSON.stringify(temp);
  },
  changeNoteArrayToObject: function (arr) {
    let tempObj = {};
    arr = this.noteFixer(arr);
    arr.forEach((i) => {
      tempObj[i[0]] = i[1];
    });
    return tempObj;
  },
  changeNoteObjectToArray: function (obj) {
    let temp = [];
    for (let i = 0; i < 52; i++) {
      let toothIndex = this.getToothIndexNumber(i);
      temp.push([parseInt(toothIndex), obj[toothIndex] ?? ""]);
    }
    return temp;
  },
  parseOpsiFoto: function (str, arrLength = 2) {
    let temp;
    if (_.isString(str)) {
      temp = str.split("|");
    } else {
      temp = [];
    }
    if (temp.length < arrLength) {
      _.times(arrLength - temp.length, (i) => {
        temp.push(0);
      });
    }
    for (let i = 0; i < arrLength; i++) {
      if (temp[i] === "true" || temp[i] === 1 || temp[i] === "1") {
        temp[i] = 1;
      } else {
        temp[i] = 0;
      }
    }
    return temp;
  },
  labelGenerator: function (arr, doc, teethMode) {
    if (!Array.isArray(arr) || arr.length < 1) {
      return "";
    } else {
      if (arr.length === 1) {
        let data = doc[1].find((j) => j[0] === String(arr[0]));
        if (data) {
          if (this.isDisabled(data, teethMode)) {
            return "-";
          }
          return this.detailLabelGenerator(data);
        } else {
          return "nf data";
        }
      }
      let temp = "";
      let j = 0;
      for (let i = 0; i < arr.length; i++) {
        let data = doc[1].find((j) => j[0] === String(arr[i]));
        if (data) {
          let label = "";

          if (this.isDisabled(data, teethMode)) {
            continue;
          } else {
            label = this.detailLabelGenerator(data);
          }
          if (j === 0) {
            temp = `${arr[i]} : ${label}  `;
          } else {
            if (temp.length < 1) {
              temp = `${arr[i]} : ${label}  `;
            } else {
              temp = `${temp}${arr[i]} : ${label}  `;
            }
          }
          j++;
        }
      }
      return temp;
    }
  },
};

export default odontogramHelper;
