import { useTheme } from "@emotion/react";
import {
  Grid,
  IconButton,
  InputBase,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import HardwareStatus from "./hardware-status/HardwareStatus";
import StorageUsage from "./storage-usage/StorageUsage";
import { header__container, header__h1, search__input } from "./style";
import HasilPemeriksaanTable from "./table/HasilPemeriksaanTable";
export default function HasilPemeriksaan() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [search, setSearch] = useState("");

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Hasil Pemeriksaan
        </Typography>
        <Box>
          <InputBase
            sx={search__input}
            size="18"
            placeholder="Cari"
            value={search}
            onChange={handleSearchInputChange}
          />
          <IconButton
            aria-label="search"
            sx={{
              backgroundColor: "blue",
              borderRadius: "0px 8px 8px 0px",
              height: "38px",
            }}
          >
            <img src="/assets/icons/search.svg" alt="search"></img>
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={isDesktop ? 8 : 12}>
          <HasilPemeriksaanTable search={search} />
        </Grid>
        <Grid item xs={isDesktop ? 4 : 12}>
          <HardwareStatus />
          <StorageUsage />
        </Grid>
      </Grid>
    </>
  );
}
