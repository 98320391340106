import React from "react";
import { Container, Typography } from "@mui/material";

function FirebaseError() {
  return (
    <Container maxWidth="xs">
      <div
        style={{
          textAlign: "justify",
        }}
      >
        <Typography
          variant="subtitle"
          component="h5"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img
            src="/assets/logo-mediplus.svg"
            alt="logo-mediplus"
            width="200"
            height="30"
            style={{
              marginBottom: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></img>
          <div
            style={{
              width: "100%",
            }}
          >
            Mohon maaf, website tidak mendapatkan izin notifikasi, harap izinkan
            notifikasi website ini terlebih dahulu di pengaturan browser anda.
            Setelah itu silakan refresh halaman ini. Jika ada pertanyaan harap
            hubungi kami di 081234722711.
          </div>
        </Typography>
      </div>
    </Container>
  );
}

export default FirebaseError;
