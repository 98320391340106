import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { isEmpty, isString, isInteger } from "lodash";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import {
  form__control,
  input__label,
  input__text,
  section__title,
} from "../style";
import {
  medicine__box__container,
  medicine__box__header__btn,
  medicine__box__header__container,
  medicine__box__header__text,
  medicne__list__item,
  medicne__list__item__label,
  modal__container,
  modal__header,
  resep__container,
  resep__list__container,
  resep__list__item,
  resep__list__item__btn,
  resep__list__item__btn__container,
} from "./style";
import TemplateText from "../template-text/TemplateText";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import * as Sentry from "@sentry/react";
import {
  snackbar__content__container,
  snackbar__content__text,
} from "../../main/table/style";
import ResepRacikInput from "./ResepRacikInput";

export default function TabResepIer({
  form,
  setForm,
  handleGeneralInputFormChange,
}) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [medicineInputMode, setMedicineInputMode] = useState("NORMAL");
  const [
    medicineSwitchModelConfirmationOpen,
    setMedicineSwitchModelConfirmationOpen,
  ] = useState(false);

  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const [displayMedicines, setDisplayMedicines] = useState([]);
  const [onSubmenu, setOnSubmenu] = useState(false);
  const [medicines, setMedicines] = useState([]);
  const [noSubMedicines, setNoSubMedicines] = useState([]);
  const [focusInput, setFocusInput] = useState("");

  const [focusInputModalObat, setFocusInputModalObat] = useState("");

  //modal
  const [medicineModalOpen, setMedicineModalOpen] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState({});

  const loadMedicine = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/medicines`, {
        params: {
          pagination: 100,
          clinic: current_active_schedule.clinic_id,
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setMedicines(response.data.data);
        setDisplayMedicines(response.data.data);
        let tempNoSubMedicine = [];
        response.data.data.map((i) => {
          tempNoSubMedicine.push(i);
          i.submenu.map((sub) => {
            tempNoSubMedicine.push(sub);
            return true;
          });
          return true;
        });
        setNoSubMedicines(tempNoSubMedicine);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      })
      .finally(() => {});
  };

  const handleMedicineInputSwitch = () => {
    if (form.resep.length > 0) {
      setMedicineSwitchModelConfirmationOpen(true);
    } else {
      handleForceMedicineInputSwitch();
    }
  };

  const handleForceMedicineInputSwitch = () => {
    if (medicineInputMode === "NORMAL") {
      setForm((prev) => ({ ...prev, resep: "" }));
    } else {
      setForm((prev) => ({ ...prev, resep: [] }));
    }
    setMedicineSwitchModelConfirmationOpen(false);
  };

  const handleInputFocus = (e) => {
    setFocusInput(e.target.name);
  };

  const handleTemplateClick = (value, name) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: prevForm[name]?.length < 1 ? value : prevForm[name] + " " + value,
    }));
  };

  const handleInputModalObatFocus = (e) => {
    setFocusInputModalObat(e.target.name);
  };

  const handleTemplateModalChange = (value, name) => {
    setSelectedMedicine((prev) => ({
      ...prev,
      [name]: prev[name]?.length < 1 ? value : prev[name] + " " + value,
    }));
  };

  const backToMainMedicine = () => {
    setDisplayMedicines(medicines);
    setOnSubmenu(false);
  };

  const handleMedicineClick = (medicine) => {
    if (medicine.submenu?.length > 0) {
      setDisplayMedicines(medicine.submenu);
      setOnSubmenu(true);
    } else {
      setSelectedMedicine({
        id: medicine.id,
        harga: medicine.harga,
        nama: medicine.nama,
        qty: 0,
        stok: medicine.stok,
        aturan_minum: "",
        keterangan: "",
        dosis: "",
      });
      setMedicineModalOpen(true);
    }
  };

  const handleFormModalChange = (e) => {
    if (e.target.name === "qty") {
      if (
        isNaN(e.target.value) ||
        e.target.value === "" ||
        isEmpty(e.target.value)
      ) {
        setSelectedMedicine((prev) => ({
          ...prev,
          [e.target.name]: "",
        }));
      } else {
        if (parseInt(e.target.value) > selectedMedicine.stok) {
          enqueueSnackbar("stok tidak mencukupi");
        } else {
          setSelectedMedicine((prev) => ({
            ...prev,
            [e.target.name]: parseInt(e.target.value),
          }));
        }
      }
    } else {
      setSelectedMedicine((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleModalFormSave = () => {
    console.table(selectedMedicine);
    if (
      !isString(selectedMedicine.aturan_minum) ||
      isEmpty(selectedMedicine.aturan_minum)
    ) {
      enqueueSnackbar("harap isi aturan minum dengan benar");
      return;
    }

    if (
      !isString(selectedMedicine.keterangan) ||
      isEmpty(selectedMedicine.keterangan)
    ) {
      enqueueSnackbar("harap isi catatan dengan benar");
      return;
    }

    if (!isInteger(selectedMedicine.qty)) {
      enqueueSnackbar("harap jumlah obat dengan benar");
      return;
    }

    let resep = { ...selectedMedicine, unsaved: true };
    delete resep.stok;

    setForm((prev) => ({
      ...prev,
      resep: [...prev.resep, resep],
    }));

    setMedicineModalOpen(false);
    setSelectedMedicine({});
  };

  const handleAddQtyResep = (index, medicineID, e) => {
    let resepObat = form.resep.find((obat, i) => i === index);
    if (!resepObat) {
      enqueueSnackbar("data resep obat tidak ditemukan");
      return;
    }
    let obatDb = noSubMedicines.find((med) => med.id === resepObat.id);
    if (!obatDb) {
      enqueueSnackbar(
        "tidak dapat menambahkan jumlah obat [data obat tidak ditemukan]"
      );
      return;
    }

    if (resepObat.unsaved === true) {
      if (parseInt(resepObat.qty) + 1 > obatDb.stok) {
        enqueueSnackbar("anda menambahkan jumlah melebihi stok");
      }
      setForm((prev) => ({
        ...prev,
        resep: prev.resep.map((resep, i) =>
          i === index ? { ...resep, qty: parseInt(resep.qty) + 1 } : resep
        ),
      }));
    } else {
      let originalQty;
      if (!resepObat.originalQty) {
        originalQty = resepObat.qty;
        setForm((prev) => ({
          ...prev,
          resep: prev.resep.map((resep, i) =>
            i === index ? { ...resep, originalQty: resep.qty } : resep
          ),
        }));
      } else {
        originalQty = resepObat.originalQty;
      }
      if (resepObat.qty - originalQty + 1 > obatDb.stok) {
        enqueueSnackbar("anda menambahkan jumlah melebihi stok");
      }

      setForm((prev) => ({
        ...prev,
        resep: prev.resep.map((resep, i) =>
          i === index ? { ...resep, qty: parseInt(resep.qty) + 1 } : resep
        ),
      }));
    }
  };

  const handleSubtractQtyResep = (index, medicineID) => {
    let resepObat = form.resep.find((obat, i) => i === index);
    if (resepObat) {
      if (parseInt(resepObat.qty) - 1 < 0) {
        //delete
        setForm((prev) => ({
          ...prev,
          resep: prev.resep.filter((resep, i) => i !== index),
        }));
      } else {
        setForm((prev) => ({
          ...prev,
          resep: prev.resep.map((resep, i) =>
            i === index ? { ...resep, qty: parseInt(resep.qty) - 1 } : resep
          ),
        }));
      }
    } else {
      enqueueSnackbar("data resep obat tidak ditemukan");
    }
  };

  useEffect(() => {
    loadMedicine();
  }, []);

  useEffect(() => {
    if (Array.isArray(form.resep)) {
      if (medicineInputMode !== "NORMAL") {
        setMedicineInputMode("NORMAL");
      }
    } else {
      if (medicineInputMode !== "MANUAL") {
        setMedicineInputMode("MANUAL");
      }
    }
  }, [form.resep]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={medicineSwitchModelConfirmationOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>
                Konfirmasi (data obat yang sudah diinput akan direset)
              </Typography>
              <Box>
                <Button
                  onClick={handleForceMedicineInputSwitch}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setMedicineSwitchModelConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />
      <Typography sx={section__title}>Resep & IER</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  pb: "5px",
                }}
              >
                <InputLabel
                  sx={{ ...input__label, marginTop: "5px", height: "20px" }}
                  shrink
                  htmlFor="resep"
                >
                  Resep Obat
                </InputLabel>
                <IconButton
                  onClick={handleMedicineInputSwitch}
                  sx={medicine__box__header__btn}
                >
                  <Typography sx={medicine__box__header__text}>
                    {medicineInputMode === "NORMAL"
                      ? "Input Manual"
                      : "Input Normal"}
                  </Typography>
                </IconButton>
              </Box>
              {medicineInputMode === "NORMAL" ? (
                <>
                  <Box
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <Box sx={resep__container}>
                      <Box sx={resep__list__container}>
                        {form?.resep &&
                          Array.isArray(form?.resep) &&
                          form.resep.length > 0 &&
                          form.resep.map((item, index) => {
                            return (
                              <Box key={index} sx={resep__list__item}>
                                <Typography
                                  component={"span"}
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {item.nama} | {item.aturan_minum} |{" "}
                                  {item.keterangan}
                                </Typography>
                                <Box sx={resep__list__item__btn__container}>
                                  <IconButton
                                    onClick={(e) => {
                                      handleSubtractQtyResep(index, item.id, e);
                                    }}
                                    aria-label="sub"
                                    sx={resep__list__item__btn}
                                  >
                                    <img
                                      width={20}
                                      height={20}
                                      src="/assets/icons/minus-btn.svg"
                                      alt="minus"
                                    ></img>
                                  </IconButton>
                                  <Typography
                                    sx={{
                                      marginRight: "6px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.qty}
                                  </Typography>
                                  <IconButton
                                    onClick={(e) => {
                                      handleAddQtyResep(index, item.id, e);
                                    }}
                                    aria-label="add"
                                    sx={resep__list__item__btn}
                                  >
                                    <img
                                      width={20}
                                      height={20}
                                      src="/assets/icons/plus-btn.svg"
                                      alt="plus"
                                    ></img>
                                  </IconButton>
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <Box sx={medicine__box__container}>
                      <Box sx={medicine__box__header__container}>
                        <Typography sx={medicine__box__header__text}>
                          Daftar List Obat
                        </Typography>
                        <IconButton
                          aria-label="search-user"
                          sx={medicine__box__header__btn}
                          disabled={onSubmenu ? false : true}
                          onClick={backToMainMedicine}
                        >
                          <Typography sx={medicine__box__header__text}>
                            Kembali
                          </Typography>
                        </IconButton>
                      </Box>
                      <Box sx={{}}>
                        {displayMedicines &&
                          displayMedicines?.map((medicine, idx) => {
                            return (
                              <IconButton
                                key={idx}
                                onClick={() => {
                                  handleMedicineClick(medicine);
                                }}
                                aria-label="medicine"
                                sx={medicne__list__item}
                              >
                                <Typography sx={medicne__list__item__label}>
                                  {medicine.nama}
                                </Typography>
                              </IconButton>
                            );
                          })}
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <InputBase
                  sx={input__text}
                  multiline
                  maxRows={3}
                  minRows={3}
                  placeholder="e.g : Paracetamol | 3x1 | Sesudah Makan"
                  id="resep"
                  name="resep"
                  type="text"
                  value={form.resep}
                  onChange={handleGeneralInputFormChange}
                  onFocus={handleInputFocus}
                />
              )}
            </Box>

            <FormControl variant="standard" sx={{ ...form__control }}>
              <InputLabel sx={input__label} shrink htmlFor="evaluasi">
                Evaluasi
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="evaluasi"
                id="evaluasi"
                name="evaluasi"
                type="text"
                value={form.evaluasi}
                onChange={handleGeneralInputFormChange}
                onFocus={handleInputFocus}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <TemplateText
              table="template_evaluasi"
              onClick={(value) => {
                handleTemplateClick(value, "evaluasi");
              }}
              open={focusInput === "evaluasi" ? true : false}
            />
          </Grid>
          <Grid item xs={6}>
            <ResepRacikInput form={form} setForm={setForm}></ResepRacikInput>

            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="implementasi">
                Implementasi
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="implementasi"
                id="implementasi"
                name="implementasi"
                type="text"
                value={form.implementasi}
                onChange={handleGeneralInputFormChange}
                onFocus={handleInputFocus}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <TemplateText
              table="template_implementasi"
              onClick={(value) => {
                handleTemplateClick(value, "implementasi");
              }}
              open={focusInput === "implementasi" ? true : false}
            />
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="revisi">
                Revisi
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="revisi"
                id="revisi"
                name="revisi"
                type="text"
                value={form.revisi}
                onChange={handleGeneralInputFormChange}
                onFocus={handleInputFocus}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <TemplateText
              table="template_revisi"
              onClick={(value) => {
                handleTemplateClick(value, "revisi");
              }}
              open={focusInput === "revisi" ? true : false}
            />
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={medicineModalOpen}
        onClose={() => {
          setMedicineModalOpen(false);
        }}
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal__container}>
          <Box sx={modal__header}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Tambah Resep Obat
            </Typography>
            <IconButton
              onClick={() => {
                setMedicineModalOpen(false);
              }}
              color="inherit"
              aria-label="refresh"
              edge="start"
              type="button"
            >
              <img
                width={25}
                height={25}
                effect="blur"
                src="/assets/icons/close.svg"
                alt="refresh"
              ></img>
            </IconButton>
          </Box>
          <Box
            sx={{
              px: 1.5,
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <FormControl
                  variant="standard"
                  sx={{
                    ...form__control,
                    paddingRight: 2,
                  }}
                >
                  <InputLabel sx={input__label} shrink htmlFor="nama">
                    Nama Obat
                  </InputLabel>
                  <InputBase
                    sx={input__text}
                    disabled={true}
                    value={selectedMedicine.nama}
                    id="nama"
                    name="nama"
                    type="text"
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" sx={form__control}>
                  <InputLabel sx={input__label} shrink htmlFor="stok">
                    Stok Obat Saat Ini
                  </InputLabel>
                  <InputBase
                    sx={input__text}
                    disabled={true}
                    value={selectedMedicine.stok}
                    id="stok"
                    name="stok"
                    type="number"
                  />
                  <FormHelperText error={true}></FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="harga">
                Harga Obat
              </InputLabel>
              <InputBase
                sx={input__text}
                value={mediplusHelper.formatRupiah(selectedMedicine.harga)}
                id="harga"
                name="harga"
                type="text"
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="aturan_minum">
                Aturan Minum
              </InputLabel>
              <InputBase
                sx={input__text}
                onFocus={handleInputModalObatFocus}
                onChange={handleFormModalChange}
                value={selectedMedicine?.aturan_minum ?? ""}
                id="aturan_minum"
                name="aturan_minum"
                type="text"
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <TemplateText
              table="template_aturan_minum"
              onClick={(value) => {
                handleTemplateModalChange(value, "aturan_minum");
              }}
              open={focusInputModalObat === "aturan_minum" ? true : false}
            />
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="keterangan">
                Catatan
              </InputLabel>
              <InputBase
                sx={input__text}
                onFocus={handleInputModalObatFocus}
                value={selectedMedicine?.keterangan ?? ""}
                onChange={handleFormModalChange}
                id="keterangan"
                name="keterangan"
                type="text"
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <TemplateText
              table="template_catatan_obat"
              onClick={(value) => {
                handleTemplateModalChange(value, "keterangan");
              }}
              open={focusInputModalObat === "keterangan" ? true : false}
            />
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="stok">
                Jumlah
              </InputLabel>
              <InputBase
                sx={input__text}
                onChange={handleFormModalChange}
                value={selectedMedicine?.qty ?? 0}
                id="qty"
                name="qty"
                type="number"
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <Button
              onClick={handleModalFormSave}
              fullWidth
              sx={{
                backgroundColor: "blue",
                borderRadius: "8px",
                textTransform: "none",
                marginBottom: "15px",
                height: "38px",
              }}
              variant="contained"
            >
              <Typography
                sx={{
                  marginLeft: "5px",
                  fontSize: "12px",
                  fontWeight: 600,
                  marginRight: "5px",
                }}
              >
                Simpan
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
