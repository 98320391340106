import { webkit__scrollbar } from "../../../style";

const header__wrapper = {
  borderBottom: "1px solid #D5D5D5;",
  borderRadius: "8px",
  pt: 2,
};

const header__container = {
  alignItems: "center",
  textAlign: "center",
  marginBottom: "16px",
};

const user__img = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "10px",
  borderRadius: "50%",
  width: "70px",
  height: "70px",
};

const timeline__h1 = {
  fontSize: "16px",
  fontWeight: 700,
  marginLeft: "10px",
  my: 1,
  display: "block",
};

const timeline__list__container = {
  padding: "5px 15px 5px 15px",
  position: "relative",
  maxHeight: "calc(100vh - 350px)",
  overflowY: "auto",
  ...webkit__scrollbar,
};

const text__subjektif = {
  fontWeight: 600,
  fontSize: "12px",
  color: "rgba(125, 125, 125, 1)",
};

const text__date = {
  fontWeight: 600,
  fontSize: "14px",
  marginBottom: "2px",
};

const text__invoice__total = {
  display: "flex",
  fontWeight: 700,
  color: "blue",
  marginLeft: "10px",
};

export {
  header__wrapper,
  header__container,
  user__img,
  timeline__h1,
  timeline__list__container,
  text__date,
  text__subjektif,
  text__invoice__total,
};
