import React from "react";

export default function BridgeCenterReverse() {
  return (
    <img
      src="/assets/odontogram/bridge-center.svg"
      alt="brigde-center"
      style={{
        WebkitTransform: "scaleY(-1)",
        transform: "scaleY(-1)",
      }}
    ></img>
  );
}
