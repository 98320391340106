const header__tab__btn = {
  position: "relative",
  textAlign: "center",
  padding: "5px",
  width: "120px",
  cursor: "pointer",
  boxSizing: "border-box",
};

const header__tab__btn__label = {
  fontWeight: 600,
  fontSize: "12px",
};

export { header__tab__btn, header__tab__btn__label };
