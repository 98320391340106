import React, { useContext, useState, useEffect } from "react";
import authHeader from "../services/authHeader";
import AuthService from "../services/authService";
import { useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import { db } from "../db/db";
import { updateScheduleData } from "../redux/slices/schedule";
import mediplusHelper from "../utils/mediplusHelper";
import * as Sentry from "@sentry/react";
import { changeTimelineGroupMode } from "../redux/slices/timelineGroupMode";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider(props) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authUpdate, setAuthUpdate] = useState(true);
  const [scheduleError, setScheduleError] = useState(false);
  const [mustSelectHead, setMustSelectHead] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    //cek apakah header token ada
    let header = authHeader();
    if (!header || Object.keys(header).length === 0) {
      //set state login ke false
      setUser(null);
      setLoading(false);
    } else {
      try {
        //variabel untuk menampung data head_doctor asisten
        let head_doctor_id = null;

        //get auth user data
        const data = await AuthService.auth();

        if (data.user !== null) {
          //jika berhasil set state user
          setUser(data.user);

          //jika tipe dokter asisten
          if (data.user.type === "ASSISTANT") {
            // cek apakah di db browser sudah pernah diset head doctor
            const head_doctor = await db.head_doctor
              .where("id")
              .aboveOrEqual(0)
              .first();

            if (head_doctor === undefined) {
              //set state pilih head doctor true
              setMustSelectHead(true);
              setLoading(false);
              return;
            } else {
              let headDoctorProfile = data.user.heads?.find(
                (i) => i.id === head_doctor.doctor_id
              );
              if (!headDoctorProfile) {
                /**
                 * Kalau data head dokter tidak ditemukan
                 * clear data dan tampilkan untuk memilih head doctor
                 */
                db.head_doctor.clear();
                dispatch(changeTimelineGroupMode("ktp"));
                setMustSelectHead(true);
                setLoading(false);
                return;
              }
              dispatch(
                changeTimelineGroupMode(headDoctorProfile.timeline_group_mode)
              );
              //update data user saat ini
              setUser((prev) => ({
                ...prev,
                head_doctor_id: head_doctor.doctor_id,
              }));
              head_doctor_id = head_doctor.doctor_id;
            }
          } else {
            setMustSelectHead(false);
            dispatch(changeTimelineGroupMode(data.user.timeline_group_mode));
          }

          /**
           * State jadwal
           */
          try {
            //get list jadwal
            let schedulesResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/vdoc/schedules`,
              {
                params: {
                  timezone: mediplusHelper.getTimezoneOffset(),
                  head: head_doctor_id,
                },
                headers: authHeader(),
              }
            );

            //cek apakah jadwal dokter tersedia
            if (schedulesResponse.data.length > 0) {
              //jika tersedia get jadwal dokter aktif jam dan tanggan saat ini
              const currentActiveScheduleResponse =
                await AuthService.currentSchedule(null, head_doctor_id);

              if (currentActiveScheduleResponse.schedule === null) {
                setScheduleError(true);
              } else {
                let now = moment();
                /**
                 * backend DoW = 0-6
                 * frontend Dow = 1-7
                 * jadi di convert dulu ya
                 */
                let nowDoW = mediplusHelper.convertIsoDayWeekToPHPSystem(
                  now.isoWeekday()
                );
                //cari tanggal aktif untuk jadwal saat ini
                let scheduleDate = "";
                if (nowDoW !== currentActiveScheduleResponse.schedule.hari) {
                  let add = 0;
                  let lday = nowDoW;
                  for (let i = 0; i <= 6; i++) {
                    if (lday === 6) {
                      if (0 === currentActiveScheduleResponse.schedule.hari) {
                        add++;
                        break;
                      } else {
                        add++;
                        lday = 0;
                      }
                    } else {
                      if (
                        lday + 1 ===
                        currentActiveScheduleResponse.schedule.hari
                      ) {
                        add++;
                        break;
                      } else {
                        add++;
                        lday++;
                      }
                    }
                  }
                  scheduleDate = now.add(add, "days").format("YYYY-MM-DD");
                } else {
                  scheduleDate = now.format("YYYY-MM-DD");
                }

                dispatch(
                  updateScheduleData({
                    current_active_schedule:
                      currentActiveScheduleResponse.schedule,
                    schedule_list: schedulesResponse.data,
                    scheduleDate: scheduleDate,
                  })
                );
              }
            } else {
              setScheduleError(true);
            }
          } catch (e) {
            console.log(e);
            //reset head doctor dan set schedule error
            db.head_doctor.clear();
            setScheduleError(true);
            Sentry.captureException(e);
          }
        }
      } catch (e) {
        console.log(e);
        //reset head doctor dan set schedule error
        db.head_doctor.clear();
        Sentry.captureException(e);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authUpdate === true) {
      fetchData();
    }
    setAuthUpdate(false);
  }, [authUpdate]);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        setAuthUpdate,
        scheduleError,
        mustSelectHead,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
