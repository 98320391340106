import mediplusHelper from "../../../../../utils/mediplusHelper";
import { webkit__scrollbar } from "../../../style";

const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "5px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "150%",
  display: "inline",
};

const riwayat_medis__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "5px",
  height: "30px",
  marginLeft: "10px",
};

const riwayat_medis__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

const tab__label = {
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "none",
  px: 0.5,
};

const footer__container = {
  marginTop: "5px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingLeft: "12px",
};

const submit__btn = {
  backgroundColor: "#FF0091",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  color: "white",
  "&:hover": {
    backgroundColor: "#FF0080",
  },
};

const submit__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
};

const tab__switch__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  color: "white",
  mx: 1,
};

const tab__switch__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
};

const form__control = {
  width: "100%",
  marginBottom: "10px",
};

const input__label = {
  color: "rgba(4, 22, 21, 1)",
  fontSize: "16px",
  fontWeight: 600,
  paddingLeft: "2px",
};

const input__text = {
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "&.MuiInputBase-root": {
    paddingLeft: "0px",
    paddingRight: "27px",
    width: "100%",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "12px",
    paddingRight: "12px",
    height: "16px",
    fontSize: 12,
    fontWeight: 700,
    borderRadius: "8px",
    marginRight: "30px",
    minWidth: "100%",
    ...webkit__scrollbar,
  },
};

const additional__style__ios__input = {
  display: "block",
  WebkitAppearance: "textfield",
  MozAppearance: "textfield",
  paddingTop: "7px",
  paddingBottom: "12px",
};

const input__date = {
  ...input__text,
  "& .MuiInputBase-input": {
    ...input__text["& .MuiInputBase-input"],
    ...(mediplusHelper.isIOS() ? additional__style__ios__input : {}),
  },
};

const input__text__date__disabled = {
  ...input__date,
  "& .MuiInputBase-input": {
    ...input__date["& .MuiInputBase-input"],
    "&::-webkit-calendar-picker-indicator": {
      display: "none",
    },
    "&::-webkit-input-placeholder": {
      visibility: "hidden !important",
    },
  },
};

const input__select = {
  borderRadius: "8px",
  backgroundColor: "rgba(239, 241, 249, 1)",
  border: "1px solid #ced4da",
  paddingLeft: "12px",
  py: 0.6,

  fontSize: "12px",
  fontWeight: 700,

  ":hover:not(.Mui-disabled):before": {
    border: "none",
  },
  ":hover": {
    border: "1px solid #ced4da",
  },
  ":before": {
    border: "none",
  },
  "label + &": {
    marginTop: "24px",
  },
};

const section__title = {
  fontWeight: 700,
  fontSize: "16px",
  marginBottom: "10px",
};

export {
  header__container,
  header__h1,
  riwayat_medis__btn,
  riwayat_medis__btn__label,
  tab__label,
  footer__container,
  submit__btn,
  submit__btn__label,
  tab__switch__btn,
  tab__switch__btn__label,
  form__control,
  input__label,
  input__select,
  input__text,
  section__title,
  input__text__date__disabled,
  input__date,
};
