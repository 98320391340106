const modal__container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 650,
  minWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  maxHeight: 500,
  overflowY: "auto",
  overflowX: "hidden",
  borderRadius: "8px",
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgb(255 0 145 / 0.1)",
    borderRadius: "10px",
    border: "1px solid rgb(255 0 145 / 0.2)",
  },
};

export { modal__container };
