import {
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function HasilPemeriksaanTablePopper({
  open,
  anchorEl,
  handlePopupAway,
  handleRedirectView,
  handleQrModalOpen,
  handleSendAntrianModalOpen,
  handleSendDataModalOpen,
}) {
  return (
    <ClickAwayListener onClickAway={handlePopupAway}>
      <Popper
        data-group="popper-element"
        open={open}
        anchorEl={anchorEl}
        placement={"left"}
        sx={{
          zIndex: 1500,
        }}
      >
        <Box>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleRedirectView}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/document-text.svg"
                      alt="send-invoice"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Detail Hasil"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleQrModalOpen}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img src="/assets/icons/qr.svg" alt="qr"></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="QR Code"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    py: "0px",
                  }}
                  onClick={handleSendAntrianModalOpen}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img
                      src="/assets/icons/profile-circle.svg"
                      alt="antrian"
                    ></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Pasien Antrian"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleSendDataModalOpen}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/send.svg"
                      alt="send-invoice"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Bagikan"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}
