import { webkit__scrollbar } from "../../../../../../style";

const record__container = {
  maxHeight: "270px",
  overflowX: "hidden",
  overflowY: "auto",
  ...webkit__scrollbar,
};

const record__box__header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "5px 5px 5px 15px",
  borderBottom: "1px solid rgba(212, 212, 212, 1)",
  marginBottom: "10px",
};

const record__box__header_text = {
  fontSize: "14px",
  fontWeight: 700,
  paddingTop: "15px",
};

const record__section__divider = {
  padding: "2px 5px",
  background: "#FFCFEA",
  color: "rgba(255, 0, 145, 1)",
  width: "fit-content",
  borderRadius: "5px",
  fontSize: "12px",
  marginBottom: "5px",
};

const record__text__label = {
  fontWeight: 600,
  fontSize: "12px",
  color: "rgba(125, 125, 125, 1)",
};

const record__text__value = {
  fontWeight: 600,
  fontSize: "14px",
  marginBottom: "12px",
  display: "inline-block",
  whiteSpace: "pre-wrap",
};

const record__box__media__container = {
  width: "100px",
  height: "100px",
  position: "relative",
  overflow: "hidden",
  borderRadius: "10px",
};

const record__box__img = {
  width: "100%",
  height: "100%",
  display: "block",
  objectFit: "cover",
};

const record__box__video = {
  width: "100%",
  height: "100%",
  display: "block",
  objectFit: "cover",
};

const record__box__video__icon = {
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const record__box__pdf = {
  position: "relative",
  width: "200px",
  height: "150px",
  background: "gray",
  overflow: "hidden",
  borderRadius: "10px",
};

const record__box__pdf__icon = {
  width: "60%",
  height: "60%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "50%",
};

export {
  record__container,
  record__box__header__container,
  record__box__header_text,
  record__text__label,
  record__text__value,
  record__section__divider,
  record__box__media__container,
  record__box__img,
  record__box__video,
  record__box__video__icon,
  record__box__pdf,
  record__box__pdf__icon,
};
