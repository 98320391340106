import {
  Button,
  IconButton,
  InputBase,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import {
  modal__container,
  modal__submit__btn,
  modal__submit__btn__label,
  template__btn,
  template__text__list__container,
} from "./style";
import PropTypes from "prop-types";
import { input__text } from "../style";
import { useLiveQuery } from "dexie-react-hooks";
import { useAuth } from "../../../../../../providers/AuthProvider";
import { db } from "../../../../../../db/db";
import {
  modal__title,
  modal__title__text,
} from "../../../../components/modal/send-data-modal/style";
import {
  snackbar__content__container,
  snackbar__content__text,
} from "../../main/table/style";

function TemplateTextModal({ table, open, onClose, onSubmit }) {
  const { user } = useAuth();
  const [input, setInput] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [deleteConfirmationModalOpen, setDeleteConfirmationOpen] =
    useState(false);
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const data_template = useLiveQuery(() => {
    return db[table].where("doctor_id").equals(user.id).toArray();
  });

  const handleSubmit = async (e) => {
    if (selectedTemplate) {
      await db[table].where("id").equals(selectedTemplate.id).modify({
        value: input,
      });
      setInput("");
      setSelectedTemplate(null);
      return;
    }
    if (typeof onSubmit === "function") {
      onSubmit(input, e);
      setInput("");
    }
  };

  const handleDeleteConfirmationOpen = (id) => {
    setSelectedTemplateId(id);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setSelectedTemplateId(null);
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteTemplate = async () => {
    await db[table].where("id").equals(selectedTemplateId).delete();
    setSelectedTemplateId(null);
    setDeleteConfirmationOpen(false);
    setInput("");
    setSelectedTemplate(null);
  };

  const handleEditTemplate = (template) => {
    setSelectedTemplate(template);
    setInput(template.value);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationModalOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={handleDeleteTemplate}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={handleDeleteConfirmationClose}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />
      <Modal
        open={open}
        onClose={() => {
          if (typeof onClose === "function") {
            onClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal__container}>
          <Box sx={modal__title}>
            <Typography sx={modal__title__text}>Tambahkan Template</Typography>
            <IconButton
              onClick={() => {
                if (typeof onClose === "function") {
                  onClose();
                }
              }}
              color="inherit"
              aria-label="refresh"
              edge="start"
              type="button"
            >
              <img
                width={25}
                height={25}
                effect="blur"
                src="/assets/icons/close.svg"
                alt="refresh"
              ></img>
            </IconButton>
          </Box>
          <Box
            id="modal-modal-description"
            sx={{
              p: 1,
              pt: 0,
            }}
          >
            <InputBase
              value={input}
              onChange={handleInputChange}
              multiline
              maxRows={3}
              minRows={3}
              placeholder="masukkan template"
              type="text"
              sx={{ ...input__text, mb: 1 }}
            />
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <IconButton
                onClick={handleSubmit}
                aria-label="search-user"
                sx={modal__submit__btn}
              >
                {!selectedTemplate && (
                  <img src="/assets/icons/plus.svg" alt="create"></img>
                )}

                <Typography sx={modal__submit__btn__label}>
                  {selectedTemplate ? "Edit" : "Tambahkan"}
                </Typography>
              </IconButton>
              {selectedTemplate && (
                <IconButton
                  onClick={handleSubmit}
                  aria-label="search-user"
                  sx={{
                    ...modal__submit__btn,
                    background: "rgba(255, 222, 222, 1)",
                  }}
                >
                  <Typography
                    sx={{
                      ...modal__submit__btn__label,
                      color: "rgba(255, 0, 0, 1)",
                    }}
                  >
                    batal
                  </Typography>
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                background: "rgba(239, 241, 249, 1)",
                p: 1,
                border: "1px solid #ced4da",
                borderRadius: "8px",
              }}
            >
              <Box sx={template__text__list__container}>
                {data_template &&
                  data_template?.map((data, idx) => {
                    return (
                      <Box
                        key={idx}
                        onClick={() => {
                          handleEditTemplate(data);
                        }}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          alignContent: "center",
                          alignItems: "center",
                          background:
                            selectedTemplate?.id === data.id
                              ? "#FF0091"
                              : "#fff",

                          borderRadius: "5px",
                          p: 0.5,
                          marginBottom: "10px",
                          height: "fit-content",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "12px",
                            marginRight: "10px",
                            whiteSpace: "pre-wrap",
                            color:
                              selectedTemplate?.id === data.id
                                ? "#fff"
                                : "#000",
                          }}
                        >
                          {data.value}
                        </Typography>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteConfirmationOpen(data.id);
                          }}
                          aria-label="delete-submenu"
                          sx={{
                            background: "#00AEEF",
                            borderRadius: "8px",
                            width: "20px",
                            height: "20px",
                            ":hover": {
                              background: "#00AEFF",
                            },
                          }}
                        >
                          <img src="/assets/icons/cross.svg" alt="delete"></img>
                        </IconButton>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

TemplateTextModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default TemplateTextModal;
