import { webkit__scrollbar } from "../../../style";

const snackbar__content__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const snackbar__content__text = {
  paddingTop: "5px",
  marginRight: "20px",
};

const queue__table__content__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "5px 15px 5px 15px",
};

const queue__table__title = {
  fontSize: "16px",
  fontWeight: 700,
  paddingTop: "7px",
};

const queue__table__container = {
  maxHeight: 330,
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  ...webkit__scrollbar,
};

const queue__user__img = {
  width: "44px",
  height: "44px",
  borderRadius: "50%",
};

const queue__status__pills = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
  padding: "5px 16px",
  gap: "10px",
  position: "relative",
  height: "30px",
  borderRadius: "32px",
  background: "#FFF3C9",
  color: "#F1BC00",
};

const queue__status__pills__label = {
  display: "inline-block",
  width: "100%",
  fontSize: "12px",
  fontWeight: 400,
};

const table__cell__text = {
  fontSize: "16px",
  fontWeight: 600,
};

export {
  snackbar__content__container,
  snackbar__content__text,
  queue__table__content__wrapper,
  queue__table__title,
  queue__table__container,
  queue__user__img,
  queue__status__pills,
  queue__status__pills__label,
  table__cell__text,
};
