import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import moment from "moment";
import {
  avatar__container,
  box,
  box__wrapper,
  container,
  date__text,
} from "./style";

export default function DateSeparator({ date = null }) {
  return (
    <>
      <Box sx={container}>
        <Box sx={avatar__container}></Box>
        <Box sx={box__wrapper}>
          <Box sx={box}>
            <Typography sx={date__text}>
              {date
                ? moment(date).format("DD/MM/YYYY") ===
                  moment().format("DD/MM/YYYY")
                  ? "Hari ini"
                  : moment(date).format("DD/MM/YYYY")
                : moment().format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
        <Box sx={avatar__container}></Box>
      </Box>
    </>
  );
}
