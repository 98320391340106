import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../../providers/AuthProvider";
import PropTypes from "prop-types";

function SettingPopper({ open, anchorEl }) {
  const { user } = useAuth();
  const history = useHistory();
  return (
    <Popper
      data-group="popper-element"
      open={open}
      anchorEl={anchorEl}
      placement={"bottom-end"}
      sx={{
        zIndex: 1500,
      }}
    >
      <Box>
        <Paper
          elevation={1}
          sx={{
            borderRadius: "10px",
          }}
        >
          {user.type === "ASSISTANT" ? (
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    history.push("/head-doctors");
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemText primary="Ganti Dokter" />
                </ListItemButton>
              </ListItem>
            </List>
          ) : (
            <></>
          )}
        </Paper>
      </Box>
    </Popper>
  );
}

SettingPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
};

export default SettingPopper;
