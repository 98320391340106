import React, { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  broadcast__btn,
  broadcast__btn__label,
  header__container,
  header__h1,
} from "./style";
import Conversations from "./conversations/Conversations";
import Messages from "./messages/Messages";
import Timeline from "./timeline/Timeline";
import BroadcastModal from "./broadcast-modal/BroadcastModal";

export default function Chat() {
  const [broadcastModalOpen, setBroadcastModalOpen] = useState(false);

  return (
    <>
      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Chat
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            onClick={() => {
              setBroadcastModalOpen(true);
            }}
            aria-label="broadcast"
            sx={broadcast__btn}
          >
            <img src="/assets/icons/broadcast.svg" alt="broadcast"></img>
            <Typography sx={broadcast__btn__label}>Broadcast</Typography>
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} sm={4}>
          <Conversations />
        </Grid>
        <Grid item lg={6} md={8} sm={8}>
          <Messages />
        </Grid>
        <Grid
          item
          lg={3}
          md={0}
          sm={0}
          display={{ lg: "block", md: "none", sm: "none" }}
        >
          <Timeline />
        </Grid>
      </Grid>
      <BroadcastModal
        open={broadcastModalOpen}
        onClose={() => setBroadcastModalOpen(false)}
      />
    </>
  );
}
