const input__text = {
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    fontSize: "16px",
    width: "100%",
    padding: "10px 12px",
    "&:focus": {
      borderColor: "#1976d2",
    },
  },
};

const webkit__scrollbar = {
  "&::-webkit-scrollbar": {
    width: "6px",
    height: "6px",
    backgroundColor: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgb(255 0 145 / 0.1)",
    borderRadius: "10px",
    border: "1px solid rgb(255 0 145 / 0.2)",
  },
};

const content__wrapper = {
  marginTop: "64px",
  display: "flex",
  flexDirection: "column",
  backgroundImage: "url(/assets/bg-dashboard.png)",
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  maxHeight: "calc(100vh - 64px)",
  minHeight: "calc(100vh - 64px)",
  overflow: "hidden",
  minWidth: `calc(100% - ${240 + 1}px)`,
};

const content__container = {
  flexGrow: 1,
  px: 3,
  py: 2,
  overflowY: "auto",
  overflowX: "hidden",
  ...webkit__scrollbar,
};

const title__h1 = {
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
  marginBottom: "20px",
};

export {
  input__text,
  content__wrapper,
  content__container,
  webkit__scrollbar,
  title__h1,
};
