const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "15px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

const create__obat__btn = {
  backgroundColor: "blue",
  height: "38px",
  color: "white",
  borderRadius: "8px",
  marginLeft: "10px",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const create__obat__btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "10px",
};

export {
  header__container,
  header__h1,
  create__obat__btn,
  create__obat__btn__label,
};
