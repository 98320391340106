import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Typography, ClickAwayListener } from "@mui/material";
import { Box } from "@mui/system";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/id";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import { updateQueue } from "../../../../../redux/slices/queueUpdate";
import TabLoading from "../tab-loading/TabLoading";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import {
  invoice__status__pills,
  invoice__status__pills__label,
  invoice__table__container,
  invoice__table__content__wrapper,
  invoice__table__title,
  invoice__user__img,
} from "./style";
import TabInvoiceTablePopper from "./TabInvoiceTablePopper";
import PopperRefresh from "../popper-refresh/PopperRefresh";
import * as Sentry from "@sentry/react";

export default function TabInvoice(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useAuth();
  /**
   * === STATE ===
   */

  //table
  const { search } = props;

  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [hasMoreData, setHasMoreData] = React.useState(false);
  const [nextUrl, setNextUrl] = React.useState(null);

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const queue_update = useSelector((state) => state.queue_update.value);
  const schedule_date = useSelector((state) => state.schedule.scheduleDate);

  //popper
  const [refreshButtonEl, setRefreshButtonEl] = React.useState(null);
  const [popupRefreshOpen, setPopupRefreshOpen] = React.useState(false);
  const [popperInvoiceEl, setPopperInvoiceEl] = React.useState(null);
  const [popupInvoiceOpen, setPopupInvoiceOpen] = React.useState(false);
  const [selectedInvoice, setSelectedInvoice] = React.useState({
    id: null,
  });

  /**
   * === HANDLER ===
   */

  //popup
  const handlePopupRefreshOpen = (event) => {
    setRefreshButtonEl(event.currentTarget);
    setPopupRefreshOpen(!popupRefreshOpen);
  };

  const handlePopupRefreshAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      setPopupRefreshOpen(false);
    }
  };

  const handlePopupInvoiceOpen = (event) => {
    setPopperInvoiceEl(event.currentTarget);
    let invoiceId = parseInt(
      event.currentTarget.getAttribute("data-invoice-id")
    );
    let invoice = rows.find((item) => {
      return item.id === invoiceId;
    });
    if (invoice !== undefined) {
      setSelectedInvoice({
        id: invoice.id,
      });
    }
    if (event.currentTarget === popperInvoiceEl) {
      setPopupInvoiceOpen(!popupInvoiceOpen);
    } else {
      setPopupInvoiceOpen(true);
    }
  };

  const handlePopupInvoiceAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupInvoiceOpen) setPopupInvoiceOpen(false);
    }
  };

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices`,
        {
          params: {
            clinic: current_active_schedule.clinic_id,
            pagination: 30,
            "start-date": moment(schedule_date).format("YYYY-MM-DD"),
            "end-date": moment(schedule_date).format("YYYY-MM-DD"),
            search: search,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (url) {
          setRows((prev) => [...prev, ...response.data.data]);
        } else {
          setRows((prev) => [...response.data.data]);
        }
        if (response.data.next_page_url !== null) {
          setHasMoreData(true);
          setNextUrl(response.data.next_page_url);
        } else {
          setHasMoreData(false);
          setNextUrl(null);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        Sentry.captureException(error);
      });
  };

  const handleRefresh = () => {
    dispatch(updateQueue());
    setPopupRefreshOpen(false);
    setLoading(true);
  };

  const handleRedirectEdit = (id) => {
    history.push(`/pembayaran/editor/${id}`);
  };

  React.useEffect(() => {
    setTimeout(() => {
      loadData(null);
    }, 750);
    const onFocus = () => {
      dispatch(updateQueue());
    };
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
      setPopupInvoiceOpen(false);
      setPopperInvoiceEl(null);
    };
  }, [current_active_schedule, queue_update, schedule_date, search]);

  if (loading) {
    return (
      <>
        <TabLoading
          title={
            "Pembayaran " +
            (moment(schedule_date).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
              ? "Hari Ini"
              : moment(schedule_date).locale("id").format("DD MMMM YYYY"))
          }
        />
      </>
    );
  }

  return (
    <Paper elevation={0}>
      <Box sx={invoice__table__content__wrapper}>
        <Typography sx={invoice__table__title}>
          Pembayaran{" "}
          {moment(schedule_date).format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD")
            ? "Hari Ini"
            : moment(schedule_date).locale("id").format("DD MMMM YYYY")}
        </Typography>
        <ClickAwayListener onClickAway={handlePopupRefreshAway}>
          <IconButton
            onClick={handlePopupRefreshOpen}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img src="/assets/icons/vertical-options.svg" alt="refresh"></img>
          </IconButton>
        </ClickAwayListener>
      </Box>

      {rows.length < 1 && (
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography>Tidak ada data pembayaran</Typography>
        </Paper>
      )}

      {rows.length > 0 && (
        <TableContainer
          id="queueTable"
          component={Paper}
          elevation={0}
          sx={invoice__table__container}
        >
          <InfiniteScroll
            dataLength={rows.length}
            next={() => {
              loadData(nextUrl);
            }}
            hasMore={hasMoreData}
            scrollableTarget="queueTable"
          >
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={() => {
                      handleRedirectEdit(row.id);
                    }}
                  >
                    <TableCell width="3%">
                      <LazyLoadImage
                        effect="blur"
                        src={
                          row.user?.photo_profile ?? "/assets/icons/avatar.svg"
                        }
                        alt="profile"
                        style={invoice__user__img}
                      ></LazyLoadImage>
                    </TableCell>
                    <TableCell width="30%" align="left">
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        {row.nama_pasien}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        {moment(row.created_at)
                          .locale("id")
                          .format("DD MMMM YYYY") +
                          " | " +
                          moment(row.created_at).format("HH:mm")}
                      </Typography>
                    </TableCell>
                    <TableCell width="20%">
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        {mediplusHelper.formatRupiah(row.harga_akhir)}
                      </Typography>
                    </TableCell>
                    <TableCell width="20%">
                      <Box
                        sx={{
                          ...invoice__status__pills,
                          background: row.user ? "#C0ECFD" : "#FFDEDE",
                          color: row.user ? "#0492C7" : "#FF0000",
                        }}
                      >
                        <Typography sx={invoice__status__pills__label}>
                          {row.user ? "Selesai" : "Belum proses"}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      width="10%"
                      align="right"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ClickAwayListener
                        onClickAway={(e) => {
                          e.stopPropagation();
                          handlePopupInvoiceAway(e);
                        }}
                      >
                        <IconButton
                          data-group="popper-toggler"
                          data-invoice-id={row.id}
                          color="inherit"
                          aria-label="refresh"
                          edge="start"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePopupInvoiceOpen(e);
                          }}
                        >
                          <LazyLoadImage
                            effect="blur"
                            src="/assets/icons/table-action.svg"
                            alt="refresh"
                          ></LazyLoadImage>
                        </IconButton>
                      </ClickAwayListener>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      )}
      <PopperRefresh
        open={popupRefreshOpen}
        anchorEl={refreshButtonEl}
        onClick={handleRefresh}
      />
      <TabInvoiceTablePopper
        handlePopupInvoiceAway={handlePopupInvoiceAway}
        open={popupInvoiceOpen}
        anchorEl={popperInvoiceEl}
        selectedInvoice={selectedInvoice}
      />
    </Paper>
  );
}
