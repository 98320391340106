import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Route } from "react-router-dom";
import SidebarIndicator from "../sidebar-indicator/SidebarIndicator";
import {
  sidebar__item__button___sidebar_close,
  sidebar__item__button___sidebar_open,
  sidebar__item__icon___sidebar_close,
  sidebar__item__icon___sidebar_open,
  sidebar__item__selected,
  sidebar__item__text___sidebar_close,
  sidebar__item__text___sidebar_open,
  sidebar__item__unselected,
} from "./style";
import PropTypes from "prop-types";

function SidebarListItem({
  name,
  path,
  isExactPath,
  icon,
  isSidebarOpen,
  first,
}) {
  return (
    <List
      sx={{
        py: "0px",
      }}
    >
      <Route
        path={path}
        exact={isExactPath ? true : false}
        children={({ match, history }) => {
          return (
            <ListItem
              disablePadding
              sx={match ? sidebar__item__selected : sidebar__item__unselected}
            >
              <ListItemButton
                sx={
                  isSidebarOpen
                    ? sidebar__item__button___sidebar_open
                    : sidebar__item__button___sidebar_close
                }
                onClick={() => {
                  history.push(path);
                }}
              >
                <ListItemIcon
                  sx={
                    isSidebarOpen
                      ? sidebar__item__icon___sidebar_open
                      : sidebar__item__icon___sidebar_close
                  }
                >
                  <img src={icon} alt={name} width="20" height="20"></img>
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  sx={
                    isSidebarOpen
                      ? sidebar__item__text___sidebar_open
                      : sidebar__item__text___sidebar_close
                  }
                />
                {match && <SidebarIndicator />}
              </ListItemButton>
            </ListItem>
          );
        }}
      />
    </List>
  );
}

SidebarListItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  isExactPath: PropTypes.bool,
};

export default SidebarListItem;
