import { webkit__scrollbar } from "../../../../../../style";

const timeline__list__container = {
  padding: "5px 15px 5px 15px",
  position: "relative",
  overflowY: "auto",
  ...webkit__scrollbar,
};

const timeline__container = {
  position: "relative",
  borderRadius: 0,
  cursor: "pointer",
  borderLeft: "2px dashed rgba(255, 0, 145, 1)",
};

const timeline__text__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  borderRadius: "8px",
  position: "relative",
};

const invoice__price = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 700,
  color: "blue",
  marginRight: "10px",
};

const dot__img = {
  position: "absolute",
  top: "0px",
  left: "-9px",
};

const text__date = {
  fontWeight: 600,
  fontSize: "14px",
  marginBottom: "12px",
  display: "inline-block",
  whiteSpace: "pre-wrap",
  marginBottom: "2px",
};

const text__subjective = {
  fontWeight: 600,
  fontSize: "12px",
  color: "rgba(125, 125, 125, 1)",
};

export {
  timeline__list__container,
  timeline__container,
  invoice__price,
  dot__img,
  text__date,
  text__subjective,
  timeline__text__wrapper,
};
