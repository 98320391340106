const forgot__text__h1 = {
  fontSize: "32px",
  fontWeight: "700",
};

const forgot__text__h2 = {
  marginBottom: "14px",
};

const forgot__btn__submit = {
  backgroundColor: "blue",
  color: "white",
  textTransform: "none",
  width: "100%",
  marginBottom: "200px",
};

const forgot__text__h1__success = {
  fontSize: "32px",
  fontWeight: "700",
  marginBottom: "15px",
};

const forgot__text__h2__success = {
  marginBottom: "20px",
};

const forgot__btn__success = {
  backgroundColor: "blue",
  color: "white",
  textTransform: "none",
  width: "100%",
  marginBottom: "140px",
};

export {
  forgot__text__h1,
  forgot__text__h2,
  forgot__btn__submit,
  forgot__text__h1__success,
  forgot__text__h2__success,
  forgot__btn__success,
};
