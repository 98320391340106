import { Modal } from "@mui/material";
import { Box, height } from "@mui/system";
import React from "react";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import { media__modal } from "./style";
import PropTypes from "prop-types";

function MediaModal({ media, onClose }) {
  return (
    <Modal
      open={Boolean(media)}
      onClose={() => {
        if (typeof onClose === "function") {
          onClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...media__modal }}>
        <Box
          id="modal-modal-description"
          sx={{
            overflow: "hidden",
          }}
        >
          {media && mediplusHelper.isImageMime(media.mime_type) && (
            <>
              <img
                style={{
                  maxWidth: "500px",
                  maxHeight: "98vh",
                  objectFit: "contain",
                }}
                src={media.original_path ?? media.s3_url}
                alt="media"
              ></img>
            </>
          )}
          {media && mediplusHelper.isVideoMime(media.mime_type) && (
            <>
              <video width="100%" height="100%" controls>
                <source src={media.s3_url + "#t=0.1"} type="video/mp4"></source>
              </video>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

MediaModal.propTypes = {
  media: PropTypes.object,
  onClose: PropTypes.func,
};

export default MediaModal;
