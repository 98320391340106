import {
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  user__detail__center__birth,
  user__detail__center__container,
  user__detail__center__img,
  user__detail__center__name,
  user__detail__container,
  user__detail__label,
  user__detail__value,
} from "./style";
import axios from "axios";
import { useEffect } from "react";
import authHeader from "../../../../../../../services/authHeader";
import { useSelector } from "react-redux";
import TimelineList from "./timeline-list/TimelineList";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import mediplusHelper from "../../../../../../../utils/mediplusHelper";
import QRModal from "./modal/QRModal";
import { useAuth } from "../../../../../../../providers/AuthProvider";

export default function UserDetail({
  currentSelectedUser,
  nextStepOpen,
  handleBack,
  onTimelineItemClick,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { user } = useAuth();
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const timeline_group_mode = useSelector((state) => state.timeline_group_mode);

  const [userDetail, setUserDetail] = useState({});
  const [selectedTimelineItemId, setSelectedTimelineItemId] = useState(null);

  const [qrModalOpen, setQrModalOpen] = useState(false);

  //popper
  const [popperEl, setPopperEl] = React.useState(null);
  const [popupOpen, setPopupOpen] = React.useState(false);

  const handleqrModalOpen = () => {
    setPopupOpen(false);
    setQrModalOpen(true);
  };

  const handleqrModalClose = () => {
    setQrModalOpen(false);
  };

  const handlePopupOpen = (event) => {
    setPopperEl(event.currentTarget);
    if (event.currentTarget === popperEl) {
      setPopupOpen(!popupOpen);
    } else {
      setPopupOpen(true);
    }
  };

  const handlePopupAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupOpen) setPopupOpen(false);
    }
  };

  const loadUserDetail = (usr) => {
    setUserDetail({});
    let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/timeline/${usr.user_id}`;
    if (usr.type === "UNLINKED") {
      url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/unlinked-timeline/${usr.medical_record_id}`;
    }
    axios
      .get(url, {
        params: {
          head: user.head_doctor_id,
          clinic: current_active_schedule.clinic_id,
          "timeline-group-mode": timeline_group_mode,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setUserDetail({
          ...response.data,
        });
      })
      .catch(function (error) {
        enqueueSnackbar(error.message);
        console.log(error);
        Sentry.captureException(error);
      });
  };

  const handleTimelineItemClick = (medicalRecordId) => {
    setSelectedTimelineItemId(medicalRecordId);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medical-records/${medicalRecordId}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (typeof onTimelineItemClick === "function") {
          onTimelineItemClick(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (currentSelectedUser) {
      loadUserDetail(currentSelectedUser);
    } else {
      setUserDetail({});
    }
    return () => {
      setUserDetail({});
    };
  }, [currentSelectedUser]);

  const handleRedirect = () => {
    if (currentSelectedUser?.user_id) {
      history.push(`/rekam-medis/editor?ref=${currentSelectedUser.user_id}`);
    } else {
      history.push(
        `/rekam-medis/editor?ref=${currentSelectedUser.medical_record_id}&type=MED`
      );
    }
  };

  return (
    <>
      <QRModal
        open={qrModalOpen}
        onClose={handleqrModalClose}
        data={currentSelectedUser}
      />
      <ClickAwayListener onClickAway={handlePopupAway}>
        <Popper
          data-group="popper-element"
          open={popupOpen}
          anchorEl={popperEl}
          placement={"left"}
          sx={{
            zIndex: 1500,
          }}
        >
          <Box>
            <Paper
              elevation={1}
              sx={{
                borderRadius: "10px",
              }}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={handleRedirect}
                    sx={{
                      py: "0px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      <LazyLoadImage
                        effect="blur"
                        src="/assets/icons/pencil.svg"
                        alt="edit-invoice"
                      ></LazyLoadImage>
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        paddingTop: "2px",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                      primary="Rekam Medis Baru"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={handleqrModalOpen}
                    sx={{
                      py: "0px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      <LazyLoadImage
                        effect="blur"
                        src="/assets/icons/qr.svg"
                        alt="edit-invoice"
                      ></LazyLoadImage>
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        paddingTop: "2px",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                      primary="Bagikan Timeline Ke User"
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Box>
        </Popper>
      </ClickAwayListener>

      <Grid
        container
        spacing={0}
        sx={{
          background: "#fff",
        }}
      >
        <Grid item xs={4}>
          <Paper
            elevation={0}
            sx={{
              borderRight: "1px solid rgba(212, 212, 212, 1)",
            }}
          >
            <Box sx={user__detail__center__container}>
              <img
                src={
                  userDetail?.user?.photo_profile ?? "/assets/icons/avatar.svg"
                }
                alt="user"
                style={user__detail__center__img}
              ></img>
              <Typography sx={user__detail__center__name}>
                {userDetail?.user?.nama ?? "-"}
              </Typography>
              <Typography sx={user__detail__center__birth}>
                {userDetail?.user?.tanggal_lahir
                  ? moment().diff(userDetail.user.tanggal_lahir, "years") +
                    " Tahun"
                  : "- Tahun"}
              </Typography>
            </Box>
            <Box sx={user__detail__container}>
              <Typography sx={user__detail__label}>Tanggal Lahir</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.user?.tanggal_lahir
                  ? moment(userDetail?.user?.tanggal_lahir).format("DD/MM/YYYY")
                  : "-"}
              </Typography>

              <Typography sx={user__detail__label}>Alamat</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.user?.alamat ?? "-"}
              </Typography>

              <Typography sx={user__detail__label}>Nomor HP</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.user?.nomor ?? "-"}
              </Typography>

              <Typography sx={user__detail__label}>Jenis Kelamin</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.user?.jenis_kelamin
                  ? userDetail?.user?.jenis_kelamin === "PRIA"
                    ? "Laki-laki"
                    : "Perempuan"
                  : "-"}
              </Typography>

              <Typography sx={user__detail__label}>Agama</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.general_data?.agama ?? "-"}
              </Typography>

              <Typography sx={user__detail__label}>Pekerjaan</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.general_data?.perkerjaan ?? "-"}
              </Typography>

              <Typography sx={user__detail__label}>Pendidikan</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.general_data?.pendidikan ?? "-"}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              borderBottom: "1px solid rgba(212, 212, 212, 1)",
              backgroundColor: "#fff",
            }}
          >
            <Paper elevation={0}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "5px 15px 5px 15px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    paddingTop: "7px",
                  }}
                >
                  Informasi Pasien
                </Typography>
                <ClickAwayListener onClickAway={handlePopupAway}>
                  <IconButton
                    data-group="popper-toggler"
                    onClick={handlePopupOpen}
                    aria-label="refresh"
                    edge="start"
                    type="button"
                  >
                    <img
                      src="/assets/icons/vertical-options.svg"
                      alt="opt"
                    ></img>
                  </IconButton>
                </ClickAwayListener>
              </Box>
            </Paper>
            <Box
              sx={{
                padding: "5px 15px 5px 15px",
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography sx={user__detail__label}>
                    Nomor Rekam Medis
                  </Typography>

                  <Typography sx={user__detail__value}>
                    {userDetail?.general_data?.nomor_rekam_medis ?? "-"}
                  </Typography>

                  <Typography sx={user__detail__label}>
                    Kartu Identitas
                  </Typography>
                  <Typography sx={user__detail__value}>
                    {userDetail?.general_data?.ktp ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={user__detail__label}>
                    Nama Keluarga
                  </Typography>
                  <Typography sx={user__detail__value}>
                    {userDetail?.general_data?.nama_keluarga ?? "-"}
                  </Typography>

                  <Typography sx={user__detail__label}>
                    No HP Keluarga
                  </Typography>
                  <Typography sx={user__detail__value}>
                    {userDetail?.general_data?.nomor_keluarga ?? "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <Paper elevation={0}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "5px 15px 5px 15px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    paddingTop: "7px",
                  }}
                >
                  Timelines
                </Typography>
              </Box>
              <TimelineList
                timelines={userDetail?.timeline ?? null}
                onClick={handleTimelineItemClick}
                currentSelectedTimelineId={selectedTimelineItemId}
              />
            </Paper>
          </Box>
        </Grid>
        <Button
          disabled={nextStepOpen ? false : true}
          onClick={handleBack}
          variant="outlined"
          sx={{
            display: nextStepOpen ? "flex" : "none",
            color: "blue",
            textTransform: "none",
            marginLeft: nextStepOpen ? "0px" : "15px",
            marginTop: nextStepOpen ? "5px" : "0px",
          }}
        >
          <img src="/assets/icons/arrow-left.svg" alt="kembali"></img>
          <Typography
            sx={{
              marginLeft: "5px",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            Kembali
          </Typography>
        </Button>
      </Grid>
    </>
  );
}
