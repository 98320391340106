const user__img = {
  display: "block",
  marginBottom: "10px",
  borderRadius: "50%",
  width: "70px",
  height: "70px",
};


export { user__img };
