import {
  Button,
  IconButton,
  InputBase,
  Modal,
  Pagination,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../providers/AuthProvider";
import {
  modal,
  search__input,
  search__input__btn,
  search__input__container,
  table__cell__text,
  table__contaner,
  table__title,
  table__wrapper,
  folder__info__container,
  folder__info__text,
  action__btn,
  action__btn__label,
} from "./style";
import TheadCell from "../../../components/table/thead-cell/TheadCell";
import PropTypes from "prop-types";
import authHeader from "../../../../../services/authHeader";
import * as Sentry from "@sentry/react";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import PreviewModal from "./PreviewModal";
import {
  snackbar__content__container,
  snackbar__content__text,
} from "../main/table/style";

function LinkToScanFolderModal({ open, onChoose, onClose, medicalRecordId }) {
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortType, setSortType] = useState("DESC");
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const { enqueueSnackbar } = useSnackbar();
  const [selectedScanFolder, setSelectedScanFolder] = React.useState(null);

  const [search, setSearch] = useState("");
  const [lastPage, setLastPage] = React.useState(1);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isLinkConfirmationOpen, setIsLinkConfirmationOpen] = useState(false);

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const loadData = (url = null) => {
    setLoading(true);
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/scan-folders`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: 3,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
        setLoading(false);
      });
  };

  const handlePreviewOpen = (scanFolder) => {
    setSelectedScanFolder(scanFolder);
    setIsPreviewModalOpen(true);
  };

  const handleLinkConfirmationOpen = (scanFolder) => {
    setSelectedScanFolder(scanFolder);
    setIsLinkConfirmationOpen(true);
  };

  const link = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/scan-folders/force-link-to-medical-record`,
        {
          medical_record_id: medicalRecordId,
          scan_folder_id: selectedScanFolder?.id,
        },
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        enqueueSnackbar("sukses mentautkan hasil pemeriksaan ke rekam medis", {
          variant: "success",
        });
        if (typeof onChoose === "function") {
          onChoose();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (open === true) {
      loadData();
    }
  }, [open, sortBy, sortType, search, page]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isLinkConfirmationOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>
                Apakah anda ingin menautkan ke hasil pemeriksaan{" "}
                {selectedScanFolder?.created_at
                  ? moment(selectedScanFolder?.created_at).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : ""}{" "}
                - {selectedScanFolder?.name ?? "-"}
              </Typography>
              <Box>
                <Button
                  onClick={link}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setIsLinkConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />
      <PreviewModal
        open={isPreviewModalOpen}
        onClose={() => {
          setIsPreviewModalOpen(false);
        }}
        scanFolder={selectedScanFolder}
      />
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <Box id="modal-modal-description">
            <Box sx={table__wrapper}>
              <Typography sx={table__title}>Pilih Hasil Pemeriksaan</Typography>
              <IconButton
                onClick={handleModalClose}
                color="inherit"
                aria-label="refresh"
                edge="start"
                type="button"
              >
                <img
                  width={25}
                  height={25}
                  effect="blur"
                  src="/assets/icons/close.svg"
                  alt="refresh"
                ></img>
              </IconButton>
            </Box>
            <Box sx={search__input__container}>
              <InputBase
                size="18"
                placeholder="Cari"
                value={search}
                onChange={handleSearchInputChange}
                sx={search__input}
              ></InputBase>
              <IconButton aria-label="search" sx={search__input__btn}>
                <img src="/assets/icons/search.svg" alt="search"></img>
              </IconButton>
            </Box>
            <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={table__contaner}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TheadCell
                        name="Nama"
                        imageColumn={false}
                        column_name="name"
                        useSort={true}
                        currentSortColumnName={sortBy}
                        currentSortType={sortType}
                        onClick={handleSortChange}
                      />
                      <TheadCell
                        name="Ruang"
                        imageColumn={false}
                        useSort={false}
                      />
                      <TheadCell
                        name="Tanggal"
                        column_name="created_at"
                        useSort={true}
                        currentSortColumnName={sortBy}
                        currentSortType={sortType}
                        onClick={handleSortChange}
                      />
                      <TheadCell name="Action" useSort={false} />
                    </TableRow>
                  </TableHead>
                  {rows.length > 0 && (
                    <TableBody>
                      {rows.map((row, idx) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={idx}
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {}}
                        >
                          <TableCell align={"left"}>
                            <Paper sx={folder__info__container}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingRight: "10px",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  data-user-id={1}
                                  component={"div"}
                                  sx={folder__info__text}
                                >
                                  {row.name ?? "-"}
                                </Typography>

                                {row.medical_record?.user?.nama ? (
                                  <Typography
                                    data-user-id={1}
                                    component={"div"}
                                    sx={{
                                      ...folder__info__text,
                                      fontSize: "12px",
                                      paddingY: "0px",
                                    }}
                                  >
                                    Diterima :{" "}
                                    {mediplusHelper.cropText(
                                      row.medical_record?.user?.nama,
                                      18,
                                      true
                                    )}
                                  </Typography>
                                ) : (
                                  <>
                                    {row.status_kirim === "FAILED" ||
                                      (row.status_kirim === "SENT" && (
                                        <Typography
                                          data-user-id={1}
                                          component={"div"}
                                          sx={{
                                            ...folder__info__text,
                                            fontSize: "12px",
                                            paddingY: "0px",
                                          }}
                                        >
                                          {row.detail_status?.replace(
                                            "Pengiriman Gagal, ",
                                            ""
                                          )}
                                        </Typography>
                                      ))}
                                  </>
                                )}
                              </Box>
                              {row.status_kirim === "PENDING" &&
                              Boolean(row.already_sent) === false ? (
                                <></>
                              ) : (
                                <>
                                  <Typography
                                    data-user-id={1}
                                    component={"div"}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      textAlign: "center",
                                      padding: "5px 16px",
                                      gap: "10px",
                                      position: "relative",
                                      height: "20px",
                                      width: "80px",
                                      borderRadius: "8px",
                                      color:
                                        row.status_kirim === "FAILED"
                                          ? "rgba(255, 0, 0, 1)"
                                          : "rgba(0, 160, 73, 1)",
                                      background:
                                        row.status_kirim === "FAILED"
                                          ? "rgba(255, 222, 222, 1)"
                                          : "rgba(211, 255, 231, 1)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {row.status_kirim === "READ"
                                      ? "Dibuka"
                                      : row.status_kirim === "SENT"
                                      ? "Terkirim"
                                      : row.status_kirim === "FAILED"
                                      ? "Gagal"
                                      : "Dikirim"}
                                  </Typography>
                                </>
                              )}
                            </Paper>
                          </TableCell>
                          <TableCell
                            align={"left"}
                            sx={table__cell__text}
                            width="15%"
                          >
                            {row.hardware?.clinic_room?.nama ?? ""}
                          </TableCell>
                          <TableCell
                            align={"left"}
                            sx={table__cell__text}
                            width="18%"
                          >
                            {moment(row.created_at).format("DD/MM/YYYY HH:mm")}
                          </TableCell>

                          <TableCell
                            align={"center"}
                            sx={table__cell__text}
                            width="7%"
                          >
                            <Button
                              sx={action__btn}
                              variant="contained"
                              onClick={() => {
                                handleLinkConfirmationOpen(row);
                              }}
                            >
                              <Typography sx={action__btn__label}>
                                Pilih
                              </Typography>
                            </Button>
                            <Button
                              sx={action__btn}
                              variant="contained"
                              onClick={() => {
                                handlePreviewOpen(row);
                              }}
                            >
                              <Typography sx={action__btn__label}>
                                Preview
                              </Typography>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                  {rows.length < 1 && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          Tidak ada data
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                {loading && (
                  <img src="/roll.svg" alt="roll" width={30} height={30}></img>
                )}

                <Stack alignItems="end">
                  <Pagination
                    shape="rounded"
                    sx={{
                      p: 2,
                      ".Mui-selected": {
                        backgroundColor: "rgba(0, 174, 239, 1)!important",
                        color: "white",
                      },
                    }}
                    count={lastPage}
                    showFirstButton
                    showLastButton
                    onChange={handlePageChange}
                  />
                </Stack>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

LinkToScanFolderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onChoose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default LinkToScanFolderModal;
