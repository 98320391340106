import { webkit__scrollbar } from "../../../../style";

const hardware__list__container = {
  maxHeight: "200px",
  overflowY: "auto",
  px: 2,
  ...webkit__scrollbar,
};

const hardware__list__item = {
  display: "flex",
  minWidth: "262px",
  minHeight: "64px",
  boxShadow: "none",
  background: "#FFF",
  position: "relative",
  borderRadius: "8px",
};

const hardware__list__item__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "23px",
  marginRight: "15px",
  borderRadius: "50%",
  objectFit: "cover",
  width: "50px",
  height: "50px",
};

const hardware__list__item__info__container = {
  display: "flex",
  flexDirection: "column",
  paddingTop: "4px",
  my: "auto",
  gap: 0.7,
};

const hardware__list__item__info__hwname = {
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "19px",
  color: "rgba(95, 95, 95, 1)",
};

const hardware__list__item__info__status__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "baseline",
};

const hardware__list__item__info__status__text__label = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "19px",
  display: "flex",
  flexDirection: "row",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  color: "rgba(125, 125, 125, 1)",
};

const hardware__list__item__info__status__text__value = {
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "19px",
  display: "flex",
  flexDirection: "row",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export {
  hardware__list__container,
  hardware__list__item,
  hardware__list__item__img,
  hardware__list__item__info__container,
  hardware__list__item__info__hwname,
  hardware__list__item__info__status__container,
  hardware__list__item__info__status__text__label,
  hardware__list__item__info__status__text__value,
};
