import {
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

import { useSnackbar } from "notistack";
import { form__box, form__label, form__value } from "./style";
import {
  input__date,
  input__select,
  input__text,
} from "../../../../rekam-medis/editor/style";
import { useAuth } from "../../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../../services/authHeader";
import {
  addMedicineInvoiceItem,
  updateInvoiceInfo,
} from "../../../../../../../redux/slices/invoice";
import * as Sentry from "@sentry/react";
import UnlinkedMedicalRecordPopper from "./UnlinkedMedicalRecordPopper";
import mediplusHelper from "../../../../../../../utils/mediplusHelper";
import odontogramHelper from "../../../../rekam-medis/editor/odontogram/components/helper";
import _, { trim } from "lodash";

export default function InvoiceInformation() {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const invoice = useSelector((state) => state.invoice);
  const { enqueueSnackbar } = useSnackbar();

  const [clinicRooms, setClinicRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const userNameInputRef = useRef(null);
  const [unlinkedMedPopperOpen, setUnlinkedMedPopperOpen] = useState(false);

  const handlePopupInvoiceAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (unlinkedMedPopperOpen) setUnlinkedMedPopperOpen(false);
    }
  };

  const loadData = async () => {
    try {
      const responseClinicRooms = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/me/clinic-rooms`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      setClinicRooms(responseClinicRooms.data);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
        autoHideDuration: "2000",
      });
      Sentry.captureException(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePoliChange = (e) => {
    if (invoice.user?.id) {
      enqueueSnackbar("tidak bisa mengganti ruang klinik, dikunci");
      return;
    }
    dispatch(
      updateInvoiceInfo({
        clinic_room_id: parseInt(e.target.value),
      })
    );
  };

  const handleNamaPasienChange = (e) => {
    if (invoice.user?.id) {
      enqueueSnackbar("tidak bisa mengubah nama pasien, dikunci");
      return;
    }
    dispatch(
      updateInvoiceInfo({
        nama_pasien: e.target.value,
      })
    );
  };

  const handleTanggalPeriksaChange = (e) => {
    dispatch(
      updateInvoiceInfo({
        tanggal_pemeriksaan: e.target.value,
      })
    );
  };

  const handleUnlinkedMedicalRecordClick = (data) => {
    setUnlinkedMedPopperOpen(false);
    if (mediplusHelper.hasJsonStructure(data.resep)) {
      let resep = JSON.parse(data.resep);
      console.log(resep);
      if (Array.isArray(resep)) {
        resep.forEach((item) => {
          if (
            item.hasOwnProperty("id") &&
            item.hasOwnProperty("nama") &&
            item.hasOwnProperty("harga") &&
            item.id
          ) {
            let medData = {
              id: item.id,
              nama: item.nama,
              harga: item.harga,
              qty: item?.qty ? (_.isInteger(item.qty) ? item.qty : 0) : 0,
            };
            dispatch(addMedicineInvoiceItem(medData));
          }
        });
      }
    }
    dispatch(
      updateInvoiceInfo({
        medical_record_id: data.medical_record_id,
        nama_pasien: trim(data.nama_pasien.split("-")[0]),
      })
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <UnlinkedMedicalRecordPopper
        open={unlinkedMedPopperOpen}
        anchorEl={userNameInputRef?.current ?? null}
        onClickAway={handlePopupInvoiceAway}
        search={invoice.nama_pasien ?? ""}
        handleUnlinkedMedicalRecordClick={handleUnlinkedMedicalRecordClick}
      />
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          marginBottom: "10px",
        }}
      >
        Detail Pasien
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{
              mb: 0.5,
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <Box sx={form__box}>
                  <Typography sx={form__label}>Nama Pasien</Typography>
                  <Typography sx={form__value}>:</Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <FormControl
                  data-group="popper-toggler"
                  sx={{
                    paddingLeft: "10px",
                    width: "80%",
                  }}
                >
                  <InputBase
                    ref={userNameInputRef}
                    autoComplete="false"
                    onFocus={() => {
                      setUnlinkedMedPopperOpen(true);
                    }}
                    sx={{
                      ...input__text,
                      "& .MuiInputBase-input": {
                        ...input__text["& .MuiInputBase-input"],
                        height: "14px",
                        padding: "5px",
                        fontSize: "14px",
                        borderRadius: "8px",
                      },
                    }}
                    name="nama_pasien"
                    id="nama_pasien"
                    placeholder="nama pasien"
                    value={invoice.nama_pasien ?? ""}
                    disabled={
                      invoice.user?.id || invoice.medical_record_id
                        ? true
                        : false
                    }
                    onChange={handleNamaPasienChange}
                    type="text"
                  ></InputBase>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              mb: 0.5,
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <Box sx={form__box}>
                  <Typography sx={form__label}>Invoice Number</Typography>
                  <Typography sx={form__value}>:</Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={{ ...form__value, paddingRight: "20px" }}>
                  {invoice.invoice_number ?? "--/--/--/--"}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Grid container>
            <Grid item xs={4}>
              <Box sx={form__box}>
                <Typography sx={form__label}>Jenis Pasien</Typography>
                <Typography sx={form__value}>:</Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={form__value}>Umum</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              mb: 1,
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <Box sx={form__box}>
                  <Typography sx={form__label}>Nama Poli</Typography>
                  <Typography sx={form__value}>:</Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                {loading ? (
                  <Skeleton
                    width={"90%"}
                    height={40}
                    sx={{
                      marginLeft: "10px",
                    }}
                  ></Skeleton>
                ) : (
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                      marginLeft: "10px",
                      paddingRight: "25px",
                    }}
                  >
                    <Select
                      id="diskon_type"
                      name="diskon_type"
                      value={invoice.clinic_room_id ?? ""}
                      disabled={invoice.user?.id ? true : false}
                      onChange={handlePoliChange}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      IconComponent={() => {
                        return <></>;
                      }}
                      sx={{
                        ...input__select,
                        height: "25.8px",
                        fontSize: "14px",
                      }}
                    >
                      {clinicRooms.map((i) => {
                        return (
                          <MenuItem key={i.id} value={i.id}>
                            {i.nama}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              mb: 0.5,
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <Box sx={form__box}>
                  <Typography sx={form__label}>Tanggal Periksa</Typography>
                  <Typography sx={form__value}>:</Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <FormControl
                  sx={{
                    paddingLeft: "10px",
                    width: "100%",
                  }}
                >
                  <InputBase
                    sx={{
                      ...input__date,
                      "& .MuiInputBase-input": {
                        ...input__date["& .MuiInputBase-input"],
                        height: "14px",
                        padding: "5px",
                        fontSize: "14px",
                        borderRadius: "8px",
                      },
                    }}
                    disabled={invoice.user?.id ? true : false}
                    onChange={handleTanggalPeriksaChange}
                    value={
                      invoice.tanggal_pemeriksaan
                        ? moment(invoice.tanggal_pemeriksaan).format(
                            "YYYY-MM-DDTkk:mm"
                          )
                        : ""
                    }
                    name="tanggal_pemeriksaan"
                    id="tanggal_pemeriksaan"
                    placeholder=""
                    type="datetime-local"
                  ></InputBase>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
