import { useTheme } from "@emotion/react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  form__control,
  input__date,
  input__label,
  input__text,
  section__title,
} from "../style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../datepicker.css";
import { isMacOs, isSafari, browserVersion } from "react-device-detect";
import moment from "moment";

export default function TabDataMedis({ form, handleGeneralInputFormChange }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [legacyBrowser, setLegacyBrowser] = useState(false);

  const handleWheel = (event) => {
    event.target.blur();
    event.stopPropagation();
  };

  useEffect(() => {
    if (isMacOs && isSafari) {
      let vrs = parseInt(browserVersion);
      if (!isNaN(vrs) && vrs < 14) {
        setLegacyBrowser(true);
      }
    }
  }, []);

  return (
    <>
      <Typography sx={section__title}>Tanggal Pemeriksaan</Typography>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="tanggal_periksa">
                Tanggal Periksa
              </InputLabel>
              {legacyBrowser ? (
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    form.tanggal_periksa
                      ? moment(form.tanggal_periksa).toDate()
                      : ""
                  }
                  onChange={(date) => {
                    handleGeneralInputFormChange({
                      target: {
                        name: "tanggal_periksa",
                        value: date,
                        id: "tanggal_periksa",
                        type: "date",
                      },
                    });
                  }}
                />
              ) : (
                <InputBase
                  sx={input__date}
                  placeholder="tanggal pemeriksaan"
                  id="tanggal_periksa"
                  name="tanggal_periksa"
                  type="date"
                  value={form.tanggal_periksa}
                  onChange={handleGeneralInputFormChange}
                />
              )}
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="reminder">
                Reminder Pemeriksaan
              </InputLabel>
              {legacyBrowser ? (
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={form.reminder ? moment(form.reminder).toDate() : ""}
                  onChange={(date) => {
                    handleGeneralInputFormChange({
                      target: {
                        name: "reminder",
                        value: date,
                        id: "reminder",
                        type: "date",
                      },
                    });
                  }}
                />
              ) : (
                <InputBase
                  sx={input__date}
                  placeholder="reminder pemeriksaan"
                  id="reminder"
                  name="reminder"
                  type="date"
                  value={form.reminder}
                  onChange={handleGeneralInputFormChange}
                />
              )}

              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Typography sx={section__title}>Fisik</Typography>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="tinggi_badan">
                Tinggi (cm)
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="tinggi badan"
                id="tinggi_badan"
                name="tinggi_badan"
                type="number"
                value={form.tinggi_badan}
                onChange={handleGeneralInputFormChange}
                onWheel={handleWheel}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="berat_badan">
                Berat (kg)
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="berat badan"
                id="berat_badan"
                name="berat_badan"
                type="number"
                value={form.berat_badan}
                onChange={handleGeneralInputFormChange}
                onWheel={handleWheel}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="bmi">
                BMI
              </InputLabel>
              <InputBase
                sx={input__text}
                value={form.bmi}
                placeholder="bmi"
                id="bmi"
                name="bmi"
                type="bmi"
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="bsa">
                BSA
              </InputLabel>
              <InputBase
                sx={input__text}
                value={form.bsa}
                placeholder="bsa"
                id="bsa"
                name="bsa"
                type="text"
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="kategori_bmi">
                Kategori
              </InputLabel>
              <InputBase
                sx={input__text}
                value={form.kategori_bmi ?? ""}
                placeholder=""
                id="kategori_bmi"
                name="kategori_bmi"
                type="text"
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="lingkar_kepala">
                Lingkar Kepala (cm)
              </InputLabel>
              <InputBase
                sx={input__text}
                value={form.lingkar_kepala ?? ""}
                placeholder=""
                id="lingkar_kepala"
                name="lingkar_kepala"
                type="number"
                onChange={handleGeneralInputFormChange}
                onWheel={handleWheel}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Typography sx={section__title}>Tanda Vital</Typography>
        <Grid container spacing={1}>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="temp">
                Suhu (C)
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="suhu"
                id="temp"
                name="temp"
                type="number"
                value={form.temp}
                onChange={handleGeneralInputFormChange}
                onWheel={handleWheel}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="spo2">
                SPO2 (%)
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="spo2"
                id="spo2"
                name="spo2"
                type="number"
                value={form.spo2}
                onChange={handleGeneralInputFormChange}
                onWheel={handleWheel}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="rr">
                Respiration Rate ( /Min )
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="rr"
                id="rr"
                name="rr"
                type="number"
                value={form.rr}
                onChange={handleGeneralInputFormChange}
                onWheel={handleWheel}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Typography sx={section__title}>Tekanan Darah</Typography>
        <Grid container spacing={1}>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="sistol">
                Systole (mmHg)
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="systole"
                id="sistol"
                name="sistol"
                type="number"
                value={form.sistol}
                onChange={handleGeneralInputFormChange}
                onWheel={handleWheel}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="diastol">
                Diastole (mmHg)
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="diastol"
                id="diastol"
                name="diastol"
                type="number"
                value={form.diastol}
                onChange={handleGeneralInputFormChange}
                onWheel={handleWheel}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="bpm">
                Hear Rate ( /Min )
              </InputLabel>
              <InputBase
                sx={input__text}
                placeholder="bpm"
                id="bpm"
                name="bpm"
                type="bpm"
                value={form.bpm}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Typography sx={section__title}>Riwayat Medis</Typography>
        <Grid container spacing={1}>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="golongan_darah">
                Golongan Darah
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="golongan darah"
                id="golongan_darah"
                name="golongan_darah"
                type="text"
                value={form.golongan_darah}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="riwayat_penyakit">
                Riwayat Penyakit
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="riwayat penyakit"
                id="riwayat_penyakit"
                name="riwayat_penyakit"
                type="text"
                value={form.riwayat_penyakit}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="riwayat_alergi">
                Riwayat Alergi
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="riwayat alergi"
                id="riwayat_alergi"
                name="riwayat_alergi"
                type="text"
                value={form.riwayat_alergi}
                onChange={handleGeneralInputFormChange}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
