import React from "react";
import {
  Button,
  ClickAwayListener,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import {
  header__btn,
  header__btn__label,
  header__container,
  header__h1,
  search__icon,
  table__cell__text,
  table__container,
  user__info__container,
  user__info__img,
  user__info__text,
} from "./style";
import { search__input } from "../../hasil-pemeriksaan/main/style";
import TheadCell from "../../../components/table/thead-cell/TheadCell";
import FilterModal from "./modal/FilterModal";
import PembayaranPopper from "./popper/PembayaranPopper";
import _ from "lodash";
import { useTheme } from "@emotion/react";
import GeneratePDFLoadingModal from "./modal/GeneratePDFLoadingModal";
import { useState } from "react";
import SendDataModal from "../../../components/modal/send-data-modal/SendDataModal";
import * as Sentry from "@sentry/react";

export default function Pembayaran() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [isGeneratePDFLoading, setIsGeneratePDFLoading] = useState(false);
  const [sendModalOpen, setSendModalOpen] = useState(false);

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  //table
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState("created_at");
  const [sortType, setSortType] = React.useState("DESC");
  const [search, setSearch] = React.useState("");

  //snackbar
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  //popper
  const [popperInvoiceEl, setPopperInvoiceEl] = React.useState(null);
  const [popupInvoiceOpen, setPopupInvoiceOpen] = React.useState(false);
  const [selectedInvoice, setSelectedInvoice] = React.useState({});

  //modal
  const [modalFilterOpen, setModalFilterOpen] = React.useState(false);
  const [filter, setFilter] = React.useState({
    start_date: "",
    end_date: "",
    pagination: 6,
  });

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePopupInvoiceOpen = (event) => {
    setPopperInvoiceEl(event.currentTarget);
    let invoiceId = parseInt(
      event.currentTarget.getAttribute("data-invoice-id")
    );
    let invoice = rows.find((item) => {
      return item.id === invoiceId;
    });
    console.log(invoice);
    if (invoice !== undefined) {
      setSelectedInvoice(invoice);
      if (event.currentTarget === popperInvoiceEl) {
        setPopupInvoiceOpen(!popupInvoiceOpen);
      } else {
        setPopupInvoiceOpen(true);
      }
    } else {
      enqueueSnackbar("pembayaran not found", {
        type: "error",
      });
    }
  };

  const handlePopupInvoiceAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupInvoiceOpen) setPopupInvoiceOpen(false);
    }
  };

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            "start-date":
              filter.start_date === null ||
              filter.start_date === undefined ||
              filter.start_date === ""
                ? null
                : moment(filter.start_date).format("YYYY-MM-DD"),
            "end-date":
              filter.end_date === null ||
              filter.end_date === undefined ||
              filter.end_date === ""
                ? null
                : moment(filter.end_date).format("YYYY-MM-DD"),
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: filter.pagination,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        Sentry.captureException(error);
      });
  };

  const deleteData = async () => {
    setDeleteConfirmationOpen(false);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices/${selectedInvoice.id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      loadData();
      enqueueSnackbar("data terhapus", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
      });
    } finally {
    }
  };

  const handleOpenMedicalRecord = () => {
    if (selectedInvoice?.medical_record_id) {
      history.push(`/rekam-medis/editor/${selectedInvoice?.medical_record_id}`);
      return;
    }
    enqueueSnackbar("Data rekam medis tidak ditemukan", {
      variant: "error",
    });
  };

  const handleRedirectCreateInvoice = () => {
    history.push("/pembayaran/editor");
  };

  const handleRedirectEditInvoice = (id) => {
    let selectedInvId = selectedInvoice?.id;
    history.push(
      `/pembayaran/editor/${
        _.isString(id) || _.isNumber(id) ? id : selectedInvId
      }`
    );
  };

  const handleRedirectEditInvoiceNewTab = () => {
    let selectedInvId = selectedInvoice?.id;
    window.open(`/pembayaran/editor/${selectedInvId}`, "_blank");
  };

  const handleSendModalOpen = () => {
    setPopupInvoiceOpen(false);
    setSendModalOpen(true);
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
    setPopupInvoiceOpen(false);
  };

  const handleCreatePdf = async () => {
    setIsGeneratePDFLoading(true);
    try {
      let data = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices/${selectedInvoice.id}/download`,
        {},
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      window.open(data.data.link, "_blank");
    } catch (e) {
      console.log(e);
      enqueueSnackbar("gagal generate PDF");
      Sentry.captureException(e);
    } finally {
      setIsGeneratePDFLoading(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    loadData();
    return () => {
      setPopupInvoiceOpen(false);
      setPopperInvoiceEl(null);
    };
  }, [current_active_schedule, page, sortBy, sortType, search]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "5px",
                  marginRight: "20px",
                }}
              >
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={deleteData}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />

      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Pembayaran
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InputBase
            sx={search__input}
            size="18"
            placeholder="Cari pasien"
            value={search}
            onChange={handleSearchChange}
          ></InputBase>
          <IconButton aria-label="search" sx={search__icon}>
            <img src="/assets/icons/search.svg" alt="search"></img>
          </IconButton>
          <IconButton
            onClick={() => {
              history.push("/pembayaran/statistik");
            }}
            aria-label="rekap"
            sx={header__btn}
          >
            <img src="/assets/icons/strongbox.svg" alt="rekap"></img>
            <Typography sx={header__btn__label}>Rekapitulasi</Typography>
          </IconButton>
          <IconButton
            onClick={() => {
              setModalFilterOpen(true);
            }}
            aria-label="filter"
            sx={header__btn}
          >
            <img src="/assets/icons/filter.svg" alt="filter"></img>
            <Typography sx={header__btn__label}>Filter</Typography>
          </IconButton>
          <IconButton
            onClick={handleRedirectCreateInvoice}
            aria-label="search-user"
            sx={header__btn}
          >
            <img src="/assets/icons/plus.svg" alt="create"></img>
            <Typography sx={header__btn__label}>Invoice</Typography>
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            sx={{
              ...table__container,
              maxHeight: isDesktop ? 520 : "calc(100vh - 300px)",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TheadCell
                    name="Nama"
                    useSort={true}
                    column_name="nama_pasien"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    imageColumn={true}
                    onClick={handleSortChange}
                  />
                  <TheadCell
                    name="Tanggal"
                    useSort={true}
                    column_name="created_at"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    onClick={handleSortChange}
                  />
                  <TheadCell
                    name="No Invoice"
                    useSort={true}
                    column_name="invoice_number"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    onClick={handleSortChange}
                  />
                  <TheadCell
                    name="Total"
                    useSort={true}
                    column_name="harga_akhir"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    onClick={handleSortChange}
                  />
                  {/* <TheadCell name="Status" useSort={false} /> */}
                  <TheadCell name="Action" useSort={false} />
                </TableRow>
              </TableHead>
              {rows.length > 0 && (
                <TableBody>
                  {rows.map((row, idx) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={idx}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleRedirectEditInvoice(row.id);
                      }}
                    >
                      <TableCell align={"left"}>
                        <Paper sx={user__info__container}>
                          <img
                            src={
                              row.user?.photo_profile
                                ? row.user?.photo_profile
                                : "/assets/icons/avatar.svg"
                            }
                            alt="doctor"
                            style={user__info__img}
                          ></img>
                          <Typography component={"span"} sx={user__info__text}>
                            {row.user?.nama ? row.user.nama : row.nama_pasien}
                          </Typography>
                        </Paper>
                      </TableCell>
                      <TableCell align={"left"} sx={table__cell__text}>
                        {moment(row.created_at).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell align={"left"} sx={table__cell__text}>
                        {row.invoice_number}
                      </TableCell>
                      <TableCell
                        align={"left"}
                        sx={{ ...table__cell__text, maxWidth: "300px" }}
                      >
                        {mediplusHelper.formatRupiah(row.harga_akhir)}
                      </TableCell>

                      {/* <TableCell align={"left"} sx={table__cell__text}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            textAlign: "center",
                            padding: "5px 16px",
                            gap: "10px",
                            position: "relative",
                            height: "30px",
                            background:
                              row.status_pembayaran === "DIBAYAR"
                                ? "rgba(211, 255, 231, 1)"
                                : "rgba(255, 222, 222, 1)",
                            color:
                              row.status_pembayaran === "DIBAYAR"
                                ? "rgba(0, 160, 73, 1)"
                                : "rgba(255, 0, 0, 1)",
                            borderRadius: "32px",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "inline-block",
                              width: "100%",
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                          >
                            {row.status_pembayaran === "DIBAYAR"
                              ? "Dibayar"
                              : "Belum Bayar"}
                          </Typography>
                        </Box>
                      </TableCell> */}

                      <TableCell
                        align={"left"}
                        sx={table__cell__text}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={(e) => {
                            e.stopPropagation();
                            handlePopupInvoiceAway(e);
                          }}
                        >
                          <IconButton
                            data-group="popper-toggler"
                            data-invoice-id={row.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePopupInvoiceOpen(e);
                            }}
                            color="inherit"
                            aria-label="refresh"
                            edge="start"
                            type="button"
                            sx={{
                              px: "15px",
                              ":hover": {
                                background: "none",
                              },
                            }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/icons/table-action.svg"
                              alt="refresh"
                            ></LazyLoadImage>
                          </IconButton>
                        </ClickAwayListener>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {rows.length < 1 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Tidak ada data
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <Stack alignItems="end">
            <Pagination
              shape="rounded"
              sx={{
                p: 2,
                ".Mui-selected": {
                  backgroundColor: "rgba(0, 174, 239, 1)!important",
                  color: "white",
                },
              }}
              count={lastPage}
              showFirstButton
              showLastButton
              onChange={handlePageChange}
            />
          </Stack>
        </Paper>
      </Box>

      <PembayaranPopper
        open={popupInvoiceOpen}
        anchorEl={popperInvoiceEl}
        handleRedirectEditInvoice={handleRedirectEditInvoice}
        handleOpenDeleteConfirmation={handleOpenDeleteConfirmation}
        onClickAway={handlePopupInvoiceAway}
        handleCreatePdf={handleCreatePdf}
        handleSendModalOpen={handleSendModalOpen}
        handleRedirectEditInvoiceNewTab={handleRedirectEditInvoiceNewTab}
        handleOpenMedicalRecord={handleOpenMedicalRecord}
      />
      <FilterModal
        open={modalFilterOpen}
        onClose={() => {
          setModalFilterOpen(false);
        }}
        filter={filter}
        setFilter={setFilter}
        loadData={loadData}
      />
      <GeneratePDFLoadingModal open={isGeneratePDFLoading} />
      <SendDataModal
        type={"INVOICE"}
        open={sendModalOpen}
        data={selectedInvoice}
        onClose={() => {
          setSendModalOpen(false);
        }}
      />
    </>
  );
}
