import { ClickAwayListener, Paper, Popper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAuth } from "../../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../../services/authHeader";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { webkit__scrollbar } from "../../../../../style";

export default function UnlinkedMedicalRecordPopper({
  open,
  anchorEl,
  search,
  onClickAway,
  handleUnlinkedMedicalRecordClick,
  onClose,
}) {
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const { user } = useAuth();
  const [medicalRecord, setMedicalRecord] = useState([]);

  const loadData = async (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices/unlinked-medical-record`,
        {
          params: {
            head: user.head_doctor_id,
            clinic: current_active_schedule.clinic_id,
            nama: search,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setMedicalRecord(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    loadData();
  }, [search]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        data-group="popper-element"
        open={open}
        anchorEl={anchorEl}
        placement={"bottom"}
        sx={{
          zIndex: 1500,
        }}
      >
        <Box>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              minHeight: "100px",
              maxHeight: "300px",
              minWidth: "200px",
              overflowY: "auto",
              ...webkit__scrollbar,
            }}
          >
            {medicalRecord?.length > 0 &&
              medicalRecord.map((i, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      p: 1,
                      borderBottom: "1px solid gray",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleUnlinkedMedicalRecordClick(i);
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {i.nama_pasien}
                    </Typography>
                  </Box>
                );
              })}

            {medicalRecord.length < 1 && (
              <Box
                sx={{
                  p: 1,
                  borderBottom: "1px solid gray",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  Tidak Ada Data
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}
