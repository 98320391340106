import { webkit__scrollbar } from "../../../../style";

const table__container = {
  maxHeight: 540,
  ...webkit__scrollbar,
};

const folder__info__container = {
  display: "flex",
  minWidth: "100px",
  maxWidth: "100%",
  minHeight: "45px",
  boxShadow: "none",
  background: "transparent",
  width: "auto",
  cursor: "pointer",
  marginRight: "5px",
  alignItems: "center",
};

const folder__info__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "10px",
  marginRight: "15px",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
};

const folder__info__text = {
  paddingLeft: "5px",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "19px",
  textAlign: "left",
};

const table__cell__text = {
  fontSize: "14px",
  fontWeight: 600,
  paddingTop: "0px",
  paddingBottom: "0px",
};

export {
  table__container,
  folder__info__container,
  folder__info__img,
  folder__info__text,
  table__cell__text,
};
