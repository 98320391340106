import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Typography, ClickAwayListener } from "@mui/material";
import { Box } from "@mui/system";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../providers/AuthProvider";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import authHeader from "../../../../../services/authHeader";
import { updateScanFolder } from "../../../../../redux/slices/scanFolderUpdate";
import {
  scan__table__container,
  scan__table__content__wrapper,
  scan__table__title,
  table__cell__text,
} from "./style";
import SendAntrianModal from "../../../components/modal/send-antrian-modal/SendAntrianModal";
import QRModal from "../../../components/modal/qr-modal/QRModal";
import SendDataModal from "../../../components/modal/send-data-modal/SendDataModal";
import PopperRefresh from "../popper-refresh/PopperRefresh";
import TabScanfolderPopper from "./TabScanfolderPopper";
import TabLoading from "../tab-loading/TabLoading";
import * as Sentry from "@sentry/react";

export default function ScanFolderTab(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth();

  /**
   * === STATE ===
   */

  //table
  const search = props.search;
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [hasMoreData, setHasMoreData] = React.useState(false);
  const [nextUrl, setNextUrl] = React.useState(null);

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const schedule_date = useSelector((state) => state.schedule.scheduleDate);
  const scan_folder_update = useSelector(
    (state) => state.scan_folder_update.value
  );

  //popper
  const [refreshButtonEl, setRefreshButtonEl] = React.useState(null);
  const [popupRefreshOpen, setPopupRefreshOpen] = React.useState(false);
  const [popperScanEl, setPopperScanEl] = React.useState(null);
  const [popupScanOpen, setPopupScanOpen] = React.useState(false);
  const [selectedScan, setSelectedScan] = React.useState({
    id: null,
  });
  const [sendAntrianModalOpen, setSendAntrianModalOpen] = React.useState(false);
  const [qrModalOpen, setQRModalOpen] = React.useState(false);
  const [sendModalOpen, setSendModalOpen] = React.useState(false);

  /**
   * === HANDLER ===
   */

  const handlePopupRefreshOpen = (event) => {
    setRefreshButtonEl(event.currentTarget);
    setPopupRefreshOpen(!popupRefreshOpen);
  };

  const handlePopupRefreshAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      setPopupRefreshOpen(false);
    }
  };

  const handlePopupScanOpen = (event) => {
    setPopperScanEl(event.currentTarget);
    let scanId = parseInt(event.currentTarget.getAttribute("data-scan-id"));
    let scanFolder = rows.find((item) => {
      return item.id === scanId;
    });
    if (scanFolder !== undefined) {
      setSelectedScan({
        id: scanFolder.id,
      });
    }
    if (event.currentTarget === popperScanEl) {
      setPopupScanOpen(!popupScanOpen);
    } else {
      setPopupScanOpen(true);
    }
  };

  const handlePopupScanAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupScanOpen) setPopupScanOpen(false);
    }
  };

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/scan-folders`,
        {
          params: {
            clinic: current_active_schedule.clinic_id,
            pagination: 30,
            search: search,
            "start-date": moment(schedule_date).format("YYYY-MM-DD"),
            "end-date": moment(schedule_date).format("YYYY-MM-DD"),
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (url) {
          setRows((prev) => [...prev, ...response.data.data]);
        } else {
          setRows((prev) => [...response.data.data]);
        }

        if (response.data.next_page_url !== null) {
          setHasMoreData(true);
          setNextUrl(response.data.next_page_url);
        } else {
          setHasMoreData(false);
          setNextUrl(null);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        Sentry.captureException(error);
      });
  };

  const handleRefresh = () => {
    dispatch(updateScanFolder());
    setPopupRefreshOpen(false);
    setLoading(true);
  };

  const handleRedirectView = (id) => {
    history.push(`/hasil-pemeriksaan/${id}`);
  };

  React.useEffect(() => {
    setTimeout(() => {
      loadData(null);
    }, 750);
    const onFocus = () => {
      dispatch(updateScanFolder());
    };
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
      setPopupScanOpen(false);
      setPopperScanEl(null);
    };
  }, [current_active_schedule, scan_folder_update, schedule_date, search]);

  if (loading) {
    return (
      <>
        <TabLoading
          title={
            "Hasil Pemeriksaan " +
            (moment(schedule_date).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
              ? "Hari Ini"
              : moment(schedule_date).locale("id").format("DD MMMM YYYY"))
          }
        />
      </>
    );
  }

  return (
    <Paper elevation={0}>
      <Box sx={scan__table__content__wrapper}>
        <Typography sx={scan__table__title}>
          Hasil Pemeriksaan{" "}
          {moment(schedule_date).format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD")
            ? "Hari Ini"
            : moment(schedule_date).locale("id").format("DD MMMM YYYY")}
        </Typography>
        <ClickAwayListener onClickAway={handlePopupRefreshAway}>
          <IconButton
            onClick={handlePopupRefreshOpen}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img src="/assets/icons/vertical-options.svg" alt="refresh"></img>
          </IconButton>
        </ClickAwayListener>
      </Box>

      {rows.length < 1 && (
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography>Tidak ada data hasil pemeriksaan</Typography>
        </Paper>
      )}
      {rows.length > 0 && (
        <TableContainer
          id="scanFolderTable"
          component={Paper}
          elevation={0}
          sx={scan__table__container}
        >
          <InfiniteScroll
            dataLength={rows.length}
            next={() => {
              loadData(nextUrl);
            }}
            hasMore={hasMoreData}
            scrollableTarget="scanFolderTable"
          >
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={() => {
                      handleRedirectView(row.id);
                    }}
                  >
                    <TableCell width="3%">
                      <LazyLoadImage
                        effect="blur"
                        src="/assets/icons/folder.svg"
                        alt="profile"
                        width="44"
                        height="44"
                        style={{
                          borderRadius: "50%",
                        }}
                      ></LazyLoadImage>
                    </TableCell>
                    <TableCell width="80%" align="left">
                      <Typography sx={table__cell__text}>
                        {mediplusHelper.cropText(row.name, 30)} |{" "}
                        {row.file_count} Berkas
                      </Typography>
                      <Typography sx={table__cell__text}>
                        {moment(row.created_at)
                          .locale("id")
                          .format("DD MMMM YYYY") +
                          " " +
                          moment(row.created_at).format("HH:mm") +
                          " | " +
                          mediplusHelper.fileSizeIEC(row.size)}
                      </Typography>
                    </TableCell>

                    <TableCell width="10%" align="right">
                      <ClickAwayListener
                        onClickAway={(e) => {
                          e.stopPropagation();
                          handlePopupScanAway(e);
                        }}
                      >
                        <IconButton
                          data-group="popper-toggler"
                          color="inherit"
                          aria-label="act"
                          edge="start"
                          type="button"
                          data-scan-id={row.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePopupScanOpen(e);
                          }}
                        >
                          <img
                            src="/assets/icons/table-action.svg"
                            alt="act"
                          ></img>
                        </IconButton>
                      </ClickAwayListener>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      )}
      <TabScanfolderPopper
        open={popupScanOpen}
        anchorEl={popperScanEl}
        selectedScan={selectedScan}
        handleRedirectView={handleRedirectView}
        handleQrModalOpen={() => {
          setQRModalOpen(true);
          setPopupScanOpen(false);
        }}
        handleSendAntrianModalOpen={() => {
          setSendAntrianModalOpen(true);
          setPopupScanOpen(false);
        }}
        handleSendDataModalOpen={() => {
          setSendModalOpen(true);
          setPopupScanOpen(false);
        }}
        handlePopupScanAway={handlePopupScanAway}
      />
      <PopperRefresh
        open={popupRefreshOpen}
        anchorEl={refreshButtonEl}
        onClick={handleRefresh}
      />
      <SendAntrianModal
        open={sendAntrianModalOpen}
        data={selectedScan}
        onChoose={() => {
          setSendAntrianModalOpen(false);
          loadData();
        }}
        onClose={() => {
          setSendAntrianModalOpen(false);
        }}
      />
      <QRModal
        open={qrModalOpen}
        data={selectedScan}
        onClose={() => {
          setQRModalOpen(false);
        }}
        type="SCAN_FOLDER"
      />
      <SendDataModal
        open={sendModalOpen}
        data={selectedScan}
        onClose={() => {
          setSendModalOpen(false);
        }}
        type="SCAN_FOLDER"
      />
    </Paper>
  );
}
