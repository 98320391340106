import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import mediplusHelper from "../../../../../../utils/mediplusHelper";

export default function UploadFileModal({
  open,
  onClose,
  files,
  handleFileChange,
  handleFileDelete,
  handleFileSend,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Kirim File
          </Typography>
          <IconButton
            onClick={onClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <Dropzone onDrop={handleFileChange}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  style={{
                    height: "95px",
                    borderRadius: "8px",
                    backgroundColor: "rgba(239, 241, 249, 1)",
                    border: "1px solid #ced4da",
                    position: "relative",
                    marginBottom: "10px",
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div
                    style={{
                      margin: "0px",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      WebkitTransform: "translate(-50%, -50%)",
                      transform: "translate(-50%, -50%)",
                      display: "inline-block",
                      width: "80%",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px 0px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src="/assets/icons/gallery-import.svg"
                        alt="import"
                      ></img>
                      <div>drag or upload files here</div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {files &&
              files.length > 0 &&
              files.map((item, index) => {
                return (
                  <Box key={`f_scan_box_${index}`}>
                    <Typography component={"span"} key={`f_scan_${index}`}>
                      {item.name} | {mediplusHelper.fileSizeIEC(item.size)}{" "}
                      <Typography
                        component={"span"}
                        sx={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFileDelete(index);
                        }}
                      >
                        delete
                      </Typography>
                    </Typography>
                  </Box>
                );
              })}
          </Box>

          <Button
            fullWidth
            sx={{
              backgroundColor: "blue",
              borderRadius: "8px",
              textTransform: "none",
              marginBottom: "15px",
              height: "38px",
            }}
            variant="contained"
            onClick={handleFileSend}
          >
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: 600,
                marginRight: "5px",
              }}
            >
              Kirim
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
