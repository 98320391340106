import { createSlice } from "@reduxjs/toolkit";

export const product = createSlice({
  name: "product",
  initialState: {
    products: [],
    selected_product: {},
  },
  reducers: {
    updateListProduct: (state, data) => {
      return { ...state, products: [...data.payload] };
    },
    updateSelectedProduct: (state, data) => {
      return {
        ...state,
        selected_product: { ...state.selected_product, ...data.payload },
      };
    },
  },
});

export const { updateListProduct, updateSelectedProduct } = product.actions;
export default product.reducer;
