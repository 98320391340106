const search__input = {
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    width: "100%",
    padding: "10px 12px",
    height: "16px",
    fontSize: 12,
    borderRadius: "8px 0px 0px 8px",
    minWidth: "170px",
    "&:focus": {
      borderColor: "#1976d2",
    },
  },
};

const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "15px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

export { search__input, header__container, header__h1 };
