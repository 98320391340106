import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import "moment/locale/id";
import React from "react";
import { useState } from "react";
import { search__input } from "../../hasil-pemeriksaan/main/style";
import {
  calendar__btn,
  calendar__btn__label,
  header__container,
  header__h1,
  table__cell__text,
  table__container,
  table__wrapper,
  tab__label,
  widget__container,
  widget__foooter__text,
  widget__footer__pill,
  widget__footer__pill__label,
  widget__footer__wrapper,
  widget__header__title,
  widget__header__wrapper,
} from "./style";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import { useEffect } from "react";
import {
  form__control,
  input__date,
  input__label,
} from "../../rekam-medis/editor/style";
import a11yProps from "../../../../components/tab/a11yProps";
import TheadCell from "../../../components/table/thead-cell/TheadCell";
import * as Sentry from "@sentry/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
  },
  maintainAspectRatio: false,
};

export default function StatistikPembayaran() {
  const { user } = useAuth();
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const [tab, setTab] = useState(parseInt(moment().format("MM")) - 1);
  const [rows, setRows] = useState([]);
  const [dateModalOpen, setDateModalOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState({
    month: moment().format("MM"),
    year: moment().format("YYYY"),
  });

  const [widgetData, setWidgetData] = useState({
    pendapatanBulanIni: {
      value: 0,
      percentage: 0,
      percentageType: "up",
    },
    pendapatanTahunIni: {
      value: 0,
      percentage: 0,
      percentageType: "up",
    },
    pasienBulanIni: {
      value: 0,
      percentage: 0,
      percentageType: "up",
    },
    invoiceBulanIni: {
      value: 0,
      percentage: 0,
      percentageType: "up",
    },
  });

  const handleTabChange = (e, value) => {
    let month = String(value + 1);
    if (month.length < 2) {
      month = `0${month}`;
    }
    setSelectedDate((prev) => ({ ...prev, month: month }));
    setTab(value);
  };

  const handleDateModalOpen = () => {
    setDateModalOpen(true);
  };

  const handleDateModalClose = () => {
    setDateModalOpen(false);
  };

  const handleMonthChange = (e) => {
    const inpDate = moment(e.target.value, "YYYY-MM");
    setSelectedDate({
      month: inpDate.format("MM"),
      year: inpDate.format("YYYY"),
    });
  };

  const labels = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const [charData, setChartData] = useState({
    labels: labels,
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "#D9D9D9",
      },
    ],
  });

  const loadDataStatistik = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices/statistics`, {
        params: {
          clinic: current_active_schedule.clinic_id,
          timezone: parseInt(mediplusHelper.getTimezoneOffset()),
          head: user.head_doctor_id,
          month: parseInt(selectedDate.month),
          year: parseInt(selectedDate.year),
        },
        headers: authHeader(),
      })
      .then((response) => {
        let monthlyChartData = [];
        let backgroundColor = [];
        for (let i = 1; i <= 12; i++) {
          if (i === parseInt(selectedDate.month)) {
            backgroundColor.push("#00AEEF");
          } else {
            backgroundColor.push("#D9D9D9");
          }
        }
        if (response.data.monthly_chart) {
          response.data.monthly_chart.forEach((e) => {
            monthlyChartData.push(e.total);
          });
          setChartData((prev) => ({
            ...prev,
            datasets: [
              {
                data: monthlyChartData,
                backgroundColor: backgroundColor,
              },
            ],
          }));
        }

        if (response.data.stats) {
          //pendapatan bulan
          let pendapatanBulanIniValue =
            response.data.stats.sum_invoice_month.current ?? 0;
          let pendapatanBulanIniLast =
            response.data.stats.sum_invoice_month.last ?? 0;
          let pendapatanBulanIniPercentage =
            pendapatanBulanIniLast === 0
              ? pendapatanBulanIniValue
              : Math.abs(
                  (
                    ((pendapatanBulanIniValue - pendapatanBulanIniLast) /
                      pendapatanBulanIniLast) *
                    100
                  ).toFixed(2)
                );
          let pendapatanBulanIniPercentageType =
            pendapatanBulanIniValue > pendapatanBulanIniLast ? "up" : "down";

          //pendapatan tahun
          let pendapatanTahunIniValue =
            response.data.stats.sum_invoice_year.current ?? 0;
          let pendapatanTahunIniLast =
            response.data.stats.sum_invoice_year.last ?? 0;
          let pendapatanTahunIniPercentage =
            pendapatanTahunIniLast === 0
              ? pendapatanTahunIniValue
              : Math.abs(
                  (
                    ((pendapatanTahunIniValue - pendapatanTahunIniLast) /
                      pendapatanTahunIniLast) *
                    100
                  ).toFixed(2)
                );
          let pendapatanTahunIniPercentageType =
            pendapatanTahunIniValue > pendapatanTahunIniLast ? "up" : "down";

          //pasien bulan
          let pasienBulanIniValue = response.data.stats.patient.current ?? 0;
          let pasienBulanIniLast = response.data.stats.patient.last ?? 0;
          let pasienBulanIniPercentage =
            pasienBulanIniLast === 0
              ? pasienBulanIniValue
              : Math.abs(
                  (
                    ((pasienBulanIniValue - pasienBulanIniLast) /
                      pasienBulanIniLast) *
                    100
                  ).toFixed(2)
                );
          let pasienBulanIniPercentageType =
            pasienBulanIniValue > pasienBulanIniLast ? "up" : "down";

          //invoice bulan
          let invoiceBulanIniValue =
            response.data.stats.count_invoice.current ?? 0;
          let invoiceBulanIniLast = response.data.stats.count_invoice.last ?? 0;
          let invoiceBulanIniPercentage =
            invoiceBulanIniLast === 0
              ? invoiceBulanIniValue
              : Math.abs(
                  (
                    ((invoiceBulanIniValue - invoiceBulanIniLast) /
                      invoiceBulanIniLast) *
                    100
                  ).toFixed(2)
                );
          let invoiceBulanIniPercentageType =
            invoiceBulanIniValue > invoiceBulanIniLast ? "up" : "down";

          setWidgetData((prev) => ({
            ...prev,
            pendapatanBulanIni: {
              value: pendapatanBulanIniValue,
              percentage: mediplusHelper.abbreviateNumber(
                pendapatanBulanIniPercentage
              ),
              percentageType: pendapatanBulanIniPercentageType,
            },
            pendapatanTahunIni: {
              value: pendapatanTahunIniValue,
              percentage: mediplusHelper.abbreviateNumber(
                pendapatanTahunIniPercentage
              ),
              percentageType: pendapatanTahunIniPercentageType,
            },
            pasienBulanIni: {
              value: pasienBulanIniValue,
              percentage: mediplusHelper.abbreviateNumber(
                pasienBulanIniPercentage
              ),
              percentageType: pasienBulanIniPercentageType,
            },
            invoiceBulanIni: {
              value: invoiceBulanIniValue,
              percentage: mediplusHelper.abbreviateNumber(
                invoiceBulanIniPercentage
              ),
              percentageType: invoiceBulanIniPercentageType,
            },
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  const loadDataTable = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices/daily-stats-by-month`,
        {
          params: {
            clinic: current_active_schedule.clinic_id,
            timezone: parseInt(mediplusHelper.getTimezoneOffset()),
            head: user.head_doctor_id,
            month: parseInt(selectedDate.month),
            year: parseInt(selectedDate.year),
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    loadDataStatistik();
    loadDataTable();
  }, [selectedDate]);

  return (
    <>
      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Rekapitulasi
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            onClick={handleDateModalOpen}
            aria-label="search-user"
            sx={calendar__btn}
          >
            <img src="/assets/icons/calendar-mini.svg" alt="create"></img>
            <Typography sx={calendar__btn__label}>
              {moment(
                `${selectedDate.year}-${selectedDate.month}`,
                "YYYY-MM"
              ).format("MMMM YYYY")}
            </Typography>
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={3} md={6} sm={6}>
          <Box sx={widget__container}>
            <Box sx={widget__header__wrapper}>
              <img src="/assets/icons/money-send.svg" alt="pendapatan"></img>
              <Typography sx={widget__header__title}>
                Pendapatan bulan ini
              </Typography>
            </Box>
            <Box sx={widget__footer__wrapper}>
              <Typography sx={widget__foooter__text}>
                {mediplusHelper.formatRupiah(
                  widgetData.pendapatanBulanIni.value
                )}
              </Typography>
              <Box
                sx={{
                  ...widget__footer__pill,
                  background:
                    widgetData.pendapatanBulanIni.percentageType === "up"
                      ? "rgba(211, 255, 231, 1)"
                      : "rgba(255, 222, 222, 1)",
                  color:
                    widgetData.pendapatanBulanIni.percentageType === "up"
                      ? "rgba(0, 160, 73, 1)"
                      : "rgba(255, 0, 0, 1)",
                }}
              >
                <Typography sx={widget__footer__pill__label}>
                  {widgetData.pendapatanBulanIni.percentageType === "up"
                    ? "▲"
                    : "▼"}{" "}
                  {widgetData.pendapatanBulanIni.percentage}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={3} md={6} sm={6}>
          <Box sx={widget__container}>
            <Box sx={widget__header__wrapper}>
              <img
                src="/assets/icons/wallet-money.svg"
                alt="pendapatan-tahun"
              ></img>
              <Typography sx={widget__header__title}>
                Pendapatan Tahun Ini
              </Typography>
            </Box>
            <Box sx={widget__footer__wrapper}>
              <Typography sx={widget__foooter__text}>
                {mediplusHelper.formatRupiah(
                  widgetData.pendapatanTahunIni.value
                )}
              </Typography>
              <Box
                sx={{
                  ...widget__footer__pill,
                  background:
                    widgetData.pendapatanTahunIni.percentageType === "up"
                      ? "rgba(211, 255, 231, 1)"
                      : "rgba(255, 222, 222, 1)",
                  color:
                    widgetData.pendapatanTahunIni.percentageType === "up"
                      ? "rgba(0, 160, 73, 1)"
                      : "rgba(255, 0, 0, 1)",
                }}
              >
                <Typography sx={widget__footer__pill__label}>
                  {widgetData.pendapatanTahunIni.percentageType === "up"
                    ? "▲"
                    : "▼"}{" "}
                  {widgetData.pendapatanTahunIni.percentage}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={3} md={6} sm={6}>
          <Box sx={widget__container}>
            <Box sx={widget__header__wrapper}>
              <img
                src="/assets/icons/profile-2-user-big.svg"
                alt="pasien"
              ></img>
              <Typography sx={widget__header__title}>
                Pasien Bulan Ini
              </Typography>
            </Box>
            <Box sx={widget__footer__wrapper}>
              <Typography sx={widget__foooter__text}>
                {mediplusHelper.formatNumber(widgetData.pasienBulanIni.value)}
              </Typography>
              <Box
                sx={{
                  ...widget__footer__pill,
                  background:
                    widgetData.pasienBulanIni.percentageType === "up"
                      ? "rgba(211, 255, 231, 1)"
                      : "rgba(255, 222, 222, 1)",
                  color:
                    widgetData.pasienBulanIni.percentageType === "up"
                      ? "rgba(0, 160, 73, 1)"
                      : "rgba(255, 0, 0, 1)",
                }}
              >
                <Typography sx={widget__footer__pill__label}>
                  {widgetData.pasienBulanIni.percentageType === "up"
                    ? "▲"
                    : "▼"}{" "}
                  {widgetData.pasienBulanIni.percentage}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={3} md={6} sm={6}>
          <Box sx={widget__container}>
            <Box sx={widget__header__wrapper}>
              <img src="/assets/icons/receipt.svg" alt="pasien"></img>
              <Typography sx={widget__header__title}>
                Invoice Bulan Ini
              </Typography>
            </Box>
            <Box sx={widget__footer__wrapper}>
              <Typography sx={widget__foooter__text}>
                {" "}
                {mediplusHelper.formatNumber(widgetData.invoiceBulanIni.value)}
              </Typography>
              <Box
                sx={{
                  ...widget__footer__pill,
                  background:
                    widgetData.invoiceBulanIni.percentageType === "up"
                      ? "rgba(211, 255, 231, 1)"
                      : "rgba(255, 222, 222, 1)",
                  color:
                    widgetData.invoiceBulanIni.percentageType === "up"
                      ? "rgba(0, 160, 73, 1)"
                      : "rgba(255, 0, 0, 1)",
                }}
              >
                <Typography sx={widget__footer__pill__label}>
                  {widgetData.invoiceBulanIni.percentageType === "up"
                    ? "▲"
                    : "▼"}{" "}
                  {widgetData.invoiceBulanIni.percentage}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          paddingTop: "20px",
        }}
      >
        <Bar options={options} data={charData} height={450} />
      </Box>

      <Box sx={table__wrapper}>
        <Tabs
          value={tab}
          variant="scrollable"
          onChange={handleTabChange}
          aria-label="tabk"
          sx={{
            ".MuiTabs-flexContainer": {
              background: "transparent",
            },
            overflowX: "auto",
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#FF0091",
              height: "3px",
            },
          }}
        >
          {labels.map((month, idx) => {
            return (
              <Tab
                sx={{
                  mx: 0.1,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      px: 0.3,
                      color: tab === idx ? "#041615" : "#7D7D7D",
                    }}
                  >
                    {month}
                  </Typography>
                }
                {...a11yProps(idx)}
              />
            );
          })}
        </Tabs>
        <TableContainer sx={table__container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TheadCell name="Tanggal" useSort={false} />
                <TheadCell name="Jumlah Invoice" useSort={false} />
                <TheadCell name="Jumlah Pasien" useSort={false} />
                <TheadCell name="Total" useSort={false} />
              </TableRow>
            </TableHead>
            {rows.length > 0 && (
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow hover tabIndex={-1} key={`tr_${i}`}>
                    <TableCell
                      align={"left"}
                      sx={table__cell__text}
                      width="40%"
                    >
                      {moment(row.dt).format("DD MMMM YYYY")}
                    </TableCell>
                    <TableCell
                      align={"left"}
                      sx={table__cell__text}
                      width="20%"
                    >
                      {mediplusHelper.formatNumber(row.jumlah_invoice ?? 0)}
                    </TableCell>
                    <TableCell
                      align={"left"}
                      sx={table__cell__text}
                      width="20%"
                    >
                      {mediplusHelper.formatNumber(row.pasien ?? 0)}
                    </TableCell>
                    <TableCell
                      align={"left"}
                      sx={table__cell__text}
                      width="20%"
                    >
                      {mediplusHelper.formatRupiah(row.total ?? 0)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            {rows.length < 1 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Tidak ada data
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      <Modal
        open={dateModalOpen}
        onClose={handleDateModalClose}
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "12px",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Pilih Bulan & Tahun
            </Typography>
            <IconButton
              onClick={handleDateModalClose}
              color="inherit"
              aria-label="refresh"
              edge="start"
              type="button"
            >
              <img
                width={25}
                height={25}
                effect="blur"
                src="/assets/icons/close.svg"
                alt="refresh"
              ></img>
            </IconButton>
          </Box>
          <Box
            sx={{
              px: 1.5,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="standard" sx={form__control}>
                  <InputLabel sx={input__label} shrink htmlFor="month_year">
                    Bulan Tahun
                  </InputLabel>
                  <InputBase
                    sx={input__date}
                    onChange={handleMonthChange}
                    value={moment(
                      `${selectedDate.year}-${selectedDate.month}`,
                      "YYYY-MM"
                    ).format("YYYY-MM")}
                    placeholder=""
                    id="month_year"
                    name="month_year"
                    type="month"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
