import {
  Box,
  ClickAwayListener,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import AppBar from "./components/app-bar/AppBar";
import {
  toolbar,
  topbar__profile__btn,
  topbar__profile__container,
  topbar__profile__img,
  topbar__profile__info__container,
  topbar__profile__info__text__acc_type,
  topbar__profile__info__text__name,
} from "./style";
import PropTypes from "prop-types";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../db/db";
import { useAuth } from "../../../../providers/AuthProvider";
import { useEffect } from "react";
import NotificationPopper from "./components/poppers/NotificationPopper";
import ProfilePopper from "./components/poppers/ProfilePopper";
import SettingPopper from "./components/poppers/SettingPopper";
import mediplusHelper from "../../../../utils/mediplusHelper";

function Topbar({ isSidebarOpen, handleSidebar }) {
  const { user } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [popOverOpen, setPopOverOpen] = React.useState({
    profile: false,
    notification: false,
    setting: false,
  });

  const notifications = useLiveQuery(() =>
    db.notifications.reverse().toArray()
  );

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
    let type = event.currentTarget.getAttribute("aria-describedby");
    if (type === "profile") {
      setPopOverOpen((prev) => ({
        ...prev,
        profile: !prev.profile,
        notification: false,
      }));
    } else if (type === "setting") {
      setPopOverOpen((prev) => ({
        ...prev,
        setting: !prev.setting,
        notification: false,
      }));
    } else {
      setPopOverOpen((prev) => ({
        ...prev,
        profile: false,
        notification: !prev.notification,
      }));
    }
  };

  const handlePopperAway = (event, type) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (type === "profile") {
        setPopOverOpen((prev) => ({
          ...prev,
          profile: false,
        }));
      } else if (type === "setting") {
        setPopOverOpen((prev) => ({
          ...prev,
          setting: false,
        }));
      } else {
        setPopOverOpen((prev) => ({
          ...prev,
          notification: false,
        }));
      }
    }
  };

  useEffect(() => {
    return () => {
      setPopOverOpen({
        profile: false,
        notification: false,
        setting: false,
      });
    };
  }, []);

  return (
    <AppBar position="fixed" open={isSidebarOpen} elevation={0}>
      <Toolbar sx={toolbar}>
        <IconButton
          color="inherit"
          aria-label="sidebar-toggler"
          onClick={handleSidebar}
          edge="start"
          sx={{
            marginRight: 5,
          }}
        >
          <img
            src={
              isSidebarOpen
                ? "/assets/icons/hamburger.svg"
                : "/mediplus-favicon.svg"
            }
            width="24"
            height="24"
            alt="hamburger"
          ></img>
        </IconButton>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            width: "100%",
          }}
        >
          <ClickAwayListener
            onClickAway={(e) => handlePopperAway(e, "notification")}
          >
            <IconButton
              color="inherit"
              aria-label="notif"
              edge="start"
              sx={{
                marginRight: "20px",
              }}
              aria-describedby={"notification"}
              type="button"
              onClick={handlePopOver}
            >
              <img
                src={
                  notifications && notifications.length > 0
                    ? "/assets/icons/notification.svg"
                    : "/assets/icons/notification-nobell.svg"
                }
                alt="notification"
              ></img>
            </IconButton>
          </ClickAwayListener>
          <ClickAwayListener
            onClickAway={(e) => handlePopperAway(e, "setting")}
          >
            <IconButton
              onClick={handlePopOver}
              aria-describedby={"setting"}
              color="inherit"
              aria-label="setting"
              edge="start"
              sx={{
                marginRight: "10px",
              }}
            >
              <img src="/assets/icons/setting.svg" alt="setting"></img>
            </IconButton>
          </ClickAwayListener>
        </Box>
        <Paper sx={topbar__profile__container}>
          <img
            src={user.photo_profile}
            alt="doctor"
            width="40"
            height="40"
            style={topbar__profile__img}
          ></img>
          <Box component={"div"} sx={topbar__profile__info__container}>
            <Typography
              component={"span"}
              sx={topbar__profile__info__text__acc_type}
            >
              {user.type === "ASSISTANT" ? "Asisten" : "Dokter"}
            </Typography>
            <Typography
              component={"span"}
              sx={topbar__profile__info__text__name}
            >
              {user.nama}
            </Typography>
          </Box>
          <ClickAwayListener
            onClickAway={(e) => handlePopperAway(e, "profile")}
          >
            <IconButton
              color="inherit"
              aria-label="profil-opt"
              edge="start"
              sx={topbar__profile__btn}
              aria-describedby={"profile"}
              type="button"
              onClick={handlePopOver}
            >
              <img
                src="/assets/icons/vertical-options.svg"
                alt="profil-opt"
              ></img>
            </IconButton>
          </ClickAwayListener>
        </Paper>
        <NotificationPopper
          open={popOverOpen.notification}
          anchorEl={anchorEl}
        />
        <ProfilePopper open={popOverOpen.profile} anchorEl={anchorEl} />
        <SettingPopper open={popOverOpen.setting} anchorEl={anchorEl} />
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  handleSidebar: PropTypes.func.isRequired,
};

export default Topbar;
