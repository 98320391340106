import React, { useEffect, useState } from "react";
import validator from "validator";
import { useSnackbar } from "notistack";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import authHeader from "../../../../../services/authHeader";
import {
  form__control,
  input__date,
  input__label,
  input__select,
  input__text,
} from "../../rekam-medis/editor/style";
import { useAuth } from "../../../../../providers/AuthProvider";
import * as Sentry from "@sentry/react";
import { isMacOs, isSafari, browserVersion } from "react-device-detect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../datepicker.css";
import moment from "moment";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { webkit__scrollbar } from "../../../style";

const defaultForm = {
  clinic_id: "",
  waktu_mulai: "",
  waktu_akhir: "",
  status: "ACTIVE",
  message: "",
  batal_antrian: false,
};

export default function EditorModal({ open, onClose, onSave, cutiID }) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [legacyBrowser, setLegacyBrowser] = useState(false);

  const [form, setForm] = useState({
    clinic_id: "",
    waktu_mulai: "",
    waktu_akhir: "",
    status: "ACTIVE",
    message: "",
    batal_antrian: false,
  });

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnSave = () => {
    if (typeof onSave === "function") {
      onSave();
    }
  };

  const [validationError, setValidationError] = useState({});

  const handleChange = (e) => {
    if (e.target.name === "batal_antrian") {
      setForm((prev) => ({ ...prev, batal_antrian: !prev.batal_antrian }));
    } else {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    setValidationError((prevError) => ({
      ...prevError,
      [e.target.name]: "",
    }));
  };

  const validate = () => {
    const newError = {};

    if (!form.clinic_id) {
      newError.clinic_id = "pilih klinik anda terlebih dahulu";
    }
    if (!form.waktu_mulai) {
      newError.waktu_mulai = "isi tanggal mulai";
    }
    if (!form.waktu_akhir) {
      newError.waktu_akhir = "isi tanggal akhir";
    }
    if (!form.message) {
      newError.message = "isi pesan anda";
    }

    return newError;
  };

  const loadData = async () => {
    try {
      if (!cutiID) {
        enqueueSnackbar("pilih data terlebuh dahulu", {
          variant: "error",
        });
        return;
      }
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/off-days/${cutiID}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      setForm({
        ...response.data,
        batal_antrian: Boolean(response.data.batal_antrian),
      });
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.message ?? e.message, {
        variant: "error",
      });
      Sentry.captureException(e);
    }
  };

  const handleSubmit = async (e) => {
    const findValidationError = validate();
    if (Object.values(findValidationError).some((err) => err !== "")) {
      setValidationError(findValidationError);
    } else {
      try {
        let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/off-days`;
        if (cutiID) {
          url = url + `/${cutiID}`;
        }
        let newForm = {
          ...form,
          waktu_mulai: moment(form.waktu_mulai).format("YYYY-MM-DD"),
          waktu_akhir: moment(form.waktu_akhir).format("YYYY-MM-DD"),
        };
        await axios.post(url, newForm, {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        });
        enqueueSnackbar("sukses menyimpan data", {
          variant: "success",
        });
        handleOnSave();
        handleModalClose();
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.response?.data?.message ?? e.message, {
          variant: "error",
        });
        if (e.request?.status === 422) {
          Object.entries(e.response.data.errors).forEach(([key, value]) => {
            setValidationError((prev) => ({
              ...prev,
              [key]: Array.isArray(value) ? value[0] : value,
            }));
          });
        }
        Sentry.captureException(e);
      } finally {
      }
    }
  };

  useEffect(() => {
    if (open && cutiID) {
      loadData();
    } else {
      setForm({ ...defaultForm });
    }
  }, [open, cutiID]);

  useEffect(() => {
    if (isMacOs && isSafari) {
      let vrs = parseInt(browserVersion);
      if (!isNaN(vrs) && vrs < 14) {
        setLegacyBrowser(true);
      }
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: "10px",
          maxHeight: "90vh",
          overflowY: "auto",
          ...webkit__scrollbar,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {cutiID ? "Edit Data" : "Buat Data"}
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <FormControl variant="standard" sx={form__control}>
            <InputLabel
              sx={{ ...input__label, marginBottom: "10px" }}
              shrink
              htmlFor="clinic_room_id"
            >
              Klinik
            </InputLabel>
            <Select
              id="clinic_id"
              name="clinic_id"
              value={form.clinic_id ?? ""}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              IconComponent={() => {
                return <></>;
              }}
              sx={input__select}
            >
              {user.clinics &&
                user.clinics.map((item, index) => {
                  return (
                    <MenuItem key={`options_${index}`} value={item.id}>
                      {item.nama}
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText error={true}>
              {validationError?.clinic_id}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="waktu_mulai">
              Start Date
            </InputLabel>
            {legacyBrowser ? (
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={
                  form.waktu_mulai ? moment(form.waktu_mulai).toDate() : ""
                }
                onChange={(date) => {
                  handleChange({
                    target: {
                      name: "waktu_mulai",
                      value: date,
                      id: "waktu_mulai",
                      type: "date",
                    },
                  });
                }}
              />
            ) : (
              <InputBase
                sx={input__date}
                placeholder=""
                id="waktu_mulai"
                name="waktu_mulai"
                type="date"
                value={form.waktu_mulai ?? ""}
                onChange={handleChange}
              />
            )}
            <FormHelperText error={true}>
              {validationError?.waktu_mulai}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="waktu_akhir">
              End Date
            </InputLabel>
            {legacyBrowser ? (
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={
                  form.waktu_akhir ? moment(form.waktu_akhir).toDate() : ""
                }
                onChange={(date) => {
                  handleChange({
                    target: {
                      name: "waktu_akhir",
                      value: date,
                      id: "waktu_akhir",
                      type: "date",
                    },
                  });
                }}
              />
            ) : (
              <InputBase
                sx={input__date}
                placeholder=""
                id="waktu_akhir"
                name="waktu_akhir"
                type="date"
                value={form.waktu_akhir ?? ""}
                onChange={handleChange}
              />
            )}
            <FormHelperText error={true}>
              {validationError?.waktu_akhir}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel
              sx={{ ...input__label, marginBottom: "10px" }}
              shrink
              htmlFor="clinic_room_id"
            >
              Status
            </InputLabel>
            <Select
              id="status"
              name="status"
              value={form.status ?? ""}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              IconComponent={() => {
                return <></>;
              }}
              sx={input__select}
            >
              <MenuItem value={"ACTIVE"}>Aktif</MenuItem>
              <MenuItem value={"HOLD"}>Hold</MenuItem>
            </Select>
            <FormHelperText error={true}>
              {validationError?.status}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="message">
              Pesan
            </InputLabel>
            <InputBase
              sx={input__text}
              multiline
              minRows={5}
              maxRows={5}
              placeholder=""
              id="message"
              name="message"
              type="text"
              value={form.message ?? ""}
              onChange={handleChange}
            />
            <FormHelperText error={true}>
              {validationError?.message}
            </FormHelperText>
          </FormControl>
          <FormControlLabel
            sx={{
              ".MuiFormControlLabel-label": {
                fontSize: "14px",
              },
              marginBottom: "10px",
            }}
            control={
              <Checkbox
                checked={form.batal_antrian}
                name="batal_antrian"
                id="batal_antrian"
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Batalkan Antrian
                  </Typography>
                  <Tooltip
                    title="Jika dicentang akan membatalkan pasien antrian yang sudah ada dan memblokir antrian baru di tanggal terpilih (akan muncul pesan anda ketika pasien gagal antri). Jika tidak dicentang, pasien antrian yang sudah ada di tanggal terpilih akan mendapatkan notifikasi sesuai pesan yang anda isi, serta akan memunculkan pesan anda di pasien saat antri di tanggal terpilih (Mis. pengumuman jadwal mundur / dokter pengganti)"
                    placement="right"
                  >
                    <IconButton>
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            }
          />
          <Button
            fullWidth
            sx={{
              backgroundColor: "blue",
              borderRadius: "8px",
              textTransform: "none",
              marginBottom: "15px",
              height: "38px",
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: 600,
                marginRight: "5px",
              }}
            >
              Simpan
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
