import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./middleware/PrivateRoute";
import Auth from "./pages/auth/Auth";
import HeadDoctorChooser from "./pages/auth/HeadDoctorChooser";
import Dashboard from "./pages/dashboard/Dashboard";
import AuthProvider from "./providers/AuthProvider";
import FirebaseProvider from "./providers/FirebaseProvider";

function App() {
  return (
    <>
      <FirebaseProvider>
        <AuthProvider>
          <Router>
            <Switch>
              <PrivateRoute path="/" exact component={Dashboard} />
              <PrivateRoute path="/rekam-medis" component={Dashboard} />
              <PrivateRoute path="/hasil-pemeriksaan" component={Dashboard} />
              <PrivateRoute path="/pembayaran" component={Dashboard} />
              <PrivateRoute path="/jadwal-praktek" component={Dashboard} />
              <PrivateRoute path="/obat" component={Dashboard} />
              <PrivateRoute path="/penyimpanan" component={Dashboard} />
              <PrivateRoute path="/cuti" component={Dashboard} />
              <PrivateRoute path="/icd10" component={Dashboard} />
              <PrivateRoute path="/chat" component={Dashboard} />
              <PrivateRoute path="/profile" component={Dashboard} />
              <PrivateRoute path="/desain-pembayaran" component={Dashboard} />
              <PrivateRoute path="/help" component={Dashboard} />
              <Route path="/login" component={Auth} />
              <Route path="/otp" component={Auth} />
              <Route path="/forgot-password" component={Auth} />
              <Route path="/head-doctors" component={HeadDoctorChooser} />
            </Switch>
          </Router>
        </AuthProvider>
      </FirebaseProvider>
    </>
  );
}

export default App;
