import { webkit__scrollbar } from "../../../style";

const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "15px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

const calendar__btn = {
  backgroundColor: "blue",
  height: "38px",
  color: "white",
  borderRadius: "8px",
  marginLeft: "10px",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const calendar__btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "10px",
  paddingLeft: "10px",
};

const widget__container = {
  width: "100%",
  p: 1,
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  background: "#fff",
  borderRadius: "8px",
};

const widget__header__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
};

const widget__header__title = {
  fontSize: "16px",
  fontWeight: 700,
  paddingLeft: "10px",
};

const widget__footer__wrapper = {
  paddingLeft: "5px",
  paddingTop: "10px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
  flexWrap: "wrap",
};

const widget__foooter__text = {
  fontSize: "16px",
  fontWeight: 700,
  paddingRight: "10px",
};

const widget__footer__pill = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
  padding: "3px 7px",
  gap: "10px",
  position: "relative",
  background: "rgba(211, 255, 231, 1)",
  color: "rgba(0, 160, 73, 1)",
  borderRadius: "8px",
};

const widget__footer__pill__label = {
  display: "inline-block",
  px: 0.3,
  width: "fit-content",
  fontSize: "12px",
  fontWeight: 400,
};

const tab__label = {
  fontWeight: 600,
  textTransform: "none",
  px: 0.5,
  fontSize: "14px",
};

const table__container = {
  maxHeight: "420px",
  ...webkit__scrollbar,
};

const table__cell__text = {
  fontSize: "14px",
  fontWeight: 600,
  padding: "10px auto",
};

const table__wrapper = {
  marginTop: "30px",
  width: "100%",
  paddingLeft: "20px",
  background: "#fff",
  borderRadius: "8px",
};

export {
  header__container,
  header__h1,
  calendar__btn,
  calendar__btn__label,
  widget__container,
  widget__header__wrapper,
  widget__header__title,
  widget__footer__wrapper,
  widget__foooter__text,
  widget__footer__pill,
  widget__footer__pill__label,
  tab__label,
  table__container,
  table__cell__text,
  table__wrapper,
};
