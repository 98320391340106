import { Box, Grid, Modal } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ScheduleBox from "../schedule-box/ScheduleBox";
import { modal__container } from "./style";
import PropTypes from "prop-types";

function ScheduleChooserModal({ open, onClose, date, handleScheduleSwitch }) {
  const schedule_list = useSelector((state) => state.schedule.schedule_list);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal__container}>
        <Grid container>
          {schedule_list &&
            schedule_list.length > 0 &&
            schedule_list
              .filter((i) => date.getDay() === i.hari)
              .map((item, idx) => {
                return (
                  <Grid key={idx} item xs={12} md={6} lg={6}>
                    <ScheduleBox
                      schedule={item}
                      date={date}
                      handleScheduleSwitch={handleScheduleSwitch}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </Box>
    </Modal>
  );
}

ScheduleChooserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  date: PropTypes.any.isRequired,
  handleScheduleSwitch: PropTypes.func.isRequired,
};

export default ScheduleChooserModal;
