const sidebar__item__selected = {
  position: "relative",
  display: "block",
  paddingBottom: "0px",
  color: "blue",
  "&:hover": {
    backgroundColor: "rgb(0 174 239 / 0.2)",
  },
  "&:click": {
    backgroundColor: "rgb(0 174 239 / 0.2)",
  },
  ".MuiTouchRipple-rippleVisible": {
    color: "rgb(0 174 239 / 0.5)",
  },
};

const sidebar__item__unselected = {
  position: "relative",
  display: "block",
  paddingBottom: "0px",
  "&:hover": {
    backgroundColor: "rgb(0 174 239 / 0.2)",
  },
  "&:click": {
    backgroundColor: "rgb(0 174 245 / 0.2)",
  },
  ".MuiTouchRipple-rippleVisible": {
    color: "rgb(0 174 245 / 0.5)",
  },
};

const sidebar__item__button___sidebar_open = {
  minHeight: 48,
  justifyContent: "center",
  paddingLeft: "20px",
  paddingRight: "20px",
};

const sidebar__item__button___sidebar_close = {
  ...sidebar__item__button___sidebar_open,
  justifyContent: "initial",
};

const sidebar__item__icon___sidebar_open = {
  minWidth: 0,
  mr: "10px",
  justifyContent: "center",
};

const sidebar__item__icon___sidebar_close = {
  ...sidebar__item__icon___sidebar_open,
  mr: "auto",
};

const sidebar__item__text___sidebar_open = {
  opacity: 1,
  fontWeight: 600,
};

const sidebar__item__text___sidebar_close = {
  ...sidebar__item__text___sidebar_open,
  opacity: 0,
};

export {
  sidebar__item__selected,
  sidebar__item__unselected,
  sidebar__item__button___sidebar_open,
  sidebar__item__button___sidebar_close,
  sidebar__item__icon___sidebar_open,
  sidebar__item__icon___sidebar_close,
  sidebar__item__text___sidebar_open,
  sidebar__item__text___sidebar_close,
};
