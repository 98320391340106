import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Stack } from "@mui/system";
import { useLiveQuery } from "dexie-react-hooks";
import {
  bookmark__header__text,
  bookmark__list__container,
  bookmark__list__item__box,
  bookmark__list__item__star__icon,
  header__container,
  header__h1,
  search__btn,
  table__cell__text,
  table__container,
  table__star__btn,
  table__star__icon__wrapper,
} from "./style";
import { search__input } from "../hasil-pemeriksaan/main/style";
import TheadCell from "../../components/table/thead-cell/TheadCell";
import { useAuth } from "../../../../providers/AuthProvider";
import { db } from "../../../../db/db";
import authHeader from "../../../../services/authHeader";
import * as Sentry from "@sentry/react";

export default function Icd() {
  const { user } = useAuth();
  const [search, setSearch] = useState("");
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState("icd10_code");
  const [sortType, setSortType] = React.useState("ASC");

  const icd_bookmark = useLiveQuery(() =>
    db.icd_bookmark.where("doctor_id").equals(user.id).toArray()
  );

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/icd10-table`,
        {
          params: {
            page: page,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: 10,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        Sentry.captureException(error);
      });
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handleICDClick = async (icd10_code, who_full_desc) => {
    let icdbook = await db.icd_bookmark
      .where("icd10_code")
      .equals(icd10_code)
      .and((item) => item.doctor_id === user.id)
      .toArray();
    if (icdbook.length > 0) {
      await db.icd_bookmark
        .where("icd10_code")
        .equals(icd10_code)
        .and((item) => item.doctor_id === user.id)
        .delete();
    } else {
      await db.icd_bookmark.add({
        icd10_code: icd10_code,
        who_full_desc: who_full_desc,
        doctor_id: user.id,
      });
    }
  };

  useEffect(() => {
    loadData();
  }, [search, page, sortBy, sortType]);

  return (
    <>
      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          ICD10
        </Typography>
        <Box>
          <InputBase
            sx={search__input}
            size="18"
            placeholder="Cari"
            value={search}
            onChange={handleSearchInputChange}
          ></InputBase>
          <IconButton aria-label="search" sx={search__btn}>
            <img src="/assets/icons/search.svg" alt="search"></img>
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={table__container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TheadCell
                      name="Kode ICD"
                      useSort={true}
                      column_name="icd10_code"
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    />
                    <TheadCell
                      name="Deskripsi"
                      useSort={true}
                      column_name="who_full_desc"
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    />
                    <TheadCell
                      name="Action"
                      useSort={false}
                      align={"center"}
                      style={{ maxWidth: 30, minWidth: 30 }}
                    />
                  </TableRow>
                </TableHead>
                {rows.length > 0 && (
                  <TableBody>
                    {rows.map((row, idx) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell
                          width={"10%"}
                          align={"left"}
                          sx={table__cell__text}
                        >
                          {row.icd10_code}
                        </TableCell>
                        <TableCell
                          align={"left"}
                          sx={table__cell__text}
                          width="20%"
                        >
                          {row.who_full_desc}
                        </TableCell>
                        <TableCell
                          align={"center"}
                          sx={table__cell__text}
                          width="5%"
                        >
                          <IconButton
                            onClick={() => {
                              handleICDClick(row.icd10_code, row.who_full_desc);
                            }}
                            color="inherit"
                            aria-label="refresh"
                            edge="start"
                            type="button"
                            sx={table__star__btn}
                          >
                            <Box sx={table__star__icon__wrapper}>
                              <LazyLoadImage
                                effect="blur"
                                src={
                                  icd_bookmark &&
                                  icd_bookmark?.find(
                                    (i) => i.icd10_code === row.icd10_code
                                  ) !== undefined
                                    ? "/assets/icons/star-icd-fill.svg"
                                    : "/assets/icons/star-icd.svg"
                                }
                                alt="refresh"
                              ></LazyLoadImage>
                            </Box>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {rows.length < 1 && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        Tidak ada data
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <Stack alignItems="end">
              <Pagination
                shape="rounded"
                sx={{
                  p: 2,
                  ".Mui-selected": {
                    backgroundColor: "rgba(0, 174, 239, 1)!important",
                    color: "white",
                  },
                }}
                count={lastPage}
                showFirstButton
                showLastButton
                onChange={handlePageChange}
              />
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={0}>
            <Typography sx={bookmark__header__text}>Bookmark</Typography>
            <Box sx={bookmark__list__container}>
              {icd_bookmark &&
                icd_bookmark.length > 0 &&
                icd_bookmark.map((item, index) => {
                  return (
                    <Box key={index} sx={bookmark__list__item__box}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Kode
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                        }}
                      >
                        {item.icd10_code}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Diagnosa
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                        }}
                      >
                        {item.who_full_desc}
                      </Typography>
                      <img
                        onClick={() => {
                          handleICDClick(item.icd10_code, item.who_full_desc);
                        }}
                        effect="blur"
                        src="/assets/icons/star-icd-fill.svg"
                        alt="refresh"
                        style={bookmark__list__item__star__icon}
                      ></img>
                    </Box>
                  );
                })}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
