import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { input__label, input__text, section__title } from "../style";
import {
  diagnose__box__container,
  diagnose__box__header,
  diagnose__box__header__btn,
  diagnose__box__header__text,
  diagnose__list__container,
  diagnose__list__item,
  diagnose__list__item__delete__btn,
} from "./style";
import DiagnoseModal from "./diagnose-modal/DiagnoseModal";
import TemplateText from "../template-text/TemplateText";

export default function TabSoap({
  form,
  setForm,
  handleGeneralInputFormChange,
}) {
  const [focusInput, setFocusInput] = useState("");
  const [diagnoseModalOpen, setDiagnoseModalOpen] = useState(false);

  const handleDiagnoseModalOpen = () => {
    setDiagnoseModalOpen(true);
  };

  const handleDiagnoseModalClose = () => {
    setDiagnoseModalOpen(false);
  };

  const handleDiagnoseChoose = (diagnose) => {
    let find = form.diagnosa.find((i) => {
      return i.icd10_code === diagnose.icd10_code;
    });

    if (find === undefined) {
      setForm((prevForm) => ({
        ...prevForm,
        diagnosa: [...prevForm.diagnosa, diagnose],
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        diagnosa: prevForm.diagnosa.filter((item) => {
          return item.icd10_code !== diagnose.icd10_code;
        }),
      }));
    }
  };

  const handleInputFocus = (e) => {
    setFocusInput(e.target.name);
  };

  const handleTemplateClick = (value, name) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: prevForm[name]?.length < 1 ? value : prevForm[name] + " " + value,
    }));
  };

  return (
    <>
      <Typography sx={section__title}>SOAP</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={input__label} shrink htmlFor="subjektif">
                Subjektif
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="subjektif"
                id="subjektif"
                name="subjektif"
                type="text"
                value={form.subjektif}
                onChange={handleGeneralInputFormChange}
                onFocus={handleInputFocus}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>

            <TemplateText
              table="template_subjektif"
              onClick={(value) => {
                handleTemplateClick(value, "subjektif");
              }}
              open={focusInput === "subjektif" ? true : false}
            />

            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={input__label} shrink htmlFor="assesment">
                Penilaian
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="assesment"
                id="assesment"
                name="assesment"
                type="text"
                value={form.assesment}
                onChange={handleGeneralInputFormChange}
                onFocus={handleInputFocus}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>

            <TemplateText
              table="template_penilaian"
              onClick={(value) => {
                handleTemplateClick(value, "assesment");
              }}
              open={focusInput === "assesment" ? true : false}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={input__label} shrink htmlFor="objektif">
                Objektif
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="objektif"
                id="objektif"
                name="objektif"
                type="text"
                value={form.objektif}
                onChange={handleGeneralInputFormChange}
                onFocus={handleInputFocus}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>

            <TemplateText
              table="template_objektif"
              onClick={(value) => {
                handleTemplateClick(value, "objektif");
              }}
              open={focusInput === "objektif" ? true : false}
            />

            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={input__label} shrink htmlFor="plan">
                Perencanaan
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="perencanaan"
                id="plan"
                name="plan"
                type="text"
                value={form.plan}
                onChange={handleGeneralInputFormChange}
                onFocus={handleInputFocus}
              />
              <FormHelperText error={true}></FormHelperText>
            </FormControl>
            <TemplateText
              table="template_perencanaan"
              onClick={(value) => {
                handleTemplateClick(value, "plan");
              }}
              open={focusInput === "plan" ? true : false}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography sx={section__title}>Diagnosa</Typography>
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <Box sx={diagnose__box__container}>
              <Box sx={diagnose__box__header}>
                <Typography sx={diagnose__box__header__text}>
                  Diagnosa
                </Typography>
                <IconButton
                  aria-label="search-user"
                  sx={diagnose__box__header__btn}
                  onClick={handleDiagnoseModalOpen}
                >
                  <img src="/assets/icons/plus.svg" alt="create"></img>
                  <Typography sx={diagnose__box__header__text}>
                    Diagnosa
                  </Typography>
                </IconButton>
              </Box>
              <Box sx={diagnose__list__container}>
                {form?.diagnosa &&
                  form.diagnosa.length > 0 &&
                  form.diagnosa.map((item) => {
                    return (
                      <Box
                        key={`d_item_${item.icd10_code}`}
                        sx={diagnose__list__item}
                      >
                        <Typography
                          component={"span"}
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {item.icd10_code} - {item.who_full_desc}
                        </Typography>
                        <Typography
                          onClick={() => handleDiagnoseChoose(item)}
                          component={"span"}
                          sx={diagnose__list__item__delete__btn}
                        >
                          delete
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DiagnoseModal
        open={diagnoseModalOpen}
        onClose={handleDiagnoseModalClose}
        onChoose={handleDiagnoseChoose}
        form={form}
      />
    </>
  );
}
