import {
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function RekamMedisTablePopper({
  open,
  anchorEl,
  handleRedirectEdit,
  selectedRecord,
  handleRedirectCreate,
  handleDeleteConfirmationOpen,
  handleQrModalOpen,
  handleSendDataModalOpen,
  handlePopupRecordAway,
  handleRedirectEditNewTab,
  handleOpenInvoice,
  handleLinkScanFolderModalOpen,
}) {
  return (
    <ClickAwayListener onClickAway={handlePopupRecordAway}>
      <Popper
        data-group="popper-element"
        open={open}
        anchorEl={anchorEl}
        placement={"left"}
        sx={{
          zIndex: 1500,
        }}
      >
        <Box>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRedirectEditNewTab();
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/pencil.svg"
                      alt="edit"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Edit di Tab Baru"
                  />
                </ListItemButton>
              </ListItem>
              {selectedRecord && selectedRecord.user?.id && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={handleRedirectCreate}
                    sx={{
                      py: "0px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      <LazyLoadImage
                        effect="blur"
                        src="/assets/icons/pencil.svg"
                        alt="edit-invoice"
                      ></LazyLoadImage>
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        paddingTop: "2px",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                      primary="Rekam Medis Baru"
                    />
                  </ListItemButton>
                </ListItem>
              )}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleDeleteConfirmationOpen}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/trash.svg"
                      alt="send-invoice"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Hapus"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleQrModalOpen}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img src="/assets/icons/qr.svg" alt="qr"></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="QR Code"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleSendDataModalOpen}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/send.svg"
                      alt="send-invoice"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Bagikan"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleLinkScanFolderModalOpen}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/send.svg"
                      alt="send-invoice"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Tautkan Hasil Pemeriksaan"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleOpenInvoice}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img src="/assets/icons/document.svg" alt="invoice"></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary={
                      selectedRecord?.invoice?.id
                        ? "Buka Invoice"
                        : "Buat Invoice"
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}

export default RekamMedisTablePopper;
