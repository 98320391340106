import React, { useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import firebaseConfig from "../config/firebase";
import io from "socket.io-client";
import * as Sentry from "@sentry/react";

const socket = io("wss://socket.mediplusindonesia.co.id", {
  transports: ["websocket", "pooling"],
  extraHeaders: {
    Connection: "Upgrade",
    Upgrade: "websocket",
  },
});

let firebaseApp;
let messaging;
try {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
} catch (err) {
  console.error("Failed to initialize Firebase Messaging", err);
}
const FirebaseContext = React.createContext();

/**
 * Hybrid FCM & Websocket
 *
 */

export function useFirebase() {
  return useContext(FirebaseContext);
}

function FirebaseProvider(props) {
  const [webSockConnected, SetWebSockConnected] = useState(socket.connected);
  const [fcmToken, setFcmToken] = useState(null);
  const [fcmLoading, setFcmLoading] = useState(true);
  const [fcmError, setFcmError] = useState(false);
  const [wsLoading, setWsLoading] = useState(true);
  const [wsError, setWsError] = useState(false);
  const [ws, setWs] = useState(null);
  const [wsToken, setWsToken] = useState(null);

  const fetchToken = async () => {
    let supported = await isSupported();
    if (supported) {
      let firebaseTimeOut = setTimeout(() => {
        setFcmLoading(false);
        setFcmError(true);
      }, 10000);
      return getToken(messaging, {
        vapidKey:
          "BDfPYMkI_r0YoxQVSObnPmzRKyIqitBJqnlLRuXUnYIN6gM-uZ0aj5_BdjVxt1hLjI64k4u3iHxxx-rMC0R-wI4",
      })
        .then((currentToken) => {
          if (currentToken) {
            clearInterval(firebaseTimeOut);
            setFcmToken(currentToken);
            setFcmLoading(false);
            setFcmError(false);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
            setFcmLoading(false);
            setFcmError(true);
          }
        })
        .catch((err) => {
          console.log("", err);
          setFcmError(true);
          setFcmLoading(false);
        });
    } else {
      setFcmError(true);
      setFcmLoading(false);
    }
  };

  useEffect(() => {
    let socketTimeout = setTimeout(() => {
      setWsLoading(false);
      setWsError(true);
    }, 5000);
    socket.on("connect", (e) => {
      clearInterval(socketTimeout);
      SetWebSockConnected(true);
      setWs(socket);
      setWsToken(socket.id);
      setWsLoading(false);
    });
    socket.on("disconnect", () => {
      clearInterval(socketTimeout);
      setWsLoading(false);
      SetWebSockConnected(false);
    });
    socket.on("error", (e) => {
      clearInterval(socketTimeout);
      setWsLoading(false);
      setWsError(true);
      console.log(e);
    });

    fetchToken();
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("error");

      clearInterval(socketTimeout);
    };
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        fcmToken,
        fcmLoading,
        fcmError,
        messaging,
        wsToken,
        wsLoading,
        wsError,
        ws,
        webSockConnected,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;
