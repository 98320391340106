import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  form__control,
  input__label,
  input__select,
} from "../../../../rekam-medis/editor/style";

export default function SortModal({
  open = false,
  onClose = () => {},
  sortBy,
  setSortBy,
  sortType,
  setSortType,
}) {
  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleSortTypeChange = (e) => {
    setSortType(e.target.value);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Sort Data Produk
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel
                  sx={{ ...input__label, marginBottom: "10px" }}
                  shrink
                  htmlFor="clinic_room_id"
                >
                  Sort By
                </InputLabel>
                <Select
                  id="sort_by"
                  name="sort_by"
                  value={sortBy ?? ""}
                  onChange={handleSortByChange}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  IconComponent={() => {
                    return <></>;
                  }}
                  sx={input__select}
                >
                  <MenuItem value={"nama"}>Nama</MenuItem>
                  <MenuItem value={"harga"}>Harga</MenuItem>
                  <MenuItem value={"created_at"}>Dibuat</MenuItem>
                </Select>
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" sx={form__control}>
                <InputLabel
                  sx={{ ...input__label, marginBottom: "10px" }}
                  shrink
                  htmlFor="clinic_room_id"
                >
                  Sort Type
                </InputLabel>
                <Select
                  id="sort_type"
                  name="sort_type"
                  value={sortType ?? ""}
                  onChange={handleSortTypeChange}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  IconComponent={() => {
                    return <></>;
                  }}
                  sx={input__select}
                >
                  <MenuItem value={"ASC"}>ASC</MenuItem>
                  <MenuItem value={"DESC"}>DESC</MenuItem>
                </Select>
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
