import React from "react";
import {
  Button,
  ClickAwayListener,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import {
  header__btn,
  header__btn__label,
  header__container,
  header__h1,
  table__cell__text,
  table__container,
} from "./style";
import _ from "lodash";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import * as Sentry from "@sentry/react";
import { search__input } from "../hasil-pemeriksaan/main/style";
import TheadCell from "../../components/table/thead-cell/TheadCell";
import CutiPopper from "./popper/CutiPopper";
import { useAuth } from "../../../../providers/AuthProvider";
import mediplusHelper from "../../../../utils/mediplusHelper";
import authHeader from "../../../../services/authHeader";
import EditorModal from "./modal/EditorModal";

export default function Cuti() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [isGeneratePDFLoading, setIsGeneratePDFLoading] = useState(false);
  const [sendModalOpen, setSendModalOpen] = useState(false);

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  //table
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState("created_at");
  const [sortType, setSortType] = React.useState("DESC");
  const [search, setSearch] = React.useState("");

  //snackbar
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  //popper
  const [popperEl, setPopperEl] = React.useState(null);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});

  //editor modal
  const [cutiID, setCutiID] = useState(null);
  const [editorModalOpen, setEditorModalOpen] = useState(false);

  const handleCreateCuti = () => {
    setPopupOpen(false);
    setCutiID(null);
    setEditorModalOpen(true);
  };

  const handleEditCuti = () => {
    if (_.isEmpty(selectedData)) {
      enqueueSnackbar("pilih data terlebuh dahulu", {
        variant: "error",
      });
      return;
    }
    setPopupOpen(false);
    setCutiID(selectedData.id);
    setEditorModalOpen(true);
  };

  const handleEditorModalClose = () => {
    setCutiID(null);
    setEditorModalOpen(false);
  };

  const handleEditorOnSave = () => {
    setCutiID(null);
    setEditorModalOpen(false);
    loadData();
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const handlePopupOpen = (event) => {
    setPopperEl(event.currentTarget);
    let cutiId = parseInt(event.currentTarget.getAttribute("data-id"));
    let cuti = rows.find((item) => {
      return item.id === cutiId;
    });

    if (cuti !== undefined) {
      setSelectedData(cuti);
      if (event.currentTarget === popperEl) {
        setPopupOpen(!popupOpen);
      } else {
        setPopupOpen(true);
      }
    } else {
      enqueueSnackbar("data not found", {
        type: "error",
      });
    }
  };

  const handlePopupAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupOpen) setPopupOpen(false);
    }
  };

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/off-days`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            search: search,
            pagination: 6,
            head: user.head_doctor_id,
            "sort-by": sortBy,
            "sort-type": sortType,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        Sentry.captureException(error);
      });
  };

  const deleteData = async () => {
    setDeleteConfirmationOpen(false);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/off-days/${selectedData.id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      loadData();
      enqueueSnackbar("data terhapus", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
      });
    } finally {
    }
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
    setPopupOpen(false);
  };

  React.useEffect(() => {
    setLoading(true);
    loadData();
    return () => {
      setPopupOpen(false);
      setPopperEl(null);
    };
  }, [current_active_schedule, page, sortBy, sortType, search]);

  return (
    <>
      <EditorModal
        open={editorModalOpen}
        onClose={handleEditorModalClose}
        cutiID={cutiID}
        onSave={handleEditorOnSave}
      />
      <CutiPopper
        open={popupOpen}
        anchorEl={popperEl}
        handleOpenDeleteConfirmation={handleOpenDeleteConfirmation}
        onClickAway={handlePopupAway}
        handleEditCuti={handleEditCuti}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "5px",
                  marginRight: "20px",
                }}
              >
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={deleteData}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />

      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Cuti Dokter
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            onClick={handleCreateCuti}
            aria-label="search-user"
            sx={header__btn}
          >
            <img src="/assets/icons/plus.svg" alt="create"></img>
            <Typography sx={header__btn__label}>Buat Data Cuti</Typography>
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            sx={{
              ...table__container,
              maxHeight: isDesktop ? 520 : "calc(100vh - 300px)",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TheadCell
                    name="Klinik"
                    useSort={true}
                    column_name="clinic_id"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    onClick={handleSortChange}
                  />
                  <TheadCell
                    name="Tanggal Cuti"
                    useSort={true}
                    column_name="waktu_mulai"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    onClick={handleSortChange}
                  />
                  <TheadCell
                    name="Pesan"
                    useSort={true}
                    column_name="message"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    onClick={handleSortChange}
                  />
                  <TheadCell
                    name="Antrian"
                    useSort={true}
                    column_name="batal_antrian"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    onClick={handleSortChange}
                  />
                  <TheadCell
                    name="Dibuat"
                    useSort={true}
                    column_name="created_at"
                    currentSortColumnName={sortBy}
                    currentSortType={sortType}
                    onClick={handleSortChange}
                  />
                  <TheadCell name="Action" useSort={false} />
                </TableRow>
              </TableHead>
              {rows.length > 0 && (
                <TableBody>
                  {rows.map((row, idx) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={idx}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <TableCell align={"left"} sx={table__cell__text}>
                        {row.clinic?.nama}
                      </TableCell>
                      <TableCell align={"left"} sx={table__cell__text}>
                        {moment(row.waktu_mulai).format("DD-MM-YYYY")} s/d{" "}
                        {moment(row.waktu_akhir).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align={"left"} sx={table__cell__text}>
                        {row.message}
                      </TableCell>
                      <TableCell align={"left"} sx={table__cell__text}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            textAlign: "center",
                            padding: "5px 16px",
                            gap: "10px",
                            position: "relative",
                            height: "30px",
                            background:
                              Boolean(row.batal_antrian) === false
                                ? "rgba(211, 255, 231, 1)"
                                : "rgba(255, 222, 222, 1)",
                            color:
                              Boolean(row.batal_antrian) === false
                                ? "rgba(0, 160, 73, 1)"
                                : "rgba(255, 0, 0, 1)",
                            borderRadius: "32px",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "inline-block",
                              width: "100%",
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                          >
                            {Boolean(row.batal_antrian) === false
                              ? "Dilanjutkan"
                              : "Dibatalkan"}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align={"left"} sx={table__cell__text}>
                        {moment(row.created_at).format("DD-MM-YYYY HH:MM")}
                      </TableCell>

                      <TableCell
                        align={"left"}
                        sx={table__cell__text}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={(e) => {
                            e.stopPropagation();
                            handlePopupAway(e);
                          }}
                        >
                          <IconButton
                            data-group="popper-toggler"
                            data-id={row.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePopupOpen(e);
                            }}
                            color="inherit"
                            aria-label="refresh"
                            edge="start"
                            type="button"
                            sx={{
                              px: "15px",
                              ":hover": {
                                background: "none",
                              },
                            }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/icons/table-action.svg"
                              alt="refresh"
                            ></LazyLoadImage>
                          </IconButton>
                        </ClickAwayListener>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {rows.length < 1 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Tidak ada data
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <Stack alignItems="end">
            <Pagination
              shape="rounded"
              sx={{
                p: 2,
                ".Mui-selected": {
                  backgroundColor: "rgba(0, 174, 239, 1)!important",
                  color: "white",
                },
              }}
              count={lastPage}
              showFirstButton
              showLastButton
              onChange={handlePageChange}
            />
          </Stack>
        </Paper>
      </Box>
    </>
  );
}
