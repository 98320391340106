import React from "react";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import Topbar from "./components/topbar/Topbar";
import Sidebar from "./components/sidebar/Sidebar";
import DrawerHeader from "./components/sidebar/components/drawer-header/DrawerHeader";
import { content__container, content__wrapper } from "./style";
import DashboardRoute from "./route/DashboardRoute";
import { useAuth } from "../../providers/AuthProvider";
import { useFirebase } from "../../providers/FirebaseProvider";
import AppLoading from "../components/loading/AppLoading";
import FirebaseError from "../components/error/FirebaseError";
import { useRouteMatch } from "react-router-dom";
import { onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";
import { db } from "../../db/db";
import { useSnackbar } from "notistack";
import { updateQueue } from "../../redux/slices/queueUpdate";
import { updateScanFolder } from "../../redux/slices/scanFolderUpdate";
import { updateChat } from "../../redux/slices/chatUpdate";
import { updateChatRead } from "../../redux/slices/chatRead";
import { updateHardware } from "../../redux/slices/hardwareUpdate";
import axios from "axios";
import authHeader from "../../services/authHeader";
import { useEffect } from "react";
import { offset } from "@popperjs/core";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { updateNewMedicalRecord } from "../../redux/slices/newMedicalRecordUpdate";

export default function Dashboard() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const { loading, user } = useAuth();
  const {
    fcmLoading,
    fcmError,
    messaging,
    fcmToken,
    wsLoading,
    wsError,
    ws,
    wsToken,
  } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const routeMatch = useRouteMatch();

  const [sidebarOpen, setSidebarOpen] = React.useState(
    isDesktop ? true : false
  );
  const [sidebarSize, setSidebarSize] = React.useState(isDesktop ? 240 : 60);

  const handleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    if (sidebarOpen === true) {
      setSidebarSize(65);
    } else {
      setSidebarSize(240);
    }
  };

  const handleUpdateTokenFcmWebsock = (token, type = "FCM") => {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/`;
    let key = "";
    if (type === "FCM") {
      url = url + `me/fcm`;
      key = "fcm_token";
    } else {
      url = url + `me/websocket`;
      key = "websocket_token";
    }
    if (user && token) {
      axios
        .post(
          url,
          {
            [key]: token,
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          Sentry.captureException(error);
        });
    }
  };

  useEffect(() => {
    let fcmListener;
    if (loading === false && fcmLoading === false && wsLoading === false) {
      if (fcmError === false) {
        handleUpdateTokenFcmWebsock(fcmToken, "FCM");
        fcmListener = onMessage(messaging, (payload) => {
          console.log(payload);
          if (payload.data.type === "queue_update") {
            dispatch(updateQueue());
          }
          if (payload.data.type === "file_update") {
            dispatch(updateScanFolder());
          }
          if (payload.data.type === "chat_update_2") {
            dispatch(updateChat());
          }
          if (payload.data.type === "chat_read") {
            dispatch(updateChatRead());
          }
          if (payload.data.type === "hardware_update") {
            dispatch(updateHardware());
          }
          if (payload.data.type === "new_medical_record") {
            dispatch(updateNewMedicalRecord());
          }
          if (payload.data.type === "notification_update") {
            if (payload.data.hasOwnProperty("message")) {
              enqueueSnackbar(
                <>
                  <div>
                    {payload.data.title}
                    <br></br>
                    {payload.data.message}
                  </div>
                </>,
                {
                  variant: "default",
                }
              );
            }
            db.notifications.add({
              title: payload.data.title,
              message: payload.data.message,
              is_read: false,
              created: new Date().getTime(),
            });
          }
          if (payload.data.type === "chat_update") {
            dispatch(updateChat());
          }
        });
      }

      if (wsError === false) {
        handleUpdateTokenFcmWebsock(wsToken, "WEBSOCKET");
        if (ws !== null) {
          ws.on("data", (event) => {
            console.log(event);
            if (fcmError === true) {
              if (event.type === "queue_update") {
                dispatch(updateQueue());
              }
              if (event.type === "file_update") {
                dispatch(updateScanFolder());
              }
              if (event.type === "chat_update_2") {
                dispatch(updateChat());
              }
              if (event.type === "chat_read") {
                dispatch(updateChatRead());
              }
              if (event.type === "hardware_update") {
                dispatch(updateHardware());
              }
              if (event.type === "chat_update") {
                dispatch(updateChat());
              }
              if (event.type === "new_medical_record") {
                dispatch(updateNewMedicalRecord());
              }
              if (event.type === "notification_update") {
                if (event?.data.hasOwnProperty("message")) {
                  enqueueSnackbar(
                    <>
                      <div>
                        {event.data?.title}
                        <br></br>
                        {event.data?.message}
                      </div>
                    </>,
                    {
                      variant: "default",
                    }
                  );
                }
                db.notifications.add({
                  title: event.data?.title,
                  message: event.data?.message,
                  is_read: false,
                  created: new Date().getTime(),
                });
              }
            }
          });
        }
      }
    }

    return () => {
      /**
       * Clear all listener
       */
      if (fcmListener !== undefined) {
        if (typeof fcmListener === "function") {
          fcmListener();
        }
        if (ws !== null) {
          ws.off("data");
        }
      }
    };
  }, [loading, fcmLoading, wsLoading, fcmError]);

  if (loading || fcmLoading || wsLoading) {
    return <AppLoading />;
  }

  if (fcmError && wsError) {
    return <FirebaseError />;
  }

  /**
   * Firebase Messaging Listener Handler
   */

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar isSidebarOpen={sidebarOpen} handleSidebar={handleSidebar} />
      <Sidebar open={sidebarOpen} />
      <DrawerHeader
        sx={{
          padding: "0px",
        }}
      />
      <Box
        component="div"
        sx={{
          ...content__wrapper,
          minWidth: `calc(100% - ${sidebarSize + 1}px)`,
        }}
      >
        <Box component="main" sx={content__container}>
          <DashboardRoute />
        </Box>
      </Box>
    </Box>
  );
}
