import React from "react";
import { Container, Typography } from "@mui/material";
import PropTypes from "prop-types";

function LoadError({ title, message }) {
  return (
    <Container maxWidth="xs">
      <div
        style={{
          textAlign: "center",
          marginTop: "-30px",
        }}
      >
        <Typography
          variant="subtitle"
          component="h4"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "calc(100vh - 100px)",
          }}
        >
          <img
            src="/assets/logo-mediplus.svg"
            alt="logo-mediplus"
            width="200"
            height="30"
            style={{
              marginBottom: "10px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></img>
          <div
            style={{
              width: "100%",
            }}
          >
            {title}
            <br></br>
            {message}
          </div>
        </Typography>
      </div>
    </Container>
  );
}

LoadError.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default LoadError;
