import { TableCell } from "@mui/material";
import React from "react";
import { sort__img, table__head } from "./style";
import PropTypes from "prop-types";

const stickyStyle = {
  top: 0,
  left: 0,
  zIndex: 100,
  position: "sticky",
  background: "#fff",
};

function TheadCell({
  name,
  column_name,
  onClick,
  useSort,
  currentSortColumnName,
  currentSortType,
  imageColumn,
  align = "left",
  style = {},
}) {
  return (
    <TableCell
      onClick={() => {
        if (typeof onClick === "function") {
          onClick(column_name);
        }
      }}
      align={align}
      sx={{
        ...{ ...table__head, paddingLeft: imageColumn ? "75px" : "auto" },
        borderTop: "1px solid rgba(212, 212, 212, 1)",
        ...style,
        ...stickyStyle,
      }}
    >
      {name}
      {useSort ? (
        <>
          {currentSortColumnName === column_name && (
            <img
              src="/assets/icons/arrow-updown.svg"
              alt="sort"
              style={{
                ...sort__img,
                transform:
                  currentSortType === "ASC"
                    ? "translateY(-50%) rotate(180deg)"
                    : "translateY(-50%)",
              }}
            ></img>
          )}
        </>
      ) : (
        <></>
      )}
    </TableCell>
  );
}

TheadCell.propTypes = {
  name: PropTypes.string.isRequired,
  column_name: PropTypes.string,
  onClick: PropTypes.func,
  useSort: PropTypes.bool.isRequired,
  currentSortColumnName: PropTypes.string,
  currentSortType: PropTypes.string,
  imageColumn: PropTypes.bool,
  align: PropTypes.string,
  style: PropTypes.object,
};

export default TheadCell;
