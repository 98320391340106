import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Pagination,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import TheadCell from "../../../../components/table/thead-cell/TheadCell";
import { folder__info__text } from "../../../hasil-pemeriksaan/main/table/style";
import FilterModal from "../modal/FiterModal";
import {
  delete__btn,
  delete__btn__label,
  detail__btn,
  detail__btn__label,
  folder__info__container,
  folder__info__img,
  table__cell__text,
  table__container,
  table__info__header,
  thead__checkbox__container,
} from "./style";
import * as Sentry from "@sentry/react";
import {
  snackbar__content__container,
  snackbar__content__text,
} from "../../../rekam-medis/main/table/style";

export default function PenyimpananTable({
  search,
  modalFilterOpen,
  setModalFilterOpen,
  loadUsage,
}) {
  const history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  //table
  const [sortBy, setSortBy] = React.useState("created_at");
  const [sortType, setSortType] = React.useState("DESC");
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [selectedFolders, setSelectedFolders] = React.useState([]);
  const [isRowAllChecked, setIsRowAllChecked] = React.useState(false);

  //filter modal
  const [filter, setFilter] = React.useState({
    start_date: "",
    end_date: "",
    pagination: 5,
  });

  //snackbar
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  const loadData = (url = null) => {
    setLoading(true);
    axios
      .get(
        url
          ? url
          : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/v2-storage/folders`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: filter.pagination,
            head: user.head_doctor_id,
            "start-date":
              filter.start_date === null ||
              filter.start_date === undefined ||
              filter.start_date === ""
                ? null
                : moment(filter.start_date).format("YYYY-MM-DD"),
            "end-date":
              filter.end_date === null ||
              filter.end_date === undefined ||
              filter.end_date === ""
                ? null
                : moment(filter.end_date).format("YYYY-MM-DD"),
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
        setLoading(false);
      })
      .catch(function (e) {
        console.log(e);
        enqueueSnackbar(e.response?.data?.message ?? e.message, {
          variant: "error",
          autoHideDuration: "2000",
        });
        Sentry.captureException(e);
        setLoading(false);
      });
  };

  const handleFolderDelete = () => {
    setDeleteConfirmationOpen(false);
    if (selectedFolders.length > 0) {
      enqueueSnackbar("memproses hapus folder...", {
        variant: "default",
        autoHideDuration: "10000",
      });
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/v2-storage/folders`,
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
            data: {
              ids: selectedFolders,
            },
          }
        )
        .then((response) => {
          enqueueSnackbar("sukses menghapus folder", {
            variant: "success",
            autoHideDuration: "2000",
          });
          setSelectedFolders([]);
          loadUsage();
        })
        .catch(function (e) {
          console.log(e);
          enqueueSnackbar(e.response?.data?.message ?? e.message, {
            variant: "error",
            autoHideDuration: "2000",
          });
          Sentry.captureException(e);
        })
        .finally(() => {
          loadData();
        });
    } else {
      enqueueSnackbar("silahkan pilih minimal 1 folder", {
        variant: "warning",
        autoHideDuration: "2000",
      });
    }
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handleIsAllChecked = () => {
    let rowIds = [];
    rows.forEach((i) => {
      rowIds.push(i.id);
    });
    let isAllChecked = true;
    if (rowIds.length < 1) {
      isAllChecked = false;
    } else {
      for (const v of rowIds) {
        if (!selectedFolders.includes(v)) {
          isAllChecked = false;
          break;
        }
      }
    }
    setIsRowAllChecked(isAllChecked);
  };

  const handleParentCheckBox = (e) => {
    let rowIds = [];
    rows.forEach((i) => {
      rowIds.push(i.id);
    });

    if (e.target.checked) {
      rowIds.every((v) => {
        if (!selectedFolders.includes(v)) {
          if (selectedFolders.length >= 20) {
            enqueueSnackbar("maksimal 20 folder", {
              variant: "warning",
              autoHideDuration: "2000",
            });
            return false;
          } else {
            setSelectedFolders((prev) => [...prev, v]);
            handleIsAllChecked();
          }
        }
        return true;
      });
    } else {
      setSelectedFolders((prev) => prev.filter((v) => !rowIds.includes(v)));
      handleIsAllChecked();
    }
  };

  const handleCheckBox = (e, id) => {
    if (e.target.checked) {
      if (!selectedFolders.includes(id)) {
        if (selectedFolders.length >= 20) {
          enqueueSnackbar("maksimal 20 folder", {
            variant: "warning",
            autoHideDuration: "2000",
          });
        } else {
          setSelectedFolders((prev) => [...prev, id]);
        }
      }
    } else {
      setSelectedFolders((prev) => prev.filter((v) => v !== id));
    }
    handleIsAllChecked();
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const handleRedirect = (id) => {
    window.open(`/penyimpanan/${id}`, "_blank");
  };

  useEffect(() => {
    loadData();
  }, [page, search, sortBy, sortType]);

  useEffect(() => {
    handleIsAllChecked();
  }, [selectedFolders, rows]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={handleFolderDelete}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />
      <Box sx={table__info__header}>
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          Folder Penyimpanan
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyItems: "center",
            gap: "5px",
          }}
        >
          {loading && (
            <img src="/roll.svg" alt="roll" width={30} height={30}></img>
          )}
          <IconButton
            onClick={() => {
              setDeleteConfirmationOpen(true);
            }}
            aria-label="search-user"
            sx={delete__btn}
          >
            <img src="/assets/icons/trash-white.svg" alt="delete"></img>
            <Typography sx={delete__btn__label}>
              Hapus ({selectedFolders.length} Folder)
            </Typography>
          </IconButton>
        </Box>
      </Box>

      <TableContainer sx={table__container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={thead__checkbox__container} align={"left"}>
                <FormGroup
                  sx={{
                    width: "35px",
                    height: "35px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleParentCheckBox}
                        checked={isRowAllChecked ? true : false}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                      />
                    }
                    label=""
                  />
                </FormGroup>
              </TableCell>
              <TheadCell
                name="Nama"
                useSort={true}
                column_name="name"
                currentSortColumnName={sortBy}
                currentSortType={sortType}
                imageColumn={true}
                onClick={handleSortChange}
              />
              <TheadCell
                name="Tanggal"
                useSort={true}
                column_name="created_at"
                currentSortColumnName={sortBy}
                currentSortType={sortType}
                onClick={handleSortChange}
              />
              <TheadCell
                name="Jumlah Berkas"
                useSort={true}
                column_name="count_files"
                currentSortColumnName={sortBy}
                currentSortType={sortType}
                onClick={handleSortChange}
              />
              <TheadCell
                name="Storage Terpakai"
                useSort={true}
                column_name="size"
                currentSortColumnName={sortBy}
                currentSortType={sortType}
                onClick={handleSortChange}
              />
              <TheadCell name="Action" useSort={false} />
            </TableRow>
          </TableHead>
          {rows.length > 0 && (
            <TableBody>
              {rows.map((row, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={`tr_${i}`}>
                  <TableCell align={"left"}>
                    <FormGroup
                      sx={{
                        width: "35px",
                        height: "35px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              handleCheckBox(e, row.id);
                            }}
                            checked={
                              selectedFolders.includes(row.id) ? true : false
                            }
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 18 },
                            }}
                          />
                        }
                        label=""
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell align={"left"}>
                    <Paper sx={folder__info__container}>
                      <img
                        data-user-id={i}
                        src={"/assets/icons/folder.svg"}
                        alt="sfolder"
                        style={folder__info__img}
                      ></img>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography component={"span"} sx={folder__info__text}>
                          {row.name
                            ? mediplusHelper.formatScanFolderName(row.name)
                            : "-"}
                        </Typography>
                        <Typography
                          component={"span"}
                          sx={{
                            ...folder__info__text,
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          {row.medical_record?.user?.nama ??
                            row.medical_record?.record_temporary_patient_data ??
                            ""}
                        </Typography>
                      </Box>
                    </Paper>
                  </TableCell>
                  <TableCell align={"left"} sx={table__cell__text} width="20%">
                    {moment(row.created_at).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell align={"left"} sx={table__cell__text} width="20%">
                    {row.count_files ?? "0"}
                  </TableCell>
                  <TableCell align={"left"} sx={table__cell__text} width="20%">
                    {mediplusHelper.fileSizeIEC(row.size)}
                  </TableCell>
                  <TableCell align={"left"} sx={table__cell__text} width="10%">
                    <IconButton
                      data-scan-id={row.id}
                      onClick={() => {
                        handleRedirect(row.id);
                      }}
                      color="inherit"
                      aria-label="refresh"
                      edge="start"
                      type="button"
                      sx={{
                        marginLeft: "0px",
                      }}
                    >
                      <Box sx={detail__btn}>
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/icons/user-search.svg"
                          alt="refresh"
                        ></LazyLoadImage>
                        <Typography sx={detail__btn__label}>Detail</Typography>
                      </Box>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {rows.length < 1 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Tidak ada data
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Stack alignItems="end">
        <Pagination
          shape="rounded"
          sx={{
            p: 2,
            ".Mui-selected": {
              backgroundColor: "rgba(0, 174, 239, 1)!important",
              color: "white",
            },
          }}
          count={lastPage}
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </Stack>
      <FilterModal
        open={modalFilterOpen}
        onClose={() => setModalFilterOpen(false)}
        filter={filter}
        setFilter={setFilter}
        loadData={loadData}
      />
    </>
  );
}
