import {
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import {
  box__img,
  box__media__container,
  box__pdf,
  box__pdf__icon,
  box__video,
  box__video__icon,
  footer__btn,
  footer__btn__label,
  footer__container,
  media__info__container,
  media__info__label,
  media__info__value,
  media__list__wrapper,
} from "./style";
import SendAntrianModal from "../../../../components/modal/send-antrian-modal/SendAntrianModal";
import QRModal from "../../../../components/modal/qr-modal/QRModal";
import SendDataModal from "../../../../components/modal/send-data-modal/SendDataModal";
import MediaModal from "../../../../components/modal/media-modal/MediaModal";

export default function DetailHasilPemeriksaanContent({ type, scanFolder }) {
  const history = useHistory();
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [sendAntrianModalOpen, setSendAntrianModalOpen] = useState(false);
  const [qrModalOpen, setQRModalOpen] = React.useState(false);
  const [sendModalOpen, setSendModalOpen] = React.useState(false);
  const [media, setMedia] = React.useState(null);

  const handleMediaModalOpen = (media) => {
    if (
      mediplusHelper.isImageMime(media.mime_type) ||
      mediplusHelper.isVideoMime(media.mime_type)
    ) {
      setMedia(media);
    } else {
      window.open(media.s3_url, "_blank");
    }
  };

  const handleMediaModalClose = () => {
    setMedia(null);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Box sx={media__list__wrapper}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                rowGap: "20px",
                gap: "40px",
              }}
            >
              {scanFolder &&
                scanFolder.media &&
                scanFolder.media.length > 0 &&
                scanFolder.media
                  .filter((item) => {
                    if (type === "ALL") {
                      return true;
                    } else if (type === "IMAGE") {
                      return (
                        mediplusHelper.isImageMime(item.mime_type) === true
                      );
                    } else if (type === "VIDEO") {
                      return (
                        mediplusHelper.isVideoMime(item.mime_type) === true
                      );
                    } else {
                      return (
                        mediplusHelper.isVideoMime(item.mime_type) === false &&
                        mediplusHelper.isImageMime(item.mime_type) === false
                      );
                    }
                  })
                  .map((item) => {
                    return (
                      <Box key={`box_${item.id}`}>
                        {mediplusHelper.isImageMime(item.mime_type) && (
                          <Paper
                            onClick={() => {
                              setSelectedMedia(item);
                            }}
                            key={item.id}
                            sx={box__media__container}
                          >
                            <img
                              src={item.s3_url}
                              alt="media"
                              sttyle={box__img}
                            />
                          </Paper>
                        )}
                        {mediplusHelper.isVideoMime(item.mime_type) && (
                          <Paper
                            onClick={() => {
                              setSelectedMedia(item);
                            }}
                            key={item.id}
                            sx={box__media__container}
                          >
                            <video style={box__video}>
                              <source
                                src={item.s3_url}
                                type="video/mp4"
                              ></source>
                            </video>
                            <LazyLoadImage
                              style={box__video__icon}
                              src="/assets/chat/play.png"
                            ></LazyLoadImage>
                          </Paper>
                        )}

                        {!mediplusHelper.isImageMime(item.mime_type) &&
                          !mediplusHelper.isVideoMime(item.mime_type) && (
                            <Paper
                              onClick={() => {
                                setSelectedMedia(item);
                              }}
                              key={item.id}
                              sx={box__pdf}
                            >
                              <img
                                style={box__pdf__icon}
                                src="/assets/icons/pdf.svg"
                                alt="doc"
                              ></img>
                            </Paper>
                          )}

                        <Typography
                          sx={{
                            marginTop: "5px",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {item.custom_properties?.original_filenamea ??
                            item.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#7D7D7D",
                          }}
                        >
                          {mediplusHelper.fileSizeIEC(item.size)}
                        </Typography>
                      </Box>
                    );
                  })}
            </Box>
          </Box>

          <Box sx={footer__container}>
            <Button
              sx={{
                ...footer__btn,
                backgroundColor: "#FF0091",
              }}
              variant="contained"
              onClick={() => {
                setSendAntrianModalOpen(true);
              }}
            >
              <img
                width="20"
                height="20"
                src="/assets/icons/profile-2-user.svg"
                alt="next-antrian"
              ></img>
              <Typography sx={footer__btn__label}>Pasien Antrian</Typography>
            </Button>
            <Box
              sx={{
                marginRight: "40px",
              }}
            >
              <Button
                sx={{
                  ...footer__btn,
                  backgroundColor: "blue",
                  marginRight: "20px",
                }}
                variant="contained"
                onClick={() => {
                  setQRModalOpen(true);
                }}
              >
                <img
                  width="20"
                  height="20"
                  src="/assets/icons/qr-white.svg"
                  alt="next-antrian"
                ></img>
                <Typography sx={footer__btn__label}>Scan QR</Typography>
              </Button>
              <Button
                sx={{
                  ...footer__btn,
                  backgroundColor: "blue",
                }}
                variant="contained"
                onClick={() => {
                  setSendModalOpen(true);
                }}
              >
                <img
                  width="20"
                  height="20"
                  src="/assets/icons/send-white.svg"
                  alt="next-antrian"
                ></img>
                <Typography sx={footer__btn__label}>Bagikan</Typography>
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Paper
            elevation={0}
            sx={{
              display: Boolean(selectedMedia) ? "block" : "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "0px",
              }}
            >
              {Boolean(selectedMedia) &&
                mediplusHelper.isImageMime(selectedMedia.mime_type) && (
                  <Paper
                    onClick={() => {
                      handleMediaModalOpen(selectedMedia);
                    }}
                    sx={{
                      ...box__media__container,
                      width: "290px",
                      height: "200px",
                    }}
                  >
                    <img
                      src={selectedMedia.s3_url}
                      style={box__img}
                      alt="media"
                    />
                  </Paper>
                )}

              {Boolean(selectedMedia) &&
                mediplusHelper.isVideoMime(selectedMedia.mime_type) && (
                  <Paper
                    onClick={() => {
                      handleMediaModalOpen(selectedMedia);
                    }}
                    key={selectedMedia.id}
                    sx={{
                      ...box__media__container,
                      width: "290px",
                      height: "200px",
                    }}
                  >
                    <video style={box__video} autoPlay={false}>
                      <source
                        src={selectedMedia?.s3_url ?? ""}
                        type="video/mp4"
                      ></source>
                    </video>

                    <LazyLoadImage
                      style={box__video__icon}
                      src="/assets/chat/play.png"
                    ></LazyLoadImage>
                  </Paper>
                )}

              {Boolean(selectedMedia) &&
                !mediplusHelper.isImageMime(selectedMedia.mime_type) &&
                !mediplusHelper.isVideoMime(selectedMedia.mime_type) && (
                  <Paper
                    onClick={() => {
                      handleMediaModalOpen(selectedMedia);
                    }}
                    sx={box__pdf}
                  >
                    <img
                      style={box__pdf__icon}
                      src="/assets/icons/pdf.svg"
                      alt="doc"
                    ></img>
                  </Paper>
                )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "5px 15px 5px 15px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    paddingTop: "7px",
                    maxWidth: "230px",
                    textAlign: "justify",
                  }}
                >
                  {Boolean(selectedMedia)
                    ? selectedMedia.custom_properties?.original_filenamea ??
                      selectedMedia.name
                    : "-"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#7D7D7D",
                  }}
                >
                  {Boolean(selectedMedia)
                    ? mediplusHelper.fileSizeIEC(selectedMedia.size)
                    : "-"}
                </Typography>
              </Box>

              <ClickAwayListener onClickAway={() => {}}>
                <IconButton
                  color="inherit"
                  aria-label="refresh"
                  edge="start"
                  type="button"
                >
                  <img src="/assets/icons/table-action.svg" alt="refresh"></img>
                </IconButton>
              </ClickAwayListener>
            </Box>
            <Box
              sx={{
                px: "5%",
              }}
            >
              <Typography sx={{}}>Informasi</Typography>
              <Box sx={media__info__container}>
                <Typography sx={media__info__label}>Nama Upload</Typography>
                <Typography sx={media__info__value}>
                  {Boolean(selectedMedia) ? selectedMedia.file_name : "-"}
                </Typography>
              </Box>

              <Box sx={media__info__container}>
                <Typography sx={media__info__label}>Format</Typography>
                <Typography sx={media__info__value}>
                  {Boolean(selectedMedia) ? selectedMedia.mime_type : "-"}
                </Typography>
              </Box>
              <Box sx={media__info__container}>
                <Typography sx={media__info__label}>Diupload</Typography>
                <Typography sx={media__info__value}>
                  {Boolean(selectedMedia)
                    ? moment(selectedMedia.created_at).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <MediaModal media={media} onClose={handleMediaModalClose} />
      <SendAntrianModal
        data={scanFolder}
        open={sendAntrianModalOpen}
        onChoose={() => {
          setSendAntrianModalOpen(false);
          history.push("/hasil-pemeriksaan");
        }}
        onClose={() => {
          setSendAntrianModalOpen(false);
        }}
      />
      <QRModal
        open={qrModalOpen}
        data={scanFolder}
        onClose={() => {
          setQRModalOpen(false);
        }}
        type="SCAN_FOLDER"
      />
      <SendDataModal
        open={sendModalOpen}
        data={scanFolder}
        onClose={() => {
          setSendModalOpen(false);
        }}
        type="SCAN_FOLDER"
      />
    </>
  );
}
