import { Box } from "@mui/system";
import React, { useCallback } from "react";
import useUndoableState from "../../../../../../../utils/undoableState";
import FiveSectionTooth from "./tooth/FiveSectionTooth";
import FourSectionTooth from "./tooth/FourSectionTooth";
import _ from "lodash";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import odontogramHelper from "./helper";
import {
  footer__container,
  form__control,
  input__label,
  input__select,
  input__text,
  submit__btn,
  submit__btn__label,
  tab__switch__btn,
  tab__switch__btn__label,
} from "../../style";
import {
  option__container,
  option__item__container,
  select__2,
  triangle__teeth__orientation__helper,
} from "./style";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import TopDetail from "./TopDetail";
import BottomDetail from "./BottomDetail";
import { useTheme } from "@emotion/react";
import { useMemo } from "react";
import OdontogramSymbolModal from "./OdontogramSymbolModal";

/**
 * 0 index
 * 1 global
 * 2 part
 * 3 rotasi/migrasi atas
 * 4 rotasi/migrasi bawah
 * 5 karang
 */

/**
 * part
 * 5-part 0 atas, 1 kanan, 2 bawah, 3 kiri, 4tengah
 * 4-part 0 atas, 1 kanan, 2 bawah, 3 kiri
 */

const init = [
  3, //mode gigi
  [
    ["18", [], ["", "", "", "", ""], "", "", ""],
    ["17", [], ["", "", "", "", ""], "", "", ""],
    ["16", [], ["", "", "", "", ""], "", "", ""],
    ["15", [], ["", "", "", "", ""], "", "", ""],
    ["14", [], ["", "", "", "", ""], "", "", ""],
    ["13", [], ["", "", "", ""], "", "", ""],
    ["12", [], ["", "", "", ""], "", "", ""],
    ["11", [], ["", "", "", ""], "", "", ""],
    ["21", [], ["", "", "", ""], "", "", ""],
    ["22", [], ["", "", "", ""], "", "", ""],
    ["23", [], ["", "", "", ""], "", "", ""],
    ["24", [], ["", "", "", "", ""], "", "", ""],
    ["25", [], ["", "", "", "", ""], "", "", ""],
    ["26", [], ["", "", "", "", ""], "", "", ""],
    ["27", [], ["", "", "", "", ""], "", "", ""],
    ["28", [], ["", "", "", "", ""], "", "", ""],
    ["55", [], ["", "", "", "", ""], "", "", ""],
    ["54", [], ["", "", "", "", ""], "", "", ""],
    ["53", [], ["", "", "", ""], "", "", ""],
    ["52", [], ["", "", "", ""], "", "", ""],
    ["51", [], ["", "", "", ""], "", "", ""],
    ["61", [], ["", "", "", ""], "", "", ""],
    ["62", [], ["", "", "", ""], "", "", ""],
    ["63", [], ["", "", "", ""], "", "", ""],
    ["64", [], ["", "", "", "", ""], "", "", ""],
    ["65", [], ["", "", "", "", ""], "", "", ""],
    ["85", [], ["", "", "", "", ""], "", "", ""],
    ["84", [], ["", "", "", "", ""], "", "", ""],
    ["83", [], ["", "", "", ""], "", "", ""],
    ["82", [], ["", "", "", ""], "", "", ""],
    ["81", [], ["", "", "", ""], "", "", ""],
    ["71", [], ["", "", "", ""], "", "", ""],
    ["72", [], ["", "", "", ""], "", "", ""],
    ["73", [], ["", "", "", ""], "", "", ""],
    ["74", [], ["", "", "", "", ""], "", "", ""],
    ["75", [], ["", "", "", "", ""], "", "", ""],
    ["48", [], ["", "", "", "", ""], "", "", ""],
    ["47", [], ["", "", "", "", ""], "", "", ""],
    ["46", [], ["", "", "", "", ""], "", "", ""],
    ["45", [], ["", "", "", "", ""], "", "", ""],
    ["44", [], ["", "", "", "", ""], "", "", ""],
    ["43", [], ["", "", "", ""], "", "", ""],
    ["42", [], ["", "", "", ""], "", "", ""],
    ["41", [], ["", "", "", ""], "", "", ""],
    ["31", [], ["", "", "", ""], "", "", ""],
    ["32", [], ["", "", "", ""], "", "", ""],
    ["33", [], ["", "", "", ""], "", "", ""],
    ["34", [], ["", "", "", "", ""], "", "", ""],
    ["35", [], ["", "", "", "", ""], "", "", ""],
    ["36", [], ["", "", "", "", ""], "", "", ""],
    ["37", [], ["", "", "", "", ""], "", "", ""],
    ["38", [], ["", "", "", "", ""], "", "", ""],
  ],
  [
    [18, ""], //catatan
    [17, ""],
    [16, ""],
    [15, ""],
    [14, ""],
    [13, ""],
    [12, ""],
    [11, ""],
    [21, ""],
    [22, ""],
    [23, ""],
    [24, ""],
    [25, ""],
    [26, ""],
    [27, ""],
    [28, ""],
    [55, ""],
    [54, ""],
    [53, ""],
    [52, ""],
    [51, ""],
    [61, ""],
    [62, ""],
    [63, ""],
    [64, ""],
    [65, ""],
    [85, ""],
    [84, ""],
    [83, ""],
    [82, ""],
    [81, ""],
    [71, ""],
    [72, ""],
    [73, ""],
    [74, ""],
    [75, ""],
    [48, ""],
    [47, ""],
    [46, ""],
    [45, ""],
    [44, ""],
    [43, ""],
    [42, ""],
    [41, ""],
    [31, ""],
    [32, ""],
    [33, ""],
    [34, ""],
    [35, ""],
    [36, ""],
    [37, ""],
    [38, ""],
  ],
];

const symbolOptions = [
  {
    label: "",
    mode: "",
  },
  {
    label: "Tambalan Amalgram",
    mode: "TAA",
  },
  {
    label: "Tambalan Composit",
    mode: "TAC",
  },
  {
    label: "Pit dan Fissure Sealant",
    mode: "PFS",
  },
  {
    label: "Karies",
    mode: "KAR",
  },
  {
    label: "Full Metal Crown",
    mode: "FLMC",
  },
  {
    label: "Porcelain Crown",
    mode: "PLC",
  },
  {
    label: "Metal Porcelain Crown",
    mode: "MPLC",
  },
  {
    label: "Gold Porcelain Crown",
    mode: "GMC",
  },
  {
    label: "Implant + Porcelain Crown",
    mode: "IPLC",
  },
  {
    label: "Partial Denture + Akrilik",
    mode: "PADA",
  },
  {
    label: "Full Denture + Akrilik",
    mode: "FLDA",
  },
  {
    label: "Partial Denture - Metal",
    mode: "PADM",
  },
  {
    label: "Full Denture - Metal",
    mode: "FLDM",
  },
  {
    label: "Fraktur",
    mode: "FRAK",
  },
  {
    label: "Gigi Hilang (Mis)",
    mode: "GGHG",
  },
  {
    label: "Gigi Tidak Ada (Non)",
    mode: "GGTA",
  },
  {
    label: "Un-Erupted",
    mode: "UERU",
  },
  {
    label: "Partial Erupted",
    mode: "PAERU",
  },
  {
    label: "Anomali",
    mode: "ANOM",
  },
  {
    label: "Sisa Akar",
    mode: "SSAK",
  },
  {
    label: "Gigi Non Vital",
    mode: "GGNV",
  },
  {
    label: "Perawatan Saluran Akar",
    mode: "PSA",
  },
  {
    label: "Full Metal Bridge",
    mode: "FLMB",
  },
  {
    label: "Full Metal Cantilever Bridge",
    mode: "ICLB",
  },
  {
    label: "Porcelain Bridge",
    mode: "IPCB",
  },
  {
    label: "Migrasi Atas Kiri",
    mode: "MAK2",
  },
  {
    label: "Migrasi Atas Kanan",
    mode: "MAK1",
  },
  {
    label: "Migrasi Bawah Kiri",
    mode: "MBK2",
  },
  {
    label: "Migrasi Bawah Kanan",
    mode: "MBK1",
  },
  {
    label: "Rotasi Atas Kiri",
    mode: "RAK2",
  },
  {
    label: "Rotasi Atas Kanan",
    mode: "RAK1",
  },
  {
    label: "Rotasi Bawah Kiri",
    mode: "RBK2",
  },
  {
    label: "Rotasi Bawah Kanan",
    mode: "RBK1",
  },
  {
    label: "Karang Gigi",
    mode: "KARANG",
  },
];

const MemoFiveSectionTooth = React.memo(FiveSectionTooth);
const MemoFourSectionTooth = React.memo(FourSectionTooth);
const MemoTopDetail = React.memo(TopDetail);
const MemoBottomDetail = React.memo(BottomDetail);

export default function Odontogram({
  encodedOdontogram,
  handleOdontogramViewClose,
  handleSaveOdontogram,
  submitting,
  odontogramData,
  setForm,
  handlePrintOdontogram,
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: doc,
    setState: setDoc,
    resetState: resetDoc,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    goBack: undoDoc,
    goForward: redoDoc,
  } = useUndoableState(init);

  const [scale, setScale] = useState(0.8);
  const [mode, setMode] = useState(null);
  const [deleteMode, setDeleteMode] = useState(false);
  const [teethMode, setTeethMode] = useState(3);
  const [note, setNote] = useState(
    odontogramHelper.changeNoteArrayToObject(init[2])
  );

  const [odontogramForm, setOdontogramForm] = useState({
    occlusi: "",
    torus_palatinus: "",
    torus_mandibularis: "",
    palatum: "",
    diastema: "",
    gigi_anomali: "",
    catatan_odontogram: "",
    ekstraksi_odontogram: "",
    jumlah_foto_odontogram: 0,
    jumlah_foto_rontgen_odontogram: 0,
    foto_opsi_odontogram: [0, 0],
    rontgen_opsi_odontogram: [0, 0, 0, 0],
  });

  const dmfCounter = useMemo(() => {
    let d = 0;
    let m = 0;
    let f = 0;
    doc[1]?.forEach((item) => {
      if (odontogramHelper.isDisabledByIndex(item[0], teethMode)) {
        return;
      }
      if (item[1][0] === "GGHG") {
        //gigi hilang / mis
        m++;
      } else {
        let dFound = false;
        let fFound = false;
        item[2].forEach((i) => {
          if (i === "KAR") {
            dFound = true;
          } else if (i === "PFS" || i === "TAC" || i === "TAA") {
            fFound = true;
          }
        });
        if (dFound) {
          d++;
        }
        if (fFound) {
          f++;
        }
      }
    });
    return { d, m, f };
  }, [doc, teethMode]);

  const odontogramLabel = useMemo(() => {
    return {
      1151: odontogramHelper.labelGenerator([11, 51], doc, teethMode),
      6121: odontogramHelper.labelGenerator([61, 21], doc, teethMode),
      1252: odontogramHelper.labelGenerator([12, 52], doc, teethMode),
      6222: odontogramHelper.labelGenerator([62, 22], doc, teethMode),
      1353: odontogramHelper.labelGenerator([13, 53], doc, teethMode),
      6323: odontogramHelper.labelGenerator([63, 23], doc, teethMode),
      1454: odontogramHelper.labelGenerator([14, 54], doc, teethMode),
      6424: odontogramHelper.labelGenerator([64, 24], doc, teethMode),
      1555: odontogramHelper.labelGenerator([15, 55], doc, teethMode),
      6525: odontogramHelper.labelGenerator([65, 25], doc, teethMode),
      16: odontogramHelper.labelGenerator([16], doc, teethMode),
      26: odontogramHelper.labelGenerator([26], doc, teethMode),
      17: odontogramHelper.labelGenerator([17], doc, teethMode),
      27: odontogramHelper.labelGenerator([27], doc, teethMode),
      18: odontogramHelper.labelGenerator([18], doc, teethMode),
      28: odontogramHelper.labelGenerator([28], doc, teethMode),
      48: odontogramHelper.labelGenerator([48], doc, teethMode),
      38: odontogramHelper.labelGenerator([38], doc, teethMode),
      47: odontogramHelper.labelGenerator([47], doc, teethMode),
      37: odontogramHelper.labelGenerator([37], doc, teethMode),
      46: odontogramHelper.labelGenerator([46], doc, teethMode),
      36: odontogramHelper.labelGenerator([36], doc, teethMode),
      4585: odontogramHelper.labelGenerator([45, 85], doc, teethMode),
      7535: odontogramHelper.labelGenerator([75, 35], doc, teethMode),
      4484: odontogramHelper.labelGenerator([44, 84], doc, teethMode),
      7434: odontogramHelper.labelGenerator([74, 34], doc, teethMode),
      4383: odontogramHelper.labelGenerator([43, 83], doc, teethMode),
      7333: odontogramHelper.labelGenerator([73, 33], doc, teethMode),
      4282: odontogramHelper.labelGenerator([42, 82], doc, teethMode),
      7232: odontogramHelper.labelGenerator([72, 32], doc, teethMode),
      4181: odontogramHelper.labelGenerator([41, 81], doc, teethMode),
      7131: odontogramHelper.labelGenerator([71, 31], doc, teethMode),
    };
  }, [doc, teethMode]);

  const [simbolModalOpen, setSimbolModalOpen] = useState(false);

  const handleSave = () => {
    let copy = [...doc];
    copy[2] = odontogramHelper.changeNoteObjectToArray(note);
    let encoded = odontogramHelper.encodeData(copy);
    let finalData = { ...odontogramForm, odontogram: encoded };
    handleSaveOdontogram(finalData);
  };

  const handleDownloadPdf = (e) => {
    let copy = [...doc];
    copy[2] = odontogramHelper.changeNoteObjectToArray(note);
    let encoded = odontogramHelper.encodeData(copy);
    let finalData = { ...odontogramForm, odontogram: encoded };
    if (typeof handlePrintOdontogram === "function") {
      handlePrintOdontogram(finalData);
    }
  };

  const handleUndo = () => {
    let canUndo = docStateIndex > 0;
    if (canUndo) {
      undoDoc();
    }
  };

  const handleRedo = () => {
    let canRedo = docStateIndex < docStateLastIndex;
    if (canRedo) {
      redoDoc();
    }
  };

  const handleScaleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setScale(newValue);
    }
  };

  const handleNewMargin = () => {
    let margin = 2;
    if (scale < 0.9) {
      margin = (1 / scale) * -5;
    }
    return margin;
  };

  const handleSwitchAdultTeeth = () => {
    let tempdoc = [...doc];
    tempdoc = tempdoc.map((f, idx) => (idx === 0 ? 1 : f));
    tempdoc = tempdoc?.map((f, idx) =>
      idx === 1
        ? f.map((i, idx) =>
            odontogramHelper.isChildToothIndex(i[0])
              ? [
                  i[0],
                  [""],
                  odontogramHelper.getDefaultSectionData(i),
                  "",
                  "",
                  "",
                ]
              : i
          )
        : f
    );
    setDoc(tempdoc);
  };

  const handleSwitchChildTeeth = () => {
    let tempdoc = [...doc];
    tempdoc = tempdoc.map((f, idx) => (idx === 0 ? 2 : f));
    tempdoc = tempdoc?.map((f, idx) =>
      idx === 1
        ? f.map((i, idx) =>
            !odontogramHelper.isChildToothIndex(i[0])
              ? [
                  i[0],
                  [""],
                  odontogramHelper.getDefaultSectionData(i),
                  "",
                  "",
                  "",
                ]
              : i
          )
        : f
    );
    setDoc(tempdoc);
  };

  const handleSwitchMixedTeeth = () => {
    let tempdoc = [...doc];
    tempdoc = tempdoc.map((f, idx) => (idx === 0 ? 3 : f));
    setDoc(tempdoc);
  };

  const handleTeethTypeSwitch = (e) => {
    let v = parseInt(e.target.value);
    setTeethMode(e.target.value);
    if (v === 1) {
      handleSwitchAdultTeeth();
    } else if (v === 2) {
      handleSwitchChildTeeth();
    } else {
      handleSwitchMixedTeeth();
    }
  };

  const handleSwitchDeleteMode = () => {
    console.log(doc);
    console.log(note);
    setDeleteMode(!deleteMode);
  };

  const handleToothClick = useCallback(
    (event, data) => {
      if (odontogramHelper.isDisabled(data, teethMode)) {
        enqueueSnackbar("data gigi dinonaktifkan");
        return;
      }

      if (deleteMode) {
        if (odontogramHelper.isSou(data)) {
          enqueueSnackbar("mode hapus sedang aktif");
        }
        if (odontogramHelper.isBridgeData(data)) {
          setDoc(
            doc?.map((f, idx) =>
              idx === 1
                ? f.map((i, idx) =>
                    data[1][1] === i[1][1]
                      ? [
                          i[0],
                          [""],
                          odontogramHelper.getDefaultSectionData(i),
                          "",
                          "",
                          "",
                        ]
                      : i
                  )
                : f
            )
          );
        } else {
          setDoc(
            doc?.map((f, idx) =>
              idx === 1
                ? f.map((i, idx) =>
                    data[0] === i[0]
                      ? [
                          i[0],
                          [""],
                          odontogramHelper.getDefaultSectionData(i),
                          "",
                          "",
                          "",
                        ]
                      : i
                  )
                : f
            )
          );
        }
        return;
      }

      if (!mode?.mode) {
        enqueueSnackbar("silahkan pilih simbol terlebih dahulu");
        return;
      }

      if (mode.mode === "KARANG") {
        let tempData = odontogramHelper.dataFixer(data);
        tempData[5] = tempData[5] === mode.mode ? "" : mode.mode;
        let temDoc = [...doc];
        let finalData = temDoc?.map((f, idx) =>
          idx === 1
            ? f.map((i, idx) => (data[0] === i[0] ? [...tempData] : i))
            : f
        );
        setDoc(finalData);
      } else if (odontogramHelper.isRotateOrMigrateMode(mode.mode)) {
        let tempData = odontogramHelper.dataFixer(data);
        //hapus jika ditimpa data yang sama
        if (odontogramHelper.isTopRotateOrMigrateMode(mode.mode)) {
          tempData[3] = tempData[3] === mode.mode ? "" : mode.mode;
        } else {
          tempData[4] = tempData[4] === mode.mode ? "" : mode.mode;
        }

        let temDoc = [...doc];
        let finalData = temDoc?.map((f, idx) =>
          idx === 1
            ? f.map((i, idx) => (data[0] === i[0] ? [...tempData] : i))
            : f
        );
        setDoc(finalData);
      } else if (odontogramHelper.isBridgeMode(mode.mode)) {
        if (!odontogramHelper.isSou(data)) {
          enqueueSnackbar("silahkan hapus data gigi terlebih dahulu");
          return;
        }
        let groupId = odontogramHelper.generateGroupId(doc[1]);
        setDoc(
          doc?.map((f, idx) =>
            idx === 1
              ? f.map((i, idx) =>
                  data[0] === i[0]
                    ? [
                        i[0],
                        [mode.mode, groupId],
                        odontogramHelper.getDefaultSectionData(i),
                        i[3] ?? "",
                        i[4] ?? "",
                        i[5] ?? "",
                      ]
                    : i
                )
              : f
          )
        );
      } else if (odontogramHelper.isModeAffectingGlobalStyle(mode.mode)) {
        setDoc(
          doc?.map((f, idx) =>
            idx === 1
              ? f.map((i, idx) =>
                  data[0] === i[0]
                    ? [
                        i[0],
                        [i[1][0] === mode.mode ? "" : mode.mode],
                        odontogramHelper.getDefaultSectionData(i),
                        i[3] ?? "",
                        i[4] ?? "",
                        i[5] ?? "",
                      ]
                    : i
                )
              : f
          )
        );
      } else {
        if (odontogramHelper.isModeCanUsedToSection(mode.mode)) {
          if (!odontogramHelper.isContainerClicked(event)) {
            /**
             * 5 atas, kanan, bawah, kiri, tengah
             * 4 atas, kanan, bawah, kiri
             */
            let sectionName = odontogramHelper.getSectionName(event);
            if (sectionName) {
              let sectionIndex = odontogramHelper.sectionToIndex(sectionName);
              let sectionData = doc[1].find((i) => i[0] === data[0]);
              if (!sectionData) {
                enqueueSnackbar("data section not found");
                return;
              }
              if (odontogramHelper.isGlobalStyleApplied(sectionData)) {
                enqueueSnackbar(
                  "silahkan hapus terlebih dahulu data pada gigi yang dipilih"
                );
                return;
              }
              setDoc(
                doc?.map((f, idx) =>
                  idx === 1 //key 1 dari doc
                    ? f.map((i, idx) =>
                        i[0] === data[0] //match id
                          ? i.map((j, ix) =>
                              ix === 2 //key 2 [section data]
                                ? j.map((k, idxx) =>
                                    idxx === sectionIndex
                                      ? mode.mode === k
                                        ? ""
                                        : mode.mode
                                      : k
                                  )
                                : j
                            )
                          : i
                      )
                    : f
                )
              );
            }
          }
        }
      }
    },
    [doc, teethMode, mode, deleteMode, setDoc, enqueueSnackbar]
  );

  const handleBridgeMode = () => {
    let teethGroup = [
      [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28],
      [55, 54, 53, 52, 51, 61, 62, 63, 64, 65],
      [85, 84, 83, 82, 81, 71, 72, 73, 74, 75],
      [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38],
    ];

    let tempDoc = [...doc];
    let bridging = false;

    let listBridgeMode = ["ICLB", "FLMB", "IPCB"];
    teethGroup.forEach((myGroup) => {
      listBridgeMode.forEach((mode) => {
        let groupCandidate;
        myGroup.forEach((v, idx) => {
          let data = tempDoc[1].find((i) => i[0] === String(v));
          if (data) {
            let gc = odontogramHelper.isCanBeGroupCandidate(
              mode,
              data,
              tempDoc[1]
            );

            if (gc && idx === 0) {
              groupCandidate = data;
            } else {
              bridging = true;
              if (groupCandidate && gc) {
                // console.log(
                //   "GROUP CANDIDATE",
                //   groupCandidate,
                //   "CURR DATA",
                //   data
                // );
                let groupId = odontogramHelper.generateGroupId(tempDoc[1]);
                let start = myGroup.findIndex(
                  (v) => v === parseInt(groupCandidate[0])
                );
                let end = myGroup.findIndex((v) => v === parseInt(data[0]));

                // console.log("GR_ID", groupId, "st", start, "end", end);
                // console.log(groupCandidate[0]);
                tempDoc = tempDoc?.map((f, idx) =>
                  idx === 1
                    ? f.map((i, idx) =>
                        groupCandidate[0] === i[0]
                          ? [
                              i[0],
                              [mode, groupId],
                              odontogramHelper.getDefaultSectionData(i),
                              i[3] ?? "",
                              i[4] ?? "",
                              i[5] ?? "",
                            ]
                          : i
                      )
                    : f
                );

                let endModeCode;
                if (mode === "ICLB") {
                  endModeCode = "BWS";
                } else if (mode === "FLMB") {
                  endModeCode = "BWE";
                } else {
                  endModeCode = "BWEPL";
                }

                tempDoc = tempDoc?.map((f, idx) =>
                  idx === 1
                    ? f.map((i, idx) =>
                        data[0] === i[0]
                          ? [
                              i[0],
                              [endModeCode, groupId],
                              odontogramHelper.getDefaultSectionData(i),
                              i[3] ?? "",
                              i[4] ?? "",
                              i[5] ?? "",
                            ]
                          : i
                      )
                    : f
                );

                /**
                 * Group Member
                 */
                myGroup.forEach((x, ix) => {
                  if (ix > start && ix < end) {
                    let d = doc[1].find((i) => i[0] === String(x));
                    if (d) {
                      if (mode === "ICLB" || mode === "FLMB") {
                        tempDoc = tempDoc?.map((f, idx) =>
                          idx === 1
                            ? f.map((i, idx) =>
                                d[0] === i[0]
                                  ? [
                                      i[0],
                                      ["BWS", groupId],
                                      odontogramHelper.getDefaultSectionData(i),
                                      i[3] ?? "",
                                      i[4] ?? "",
                                      i[5] ?? "",
                                    ]
                                  : i
                              )
                            : f
                        );
                      } else {
                        tempDoc = tempDoc?.map((f, idx) =>
                          idx === 1
                            ? f.map((i, idx) =>
                                d[0] === i[0]
                                  ? [
                                      i[0],
                                      ["BWPL", groupId],
                                      odontogramHelper.getDefaultSectionData(i),
                                      i[3] ?? "",
                                      i[4] ?? "",
                                      i[5] ?? "",
                                    ]
                                  : i
                              )
                            : f
                        );
                      }
                    }
                  }
                });
              } else {
                if (gc) {
                  groupCandidate = data;
                } else {
                  if (!odontogramHelper.isSou(data)) {
                    groupCandidate = undefined;
                  }
                }
              }
            }
          } else {
            enqueueSnackbar("EQ DATA NF");
          }
        });
      });
    });
    if (bridging) {
      setDoc(tempDoc, true);
    }
  };

  const handlePhotoOptChange = (index) => {
    setOdontogramForm((prev) => ({
      ...prev,
      foto_opsi_odontogram: prev.foto_opsi_odontogram.map((i, idx) =>
        idx === index ? (i === 1 ? 0 : 1) : i
      ),
    }));
  };

  const handleRontgebOptChange = (index) => {
    setOdontogramForm((prev) => ({
      ...prev,
      rontgen_opsi_odontogram: prev.rontgen_opsi_odontogram.map((i, idx) =>
        idx === index ? (i === 1 ? 0 : 1) : i
      ),
    }));
  };

  const handleNoteChange = useCallback((e) => {
    let key = parseInt(e.target.getAttribute("data-id"));
    setNote((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }, []);

  const handleFormChange = (e) => {
    setOdontogramForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (doc && doc[0] === 1) {
      setTeethMode(1);
      handleSwitchAdultTeeth();
    } else if (doc && doc[0] === 2) {
      setTeethMode(2);
      handleSwitchChildTeeth();
    } else {
      setTeethMode(3);
      handleSwitchMixedTeeth();
    }
    if (doc && doc[1].length > 0) {
      handleBridgeMode();
    }
  }, [doc]);

  useEffect(() => {
    let decoded = odontogramHelper.decodeData(encodedOdontogram);
    resetDoc(decoded);
    setNote(odontogramHelper.changeNoteArrayToObject(decoded[2]));
  }, [encodedOdontogram]);

  useEffect(() => {
    setOdontogramForm(odontogramData);
  }, [odontogramData]);

  const atasDewasa = doc && doc[1].length > 0 ? doc[1].slice(0, 16) : [];
  const atasAnak = doc && doc[1].length > 0 ? doc[1].slice(16, 26) : [];
  const bawahAnak = doc && doc[1].length > 0 ? doc[1].slice(26, 36) : [];
  const bawahDewasa = doc && doc[1].length > 0 ? doc[1].slice(36, 52) : [];

  return (
    <>
      <OdontogramSymbolModal
        open={simbolModalOpen}
        onClose={() => {
          setSimbolModalOpen(false);
        }}
        mode={mode}
        onModeChange={(v) => {
          setMode(v);
        }}
      />
      <MemoTopDetail
        odontogramLabel={odontogramLabel}
        note={note}
        handleNoteChange={handleNoteChange}
        teethMode={teethMode}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            pt: 1,
            width: "100px",
          }}
        >
          <Slider
            value={scale}
            min={0.1}
            step={0.1}
            max={1}
            onChange={handleScaleChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Box>
        <IconButton onClick={handleUndo}>
          <img
            src="/assets/odontogram/undo.png"
            width="30"
            height="30"
            alt="undo"
          ></img>
        </IconButton>
        <IconButton onClick={handleRedo}>
          <img
            src="/assets/odontogram/redo.png"
            width="30"
            height="30"
            alt="redo"
          ></img>
        </IconButton>
        <IconButton onClick={handleDownloadPdf}>
          <Typography
            sx={{ fontSize: "14px", color: "black", fontWeight: 600 }}
          >
            Download PDF
          </Typography>
        </IconButton>
      </Box>
      <Box sx={option__container}>
        <Button
          fullWidth
          sx={{
            backgroundColor: mode?.mode?.length > 0 ? "pink" : "blue",
            borderRadius: "8px",
            textTransform: "none",
            width: "150px",
          }}
          variant="contained"
          onClick={() => {
            setSimbolModalOpen(true);
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            {mode?.mode.length > 0 ? mode.label : "Pilih simbol"}
          </Typography>
        </Button>
        <Box sx={option__item__container}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            Pilih Tipe Gigi :{" "}
          </Typography>
          <FormControl
            variant="standard"
            sx={{ ...form__control, marginBottom: 0, width: "fit-content" }}
          >
            <Select
              onChange={handleTeethTypeSwitch}
              id="clinic_room_id"
              name="clinic_room_id"
              value={String(teethMode)}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              IconComponent={() => {
                return <></>;
              }}
              sx={{
                ...input__select,
                py: 0.3,
                paddingLeft: "6px",
                fontWeight: 600,
                width: "200px",
              }}
            >
              <MenuItem value={"1"}>Dewasa</MenuItem>
              <MenuItem value={"2"}>Anak</MenuItem>
              <MenuItem value={"3"}>Campuran</MenuItem>
            </Select>
          </FormControl>
          <Button
            fullWidth
            sx={{
              backgroundColor: deleteMode ? "crimson" : "blue",
              borderRadius: "8px",
              textTransform: "none",
              width: "150px",
            }}
            variant="contained"
            onClick={handleSwitchDeleteMode}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Mode Hapus ({deleteMode ? "ON" : "OFF"})
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          MozTransform: `scale(${scale})`,
          OTransform: `scale(${scale})`,
          msTransform: `scale(${scale})`,
          WebkitTransform: `scale(${scale})`,
          transform: `scale(${scale})`,
          marginTop: handleNewMargin(),
          position: "relative",
        }}
      >
        <img
          src="/assets/odontogram/triangle-down.png"
          alt="tri-up-down"
          style={{
            ...triangle__teeth__orientation__helper,
            top: "40px",
          }}
        ></img>
        <img
          src="/assets/odontogram/triangle-up.png"
          alt="tri-up-up"
          style={{
            ...triangle__teeth__orientation__helper,
            top: "350px",
          }}
        ></img>
        <img
          src="/assets/odontogram/triangle-down.png"
          alt="tri-down-down"
          style={{
            ...triangle__teeth__orientation__helper,
            top: "400px",
          }}
        ></img>
        <img
          src="/assets/odontogram/triangle-up.png"
          alt="tri-down-up"
          style={{
            ...triangle__teeth__orientation__helper,
            top: "705px",
          }}
        ></img>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "5px",
          }}
        >
          {atasDewasa.map((data, idx) => {
            if (data[2]?.length === 5) {
              return (
                <MemoFiveSectionTooth
                  handleToothClick={handleToothClick}
                  key={idx}
                  data={data}
                  teethMode={teethMode}
                />
              );
            } else {
              return (
                <MemoFourSectionTooth
                  handleToothClick={handleToothClick}
                  key={idx}
                  data={data}
                  teethMode={teethMode}
                />
              );
            }
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "5px",
          }}
        >
          {atasAnak.map((data, idx) => {
            if (data[2]?.length === 5) {
              return (
                <MemoFiveSectionTooth
                  key={idx}
                  handleToothClick={handleToothClick}
                  data={data}
                  teethMode={teethMode}
                />
              );
            } else {
              return (
                <MemoFourSectionTooth
                  key={idx}
                  handleToothClick={handleToothClick}
                  data={data}
                  teethMode={teethMode}
                />
              );
            }
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "5px",
          }}
        >
          {bawahAnak.map((data, idx) => {
            if (data[2]?.length === 5) {
              return (
                <MemoFiveSectionTooth
                  key={idx}
                  handleToothClick={handleToothClick}
                  data={data}
                  teethMode={teethMode}
                />
              );
            } else {
              return (
                <MemoFourSectionTooth
                  handleToothClick={handleToothClick}
                  key={idx}
                  data={data}
                  teethMode={teethMode}
                />
              );
            }
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "5px",
          }}
        >
          {bawahDewasa.map((data, idx) => {
            if (data[2]?.length === 5) {
              return (
                <MemoFiveSectionTooth
                  key={idx}
                  handleToothClick={handleToothClick}
                  data={data}
                  teethMode={teethMode}
                />
              );
            } else {
              return (
                <MemoFourSectionTooth
                  handleToothClick={handleToothClick}
                  key={idx}
                  data={data}
                  teethMode={teethMode}
                />
              );
            }
          })}
        </Box>
      </Box>

      <MemoBottomDetail
        odontogramLabel={odontogramLabel}
        note={note}
        handleNoteChange={handleNoteChange}
        teethMode={teethMode}
      />

      <Box
        sx={{
          pl: isDesktop ? 6 : 3,
          pr: isDesktop ? 5 : 3,
          py: 3,
        }}
      >
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <FormControl
              variant="standard"
              sx={{ ...form__control, marginBottom: 0 }}
            >
              <InputLabel
                sx={input__label}
                shrink
                htmlFor="jumlah_foto_odontogram"
              >
                Jumlah Foto
              </InputLabel>
              <InputBase
                sx={input__text}
                type="number"
                id="jumlah_foto_odontogram"
                name="jumlah_foto_odontogram"
                value={odontogramForm.jumlah_foto_odontogram ?? ""}
                onChange={handleFormChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={4} md={3} lg={2}>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
              control={
                <Checkbox
                  checked={
                    odontogramForm.foto_opsi_odontogram[0] === 1 ? true : false
                  }
                  onChange={() => {
                    handlePhotoOptChange(0);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"Digital"}
            />
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
              control={
                <Checkbox
                  checked={
                    odontogramForm.foto_opsi_odontogram[1] === 1 ? true : false
                  }
                  onChange={() => {
                    handlePhotoOptChange(1);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"Intraoral"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <FormControl
              variant="standard"
              sx={{ ...form__control, marginBottom: 0 }}
            >
              <InputLabel
                sx={input__label}
                shrink
                htmlFor="jumlah_foto_rontgen_odontogram"
              >
                Jumlah Foto Rontgen
              </InputLabel>
              <InputBase
                sx={input__text}
                type="number"
                id="jumlah_foto_rontgen_odontogram"
                name="jumlah_foto_rontgen_odontogram"
                onChange={handleFormChange}
                value={odontogramForm.jumlah_foto_rontgen_odontogram ?? ""}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={4} md={3} lg={2}>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
              control={
                <Checkbox
                  checked={
                    odontogramForm.rontgen_opsi_odontogram[0] === 1
                      ? true
                      : false
                  }
                  onChange={() => {
                    handleRontgebOptChange(0);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"Dental"}
            />
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
              control={
                <Checkbox
                  checked={
                    odontogramForm.rontgen_opsi_odontogram[1] === 1
                      ? true
                      : false
                  }
                  onChange={() => {
                    handleRontgebOptChange(1);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"PA"}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
              control={
                <Checkbox
                  checked={
                    odontogramForm.rontgen_opsi_odontogram[2] === 1
                      ? true
                      : false
                  }
                  onChange={() => {
                    handleRontgebOptChange(2);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"OPG"}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "14px",
                },
              }}
              control={
                <Checkbox
                  checked={
                    odontogramForm.rontgen_opsi_odontogram[3] === 1
                      ? true
                      : false
                  }
                  onChange={() => {
                    handleRontgebOptChange(3);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"Ceph"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl
              variant="standard"
              sx={{ ...form__control, marginBottom: 0 }}
            >
              <InputLabel sx={input__label} shrink htmlFor="occlusi">
                D
              </InputLabel>
              <InputBase
                sx={input__text}
                type="text"
                value={dmfCounter.d ?? 0}
              />
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            {teethMode === 1 ? (
              <FormControl
                variant="standard"
                sx={{ ...form__control, marginBottom: 0 }}
              >
                <InputLabel sx={input__label} shrink htmlFor="occlusi">
                  M
                </InputLabel>
                <InputBase
                  sx={input__text}
                  type="text"
                  value={dmfCounter.m ?? 0}
                />
              </FormControl>
            ) : (
              <FormControl variant="standard" sx={form__control}>
                <InputLabel
                  sx={input__label}
                  shrink
                  htmlFor="ekstraksi_odontogram"
                >
                  E
                </InputLabel>
                <InputBase
                  sx={input__text}
                  id="ekstraksi_odontogram"
                  name="ekstraksi_odontogram"
                  type="number"
                  value={
                    odontogramForm?.ekstraksi_odontogram
                      ? _.isInteger(odontogramForm?.ekstraksi_odontogram)
                        ? odontogramForm?.ekstraksi_odontogram
                        : 0
                      : 0
                  }
                  onChange={handleFormChange}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <FormControl
              variant="standard"
              sx={{ ...form__control, marginBottom: 0 }}
            >
              <InputLabel sx={input__label} shrink htmlFor="occlusi">
                F
              </InputLabel>
              <InputBase
                sx={input__text}
                type="text"
                value={dmfCounter.f ?? 0}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl
              variant="standard"
              sx={{ ...form__control, marginBottom: 0 }}
            >
              <InputLabel sx={input__label} shrink htmlFor="occlusi">
                Occlusi
              </InputLabel>
              <Select
                onChange={handleFormChange}
                id="occlusi"
                name="occlusi"
                value={odontogramForm.occlusi ?? ""}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                IconComponent={() => {
                  return <></>;
                }}
                sx={{
                  ...input__select,
                  py: 0.3,
                  paddingLeft: "6px",
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                <MenuItem value={"NORMALBITE"}>Normal Bite</MenuItem>
                <MenuItem value={"CROSSBITE"}>Cross Bite</MenuItem>
                <MenuItem value={"STEEPBITE"}>Steep Bite</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl
              variant="standard"
              sx={{ ...form__control, marginBottom: 0 }}
            >
              <InputLabel sx={input__label} shrink htmlFor="torus_palatinus">
                Torus Palatinus
              </InputLabel>
              <Select
                onChange={handleFormChange}
                id="torus_palatinus"
                name="torus_palatinus"
                value={odontogramForm.torus_palatinus ?? ""}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                IconComponent={() => {
                  return <></>;
                }}
                sx={{
                  ...input__select,
                  py: 0.3,
                  paddingLeft: "6px",
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                <MenuItem value={"TIDAKADA"}>Tidak Ada</MenuItem>
                <MenuItem value={"KECIL"}>Kecil</MenuItem>
                <MenuItem value={"SEDANG"}>Sedang</MenuItem>
                <MenuItem value={"BESAR"}>Besar</MenuItem>
                <MenuItem value={"MULTIPLE"}>Multiple</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl
              variant="standard"
              sx={{ ...form__control, marginBottom: 0 }}
            >
              <InputLabel sx={input__label} shrink htmlFor="torus_mandibularis">
                Torus Mandibularis
              </InputLabel>
              <Select
                onChange={handleFormChange}
                id="torus_mandibularis"
                name="torus_mandibularis"
                value={odontogramForm.torus_mandibularis ?? ""}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                IconComponent={() => {
                  return <></>;
                }}
                sx={{
                  ...input__select,
                  py: 0.3,
                  paddingLeft: "6px",
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                <MenuItem value={"TIDAKADA"}>Tidak Ada</MenuItem>
                <MenuItem value={"SISIKIRI"}>Sisi Kiri</MenuItem>
                <MenuItem value={"SISIKANAN"}>Sisi Kanan</MenuItem>
                <MenuItem value={"KEDUASISI"}>Kedua Sisi</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <FormControl
              variant="standard"
              sx={{ ...form__control, marginBottom: 0 }}
            >
              <InputLabel sx={input__label} shrink htmlFor="palatum">
                Palatum
              </InputLabel>
              <Select
                onChange={handleFormChange}
                id="palatum"
                name="palatum"
                value={odontogramForm.palatum ?? ""}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                IconComponent={() => {
                  return <></>;
                }}
                sx={{
                  ...input__select,
                  py: 0.3,
                  paddingLeft: "6px",
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                <MenuItem value={"DALAM"}>Dalam</MenuItem>
                <MenuItem value={"SEDANG"}>Sedang</MenuItem>
                <MenuItem value={"RENDAH"}>Rendah</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={isDesktop ? 6 : 12}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="diastema">
                Diastema
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="diastema"
                id="diastema"
                name="diastema"
                type="text"
                value={odontogramForm.diastema}
                onChange={handleFormChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="gigi_anomali">
                Gigi Anomali
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="gigi anomali"
                id="gigi_anomali"
                name="gigi_anomali"
                type="text"
                value={odontogramForm.gigi_anomali}
                onChange={handleFormChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <FormControl variant="standard" sx={form__control}>
              <InputLabel sx={input__label} shrink htmlFor="catatan_odontogram">
                Catatan Lain - Lain
              </InputLabel>
              <InputBase
                sx={input__text}
                multiline
                maxRows={3}
                minRows={3}
                placeholder="catatan lain lain"
                id="catatan_odontogram"
                name="catatan_odontogram"
                type="text"
                value={odontogramForm.catatan_odontogram}
                onChange={handleFormChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={footer__container}>
        <Button onClick={handleSave} sx={submit__btn} variant="contained">
          <img src="/assets/icons/save.svg" alt="save"></img>
          <Typography sx={submit__btn__label}>
            {submitting ? "Menyimpan..." : "Simpan"}
          </Typography>
        </Button>
        <Box>
          <Button
            onClick={handleOdontogramViewClose}
            sx={{
              ...tab__switch__btn,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "maroon" },
            }}
            variant="contained"
          >
            <Typography sx={tab__switch__btn__label}>
              Batal / Kembali
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
