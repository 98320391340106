const schedule__container = {
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  marginBottom: "10px",
};

const schedule__header__container = {
  display: "flex",
  minHeight: "64px",
  boxShadow: "none",
  position: "relative",
  margin: "2px 16px",
  borderBottom: "1px solid #D4D4D4",
};

const schedule__header__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "0px",
  marginRight: "15px",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
};

const schedule__header__info__container = {
  display: "flex",
  flexDirection: "column",
  paddingTop: "4px",
  my: "auto",
};

const schedule__header__info__text__clinic = {
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "19px",
};

const schedule__header__info__text__date = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "19px",
  display: "flex",
  flexDirection: "row",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const schedule__footer__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  margin: "10px 16px 20px 16px",
};

const schedule__footer__text = {
  fontSize: "14px",
  fontWeight: 600,
};

const schedule__footer__queue__info_container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  margin: "10px 16px",
  paddingBottom: "15px",
};

const schedule__btn = {
  backgroundColor: "blue",
  width: "65px",
  height: "38px",
  borderRadius: "8px",
  textTransform: "none",
};

const schedule__btn__label = {
  fontSize: "12px",
  fontWeight: 600,
};

const schedule__footer__queue__info__title = {
  fontSize: "12px",
  fontWeight: 400,
};

const schedule__footer__queue__info__value = {
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
};

export {
  schedule__container,
  schedule__header__container,
  schedule__header__img,
  schedule__header__info__container,
  schedule__header__info__text__clinic,
  schedule__header__info__text__date,
  schedule__footer__container,
  schedule__footer__queue__info_container,
  schedule__footer__text,
  schedule__footer__queue__info__title,
  schedule__footer__queue__info__value,
  schedule__btn,
  schedule__btn__label,
};
