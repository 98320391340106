import { webkit__scrollbar } from "../../../style";

const message__input = {
  "label + &": {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    padding: "10px 12px",
    height: "16px",
    fontSize: "14px",
    borderRadius: "8px",
    width: "100%",
    border: "none",
    outline: "none",
  },
  "&.MuiInputBase-root": {
    paddingLeft: "0px",
    width: "100%",
  },
  "& .MuiInputBase-inputMultiline": {
    padding: "0px 10px 0px 10px",
    ...webkit__scrollbar,
  },
};

const chat__box__wrapper = {
  position: "relative",
  minHeight: "calc(100vh - 150px)",
  maxHeight: "calc(100vh - 150px)",
  background: "none",
  px: 2,
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.1)",
};

const chat__box__container = {
  overflowY: "auto",
  minHeight: "calc(100vh - 190px)",
  maxHeight: "calc(100vh - 190px)",
  pr: 0.5,
  pb: 2,
  background: "none",
  gap: 1,
  display: "flex",
  flexDirection: "column-reverse",
  ...webkit__scrollbar,
};

const chat__input__wrapper = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  position: "absolute",
  bottom: 0,
  pr: 4,
  alignContent: "center",
  alignItems: "center",
};

const chat__input__container = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  background: "rgba(239, 241, 249, 1)",
  borderRadius: "8px",
  minWidth: "calc(100% - 50px)",
  alignContent: "center",
  alignItems: "center",
};

const input__btn = {
  height: "38px",
  color: "white",
  borderRadius: "8px",
  background: "none",
  "&:hover": {
    background: "none",
  },
};

const input__temlate__btn = {
  ...input__btn,
  borderRadius: "8px",
  background: "none",
  "&:hover": {
    background: "none",
  },
};

const submit__btn = {
  height: "38px",
  color: "white",
  borderRadius: "8px",
  background: "#00AEEF",
  my: 0.1,
  "&:hover": {
    background: "#00AEEE",
  },
};

export {
  message__input,
  chat__box__wrapper,
  chat__box__container,
  chat__input__wrapper,
  chat__input__container,
  input__temlate__btn,
  input__btn,
  submit__btn,
};
