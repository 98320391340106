import React from "react";
import { Container, LinearProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";

function SectionLoading({ height }) {
  return (
    <Container maxWidth="xs">
      <div
        style={{
          color: "blue",
          textAlign: "center",
          marginTop: "-30px",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: height ? height : "calc(100vh - 100px)",
          }}
        >
          <img
            src="/assets/logo-mediplus.svg"
            alt="logo-mediplus"
            width="200"
            height="30"
            style={{
              marginBottom: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></img>
          <div
            style={{
              width: "50%",
              alignSelf: "center",
            }}
          >
            <LinearProgress />
          </div>
        </Typography>
      </div>
    </Container>
  );
}

SectionLoading.propTypes = {
  height: PropTypes.string,
};

export default SectionLoading;
