const toolbar = {
  backgroundColor: "white",
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
};

const topbar__profile__container = {
  display: "flex",
  minWidth: "300px",
  minHeight: "64px",
  boxShadow: "none",
  background: "#FAFBFD",
  position: "relative",
};

const topbar__profile__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "23px",
  marginRight: "15px",
  borderRadius: "50%",
  objectFit: "cover",
};

const topbar__profile__btn = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "23px",
  marginRight: "0px",
};

const topbar__profile__info__container = {
  display: "flex",
  flexDirection: "column",
  paddingTop: "4px",
  my: "auto",
};

const topbar__profile__info__text__acc_type = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "19px",
};

const topbar__profile__info__text__name = {
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
};

export {
  toolbar,
  topbar__profile__container,
  topbar__profile__img,
  topbar__profile__btn,
  topbar__profile__info__container,
  topbar__profile__info__text__acc_type,
  topbar__profile__info__text__name,
};
