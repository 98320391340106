import React, { useEffect, useMemo, useState } from "react";
import {
  InputBase,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import { setSelectedConversation } from "../../../../../redux/slices/chatConversation";
import a11yProps from "../../../../components/tab/a11yProps";
import {
  conversation__list__container,
  search__input,
  search__input__container,
  search__input__icon,
  tab__label,
} from "./style";
import ConversationListItem from "./conversation-list-item/ConversationListItem";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";
import debounce from "lodash.debounce";
import _ from "lodash";

export default function Conversations() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const { user } = useAuth();

  const chat_update = useSelector((state) => state.chat_update.value);
  const chat_read = useSelector((state) => state.chat_read.value);
  const chat_conversation = useSelector((state) => state.chat_conversation);

  const [tabIndex, setTabIndex] = useState(0);

  const [search, setSearch] = useState("");
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const handleChatSearch = (e) => {
    setSearch(e.target.value);
  };

  const loadChat = (url) => {
    if (firstLoad) {
      setLoading(true);
      setFirstLoad(false);
    }
    axios
      .get(
        url
          ? url
          : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/chat/conversations?search=${search}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setConversations(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleConversationClick = (convId) => {
    let conversation = conversations.find((i) => i.id === convId);
    if (conversation !== undefined) {
      dispatch(setSelectedConversation(conversation));
    }
  };

  const debouncedSearchChangeHandler = useMemo(
    () => debounce(handleChatSearch, 800),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchChangeHandler.cancel();
    };
  }, []);

  useEffect(() => {
    loadChat();
    const onFocus = () => {
      loadChat();
    };
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [search, chat_update, chat_read]);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          borderRadius: "8px",
          background: "transparent",
        }}
      >
        <Box sx={{ width: "100%", position: "relative" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              p: "10px 2",
            }}
          >
            <Tabs
              value={tabIndex}
              variant="scrollable"
              onChange={handleTabChange}
              aria-label="tabk"
              sx={{
                ".MuiTabs-flexContainer": {
                  background: "transparent",
                },
                overflowX: "auto",
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#FF0091",
                  height: "3px",
                },
              }}
            >
              <Tab
                sx={{
                  mr: 2,
                  pb: 1,
                  pt: 0,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      color: tabIndex === 0 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Semua
                  </Typography>
                }
                {...a11yProps(0)}
              ></Tab>
              <Tab
                sx={{
                  pt: 0,
                  pb: 1,
                  mx: 2,
                }}
                label={
                  <Typography
                    sx={{
                      ...tab__label,
                      color: tabIndex === 1 ? "#041615" : "#7D7D7D",
                    }}
                  >
                    Pinned
                  </Typography>
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </Box>
        <Box sx={search__input__container}>
          <InputBase
            sx={search__input}
            placeholder={"Cari"}
            onChange={debouncedSearchChangeHandler}
          ></InputBase>
          <img
            src="/assets/icons/search-black.svg"
            alt="search"
            style={search__input__icon}
          ></img>
        </Box>
        <Box
          sx={{
            ...conversation__list__container,
            maxHeight: isDesktop
              ? "calc(100vh - 280px)"
              : "calc(100vh - 410px)",
          }}
        >
          {loading ? (
            <Box
              sx={{
                pt: "10px",
              }}
            >
              {_.times(10, (i) => {
                return (
                  <Skeleton
                    key={`skeletion-${i}`}
                    sx={{ mb: 0.5, borderRadius: "8px" }}
                    variant="rectangular"
                    width="100%"
                    height="40px"
                  />
                );
              })}
            </Box>
          ) : (
            <>
              {conversations &&
                conversations.length > 0 &&
                conversations
                  .sort(function compare(a, b) {
                    var dateA = new Date(a.updated_at);
                    var dateB = new Date(b.updated_at);
                    return dateB - dateA;
                  })
                  .filter((i) => {
                    if (tabIndex === 0) {
                      return true;
                    } else {
                      return i.pinned === true;
                    }
                  })
                  .map((conversation, index) => {
                    return (
                      <ConversationListItem
                        key={index}
                        conversation={conversation}
                        selected={
                          chat_conversation.id === conversation.id
                            ? true
                            : false
                        }
                        handleConversationClick={handleConversationClick}
                      />
                    );
                  })}
            </>
          )}
        </Box>
      </Paper>
    </>
  );
}
