const circular__progress__wrapper = {
  width: "50%",
  position: "relative",
  top: "20px",
  mx: "auto",
};

const circular__progress__indicator__img = {
  position: "absolute",
  top: "43%",
  marginLeft: "auto",
  marginRight: "auto",
  left: "0",
  right: "0",
  width: "40px",
  height: "40px",
  transform: `rotate(${-160 + (0 * 220) / 100}deg)`,
  transitionDuration: "0.5s",
  transitionProperty: "transform",
};

const usage__info__container = {
  position: "absolute",
  top: "56%",
  mx: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  minWidth: "100%",
  textAlign: "center",
  px: 3,
};

const redirect__btn__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  px: 5,
  gap: 2,
};

const redirect__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  height: "38px",
  marginLeft: "20px",
};

const redirect__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

export {
  circular__progress__wrapper,
  usage__info__container,
  circular__progress__indicator__img,
  redirect__btn__wrapper,
  redirect__btn,
  redirect__btn__label,
};
