const search__input = {
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: "rgba(239, 241, 249, 1)",
    border: "1px solid #ced4da",
    width: "100%",
    padding: "10px 12px",
    height: "16px",
    fontSize: 12,
    borderRadius: "0px 8px 8px 0px",
    marginRight: "30px",
    minWidth: "200px",
    "&:focus": {
      borderColor: "#1976d2",
    },
  },
};

const header__container = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  verticalAlign: "baseline",
  marginBottom: "15px",
};

const header__h1 = {
  paddingTop: "3px",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "150%",
};

const tab__header__container = {
  borderBottom: 1,
  borderTop: 1,
  borderColor: "divider",
  p: "10px 2",
};

const tab__header__label = {
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "none",
  px: 1,
};

const header__btn = {
  backgroundColor: "blue",
  borderRadius: "8px 0px 0px 8px",
  height: "38px",
  color: "white",
  "&:hover": {
    backgroundColor: "blue",
  },
};

const header__btn__label = {
  fontSize: "12px",
  fontWeight: 400,
  paddingRight: "10px",
};

const category__list__item__text = {
  paddingTop: "2px",
  fontSize: "12px",
  fontWeight: 400,
};

export {
  search__input,
  header__container,
  header__h1,
  tab__header__container,
  tab__header__label,
  header__btn,
  header__btn__label,
  category__list__item__text,
};
