import { useTheme } from "@emotion/react";
import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { webkit__scrollbar } from "../../../../../style";
import odontogramHelper from "./helper";
import {
  table__cell,
  table__row,
  odontogram__input__text,
  disabled__tooth__index,
} from "./style";

export default function TopDetail({
  odontogramLabel,
  note,
  handleNoteChange,
  teethMode,
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
          gap: 1,
          px: isDesktop ? 5 : 1,
          marginBottom: "10px",
        }}
      >
        <TableContainer sx={webkit__scrollbar}>
          <Table aria-label="sticky table">
            <TableBody>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(11, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    11
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(51, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    51
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["1151"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 11,
                    }}
                    value={note[11] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(61, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    61
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(21, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    21
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["6121"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 21,
                    }}
                    value={note[21] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(12, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    12
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(52, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    52
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["1252"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 12,
                    }}
                    value={note[12] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(62, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    62
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(22, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    22
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["6222"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 22,
                    }}
                    value={note[22] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(13, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    13
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(53, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    53
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["1353"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 13,
                    }}
                    value={note[13] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(63, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    63
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(23, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    23
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["6323"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 23,
                    }}
                    value={note[23] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(14, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    14
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(54, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    54
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["1454"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 14,
                    }}
                    value={note[14] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(64, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    64
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(24, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    24
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["6424"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 24,
                    }}
                    value={note[24] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(15, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    15
                  </span>{" "}
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(55, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    55
                  </span>
                  ]
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["1555"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 15,
                    }}
                    value={note[15] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  [
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(65, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    65
                  </span>
                  ]{" "}
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(25, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    25
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["6525"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 25,
                    }}
                    value={note[25] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(16, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    16
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["16"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 16,
                    }}
                    value={note[16] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(26, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    26
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["26"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 26,
                    }}
                    value={note[26] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(17, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    17
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["17"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 17,
                    }}
                    value={note[17] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(27, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    27
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["27"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 27,
                    }}
                    value={note[27] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
              <TableRow sx={table__row}>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(18, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    18
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["18"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 18,
                    }}
                    value={note[18] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell} width={"10%"}></TableCell>
                <TableCell sx={table__cell} align={"left"}>
                  <span
                    style={
                      odontogramHelper.isDisabledByIndex(28, teethMode)
                        ? disabled__tooth__index
                        : {}
                    }
                  >
                    28
                  </span>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    value={odontogramLabel["28"]}
                  ></InputBase>
                </TableCell>
                <TableCell sx={table__cell}>
                  <InputBase
                    sx={odontogram__input__text}
                    placeholder="catatan"
                    inputProps={{
                      "data-id": 28,
                    }}
                    value={note[28] ?? ""}
                    onChange={handleNoteChange}
                  ></InputBase>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
