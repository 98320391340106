const home__widget__welcome__container = {
  minHeight: "150px",
  display: "flex",
  position: "relative",
  marginBottom: "20px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  borderRadius: "10px",
};

const home__widget__welcome__img__container = {
  width: "100%",
  height: "100%",
  position: "relative",
};

const home__widget__welcome__img = {
  width: "100px",
  height: "100px",
  margin: "0px",
  borderRadius: "50%",
  objectFit: "cover",
  position: "absolute",
  top: "50%",
  left: "50%",
  msTransform: "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
};

const home__widget__welcome__text__container = {
  width: "100%",
  height: "100%",
  position: "relative",
  paddingTop: "29px",
};

const home__widget__welcome__text__greeting = {
  fontSize: "16px",
  fontWeight: 700,
};

const home__widget__welcome__text__details = {
  fontSize: "14px",
  marginBottom: "5px",
};

const home__widget__welcome__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
};

const home__widget__welcome__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
};

const home__widget__welcome__illustration = {
  position: "absolute",
  right: "35px",
  bottom: "0px",
  width: "140px",
  height: "188px",
};

const tab__label = {
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "none",
  color: "#7D7D7D",
  px: 1,
};

const search__icon__img = {
  backgroundColor: "blue",
  borderRadius: "0px 8px 8px 0px",
  height: "38px",
};

const queue__stats__container = {
  display: "flex",
  position: "relative",
  background: "#FFFFFF",
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  borderRadius: "10px",
  marginTop: "0px",
  marginBottom: "20px",
};

const circular__container = {
  position: "relative",
  top: "-35px",
  mx: "auto",
  width: "226px",
  height: "226px",
  borderRadius: "50%",
  backgroundColor: "#00AEEF",
  p: 2,
};

const queue__stats__circular__inner__container = {
  position: "absolute",
  top: "45%",
  textAlign: "center",
  left: "50%",
  transform: "translate(-50%, -35%)",
  width: "400px",
};

const queue__stats__circular__inner__text__header = {
  fontSize: "14px",
  fontWeight: 400,
  color: "white",
};

const queue__stats__circular__inner__text__number = {
  fontSize: "32px",
  fontWeight: 700,
  color: "white",
};

const queue__stats__circular__inner__text__name = {
  fontSize: "14px",
  fontWeight: 400,
  color: "white",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const queue__stats__footer__container = {
  display: "block",
  position: "relative",
  marginBottom: "20px",
};

const queue__stats__footer__text__title = {
  fontSize: "14px",
  fontWeight: 600,
  marginBottom: "5px",
};

const queue__stats__footer__text__value = {
  fontSize: "24px",
  fontWeight: 700,
  color: "blue",
};

const call_queue__btn = {
  backgroundColor: "blue",
  borderRadius: "8px",
  textTransform: "none",
  marginBottom: "15px",
  height: "38px",
};

const call_queue__btn__label = {
  marginLeft: "5px",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "5px",
};

const schedule_switcher___container = {
  background: "#FFFFFF",
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  borderRadius: "10px",
  marginBottom: "20px",
  px: 2,
};

const schedule_switcher__header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "5px 15px 5px 15px",
};

const schedule_switcher__header__text = {
  fontSize: "16px",
  fontWeight: 700,
  paddingTop: "7px",
};

export {
  home__widget__welcome__container,
  home__widget__welcome__img__container,
  home__widget__welcome__img,
  home__widget__welcome__text__container,
  home__widget__welcome__text__greeting,
  home__widget__welcome__text__details,
  home__widget__welcome__btn,
  home__widget__welcome__btn__label,
  home__widget__welcome__illustration,
  tab__label,
  search__icon__img,
  queue__stats__container,
  circular__container,
  queue__stats__circular__inner__container,
  queue__stats__circular__inner__text__header,
  queue__stats__footer__container,
  queue__stats__circular__inner__text__number,
  queue__stats__circular__inner__text__name,
  queue__stats__footer__text__title,
  queue__stats__footer__text__value,
  call_queue__btn,
  call_queue__btn__label,
  schedule_switcher___container,
  schedule_switcher__header__container,
  schedule_switcher__header__text,
};
