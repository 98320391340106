import {
  Button,
  IconButton,
  InputBase,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";
import { webkit__scrollbar } from "../../../../../style";
import { table__cell, table__row } from "./style";
import TheadCell from "../../../../../components/table/thead-cell/TheadCell";
import { input__text } from "../../style";
import { useState } from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  minHeight: "calc(100vh - 150px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

const odontogramSymbolData = [
  {
    label: "Tambalan Amalgram",
    kode: "amf",
    image: ["amf.png"],
    mode: "TAA",
  },
  {
    label: "Tambalan Composit",
    kode: "cof",
    image: ["cof.png"],
    mode: "TAC",
  },
  {
    label: "Pit dan Fissure Sealant",
    kode: "fis",
    image: ["fis.png"],
    mode: "PFS",
  },
  {
    label: "Karies",
    kode: "car",
    image: ["car.png"],
    mode: "KAR",
  },
  {
    label: "Full Metal Crown",
    kode: "fmc",
    image: ["fmc.png"],
    mode: "FLMC",
  },
  {
    label: "Porcelain Crown",
    kode: "poc",
    image: ["poc.png"],
    mode: "PLC",
  },
  {
    label: "Metal Porcelain Crown",
    kode: "mpc",
    image: ["mpc.png"],
    mode: "MPLC",
  },
  {
    label: "Gold Porcelain Crown",
    kode: "gmc",
    image: ["gmc.png"],
    mode: "GMC",
  },
  {
    label: "Implant + Porcelain Crown",
    kode: "ipx - poc",
    image: ["ipx-poc.png"],
    mode: "IPLC",
  },
  {
    label: "Partial Denture + Akrilik",
    kode: "prd - acr",
    image: ["prd-acr.png"],
    mode: "PADA",
  },
  {
    label: "Full Denture + Akrilik",
    kode: "fld - acr",
    image: ["fld-acr.png"],
    mode: "FLDA",
  },
  {
    label: "Partial Denture - Metal",
    kode: "prd - frm",
    image: ["prd-frm.png"],
    mode: "PADM",
  },
  {
    label: "Full Denture - Metal",
    kode: "fld - frm",
    image: ["fld-frm.png"],
    mode: "FLDM",
  },
  {
    label: "Fraktur",
    kode: "cfr",
    image: ["cfr.png"],
    mode: "FRAK",
  },
  {
    label: "Gigi Hilang (Mis)",
    kode: "mis",
    image: ["miss.png"],
    mode: "GGHG",
  },
  {
    label: "Gigi Tidak Ada (Non)",
    kode: "non",
    image: ["non.png"],
    mode: "GGTA",
  },
  {
    label: "Un-Erupted",
    kode: "une",
    image: ["une.png"],
    mode: "UERU",
  },
  {
    label: "Partial Erupted",
    kode: "pre",
    image: ["pre.png"],
    mode: "PAERU",
  },
  {
    label: "Anomali",
    kode: "ano",
    image: ["ano.png"],
    mode: "ANOM",
  },
  {
    label: "Sisa Akar",
    kode: "rrx",
    image: ["rrx.png"],
    mode: "SSAK",
  },
  {
    label: "Gigi Non Vital",
    kode: "nvt",
    image: ["gigi-non-vital.png"],
    mode: "GGNV",
  },
  {
    label: "Perawatan Saluran Akar",
    kode: "rct",
    image: ["perawatan-saluran-akar.png"],
    mode: "PSA",
  },
  {
    label: "Full Metal Bridge",
    kode: "meb",
    image: ["meb.png"],
    mode: "FLMB",
  },
  {
    label: "Full Metal Cantilever Bridge",
    kode: "meb",
    image: ["meb-pon.png"],
    mode: "ICLB",
  },
  {
    label: "Porcelain Bridge",
    kode: "pob",
    image: ["pob.png"],
    mode: "IPCB",
  },
  {
    label: "Migrasi Atas Kiri",
    kode: "-",
    image: ["migrasi-kiri.png"],
    mode: "MAK2",
  },
  {
    label: "Migrasi Atas Kanan",
    kode: "-",
    image: ["migrasi-kanan.png"],
    mode: "MAK1",
  },
  {
    label: "Migrasi Bawah Kiri",
    kode: "-",
    image: ["migrasi-kiri.png"],
    mode: "MBK2",
  },
  {
    label: "Migrasi Bawah Kanan",
    kode: "-",
    image: ["migrasi-kanan.png"],
    mode: "MBK1",
  },
  {
    label: "Rotasi Atas Kiri",
    kode: "-",
    image: ["rotasi-atas-kiri.png"],
    mode: "RAK2",
  },
  {
    label: "Rotasi Atas Kanan",
    kode: "-",
    image: ["rotasi-atas-kanan.png"],
    mode: "RAK1",
  },
  {
    label: "Rotasi Bawah Kiri",
    kode: "-",
    image: ["rotasi-bawah-kiri.png"],
    mode: "RBK2",
  },
  {
    label: "Rotasi Bawah Kanan",
    kode: "-",
    image: ["rotasi-bawah-kanan.png"],
    mode: "RBK1",
  },
  {
    label: "Karang Gigi",
    kode: "-",
    image: ["karang.svg"],
    mode: "KARANG",
  },
];

function OdontogramSymbolModal({ open, onClose, mode, onModeChange }) {
  const [filter, setFilter] = useState("");

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleModeChange = (data) => {
    if (typeof onModeChange === "function") {
      onModeChange({ mode: data.mode, label: data.label });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                display: "block",
                minWidth: "150px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Simbol Odontogram
            </Typography>
            <InputBase
              sx={input__text}
              value={filter}
              onChange={handleFilterChange}
            ></InputBase>
          </Box>

          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={30}
              height={30}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <TableContainer
          sx={{
            ...webkit__scrollbar,
            maxHeight: "calc(100vh - 150px)",
            overflowY: "auto",
          }}
        >
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TheadCell name="Nama" useSort={false} />
                <TheadCell name="Kode" useSort={false} />
                <TheadCell name="Simbol" align="center" useSort={false} />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {odontogramSymbolData
                .filter((item) => {
                  if (filter.length < 1) {
                    return true;
                  } else {
                    return (
                      item.label.toLowerCase().includes(filter.toLowerCase()) ||
                      item.kode.toLowerCase().includes(filter.toLowerCase())
                    );
                  }
                })
                .map((item, idx) => {
                  return (
                    <TableRow sx={table__row} key={idx}>
                      <TableCell align={"left"}>{item.label}</TableCell>
                      <TableCell align={"left"}>{item.kode}</TableCell>
                      <TableCell align={"center"}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {item.image.map((image, x) => {
                            return (
                              <img
                                key={x}
                                src={`/assets/odontogram/${image}`}
                                alt="simbol"
                                style={{
                                  maxHeight: "50px",
                                  maxWidth: "70px",
                                }}
                              ></img>
                            );
                          })}
                        </Box>
                      </TableCell>
                      <TableCell align={"left"}>
                        <Button
                          sx={{
                            backgroundColor: "blue",
                            borderRadius: "8px",
                            textTransform: "none",
                            marginBottom: "15px",
                            height: "30px",
                          }}
                          disabled={mode?.mode === item.mode ? true : false}
                          variant="contained"
                          onClick={() => {
                            handleModeChange(item);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 600,
                            }}
                          >
                            Pilih
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}

OdontogramSymbolModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.any,
  onModeChange: PropTypes.func.isRequired,
};

export default OdontogramSymbolModal;
