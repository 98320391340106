import React, { useState } from "react";
import validator from "validator";
import { useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  form__control,
  input__label,
  input__select,
  input__text,
} from "../../rekam-medis/editor/style";
import { useAuth } from "../../../../../providers/AuthProvider";
import mediplusHelper from "../../../../../utils/mediplusHelper";
import authHeader from "../../../../../services/authHeader";
import * as Sentry from "@sentry/react";

export default function CreateModal(props) {
  const { user } = useAuth();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  const clinicRooms = props.clinicRooms ?? [];
  const open = Boolean(props.open);
  const onClose = props.onClose;
  const onSave = props.onSave;
  const [schedule, setSchedule] = useState({
    clinic_room_id: current_active_schedule.clinic_room?.id ?? "",
    hari: 1,
    jam_buka: "",
    jam_tutup: "",
    limit: 0,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnSave = () => {
    if (typeof onSave === "function") {
      onSave();
    }
  };

  const [validationError, setValidationError] = useState({});

  const handleChange = (e) => {
    setSchedule((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));
    setValidationError((prevError) => ({
      ...prevError,
      [e.target.name]: "",
    }));
  };

  const validate = () => {
    const newError = {};

    let isNomor = validator.isNumeric(String(schedule.limit));
    if (!isNomor) {
      newError.limit = "input wajib numerik";
    }
    if (parseInt(schedule.limit) < 0) {
      newError.limit = "limit antrian minimal 0";
    }

    if (parseInt(schedule.hari) < 0 || parseInt(schedule.hari) > 6) {
      newError.hari = "input hari invalid";
    }

    var isValidJamBuka = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(
      schedule.jam_buka
    );

    if (!isValidJamBuka) {
      newError.jam_buka = "jam invalid";
    }

    var isValidJamTutup = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(
      schedule.jam_tutup
    );

    if (!isValidJamTutup) {
      newError.jam_tutup = "jam invalid";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    const findValidationError = validate();
    if (Object.values(findValidationError).some((err) => err !== "")) {
      setValidationError(findValidationError);
    } else {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/schedules`,
          {
            ...schedule,
            timezone: mediplusHelper.getTimezoneOffset(),
          },
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        enqueueSnackbar("sukses menyimpan data", {
          variant: "success",
        });
        handleOnSave();
        handleModalClose();
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.response?.data?.message ?? e.message, {
          variant: "error",
        });
        if (e.request?.status === 422) {
          Object.entries(e.response.data.errors).forEach(([key, value]) => {
            setValidationError((prev) => ({
              ...prev,
              [key]: Array.isArray(value) ? value[0] : value,
            }));
          });
        }
        Sentry.captureException(e);
      } finally {
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Buat Jadwal
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <FormControl variant="standard" sx={form__control}>
            <InputLabel
              sx={{ ...input__label, marginBottom: "10px" }}
              shrink
              htmlFor="clinic_room_id"
            >
              Ruang
            </InputLabel>
            <Select
              id="clinic_room_id"
              name="clinic_room_id"
              value={schedule.clinic_room_id}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              IconComponent={() => {
                return <></>;
              }}
              sx={input__select}
            >
              {clinicRooms &&
                clinicRooms.map((item, index) => {
                  return (
                    <MenuItem key={`options_${index}`} value={item.id}>
                      {item.nama}
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText error={true}>
              {validationError?.clinic_room_id}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel
              sx={{ ...input__label, marginBottom: "10px" }}
              shrink
              htmlFor="hari"
            >
              Hari
            </InputLabel>
            <Select
              id="hari"
              name="hari"
              value={schedule.hari}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              IconComponent={() => {
                return <></>;
              }}
              sx={input__select}
            >
              <MenuItem value={1}>Senin</MenuItem>
              <MenuItem value={2}>Selasa</MenuItem>
              <MenuItem value={3}>Rabu</MenuItem>
              <MenuItem value={4}>Kamis</MenuItem>
              <MenuItem value={5}>Jumat</MenuItem>
              <MenuItem value={6}>Sabtu</MenuItem>
              <MenuItem value={0}>Minggu</MenuItem>
            </Select>
            <FormHelperText error={true}>
              {validationError?.hari}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="jam_buka">
              Jam Buka
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              placeholder=""
              id="jam_buka"
              name="jam_buka"
              type="time"
              value={schedule?.jam_buka ?? ""}
            />
            <FormHelperText error={true}>
              {validationError?.jam_buka}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="jam_tutup">
              Jam Tutup
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              placeholder=""
              id="jam_tutup"
              name="jam_tutup"
              type="time"
              value={schedule?.jam_tutup ?? ""}
            />
            <FormHelperText error={true}>
              {validationError?.jam_tutup}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="limit">
              Batas Antrian
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              placeholder=""
              id="limit"
              name="limit"
              type="number"
              value={schedule?.limit ?? ""}
            />
            <FormHelperText error={true}>
              {validationError?.limit}
            </FormHelperText>
          </FormControl>
          <Button
            fullWidth
            sx={{
              backgroundColor: "blue",
              borderRadius: "8px",
              textTransform: "none",
              marginBottom: "15px",
              height: "38px",
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: 600,
                marginRight: "5px",
              }}
            >
              Simpan
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
