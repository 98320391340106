import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../../../../../../providers/AuthProvider";
import {
  addQtyInvoiceItem,
  subQtyInvoiceItem,
  updateInvoiceInfo,
} from "../../../../../../../redux/slices/invoice";
import authHeader from "../../../../../../../services/authHeader";
import {
  add__discount__btn,
  box__info__container,
  box__info__label,
  box__info__value,
  discount__info__container,
  discount__info__text,
  invoice__img__container,
  invoice__info__text__container,
  invoice__info__text__name,
  invoice__info__text__price,
  invoice__info__wrapper,
  invoice__item__action__container,
  invoice__item__box,
  invoice__item__list__container,
  qty__btn,
  qty__text,
  submit__btn,
  submit__btn__label,
} from "./style";
import mediplusHelper from "../../../../../../../utils/mediplusHelper";
import DiskonModal from "./DiskonModal";
import {
  input__label,
  input__text,
} from "../../../../rekam-medis/editor/style";
import ImageLabel from "../../produk/ImageLabel";
import { useMemo } from "react";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

export default function InvoiceDetail({ loadData, setInvoiceLoading }) {
  const { user } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const invoice = useSelector((state) => state.invoice);
  const medicine_cache = useSelector((state) => state.medicine_cache);

  const [submitting, setSubmitting] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [diskonModalOpen, setDiskonModalOpen] = useState(false);

  const itemCount = useMemo(() => {
    return invoice?.invoice_items?.length ?? 0;
  }, [invoice]);

  const scrollableInvItemRef = useRef(null);

  const handleDiskonModalOpen = (index) => {
    let item = invoice.invoice_items.find((item, idx) => idx === index);
    if (item !== undefined) {
      setSelectedItem(item);
      setSelectedItemIndex(index);
      setDiskonModalOpen(true);
    }
  };

  const handleDiskonModalClose = () => {
    setDiskonModalOpen(false);
  };

  const handleCatatanOnChange = (e) => {
    dispatch(
      updateInvoiceInfo({
        catatan: e.target.value,
      })
    );
  };

  const handleAddQty = (index, item) => {
    if (item.tipe === "MEDICINE") {
      let medDb = medicine_cache.find((med) => med.id === item.medicine_id);
      if (medDb) {
        let addedToInvoice;
        if (item.hasOwnProperty("originQty")) {
          addedToInvoice = item.kuantitas + 1 - item.originQty;
        } else {
          addedToInvoice = item.kuantitas + 1;
        }
        if (addedToInvoice > medDb.stok) {
          enqueueSnackbar("menambah melebihi stok", {
            variant: "warning",
          });
        }
      }
    }
    dispatch(addQtyInvoiceItem(index));
  };

  const handleSubQty = (index, item) => {
    if (item.tipe === "MEDICINE") {
      let medDb = medicine_cache.find((med) => med.id === item.medicine_id);
      if (medDb) {
        let addedToInvoice;
        if (item.hasOwnProperty("originQty")) {
          addedToInvoice = item.kuantitas - 1 - item.originQty;
        } else {
          addedToInvoice = item.kuantitas - 1;
        }
        if (addedToInvoice > medDb.stok) {
          enqueueSnackbar("menambah melebihi stok", {
            variant: "warning",
          });
        }
      }
    }
    dispatch(subQtyInvoiceItem(index));
  };

  const saveData = async () => {
    if (!invoice?.user?.id && !invoice?.clinic_room_id) {
      enqueueSnackbar("harap pilih ruang klinik terlebih dahulu", {
        variant: "warning",
      });
      return;
    }
    if (!invoice.id) {
      if (invoice.nama_pasien.length < 1) {
        enqueueSnackbar("harap isi nama pasien terlebih dahulu", {
          variant: "warning",
        });
        return;
      }
    }
    if (submitting === false) {
      setSubmitting(true);
      try {
        const formData = {
          ...invoice,
          tanggal_pemeriksaan: moment(invoice.tanggal_pemeriksaan)
            .subtract(moment().utcOffset(), "m")
            .format("YYYY-MM-DD HH:mm:ss"),
          items: [...invoice.invoice_items],
        };
        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices${
            invoice?.id ? "/" + invoice.id : ""
          }`,
          formData,
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
          }
        );
        enqueueSnackbar("sukses menyimpan data", {
          variant: "success",
        });
        if (invoice?.id) {
          setInvoiceLoading(true);
          loadData(invoice.id);
        } else {
          history.push(`/pembayaran/editor/${result.data.invoice_id}`);
          setInvoiceLoading(true);
          loadData(result.data.invoice_id);
        }
      } catch (e) {
        if (e.request?.status === 422) {
          enqueueSnackbar("harap isi form yang disediakan dengan sesuai", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(e.response?.data?.message ?? e.message, {
            variant: "error",
          });
        }
        console.log(e);
        Sentry.captureException(e);
      } finally {
        setSubmitting(false);
      }
    } else {
      enqueueSnackbar("tunggu hingga proses menyimpan sebelumnya selesai", {
        variant: "warning",
      });
    }
  };

  useEffect(() => {
    let element = scrollableInvItemRef?.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [itemCount]);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            marginBottom: "10px",
          }}
        >
          Detail Layanan
        </Typography>
        <Box ref={scrollableInvItemRef} sx={invoice__item__list__container}>
          {invoice.invoice_items &&
            invoice.invoice_items.map((item, index) => {
              return (
                <Box key={`inv_item_${index}`}>
                  <Paper sx={invoice__item__box}>
                    <Box sx={invoice__info__wrapper}>
                      <Box sx={invoice__img__container}>
                        <ImageLabel
                          width={44}
                          height={44}
                          fontSize={"14px"}
                          text={item.nama}
                          photo={item.photo}
                        />
                      </Box>

                      <Box
                        component={"div"}
                        sx={invoice__info__text__container}
                      >
                        <Typography
                          component={"span"}
                          sx={invoice__info__text__name}
                        >
                          {item.nama}
                        </Typography>
                        <Typography
                          component={"span"}
                          sx={invoice__info__text__price}
                        >
                          {mediplusHelper.formatRupiah(
                            item.harga * item.kuantitas
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={invoice__item__action__container}>
                      <IconButton
                        onClick={() => {
                          handleSubQty(index, item);
                        }}
                        aria-label="sub"
                        sx={qty__btn}
                      >
                        <img
                          width={25}
                          height={25}
                          src="/assets/icons/minus-btn.svg"
                          alt="minus"
                        ></img>
                      </IconButton>
                      <Typography sx={qty__text}>{item.kuantitas}</Typography>
                      <IconButton
                        onClick={() => {
                          handleAddQty(index, item);
                        }}
                        aria-label="add"
                        sx={qty__btn}
                      >
                        <img
                          width={25}
                          height={25}
                          src="/assets/icons/plus-btn.svg"
                          alt="plus"
                        ></img>
                      </IconButton>
                    </Box>
                  </Paper>
                  <Box sx={discount__info__container}>
                    <Typography
                      onClick={() => {
                        handleDiskonModalOpen(index);
                      }}
                      sx={add__discount__btn}
                    >
                      {item.diskon_amount !== 0
                        ? "Edit Diskon"
                        : "Tambah Diskon"}
                    </Typography>
                    <Typography sx={discount__info__text}>
                      {item.diskon_amount !== 0
                        ? item.diskon_type === "NORMAL"
                          ? mediplusHelper.formatRupiah(item.diskon)
                          : `${mediplusHelper.formatRupiah(item.diskon)} | ${
                              item.diskon_amount
                            }%`
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Paper>
      <FormControl
        variant="standard"
        sx={{
          width: "100%",
          mt: "10px",
          mb: "10px",
        }}
      >
        <InputLabel sx={input__label} shrink htmlFor="catatan">
          Catatan (Opsional)
        </InputLabel>
        <InputBase
          sx={input__text}
          multiline
          maxRows={3}
          minRows={3}
          placeholder="catatan"
          id="catatan"
          name="catatan"
          type="text"
          value={invoice?.catatan ?? ""}
          onChange={handleCatatanOnChange}
        />
        <FormHelperText error={true}></FormHelperText>
      </FormControl>
      <Box
        sx={{
          borderBottom: "2px solid #F3F1F1",
        }}
      >
        <Box sx={box__info__container}>
          <Typography sx={box__info__label}>Subtotal</Typography>
          <Typography sx={box__info__value}>
            {mediplusHelper.formatRupiah(
              invoice.harga_akhir + invoice.total_diskon
            )}
          </Typography>
        </Box>

        <Box sx={box__info__container}>
          <Typography sx={box__info__label}>Diskon</Typography>
          <Typography sx={box__info__value}>
            {mediplusHelper.formatRupiah(invoice.total_diskon)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <Box sx={box__info__container}>
          <Typography sx={box__info__label}>Total</Typography>
          <Typography sx={box__info__value}>
            {mediplusHelper.formatRupiah(invoice.harga_akhir)}
          </Typography>
        </Box>
      </Box>
      <Button
        fullWidth
        sx={submit__btn}
        variant="contained"
        disabled={submitting ? true : false}
        onClick={saveData}
      >
        <Typography sx={submit__btn__label}>Simpan</Typography>
      </Button>
      <DiskonModal
        open={diskonModalOpen}
        invoiceItemIndex={selectedItemIndex}
        invoiceItem={selectedItem}
        setInvoiceItem={setSelectedItem}
        onClose={handleDiskonModalClose}
      />
    </>
  );
}
