import { IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { modal__container, modal__header } from "./style";

export default function AvatarModal({ open, onClose, handleAvatarClick }) {
  const availableAvatars = ["group_235", "group_236", "group_237"];
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal__container}>
        <Box sx={modal__header}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Pilih Avatar
          </Typography>
          <IconButton
            onClick={onClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {availableAvatars.map((i) => {
            return (
              <img
                onClick={() => {
                  handleAvatarClick(i);
                }}
                key={i}
                src={`/assets/avatars/${i}.png`}
                alt="avatars"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></img>
            );
          })}
        </Box>
      </Box>
    </Modal>
  );
}
