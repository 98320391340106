import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { tab__container, tab__label } from "./style";
import DetailPenyimpananContent from "./content/DetailPenyimpananContent";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import a11yProps from "../../../../components/tab/a11yProps";
import TabPanel from "../../../../components/tab/TabPanel";
import SectionLoading from "../../../../components/loading/SectionLoading";
import * as Sentry from "@sentry/react";
import {
  snackbar__content__container,
  snackbar__content__text,
} from "../../rekam-medis/main/table/style";

export default function DetailPenyimpanan(props) {
  const route = useRouteMatch();
  const history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [detailPenyimpanan, setDetailPenyimpanan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const btnOptRef = useRef();
  const [popperOptOpen, setPopperOptOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSelectAll = () => {
    setSelectedMedia([...detailPenyimpanan.media]);
    setPopperOptOpen(false);
  };

  const loadData = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/v2-storage/folders/${id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setDetailPenyimpanan(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.request?.status === 404) {
          return history.push("/penyimpanan");
        }
        enqueueSnackbar(error.message, {
          variant: "error",
        });
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileDelete = () => {
    setDeleteConfirmationOpen(false);
    setPopperOptOpen(false);
    if (selectedMedia.length > 0) {
      enqueueSnackbar("memproses hapus file...", {
        variant: "default",
        autoHideDuration: "4000",
      });
      const ids = selectedMedia.reduce((prev, val) => {
        prev.push(val.id);
        return prev;
      }, []);
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/v1/vdoc/storage/folders`,
          {
            params: {
              head: user.head_doctor_id,
            },
            headers: authHeader(),
            data: {
              ids: ids,
            },
          }
        )
        .then((response) => {
          enqueueSnackbar("sukses menghapus file", {
            variant: "success",
            autoHideDuration: "2000",
          });
          setSelectedMedia([]);
        })
        .catch(function (e) {
          console.log(e);
          if (e.request?.status === 404) {
            return history.push("/penyimpanan");
          }
          enqueueSnackbar(e.response?.data?.message ?? e.message, {
            variant: "error",
            autoHideDuration: "2000",
          });
          Sentry.captureException(e);
        })
        .finally(() => {
          loadData(route.params.id);
        });
    } else {
      enqueueSnackbar("silahkan pilih minimal 1 file", {
        variant: "warning",
        autoHideDuration: "2000",
      });
    }
  };

  useEffect(() => {
    if (route?.params?.id && !isNaN(route?.params?.id)) {
      loadData(route.params.id);
    } else {
      enqueueSnackbar("params id url invalid", {
        variant: "error",
      });
    }
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={handleFileDelete}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />
      <Box sx={{ width: "100%", position: "relative" }}>
        <Popper
          id={"medicineopt"}
          open={popperOptOpen}
          anchorEl={btnOptRef.current}
          placement={"left"}
          sx={{
            zIndex: 1500,
          }}
        >
          <Box>
            <Paper
              elevation={1}
              sx={{
                borderRadius: "10px",
              }}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={handleSelectAll}
                    sx={{
                      py: "0px",
                    }}
                  >
                    <ListItemText
                      sx={{
                        paddingTop: "2px",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                      primary="Pilih Semua"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setPopperOptOpen(false);
                      setDeleteConfirmationOpen(true);
                    }}
                    sx={{
                      py: "0px",
                    }}
                  >
                    <ListItemText
                      sx={{
                        paddingTop: "2px",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                      primary={`Hapus (${selectedMedia.length} File)`}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Box>
        </Popper>

        <Box sx={tab__container}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="tabk"
            sx={{
              ".MuiTabs-flexContainer": {
                background: "transparent",
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FF0091",
                height: "3px",
              },
            }}
          >
            <Tab
              label={
                <Typography
                  sx={{
                    ...tab__label,
                    color: tabIndex === 0 ? "#041615" : "#7D7D7D",
                  }}
                >
                  Semua
                </Typography>
              }
              {...a11yProps(0)}
            ></Tab>
            <Tab
              label={
                <Typography
                  sx={{
                    ...tab__label,
                    color: tabIndex === 1 ? "#041615" : "#7D7D7D",
                  }}
                >
                  Foto
                </Typography>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Typography
                  sx={{
                    ...tab__label,
                    color: tabIndex === 2 ? "#041615" : "#7D7D7D",
                  }}
                >
                  Video
                </Typography>
              }
              {...a11yProps(2)}
            />
            <Tab
              label={
                <Typography
                  sx={{
                    ...tab__label,
                    color: tabIndex === 3 ? "#041615" : "#7D7D7D",
                  }}
                >
                  Lainnya
                </Typography>
              }
              {...a11yProps(3)}
            />
          </Tabs>
          <IconButton
            ref={btnOptRef}
            color="inherit"
            aria-label="opt"
            edge="start"
            type="button"
            onClick={() => {
              setPopperOptOpen(!popperOptOpen);
            }}
            sx={{
              position: "absolute",
              right: "0px",
              top: "0px",
            }}
          >
            <LazyLoadImage
              effect="blur"
              src="/assets/icons/table-action.svg"
              alt="refresh"
            ></LazyLoadImage>
          </IconButton>
        </Box>
        <TabPanel
          sx={{
            ".MuiBox-root": {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          }}
          value={tabIndex}
          index={0}
        >
          {loading ? (
            <SectionLoading height="calc(100vh - 200px)" />
          ) : (
            <DetailPenyimpananContent
              detailPenyimpanan={detailPenyimpanan}
              selectedMedia={selectedMedia}
              setSelectedMedia={setSelectedMedia}
              type="ALL"
            />
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {loading ? (
            <SectionLoading height="calc(100vh - 200px)" />
          ) : (
            <DetailPenyimpananContent
              detailPenyimpanan={detailPenyimpanan}
              selectedMedia={selectedMedia}
              setSelectedMedia={setSelectedMedia}
              type="IMAGE"
            />
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          {loading ? (
            <SectionLoading height="calc(100vh - 200px)" />
          ) : (
            <DetailPenyimpananContent
              detailPenyimpanan={detailPenyimpanan}
              selectedMedia={selectedMedia}
              setSelectedMedia={setSelectedMedia}
              type="VIDEO"
            />
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          {loading ? (
            <SectionLoading height="calc(100vh - 200px)" />
          ) : (
            <DetailPenyimpananContent
              detailPenyimpanan={detailPenyimpanan}
              selectedMedia={selectedMedia}
              setSelectedMedia={setSelectedMedia}
              type="OTHER"
            />
          )}
        </TabPanel>
      </Box>
    </>
  );
}
