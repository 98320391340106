import { Button, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { db } from "../../db/db";
import { useAuth } from "../../providers/AuthProvider";
import { changeTimelineGroupMode } from "../../redux/slices/timelineGroupMode";
import AppLoading from "../components/loading/AppLoading";

export default function HeadDoctorChooser() {
  const dispatch = useDispatch();
  const { user, loading } = useAuth();
  const history = useHistory();
  if (loading) {
    return <AppLoading />;
  }
  if (!user) {
    history.push("/login");
    return <></>;
  }
  const handleHeadDoctorChoose = async (id) => {
    let headDoctorProfile = user.heads.find((i) => i.id === id);
    if (headDoctorProfile) {
      dispatch(changeTimelineGroupMode(headDoctorProfile.timeline_group_mode));
    } else {
      dispatch(changeTimelineGroupMode("ktp"));
    }
    const head_doctor = await db.head_doctor
      .where("id")
      .aboveOrEqual(0)
      .first();
    if (!head_doctor) {
      await db.head_doctor.add({
        doctor_id: id,
      });
    } else {
      await db.head_doctor.where("id").equals(head_doctor.id).modify({
        doctor_id: id,
      });
    }
    window.location.href = "/";
  };

  return (
    <Modal
      open={true}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 650,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1,
          maxHeight: 500,
          overflowY: "auto",
          overflowX: "hidden",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "wrap",
          "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(255 0 145 / 0.1)",
            borderRadius: "10px",
            border: "1px solid rgb(255 0 145 / 0.2)",
          },
        }}
      >
        {user &&
          user.heads &&
          user.heads.length > 0 &&
          user.heads.map((item) => {
            return (
              <Paper
                key={`head_${item.id}`}
                elevation={0}
                sx={{
                  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
                  marginBottom: "10px",
                }}
              >
                <Paper
                  key={`head_c_1_${item.id}`}
                  sx={{
                    display: "flex",
                    minWidth: "262px",
                    minHeight: "64px",
                    boxShadow: "none",
                    position: "relative",
                    margin: "2px 16px",
                    borderBottom: "1px solid #D4D4D4",
                  }}
                  elevation={0}
                >
                  <img
                    src={item.photo_profile}
                    alt="_photo"
                    width="40"
                    height="40"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "0px",
                      marginRight: "15px",
                      borderRadius: "50%",
                    }}
                  ></img>
                  <Box
                    component={"div"}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "4px",
                      my: "auto",
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "19px",
                      }}
                    >
                      {item.nama}
                    </Typography>
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "19px",
                        display: "flex",
                        flexDirection: "row",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {""}
                    </Typography>
                  </Box>
                </Paper>
                <Box
                  key={`schedule_c_2_${item.id}`}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    margin: "10px 16px 20px 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  ></Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  ></Typography>
                </Box>
                <Box
                  key={`schedule_c_3_${item.id}`}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    margin: "10px 16px",
                    paddingBottom: "15px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "blue",
                      width: "65px",
                      height: "38px",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      handleHeadDoctorChoose(item.id);
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      Pilih
                    </Typography>
                  </Button>
                </Box>
              </Paper>
            );
          })}
      </Box>
    </Modal>
  );
}
