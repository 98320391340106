import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import mediplusHelper from "../../../../../../utils/mediplusHelper";

export default function ImageLabel({
  width,
  height,
  fontSize,
  fontWeight,
  text,
  photo,
}) {
  return (
    <>
      <Box
        sx={{
          width: width ?? "95px",
          height: height ?? "95px",
          background: photo ? "none" : "#FF0091",
          borderRadius: "50%",
          mx: "auto",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {photo ? (
          <img
            src={photo}
            style={{
              margin: 0,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              fitContent: "cover",
            }}
            alt="product"
          ></img>
        ) : (
          <Typography
            sx={{
              margin: 0,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: fontSize ? fontSize : "28px",
              fontWeight: fontWeight ? fontWeight : 700,
              color: "white",
            }}
          >
            {mediplusHelper.cropText(
              mediplusHelper.getInitials(text),
              2,
              false
            )}
          </Typography>
        )}
      </Box>
    </>
  );
}
