import {
  Button,
  IconButton,
  InputBase,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../providers/AuthProvider";
import {
  action__btn,
  action__btn__label,
  modal,
  search__input,
  search__input__btn,
  search__input__container,
  table__cell__text,
  table__contaner,
  table__title,
  table__wrapper,
  user__profile__container,
  user__profile__img,
  user__profile__text,
} from "./style";
import TheadCell from "../../table/thead-cell/TheadCell";
import PropTypes from "prop-types";
import authHeader from "../../../../../services/authHeader";
import * as Sentry from "@sentry/react";

function SendAntrianModal({ open, onChoose, onClose, data }) {
  const { user } = useAuth();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortType, setSortType] = useState("DESC");
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState("");
  const [lastPage, setLastPage] = React.useState(1);

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      if (sortType === "ASC") {
        setSortType("DESC");
      } else {
        setSortType("ASC");
      }
    } else {
      setSortBy(field);
      setSortType("DESC");
    }
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  const loadData = (url = null) => {
    axios
      .get(
        url
          ? url
          : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/queues/called`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: 3,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        setRows((prev) => [...response.data.data]);
        setLastPage(response.data.last_page);
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  const link = (medical_record_id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/scan-folders/link-to-medical-record`,
        {
          medical_record_id: medical_record_id,
          scan_folder_id: data.id,
        },
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        enqueueSnackbar("sukses mengirim data ke antrian", {
          variant: "success",
        });
        if (typeof onChoose === "function") {
          onChoose();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (open === true) {
      loadData();
    }
  }, [open, sortBy, sortType, search, page]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal}>
        <Box id="modal-modal-description">
          <Box sx={table__wrapper}>
            <Typography sx={table__title}>Data Antrian</Typography>
            <IconButton
              onClick={handleModalClose}
              color="inherit"
              aria-label="refresh"
              edge="start"
              type="button"
            >
              <img
                width={25}
                height={25}
                effect="blur"
                src="/assets/icons/close.svg"
                alt="refresh"
              ></img>
            </IconButton>
          </Box>
          <Box sx={search__input__container}>
            <InputBase
              size="18"
              placeholder="Cari"
              value={search}
              onChange={handleSearchInputChange}
              sx={search__input}
            ></InputBase>
            <IconButton aria-label="search" sx={search__input__btn}>
              <img src="/assets/icons/search.svg" alt="search"></img>
            </IconButton>
          </Box>
          <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={table__contaner}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TheadCell
                      name="Nama"
                      column_name="nama"
                      useSort={true}
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                      imageColumn={true}
                    ></TheadCell>
                    <TheadCell
                      name="Tanggal"
                      column_name="created_at"
                      useSort={true}
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    ></TheadCell>
                    <TheadCell
                      name="Nomor Antrian"
                      column_name="nomor_antrian"
                      useSort={true}
                      currentSortColumnName={sortBy}
                      currentSortType={sortType}
                      onClick={handleSortChange}
                    ></TheadCell>
                    <TheadCell
                      name="Nomor Antrian"
                      column_name="nomor_antrian"
                      useSort={false}
                    ></TheadCell>
                  </TableRow>
                </TableHead>
                {rows.length > 0 && (
                  <TableBody>
                    {rows.map((row, idx) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell key={`tc1_${row.id}`} align={"left"}>
                          <Paper sx={user__profile__container}>
                            <img
                              src={
                                row.user?.photo_profile ??
                                "/assets/icons/folder.svg"
                              }
                              alt="img"
                              style={user__profile__img}
                            ></img>
                            <Typography
                              component={"span"}
                              sx={user__profile__text}
                            >
                              {row.user?.nama ?? "-"}
                            </Typography>
                          </Paper>
                        </TableCell>
                        <TableCell
                          align={"left"}
                          sx={table__cell__text}
                          width="20%"
                        >
                          {moment(row.created_at).format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                        <TableCell
                          align={"left"}
                          sx={table__cell__text}
                          width="20%"
                        >
                          {row.nomor_antrian ?? "0"}
                        </TableCell>
                        <TableCell
                          align={"center"}
                          sx={table__cell__text}
                          width="10%"
                        >
                          <Button
                            sx={action__btn}
                            variant="contained"
                            onClick={() => {
                              link(row.id);
                            }}
                          >
                            <img
                              width="20"
                              height="20"
                              src="/assets/icons/choose.svg"
                              alt="next-antrian"
                            ></img>
                            <Typography sx={action__btn__label}>
                              Pilih
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {rows.length < 1 && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        Tidak ada data
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <Stack alignItems="end">
              <Pagination
                shape="rounded"
                sx={{
                  p: 2,
                  ".Mui-selected": {
                    backgroundColor: "rgba(0, 174, 239, 1)!important",
                    color: "white",
                  },
                }}
                count={lastPage}
                showFirstButton
                showLastButton
                onChange={handlePageChange}
              />
            </Stack>
          </Paper>
        </Box>
      </Box>
    </Modal>
  );
}

SendAntrianModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onChoose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default SendAntrianModal;
