import { webkit__scrollbar } from "../../../../../style";

const user__detail__center__container = {
  alignItems: "center",
  textAlign: "center",
  marginBottom: "16px",
};

const user__detail__center__img = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "10px",
  borderRadius: "50%",
  width: "50px",
  height: "50px",
};

const user__detail__center__name = {
  fontWeight: 600,
  fontSize: "16px",
  overflowWrap: "break-word",
};

const user__detail__center__birth = {
  fontWeight: 600,
  fontSize: "11px",
  overflowWrap: "break-word",
};

const user__detail__container = {
  px: 3,
  maxHeight: "370px",
  overflowY: "auto",
  overflowX: "hidden",
  marginBottom: "10px",
  ...webkit__scrollbar,
};

const user__detail__label = {
  fontWeight: 600,
  fontSize: "12px",
  color: "rgba(125, 125, 125, 1)",
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
};

const user__detail__value = {
  fontWeight: 600,
  fontSize: "14px",
  marginBottom: "12px",
  display: "inline-block",
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  maxWidth: "100%",
};

export {
  user__detail__center__container,
  user__detail__center__img,
  user__detail__center__name,
  user__detail__center__birth,
  user__detail__container,
  user__detail__label,
  user__detail__value,
};
