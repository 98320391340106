import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";
import validator from "validator";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../../providers/AuthProvider";
import AuthService from "../../../../services/authService";
import authHeader from "../../../../services/authHeader";
import mediplusHelper from "../../../../utils/mediplusHelper";
import {
  otp__button__resend,
  otp__button__submit,
  otp__text__h1,
  otp__text__h2,
} from "./style";
import { auth__input__number, auth__input__text } from "../../style";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/slices/auth";
import { useSnackbar } from "notistack";

export default function Otp() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user, setAuthUpdate } = useAuth();
  const [counter, setCounter] = useState(5);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [validationError, setValidationError] = useState("");
  const [success, setSuccess] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [otpMode, setOtpMode] = useState("WHATSAPP");

  const handleLogout = async () => {
    await AuthService.logout();
    dispatch(logout());
    setAuthUpdate(true);
  };

  const secondsToTime = (secs) => {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const requestResendOtp = async (mode) => {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/otp/resend`;
    if (mode === "EMAIL") {
      url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/otp/resend-email`;
    }
    try {
      await axios.post(
        url,
        {},
        {
          headers: authHeader(),
        }
      );
      if (mode === "EMAIL") {
        enqueueSnackbar(
          "Sukses mengirim otp. silahkan periksa di bagian spam email apabila tidak ada.",
          {
            autoHideDuration: 3000,
          }
        );
        return;
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const handleResend = async () => {
    if (resendDisabled === true) {
      enqueueSnackbar("mohon tunggu hingga hitungan mundur selesai");
      return;
    }
    if (retryCount >= 3) {
      AuthService.logout();
      setAuthUpdate(true);
      return;
    }
    setCounter(5);
    setResendDisabled(true);
    setRetryCount(retryCount + 1);
    requestResendOtp(otpMode);
  };

  const handleSwitch = () => {
    if (resendDisabled === true) {
      enqueueSnackbar("Mohon tunggu hingga hitungan mundur selesai");
      return;
    }
    let newOtpMode = otpMode === "WHATSAPP" ? "EMAIL" : "WHATSAPP";
    setOtpMode(newOtpMode);
    setCounter(5);
    setResendDisabled(true);
    setRetryCount(retryCount + 1);
    requestResendOtp(newOtpMode);
  };

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    if (counter === 0) {
      setResendDisabled(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const handleSubmit = async () => {
    setValidationError("");
    let pininputs = [].slice.call(document.getElementsByClassName("pininputs"));
    let pin = "";
    for (i in pininputs) {
      let input = [].slice.call(pininputs[i].children)[0];
      pin = `${pin}${input.value}`;
    }
    if (pin.length < 6) {
      setValidationError("silahkan lengkapi pin");
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/otp`,
        {
          otp: pin,
        },
        {
          headers: authHeader(),
        }
      );
      localStorage.setItem("already_token_otp", true);
      setAuthUpdate(true);
      setSuccess(true);
    } catch (e) {
      setValidationError(e.response?.data?.message);
    }
  };

  var inputLength = [],
    i = 0,
    len = 6;
  while (++i <= len) inputLength.push(i);

  const handleKeyUp = (e) => {
    let id = e.target.getAttribute("data-id");

    let value = e.target.value;
    if (!validator.isNumeric(value)) {
      e.target.value = "";
      return;
    }

    let nextId = parseInt(id) + 1;
    if (nextId <= 6) {
      let nextInput = document.getElementById(`otp-${nextId}`);
      nextInput.focus();
      nextInput.select();
    }
  };

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (user) {
    let isOtpVerified =
      mediplusHelper.findLocalStorageItems("already_token_otp");
    if (isOtpVerified.length > 0) {
      if (isOtpVerified[0].val === "true") {
        return <Redirect to="/" />;
      }
    }
  }

  if (success) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Typography variant="h4" component="h1" sx={otp__text__h1}>
        Silahkan ketik kode konfirmasi anda
      </Typography>
      {otpMode === "WHATSAPP" ? (
        <Typography sx={otp__text__h2}>
          Yang telah kami kirimkan ke whatsapp nomor HP yang terdaftar{"  "}
          <b>{user.nomor}</b>
        </Typography>
      ) : (
        <Typography sx={otp__text__h2}>
          Yang telah kami kirimkan ke email yang terdaftar{"  "}
          <b>{user.email}</b>
        </Typography>
      )}

      <Grid
        container
        spacing={1}
        sx={{
          marginBottom: "14px",
        }}
      >
        {inputLength.map(function (i) {
          return (
            <Grid item xs={2} key={i}>
              <FormControl variant="standard" id={`form-${i}`}>
                <InputBase
                  id={`otp-${i}`}
                  className={"pininputs"}
                  type="number"
                  inputProps={{
                    maxLength: 1,
                    "data-id": i,
                    pattern: "[0-9]",
                  }}
                  onKeyUp={handleKeyUp}
                  autoFocus={i === 1 ? true : false}
                  sx={auth__input__number}
                ></InputBase>
              </FormControl>
            </Grid>
          );
        })}
      </Grid>

      <Button
        variant="contained"
        onClick={handleSubmit}
        sx={otp__button__submit}
      >
        Verifikasi
      </Button>
      <Button
        variant="outlined"
        disabled={retryCount >= 3 ? false : resendDisabled}
        onClick={handleResend}
        sx={otp__button__resend}
      >
        {counter === 0 ? "Kirim Ulang Kode" : secondsToTime(counter)}
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          mb: 2,
        }}
      >
        <Typography>Atau</Typography>
        <Typography
          sx={{
            color: "blue",
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={handleSwitch}
        >
          kirim melalui {otpMode === "WHATSAPP" ? "email" : "whatsapp"}
        </Typography>
      </Box>

      <Box
        sx={{
          marginBottom: "140px",
        }}
      >
        <Typography
          onClick={handleLogout}
          sx={{
            color: "blue",
            textDecoration: "underline",
            fontSize: "14px",
            textAlign: "right",
            cursor: "pointer",
          }}
        >
          Batal / Kembali
        </Typography>
      </Box>
    </>
  );
}
