import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Dropzone from "react-dropzone";

import axios from "axios";
import { useAuth } from "../../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../../services/authHeader";
import {
  input__text,
  form__control,
  input__label,
} from "../../../../rekam-medis/editor/style";
import {
  dropzone__container,
  dropzone__label,
} from "../../../../rekam-medis/editor/tab-internal/style";
import mediplusHelper from "../../../../../../../utils/mediplusHelper";
import * as Sentry from "@sentry/react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function ProdukModal({
  open = false,
  onClose = () => {},
  onSave = () => {},
  id = null,
}) {
  const { user } = useAuth();
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );

  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState({});

  const loadData = async (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/products/${id}`, {
        params: {
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setForm((prev) => ({
          ...{
            ...prev,
            ...response.data,
          },
          photo: [],
        }));
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
      })
      .finally(() => {});
  };

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: [e.target.value],
    }));
  };

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleFileDelete = (index) => {
    setForm((prevForm) => ({
      ...prevForm,
      photo: prevForm.photo.filter((item, idx) => {
        return idx !== index;
      }),
    }));
  };

  const handleFileChange = (file) => {
    if (file.length > 0) {
      setForm((prevForm) => ({
        ...prevForm,
        photo: [file[0]],
      }));
    }
  };

  const appendArray = (form_data, values, name) => {
    if (!values && name) form_data.append(name, "");
    else {
      if (typeof values == "object") {
        for (let key in values) {
          if (typeof values[key] == "object")
            appendArray(form_data, values[key], name + "[" + key + "]");
          else form_data.append(name + "[" + key + "]", values[key]);
        }
      } else form_data.append(name, values);
    }
    return form_data;
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      if (key !== "submenu" && key !== "photo") {
        formData.append(
          key,
          value === undefined || value === null ? "" : value
        );
      }
    });
    if (form.photo && form.photo.length > 0) {
      formData.append("photo", form.photo[0]);
    }
    if (form.submenu && form.submenu.length > 0) {
      for (let i = 0; i < form.submenu.length; i++) {
        Object.entries(form.submenu[i]).forEach(([key, value]) => {
          formData.append(
            `submenu[${i}][${key}]`,
            value === undefined || value === null ? "" : value
          );
        });
      }
    }
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/products${
          id ? "/" + id : ""
        }`,
        formData,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      enqueueSnackbar("sukses menyimpan data", {
        variant: "success",
      });
      handleModalClose();
      onSave();
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? "Gagal menyimpan data", {
        variant: "error",
      });
      console.log(e);
      Sentry.captureException(e);
    } finally {
    }
  };

  useEffect(() => {
    if (open === true) {
      if (id) {
        loadData(id);
      } else {
        setForm((prev) => ({
          clinic_id: current_active_schedule.clinic_id,
          nama: "",
          harga: 0,
          stok: 0,
          kategori: "",
          submenu: [],
          photo: [],
        }));
      }
    }
  }, [id, open]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...modalStyle, borderRadius: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Tambah Pelayanan
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="nama">
              Nama Layanan
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              value={form.nama ?? ""}
              placeholder=""
              id="nama"
              name="nama"
              type="text"
            />
            <FormHelperText error={true}></FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="harga">
              Harga
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              value={form.harga ?? ""}
              placeholder=""
              id="harga"
              name="harga"
              type="number"
            />
            <FormHelperText error={true}></FormHelperText>
          </FormControl>

          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="kategori">
              Kategori
            </InputLabel>
            <InputBase
              sx={input__text}
              onChange={handleChange}
              value={form.kategori ?? ""}
              placeholder=""
              id="kategori"
              name="kategori"
              type="text"
            />
            <FormHelperText error={true}></FormHelperText>
          </FormControl>

          <FormControl variant="standard" sx={form__control}>
            <InputLabel sx={input__label} shrink htmlFor="gambar">
              Gambar
            </InputLabel>
          </FormControl>
          <Dropzone onDrop={handleFileChange}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div style={dropzone__container} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div style={dropzone__label}>
                    <div
                      style={{
                        padding: "10px 0px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src="/assets/icons/gallery-import.svg"
                        alt="import"
                      ></img>
                      <div>drag or upload files here</div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {form?.photo &&
              form?.photo?.length > 0 &&
              form.photo.map((item, index) => {
                return (
                  <Box key={`f_scan_box_${index}`}>
                    <Typography component={"span"} key={`f_scan_${index}`}>
                      {item.name} | {mediplusHelper.fileSizeIEC(item.size)}{" "}
                      <Typography
                        component={"span"}
                        sx={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFileDelete(index);
                        }}
                      >
                        delete
                      </Typography>
                    </Typography>
                  </Box>
                );
              })}
          </Box>

          <Button
            fullWidth
            sx={{
              backgroundColor: "blue",
              borderRadius: "8px",
              textTransform: "none",
              marginBottom: "15px",
              height: "38px",
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "12px",
                fontWeight: 600,
                marginRight: "5px",
              }}
            >
              Simpan
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
