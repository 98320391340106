import { Grid, IconButton, InputBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../../../providers/AuthProvider";
import authHeader from "../../../../../services/authHeader";
import SectionLoading from "../../../../components/loading/SectionLoading";
import {
  create__invoice__btn,
  create__invoice__btn__label,
  header__container,
  header__h1,
  search__btn,
} from "./style";
import { search__input } from "../../hasil-pemeriksaan/main/style";
import Produk from "./produk/Produk";
import { resetInvoice, initInvoice } from "../../../../../redux/slices/invoice";
import InvoiceInformation from "./invoice/invoice-information/InvoiceInformation";
import InvoiceDetail from "./invoice/invoice-detail/InvoiceDetail";
import * as Sentry from "@sentry/react";
import Obat from "./obat/Obat";
import _ from "lodash";
import { updateMedicineCacheList } from "../../../../../redux/slices/medicineCache";
import { useSnackbar } from "notistack";

export default function PembayaranEditor() {
  const route = useRouteMatch();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const invoice = useSelector((state) => state.invoice);

  const [invoiceLoading, setInvoiceLoading] = useState(true);
  const [searchProduct, setSearchProduct] = useState("");
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [layananModalOpen, setLayananModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isProductView, setIsProductView] = useState(true);

  const loadData = async (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/vdoc/invoices/${id}`, {
        params: {
          head: user.head_doctor_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(initInvoice({ ...response.data }));
        let invItems = response.data.invoice_items;
        invItems.forEach((i) => {
          if (i.tipe === "MEDICINE") {
            if (!_.isEmpty(i.medicine)) {
              dispatch(updateMedicineCacheList(i.medicine));
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
        Sentry.captureException(error);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
        history.push("/pembayaran");
      })
      .finally(() => {
        setInvoiceLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    setSearchProduct(e.target.value);
  };

  useEffect(() => {
    if (route?.params?.id && !isNaN(route?.params?.id)) {
      loadData(route.params.id);
    } else {
      setInvoiceLoading(false);
      let clinicRoomId = current_active_schedule.clinic_room_id;
      dispatch(resetInvoice({ clinic_room_id: clinicRoomId }));
    }
    return () => {
      dispatch(resetInvoice());
    };
  }, []);

  if (invoiceLoading) {
    return <SectionLoading></SectionLoading>;
  }

  return (
    <>
      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Pembayaran
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            onClick={() => {
              setSearchProduct("");
              setIsProductView(!isProductView);
            }}
            aria-label="search-user"
            sx={create__invoice__btn}
          >
            <Typography sx={create__invoice__btn__label}>
              {isProductView ? "Switch Obat" : "Switch Produk"}
            </Typography>
          </IconButton>
          <IconButton
            onClick={() => {
              setSelectedProductId(null);
              setLayananModalOpen(true);
            }}
            aria-label="search-user"
            sx={create__invoice__btn}
          >
            <img src="/assets/icons/plus.svg" alt="create"></img>
            <Typography sx={create__invoice__btn__label}>Layanan</Typography>
          </IconButton>
          <InputBase
            sx={search__input}
            size="18"
            placeholder="Cari"
            value={searchProduct}
            onChange={handleSearchChange}
          ></InputBase>
          <IconButton aria-label="search" sx={search__btn}>
            <img src="/assets/icons/search.svg" alt="search"></img>
          </IconButton>
          <IconButton
            onClick={() => {
              setSortModalOpen(true);
            }}
            aria-label="sort"
            sx={{ ...create__invoice__btn, marginLeft: "10px" }}
          >
            <img src="/assets/icons/sort.svg" alt="sort"></img>
          </IconButton>
          {invoice?.medical_record_id && (
            <IconButton
              onClick={() => {
                history.push(
                  `/rekam-medis/editor/${invoice?.medical_record_id}`
                );
              }}
              aria-label="search-user"
              sx={create__invoice__btn}
            >
              <Typography sx={create__invoice__btn__label}>
                Buka R.Medis
              </Typography>
            </IconButton>
          )}
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <InvoiceInformation></InvoiceInformation>
          {isProductView ? (
            <Produk
              searchProduct={searchProduct}
              sortModalOpen={sortModalOpen}
              setSortModalOpen={setSortModalOpen}
              layananModalOpen={layananModalOpen}
              setLayananModalOpen={setLayananModalOpen}
              selectedProductId={selectedProductId}
              setSelectedProductId={setSelectedProductId}
            />
          ) : (
            <Obat
              search={searchProduct}
              sortModalOpen={sortModalOpen}
              setSortModalOpen={setSortModalOpen}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <InvoiceDetail
            loadData={loadData}
            setInvoiceLoading={setInvoiceLoading}
          />
        </Grid>
      </Grid>
    </>
  );
}
