const modal__container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  minHeight: "400px",
  width: "800px",
};

const modal__header__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "12px",
  paddingRight: "3px",
};

const modal__header__text = {
  fontSize: "14px",
  fontWeight: 600,
};

const tab__label = {
  fontWeight: 600,
  textTransform: "none",
  px: 0.5,
  fontSize: "14px",
};

export {
  modal__container,
  modal__header__container,
  modal__header__text,
  tab__label,
};
