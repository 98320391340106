import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const SidebarIndicator = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "80%",
  width: "5px",
  borderRadius: "0 15px 15px 0px",
  backgroundColor: "#FF0091",
  my: "auto",
  left: 0,
}));

export default SidebarIndicator;
