import { webkit__scrollbar } from "../../../style";

const invoice__table__content__wrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "5px 15px 5px 15px",
};

const invoice__table__title = {
  fontSize: "16px",
  fontWeight: 700,
  paddingTop: "7px",
};

const invoice__table__container = {
  maxHeight: 330,
  boxShadow: "0px 4px 20px rgba(197, 197, 197, 0.2)",
  ...webkit__scrollbar,
};

const invoice__user__img = {
  width: "44px",
  height: "44px",
  borderRadius: "50%",
};

const invoice__status__pills = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
  padding: "5px 16px",
  gap: "10px",
  position: "relative",
  height: "30px",
  background: "#C0ECFD",
  color: "#FF0000",
  borderRadius: "32px",
};

const invoice__status__pills__label = {
  display: "inline-block",
  width: "100%",
  fontSize: "12px",
  fontWeight: 400,
};

export {
  invoice__table__content__wrapper,
  invoice__table__title,
  invoice__table__container,
  invoice__user__img,
  invoice__status__pills,
  invoice__status__pills__label,
};
