import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../../providers/AuthProvider";
import authHeader from "../../../../../../services/authHeader";
import {
  header__container,
  modal__container,
  submit__btn,
  submit__btn__label,
} from "./style";
import { input__label, input__text } from "../../../rekam-medis/editor/style";
import * as Sentry from "@sentry/react";

export default function StokModal({
  open,
  onClose,
  onSave,
  medicine,
  setMedicine,
}) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleModalClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnSave = () => {
    if (typeof onSave === "function") {
      onSave();
    }
  };

  const changeStock = (e) => {
    setMedicine((prev) => ({
      ...prev,
      stok: e.target.value,
    }));
  };

  const changeSubStock = (e, index) => {
    setMedicine((prev) => ({
      ...prev,
      submenu: prev.submenu.map((submenu, i) =>
        i === index ? { ...submenu, stok: e.target.value } : submenu
      ),
    }));
  };

  const handleSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medicines/${medicine.id}`,
        {
          ...medicine,
        },
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        enqueueSnackbar("sukses mengedit data stok", {
          variant: "success",
        });
        handleModalClose();
        handleOnSave();
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error);
        enqueueSnackbar(error.response?.data?.message ?? error.message, {
          variant: "error",
        });
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={modal__container}>
        <Box sx={header__container}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Edit Stok
          </Typography>
          <IconButton
            onClick={handleModalClose}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 1.5,
          }}
        >
          {medicine &&
            (medicine.submenu.length < 1 ? (
              <FormControl
                key={medicine.id}
                variant="standard"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <InputLabel sx={input__label} shrink htmlFor="stok">
                  Stok {medicine.nama}
                </InputLabel>
                <InputBase
                  sx={input__text}
                  onChange={changeStock}
                  value={medicine.stok}
                  id="stok"
                  name="stok"
                  type="number"
                />
                <FormHelperText error={true}></FormHelperText>
              </FormControl>
            ) : (
              medicine.submenu.map((item, index) => {
                return (
                  <FormControl
                    key={`form_${item.id}`}
                    variant="standard"
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel sx={input__label} shrink htmlFor="stok">
                      Stok {item.nama}
                    </InputLabel>
                    <InputBase
                      sx={input__text}
                      onChange={(e) => {
                        changeSubStock(e, index);
                      }}
                      value={item.stok}
                      id={`stok_${item.id}`}
                      name={`stok_${item.id}`}
                      type="number"
                    />
                    <FormHelperText error={true}></FormHelperText>
                  </FormControl>
                );
              })
            ))}

          <Button
            fullWidth
            sx={submit__btn}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography sx={submit__btn__label}>Simpan</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
