import { Grid, IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  modal__container,
  modal__header__container,
  modal__header__text,
  modal_btn,
  modal_btn__label,
} from "./style";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useAuth } from "../../../../../../providers/AuthProvider";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import authHeader from "../../../../../../services/authHeader";
import {
  user__detail__center__container,
  user__detail__center__img,
  user__detail__label,
  user__detail__value,
} from "../timeline/user-detail/style";
import moment from "moment";

export default function UserDetailModal({ open, onClose, selectedUser }) {
  const history = useHistory();
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const timeline_group_mode = useSelector((state) => state.timeline_group_mode);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [userDetail, setUserDetail] = useState({});

  const loadUserDetail = (user) => {
    setUserDetail({});
    if (_.isEmpty(user)) {
      return;
    }
    let url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/timeline/${user.user_id}`;
    if (user.type === "UNLINKED") {
      url = `${process.env.REACT_APP_API_URL}/api/v1/vdoc/unlinked-timeline/${user.medical_record_id}`;
    }
    axios
      .get(url, {
        params: {
          clinic: current_active_schedule.clinic_id,
          head: user.head_doctor_id,
          "timeline-group-mode": timeline_group_mode,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setUserDetail({
          ...response.data,
        });
      })
      .catch(function (error) {
        enqueueSnackbar(error.message);
        console.log(error);
        Sentry.captureException(error);
      });
  };

  const handleTambahRekamMedis = () => {
    if (selectedUser?.user_id) {
      history.push(`/rekam-medis/editor?ref=${selectedUser.user_id}`);
    } else {
      history.push(
        `/rekam-medis/editor?ref=${selectedUser.medical_record_id}&type=MED`
      );
    }
  };

  useEffect(() => {
    loadUserDetail(selectedUser);
  }, [selectedUser]);

  return (
    <Modal
      open={open}
      onClose={(e) => {
        if (typeof onClose === "function") {
          onClose(e);
        }
      }}
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...modal__container, width: "400px" }}>
        <Box sx={modal__header__container}>
          <Typography sx={modal__header__text}>Informasi Pasien</Typography>
          <IconButton
            onClick={(e) => {
              if (typeof onClose === "function") {
                onClose(e);
              }
            }}
            color="inherit"
            aria-label="refresh"
            edge="start"
            type="button"
          >
            <img
              width={25}
              height={25}
              effect="blur"
              src="/assets/icons/close.svg"
              alt="refresh"
            ></img>
          </IconButton>
        </Box>

        <Box sx={user__detail__center__container}>
          <img
            src={userDetail?.user?.photo_profile ?? "/assets/icons/avatar.svg"}
            alt="user"
            style={user__detail__center__img}
          ></img>
        </Box>

        <Box
          sx={{
            p: 2,
            pl: 4,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography sx={user__detail__label}>Nama</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.user?.nama ?? "-"}
              </Typography>
              <Typography sx={user__detail__label}>Nomor HP</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.user?.nomor ?? "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={user__detail__label}>Tanggal Lahir</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.user?.tanggal_lahir
                  ? moment(userDetail?.user?.tanggal_lahir).format("DD/MM/YYYY")
                  : "-"}
              </Typography>
              <Typography sx={user__detail__label}>Jenis Kelamin</Typography>
              <Typography sx={user__detail__value}>
                {userDetail?.user?.jenis_kelamin
                  ? userDetail?.user?.jenis_kelamin === "PRIA"
                    ? "Laki-laki"
                    : "Perempuan"
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={user__detail__label}>Alamat</Typography>
          <Typography sx={user__detail__value}>
            {userDetail?.user?.alamat ?? "-"}
          </Typography>
        </Box>

        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={handleTambahRekamMedis} sx={modal_btn}>
            <img src="/assets/icons/plus.svg" alt="create"></img>
            <Typography sx={modal_btn__label}>Tambah Rekam Medis</Typography>
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
}
