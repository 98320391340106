import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useState } from "react";
import {
  modal__submit__btn,
  modal__submit__btn__label,
  template__btn,
  template__text__container,
  template__text__header__container,
  template__text__header__h1,
  template__text__list__container,
  template__text__wrapper,
} from "./style";
import TemplateTextModal from "./TemplateTextModal";
import PropTypes from "prop-types";
import { useAuth } from "../../../../../../providers/AuthProvider";
import { db } from "../../../../../../db/db";
import * as Sentry from "@sentry/react";

function TemplateText({
  open = false,
  table = "template_subjektif",
  onClick = () => {},
}) {
  const { user } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const data_template = useLiveQuery(() => {
    return db[table].where("doctor_id").equals(user.id).toArray();
  });

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleTemplateClick = (value) => {
    if (typeof onClick === "function") {
      onClick(value);
    }
  };

  const handleAddTemplate = async (value) => {
    if (value && value.length > 0) {
      try {
        await db[table].add({
          value: value,
          doctor_id: user.id,
        });
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          ...template__text__wrapper,
          display: open ? "block" : "none",
        }}
      >
        <Box sx={template__text__container}>
          <Box sx={template__text__header__container}>
            <Typography sx={template__text__header__h1}>Template</Typography>
            <IconButton
              aria-label="search-user"
              sx={modal__submit__btn}
              onClick={handleModalOpen}
            >
              <img src="/assets/icons/plus.svg" alt="create"></img>
              <Typography sx={modal__submit__btn__label}>Tambah</Typography>
            </IconButton>
          </Box>
          <Box sx={template__text__list__container}>
            {data_template &&
              data_template?.map((data) => {
                return (
                  <IconButton
                    key={`template_${data.id}`}
                    onClick={() => {
                      handleTemplateClick(data.value);
                    }}
                    aria-label="search-user"
                    sx={template__btn}
                  >
                    <Typography sx={modal__submit__btn__label}>
                      {data.value}
                    </Typography>
                  </IconButton>
                );
              })}
          </Box>
        </Box>
      </Box>
      <TemplateTextModal
        open={modalOpen}
        onClose={handleModalClose}
        onSubmit={handleAddTemplate}
        table={table}
      />
    </>
  );
}

TemplateText.propTypes = {
  open: PropTypes.bool.isRequired,
  table: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default TemplateText;
