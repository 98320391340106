import { Paper, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import "moment/locale/id";
import { text__date, text__subjektif } from "./style";
import { useState } from "react";
import UserRecordDetailHeader from "./header/UserRecordDetailHeader";
import UserRecordDetailContent from "./content/UserRecordDetailContent";

export default function UserRecordDetail({ medicalRecord }) {
  const [tab, setTab] = useState("Data Medis");
  return (
    <>
      {medicalRecord && Object.keys(medicalRecord).length !== 0 && (
        <Paper
          elevation={0}
          sx={{
            paddingTop: "10px",
          }}
        >
          <Typography sx={text__date}>
            {moment(medicalRecord?.created_at).format("DD MMMM YYYY | HH:MM")}
          </Typography>
          <Typography sx={text__subjektif}>
            {medicalRecord?.record_soap?.subjektif
              ? medicalRecord?.record_soap?.subjektif
              : "Tidak ada subjektif"}
          </Typography>
          <UserRecordDetailHeader tab={tab} setTab={setTab} />
          <UserRecordDetailContent medicalRecord={medicalRecord} tab={tab} />
        </Paper>
      )}
    </>
  );
}
