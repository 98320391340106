import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Nunito Sans", "Roboto", "sans-serif"].join(","),
  },
  palette: {
    blue: "rgba(0, 174, 239, 1)",
    pink: "rgba(255, 0, 145, 1)",
  },
});

export default theme;
