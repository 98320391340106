const form__box = {
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
  height: "100%",
  
};

const form__label = {
  fontSize: "14px",
  fontWeight: 400,
};

const form__value = {
  fontSize: "14px",
  fontWeight: 600,
  paddingLeft: "10px",
  overflowWrap: "break-word",
};

export { form__box, form__label, form__value };
