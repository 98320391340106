import { createSlice } from "@reduxjs/toolkit";

export const medicineCache = createSlice({
  name: "medicine_cache",
  initialState: [],
  reducers: {
    updateMedicineCacheList: (state, data) => {
      let tempState = [...state];
      if (Array.isArray(data.payload)) {
        data.payload.forEach((item) => {
          let find = tempState.find((med) => med.id === item.id);
          if (find) {
            tempState = tempState.map((med) =>
              med.id === item.id ? item : med
            );
          } else {
            tempState.push(item);
          }
          if (item.hasOwnProperty("submenu") && item.submenu.length > 0) {
            item.submenu.forEach((sub) => {
              let f = tempState.find((med) => med.id === sub.id);
              if (f) {
                tempState = tempState.map((med) =>
                  med.id === sub.id ? sub : med
                );
              } else {
                tempState.push(sub);
              }
            });
          }
        });
      } else {
        let item = data.payload;
        let find = tempState.find((med) => med.id === item.id);
        if (find) {
          tempState = tempState.map((med) => (med.id === item.id ? item : med));
        } else {
          tempState.push(item);
        }
        if (item.hasOwnProperty("submenu") && item.submenu.length > 0) {
          item.submenu.forEach((sub) => {
            let f = tempState.find((med) => med.id === sub.id);
            if (f) {
              tempState = tempState.map((med) =>
                med.id === sub.id ? sub : med
              );
            } else {
              tempState.push(sub);
            }
          });
        }
      }
      return tempState;
    },
    resetMedicineCache: () => {
      return [];
    },
  },
});

export const { updateMedicineCacheList, resetMedicineCache } =
  medicineCache.actions;
export default medicineCache.reducer;
