import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { input__label } from "../style";
import ResepRacikanModal from "./ResepRacikanModal";
import {
  medicine__box__container,
  medicine__box__header__btn,
  medicine__box__header__text,
} from "./style";
import { webkit__scrollbar } from "../../../../style";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import { useSnackbar } from "notistack";
import {
  snackbar__content__container,
  snackbar__content__text,
} from "../../main/table/style";

export default function ResepRacikInput({ form, setForm }) {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [resepRacikModalOpen, setResepRacikModalOpen] = useState(false);
  const [indexSelectedResepRacik, setIndexSelectedResepRacik] = useState("NEW");
  const [selectedResepRacik, setSelectedResepRacik] = useState({
    nama_resep: "",
    qty: 0,
    aturan_minum: "",
    jumlah_dosis: "",
    satuan_dosis: "",
    satuan_dosis_input_mode: "OPTION",
    instruksi: "",
    keterangan: "",
    rute: "",
    rute_input_mode: "OPTION",
    item: [],
  });

  const handleAddResepRacik = () => {
    setSelectedResepRacik({
      nama_resep: "",
      qty: 0,
      aturan_minum: "",
      jumlah_dosis: "",
      satuan_dosis: "",
      satuan_dosis_input_mode: "OPTION",
      instruksi: "",
      keterangan: "",
      rute: "",
      rute_input_mode: "OPTION",
      item: [],
    });
    setIndexSelectedResepRacik("NEW");
    setResepRacikModalOpen(true);
  };

  const handleResepRacikSubmit = () => {
    if (indexSelectedResepRacik === "NEW") {
      setForm((prev) => ({
        ...prev,
        resep_racikan: [...prev.resep_racikan, selectedResepRacik],
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        resep_racikan: prev.resep_racikan.map((i, idx) =>
          idx === indexSelectedResepRacik ? { ...selectedResepRacik } : i
        ),
      }));
    }
    setResepRacikModalOpen(false);
  };

  const handleEditItemResep = (idx) => {
    if (form.resep_racikan[idx]) {
      setSelectedResepRacik(form.resep_racikan[idx]);
      setIndexSelectedResepRacik(idx);
      setResepRacikModalOpen(true);
    } else {
      enqueueSnackbar("data resep tidak ditemukan");
    }
  };

  const handleDeleteResep = () => {
    setForm((prev) => ({
      ...prev,
      resep_racikan: prev.resep_racikan.filter(
        (i, idx) => idx !== indexSelectedResepRacik
      ),
    }));
    setDeleteConfirmationOpen(false);
    setIndexSelectedResepRacik("NEW"); //ini reset biar gk error
  };

  const handleODeleteConfirmationOpen = (idx) => {
    setIndexSelectedResepRacik(idx);
    setDeleteConfirmationOpen(true);
  };

  const handleODeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
    setIndexSelectedResepRacik("NEW"); //ini reset biar gk error
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box sx={snackbar__content__container}>
              <Typography sx={snackbar__content__text}>
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={handleDeleteResep}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={handleODeleteConfirmationClose}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />
      <ResepRacikanModal
        open={resepRacikModalOpen}
        selectedResepRacik={selectedResepRacik}
        setSelectedResepRacik={setSelectedResepRacik}
        onClose={() => {
          setResepRacikModalOpen(false);
        }}
        onSubmit={handleResepRacikSubmit}
      ></ResepRacikanModal>
      <Box
        sx={{
          mb: 1.6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
            pb: "9px",
          }}
        >
          <InputLabel
            sx={{ ...input__label, marginTop: "5px", height: "20px" }}
            shrink
            htmlFor="resep"
          >
            Resep Racikan
          </InputLabel>
          <IconButton
            onClick={handleAddResepRacik}
            sx={medicine__box__header__btn}
          >
            <Typography sx={medicine__box__header__text}>
              Tambah Resep
            </Typography>
          </IconButton>
        </Box>
        <Box sx={{}}>
          <Box
            sx={{
              ...medicine__box__container,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              p: 1,
              maxHeight: "250px",
              minHeight: "90px",
              overflowY: "auto",
              mr: "0px",
              ...webkit__scrollbar,
            }}
          >
            {form.resep_racikan &&
              Array.isArray(form.resep_racikan) &&
              form.resep_racikan.map((i, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    p: 1,
                    border: "0.5px solid #ced4da",
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      justifyItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 700,
                      }}
                    >
                      R / {i.nama_resep ? i.nama_resep : "-"} |{" "}
                      {i.aturan_minum ? i.aturan_minum : "-"} |{" "}
                      {i.jumlah_dosis ? i.jumlah_dosis : "-"} {i.satuan_dosis} |{" "}
                      {i.qty === null ||
                      i.qty === undefined ||
                      i.qty === "" ||
                      i.qty <= 0
                        ? "-"
                        : mediplusHelper.romanize(i.qty ?? 0)}{" "}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        onClick={() => {
                          handleEditItemResep(idx);
                        }}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </Typography>
                      <Box
                        sx={{
                          px: "5px",
                          fontSize: "12px",
                          fontWeight: 700,
                        }}
                      >
                        |
                      </Box>
                      <Typography
                        onClick={() => {
                          handleODeleteConfirmationOpen(idx);
                        }}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Hapus
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <ul
                      style={{
                        padding: "0px 15px",
                        marginTop: "0px",
                        marginBottom: "8px",
                      }}
                    >
                      {i.item &&
                        Array.isArray(i.item) &&
                        i.item.map((sub, idxx) => (
                          <li key={idxx}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              {sub.nama ? sub.nama : "-"} |{" "}
                              {sub.jumlah_dosis ? sub.jumlah_dosis : "-"}{" "}
                              {sub.satuan_dosis}
                            </Typography>
                          </li>
                        ))}
                    </ul>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Instruksi : {i.instruksi ? i.instruksi : "-"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Rute : {i.rute ?? "-"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Catatan Khusus : {i.keterangan}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}
