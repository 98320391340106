import { webkit__scrollbar } from "../../../../../style";

const search__container = {
  position: "relative",
  marginBottom: "15px",
  paddingLeft: "10px",
};

const search__icon__img = {
  position: "absolute",
  right: "7%",
  top: "23%",
};

const user__list__container = {
  display: "flex",
  flexDirection: "column",
  maxHeight: "430px",
  overflowY: "auto",
  overflowX: "hidden",
  ...webkit__scrollbar,
};

const user__info__container = {
  display: "flex",
  minHeight: "64px",
  boxShadow: "none",
  background: "#FFF",
  width: "auto",
  cursor: "pointer",
  border: "none",
  marginRight: "5px",
  alignItems: "center",
  alignContent: "center",
};

const user__info__img = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "10px",
  marginRight: "15px",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
};

const user__info__text = {
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "19px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  maxWidth: "100%",
  marginRight: "5px",
};

export {
  search__container,
  search__icon__img,
  user__list__container,
  user__info__container,
  user__info__img,
  user__info__text,
};
