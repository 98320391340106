import React, { useEffect, useState } from "react";
import "swiper/css";
import {
  Pagination,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../../providers/AuthProvider";
import {
  addInvoiceItem,
  addMedicineInvoiceItem,
} from "../../../../../../redux/slices/invoice";
import authHeader from "../../../../../../services/authHeader";
import {
  available__product__text,
  product__list__container,
  product__list__item__container,
  product__list__item__info__container,
  product__list__item__info__text,
  selected__category__container,
  selected__category__text,
} from "./../produk/style";
import mediplusHelper from "../../../../../../utils/mediplusHelper";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";
import {
  updateListMedicine,
  updateSelectedMedicine,
} from "../../../../../../redux/slices/medicine";
import ObatLoading from "./ObatLoading";
import ImageLabel from "../produk/ImageLabel";
import SortModal from "../produk/modal/SortModal";
import ObatSubmenuModal from "./ObatSubmenuModal";
import { updateMedicineCacheList } from "../../../../../../redux/slices/medicineCache";

export default function Obat({ search, sortModalOpen, setSortModalOpen }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const medicines = useSelector((state) => state.medicine.medicines);
  const selected_medicine = useSelector(
    (state) => state.medicine.selected_medicine
  );
  const invoice = useSelector((state) => state.invoice);

  const [medicineLoading, setMedicineLoading] = useState(true);

  //table
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [sortBy, setSortBy] = useState("nama");
  const [sortType, setSortType] = React.useState("ASC");
  const [total, setTotal] = React.useState(0);

  const [submenuModalOpen, setSubmenuModalOpen] = useState(false);

  const isStokWarning = (med) => {
    let medItem = invoice.invoice_items.find(
      (item) => item.medicine_id === med.id
    );
    let addedToInvoice;
    console.log(med, medItem);
    if (medItem) {
      if (medItem.hasOwnProperty("originQty")) {
        addedToInvoice = medItem.kuantitas + 1 - medItem.originQty;
      } else {
        addedToInvoice = medItem.kuantitas + 1;
      }
    } else {
      addedToInvoice = 0;
    }

    console.log(addedToInvoice, med.stok);

    if (addedToInvoice > med.stok) {
      enqueueSnackbar("menambah melebihi stok", {
        variant: "warning",
      });
    }
  };

  const handleAddInvoiceMedicineItem = (id) => {
    let medicine = medicines.find((medicine) => medicine.id === id);
    if (!medicine) {
      enqueueSnackbar("not found");
      return;
    }
    if (medicine.submenu?.length > 0) {
      dispatch(updateSelectedMedicine(medicine));
      setSubmenuModalOpen(true);
    } else {
      isStokWarning(medicine);
      dispatch(addMedicineInvoiceItem(medicine));
    }
  };

  const handleAddSubmenuMedicineItem = (id) => {
    let medicine = selected_medicine.submenu?.find(
      (medicine) => medicine.id === id
    );
    if (!medicine) {
      enqueueSnackbar("not found");
      return;
    }
    let formatted = { ...medicine };
    formatted.nama = `${selected_medicine.nama} - ${medicine.nama}`;
    isStokWarning(medicine);
    dispatch(addMedicineInvoiceItem(formatted));
  };

  const loadData = (url = null) => {
    axios
      .get(
        url ? url : `${process.env.REACT_APP_API_URL}/api/v1/vdoc/medicines`,
        {
          params: {
            page: page,
            clinic: current_active_schedule.clinic_id,
            "sort-by": sortBy,
            "sort-type": sortType,
            search: search,
            pagination: 10,
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch(updateListMedicine(response.data.data));
        setLastPage(response.data.last_page);
        setMedicineLoading(false);
        setTotal(response.data.total);
        dispatch(updateMedicineCacheList(response.data.data));
      })
      .catch(function (error) {
        console.log(error);
        setMedicineLoading(false);
        Sentry.captureException(error);
      });
  };

  const handlePageChange = (e, pageNumber) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    loadData();
  }, [page, search, sortBy, sortType]);

  return (
    <>
      <Box sx={selected__category__container}>
        <Typography sx={selected__category__text}>Semua</Typography>
        <Typography sx={available__product__text}>
          {total} data tersedia
        </Typography>
      </Box>
      <Box sx={{ ...product__list__container, maxHeight: "400px" }}>
        {medicineLoading ? (
          <ObatLoading />
        ) : (
          <>
            {medicines.map((item) => {
              return (
                <Paper
                  key={`product_${item.id}`}
                  onClick={() => {
                    handleAddInvoiceMedicineItem(item.id);
                  }}
                  elevation={0}
                  sx={{
                    ...product__list__item__container,
                    flexBasis: isDesktop ? "20%" : "33%",
                  }}
                >
                  <ImageLabel
                    width={80}
                    height={80}
                    text={item.nama}
                    photo={item.photo}
                  />
                  <Box
                    id={`box_product_${item.id}`}
                    sx={product__list__item__info__container}
                  >
                    <Typography sx={product__list__item__info__text}>
                      {item.nama}
                    </Typography>
                    <Typography sx={product__list__item__info__text}>
                      {mediplusHelper.formatRupiah(item.harga)}
                    </Typography>
                    <Typography sx={product__list__item__info__text}>
                      Stok :{" "}
                      {item.submenu?.length > 0
                        ? item.submenu.reduce(
                            (total, sub) => total + sub.stok,
                            0
                          )
                        : item.stok}
                    </Typography>
                  </Box>
                </Paper>
              );
            })}
          </>
        )}
      </Box>
      <Stack alignItems="end">
        <Pagination
          shape="rounded"
          sx={{
            p: 2,
            ".Mui-selected": {
              backgroundColor: "rgba(0, 174, 239, 1)!important",
              color: "white",
            },
          }}
          count={lastPage}
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </Stack>

      <SortModal
        open={sortModalOpen}
        onClose={() => {
          setSortModalOpen(false);
        }}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortType={sortType}
        setSortType={setSortType}
      />
      <ObatSubmenuModal
        open={submenuModalOpen}
        onClose={() => setSubmenuModalOpen(false)}
        medicine={selected_medicine}
        handleAddSubmenuMedicineItem={handleAddSubmenuMedicineItem}
      />
    </>
  );
}
