import {
  Button,
  ClickAwayListener,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../providers/AuthProvider";
import mediplusHelper from "../../../../utils/mediplusHelper";
import authHeader from "../../../../services/authHeader";
import { updateScheduleData } from "../../../../redux/slices/schedule";
import {
  calendar__now__container,
  calendar__now__hour__text,
  calendar__now__tz__text,
  clinic__selector__container,
  clinic__selector__select,
  date__selector__container,
  header__btn,
  header__btn__label,
  header__container,
  header__h1,
  month__btn,
  month__btn__selected,
  month__selector__container,
  selected__date__text,
  year__selector__btn__container,
  year__selector__container,
  year__selector__selected__text,
} from "./style";
import { webkit__scrollbar } from "../../style";
import BatasAntrianModal from "./modal/BatasAntrianModal";
import EditModal from "./modal/EditModal";
import CreateModal from "./modal/CreateModal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import * as Sentry from "@sentry/react";

const daysInMonth = (oneIndexedInputMonth, year) => {
  const zeroIndexedInputMonth = oneIndexedInputMonth - 1;
  const theNextZeroIndexedMonth = zeroIndexedInputMonth + 1;
  const theFinalDayOfThePreviousMonth = 0;
  return new Date(
    year,
    theNextZeroIndexedMonth,
    theFinalDayOfThePreviousMonth
  ).getDate();
};

export default function JadwalPraktek() {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  //redux
  const current_active_schedule = useSelector(
    (state) => state.schedule.current_active_schedule
  );
  const schedule_date = useSelector((state) => state.schedule.scheduleDate);

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [selectedDate, setSelectedDate] = useState({
    year: moment().format("YYYY"),
    month: moment().format("MM"),
    date: moment().format("DD"),
    hour: moment().format("HH"),
    minute: moment().format("mm"),
  });
  const [dataLoading, setDataLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(0);
  const [clinicRooms, setClinicRooms] = useState([]);

  //popper
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [popperScheduleEl, setPopperScheduleEl] = React.useState(null);
  const [popupScheduleOpen, setPopupScheduleOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  //modal
  const [batasAntrianModalOpen, setBatasAntrianModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleMonthChange = (month) => {
    month = String(month);
    if (month.length < 2) {
      month = `0${month}`;
    }
    setSelectedDate((prev) => ({
      ...prev,
      month: month,
    }));
  };

  const handleDateChange = (dt) => {
    if (dt !== null || dt !== undefined) {
      dt = String(dt);
      if (dt.length < 2) {
        dt = `0${dt}`;
      }
      setSelectedDate((prev) => ({
        ...prev,
        date: dt,
      }));
    }
  };

  const handleYearChange = (type = "add") => {
    let year = parseInt(selectedDate.year);
    if (type === "add") {
      setSelectedDate((prev) => ({
        ...prev,
        year: String(year + 1),
      }));
    } else {
      setSelectedDate((prev) => ({
        ...prev,
        year: String(year - 1),
      }));
    }
  };

  const loadData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/schedules`,
        {
          params: {
            timezone: mediplusHelper.getTimezoneOffset(),
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      setSchedules(response.data);
      dispatch(
        updateScheduleData({
          schedule_list: response.data,
        })
      );
      let clinics = [];
      response.data.forEach((i) => {
        if (clinics.find((item) => item.id === i.clinic.id) === undefined) {
          clinics.push(i.clinic);
        }
      });
      setClinics(clinics);
      const responseClinicRooms = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/me/clinic-rooms`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      setClinicRooms(responseClinicRooms.data);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
        autoHideDuration: "2000",
      });
      Sentry.captureException(e);
    } finally {
      setDataLoading(false);
    }
  };

  const deleteData = async () => {
    setDeleteConfirmationOpen(false);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/vdoc/schedules/${selectedSchedule.id}`,
        {
          params: {
            head: user.head_doctor_id,
          },
          headers: authHeader(),
        }
      );
      loadData();
      enqueueSnackbar("data jadwal terhapus", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? e.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
    }
  };

  const handleClinicChange = (e) => {
    setSelectedClinic(e.target.value);
  };

  const handlePopupScheduleOpen = (event) => {
    let scheduleId = parseInt(
      event.currentTarget.getAttribute("data-schedule-id")
    );
    let schedule = schedules.find((item) => {
      return item.id === scheduleId;
    });
    if (schedule !== undefined) {
      setSelectedSchedule(schedule);
      if (event.currentTarget === popperScheduleEl) {
        setPopupScheduleOpen(!popupScheduleOpen);
      } else {
        setPopperScheduleEl(event.currentTarget);
        setPopupScheduleOpen(true);
      }
    } else {
      enqueueSnackbar("data schedule record not found", {
        type: "error",
      });
    }
  };

  const handlePopupScheduleAway = (event) => {
    if (!mediplusHelper.isPopperTogglerOrPopperElementClicked(event)) {
      if (popupScheduleOpen) setPopupScheduleOpen(false);
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      setPopupScheduleOpen(false);
      setPopperScheduleEl(null);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setSelectedDate((prev) => ({
        ...prev,
        hour: moment().format("HH"),
        minute: moment().format("mm"),
      }));
    }, 1000);
    return () => clearInterval(timer);
  }, [selectedDate]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteConfirmationOpen}
        message={
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "5px",
                  marginRight: "20px",
                }}
              >
                Konfirmasi Hapus
              </Typography>
              <Box>
                <Button
                  onClick={deleteData}
                  variant="contained"
                  size="small"
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  YA
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                  variant="contained"
                  size="small"
                >
                  Batal
                </Button>
              </Box>
            </Box>
          </>
        }
        key={"topright"}
      />

      <Popper
        data-group="popper-element"
        open={popupScheduleOpen}
        anchorEl={popperScheduleEl}
        placement={"left"}
        sx={{
          zIndex: 1500,
        }}
      >
        <Box>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    setBatasAntrianModalOpen(true);
                    setPopupScheduleOpen(false);
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src="/assets/icons/profile-2-user-black.svg"
                      alt="batas-antrian"
                    ></LazyLoadImage>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Batas Antrian"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    setEditModalOpen(true);
                    setPopupScheduleOpen(false);
                  }}
                  sx={{
                    py: "0px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img src="/assets/icons/pencil.svg" alt="pencil"></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Edit"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    py: "0px",
                  }}
                  onClick={() => {
                    setDeleteConfirmationOpen(true);
                    setPopupScheduleOpen(false);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    <img src="/assets/icons/trash.svg" alt="hapus"></img>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingTop: "2px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    primary="Hapus"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>
      </Popper>

      <Box sx={header__container}>
        <Typography component="h1" sx={header__h1}>
          Jadwal Praktek
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            onClick={() => {
              setCreateModalOpen(true);
            }}
            aria-label="search-user"
            sx={header__btn}
          >
            <img src="/assets/icons/plus.svg" alt="create"></img>
            <Typography sx={header__btn__label}>Jadwal Praktek</Typography>
          </IconButton>
        </Box>
      </Box>

      <Box sx={clinic__selector__container}>
        <FormControl
          variant="standard"
          sx={{
            width: "250px",
          }}
        >
          <Select
            id="klinik"
            name="klinik"
            value={selectedClinic}
            onChange={handleClinicChange}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
            }}
            sx={clinic__selector__select}
          >
            <MenuItem value={0}>Semua Klinik</MenuItem>
            {clinics &&
              clinics.map((clinic, index) => {
                return (
                  <MenuItem key={`select_${index}`} value={clinic.id}>
                    {clinic.nama}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Box sx={calendar__now__container}>
          <Box
            sx={{
              marginRight: "15px",
            }}
          >
            <Typography sx={calendar__now__hour__text}>
              {selectedDate.hour}:{selectedDate.minute}
            </Typography>
            <Typography sx={calendar__now__tz__text}>{timeZone}</Typography>
          </Box>
          <img src="/assets/icons/calendar.svg" alt="calendar"></img>
        </Box>
      </Box>

      <Box
        sx={{
          marginBottom: "10px",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Box sx={year__selector__container}>
              <Typography sx={year__selector__selected__text}>
                {selectedDate.year}
              </Typography>
              <Box sx={year__selector__btn__container}>
                <img
                  onClick={() => {
                    handleYearChange("sub");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  src="/assets/icons/calendar-arrow-left.svg"
                  alt="arrow-left"
                ></img>
                <img
                  onClick={() => {
                    handleYearChange("add");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  src="/assets/icons/calendar-arrow-right.svg"
                  alt="arrow-right"
                ></img>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Swiper
              spaceBetween={10}
              slidesPerView={"auto"}
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                borderTop: "1px solid #D5D5D5",
                borderBottom: "1px solid #D5D5D5",
              }}
            >
              {months.map((month, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "5%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      margin: "0px 20px",
                    }}
                  >
                    <Typography
                      key={`month_${index + 1}`}
                      sx={
                        parseInt(selectedDate.month) === index + 1
                          ? month__btn__selected
                          : month__btn
                      }
                      onClick={() => {
                        handleMonthChange(index + 1);
                      }}
                    >
                      {month}
                    </Typography>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Grid>
        </Grid>
        <Swiper
          spaceBetween={1}
          slidesPerView={"auto"}
          style={{
            width: "100%",
            height: "48px",
            alignItems: "center",
          }}
        >
          {_.times(daysInMonth(selectedDate.month, selectedDate.year), (i) => (
            <SwiperSlide
              key={i}
              style={{
                display: "flex",
                alignItems: "center",
                width: "40px",
                marginTop: "auto",
                marginBottom: "auto",
                margin: "0px 5px",
              }}
            >
              <Typography
                key={i + 1}
                sx={
                  parseInt(selectedDate.date) === i + 1
                    ? month__btn__selected
                    : month__btn
                }
                onClick={() => {
                  handleDateChange(i + 1);
                }}
              >
                {i + 1}
              </Typography>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Typography sx={selected__date__text}>
        {mediplusHelper.ucwords(
          mediplusHelper
            .dayToString(
              mediplusHelper.convertIsoDayWeekToPHPSystem(
                moment(
                  `${selectedDate.year}-${selectedDate.month}-${selectedDate.date}`
                ).isoWeekday()
              )
            )
            .toLocaleLowerCase()
        )}{" "}
        |{" "}
        {moment(
          `${selectedDate.year}-${selectedDate.month}-${selectedDate.date}`
        ).format("DD MMMM YYYY")}
      </Typography>
      <Box
        sx={{
          maxHeight: "400px",
          overflowY: "auto",
          ...webkit__scrollbar,
        }}
      >
        {schedules &&
          schedules
            .filter(
              (i) =>
                i.hari ===
                mediplusHelper.convertIsoDayWeekToPHPSystem(
                  moment(
                    `${selectedDate.year}-${selectedDate.month}-${selectedDate.date}`
                  ).isoWeekday()
                )
            )
            .filter((i) => {
              if (selectedClinic === 0) {
                return true;
              } else {
                return i.clinic.id === selectedClinic;
              }
            })
            .map((item, index, arr) => {
              return (
                <Box
                  key={`schedule_${index}`}
                  sx={{
                    paddingBottom: "1px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={1}>
                      <Box
                        sx={{
                          borderRight: "2px dashed rgba(255, 0, 145, 1)",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            position: "absolute",
                            top: 5,
                            right: -9,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            {item.jam_buka}
                          </Typography>
                          <img
                            src="/assets/icons/timeline-dot.svg"
                            alt="dot"
                          ></img>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            position: "absolute",
                            top: 73,
                            right: -9,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            {item.jam_tutup}
                          </Typography>
                          <img
                            src="/assets/icons/timeline-dot.svg"
                            alt="dot"
                          ></img>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={11}>
                      <Box
                        sx={{
                          mx: 2,
                          marginTop: 1,
                          marginBottom: arr.length - 1 === index ? 2 : 7,
                          paddingLeft: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignContent: "center",
                            alignItems: "center",
                            background:
                              item.id === current_active_schedule.id &&
                              `${selectedDate.year}-${selectedDate.month}-${selectedDate.date}` ===
                                schedule_date
                                ? "rgba(0, 174, 239, 0.1)"
                                : "rgba(255, 0, 145, 0.04)",
                            py: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignContent: "center",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <img
                              width={67}
                              height={67}
                              src={
                                item.clinic?.photo_profile
                                  ? item.clinic.photo_profile
                                  : "https://mediplus.s3.ap-southeast-1.amazonaws.com/10636/2021-06-23.webp"
                              }
                              style={{
                                borderRadius: "8px",
                                objectFit: "cover",
                              }}
                              alt="clinic"
                            ></img>
                            <Box
                              sx={{
                                marginLeft: "20px",
                                minWidth: "250px",
                              }}
                            >
                              <Grid container>
                                <Grid item lg={4} md={6} sm={6}>
                                  <Typography
                                    sx={{
                                      color: "#7D7D7D",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Tempat
                                  </Typography>
                                </Grid>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.clinic?.nama ?? "-"}
                                </Typography>
                                <Grid item lg={8} md={6} sm={6}></Grid>
                              </Grid>
                              <Grid container>
                                <Grid item lg={4} md={6} sm={6}>
                                  <Typography
                                    sx={{
                                      color: "#7D7D7D",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Poli
                                  </Typography>
                                </Grid>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.clinic_room?.nama ?? "-"}
                                </Typography>
                                <Grid item lg={8} md={6} sm={6}></Grid>
                              </Grid>
                            </Box>
                          </Box>

                          <Typography
                            component={"span"}
                            sx={{
                              borderLeft: "2px solid #D5D5D5",
                              paddingLeft: 4,
                              paddingRight: 2,
                              py: 1,
                            }}
                          >
                            <Typography
                              component={"span"}
                              sx={{
                                background:
                                  item.id === current_active_schedule.id &&
                                  `${selectedDate.year}-${selectedDate.month}-${selectedDate.date}` ===
                                    schedule_date
                                    ? "rgba(0, 174, 239, 0.2)"
                                    : "rgba(255, 0, 145, 0.2)",
                                color:
                                  item.id === current_active_schedule.id &&
                                  `${selectedDate.year}-${selectedDate.month}-${selectedDate.date}` ===
                                    schedule_date
                                    ? "#00AEEF"
                                    : "#FF0091",
                                fontWeight: 600,
                                fontSize: "14px",
                                p: 1,
                                borderRadius: "8px",
                              }}
                            >
                              Batas Antrian : {item.limit ?? 0}
                            </Typography>
                          </Typography>
                          <ClickAwayListener
                            onClickAway={handlePopupScheduleAway}
                          >
                            <IconButton
                              data-schedule-id={item.id}
                              onClick={handlePopupScheduleOpen}
                              color="inherit"
                              aria-label="refresh"
                              edge="start"
                              type="button"
                              sx={{
                                marginRight: "10px",
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                data-group="popper-toggler"
                                src="/assets/icons/table-action.svg"
                                alt="refresh"
                              ></LazyLoadImage>
                            </IconButton>
                          </ClickAwayListener>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
      </Box>

      <BatasAntrianModal
        schedule={selectedSchedule}
        open={batasAntrianModalOpen}
        onChange={setSelectedSchedule}
        onClose={() => {
          setBatasAntrianModalOpen(false);
        }}
        onSave={() => {
          loadData();
        }}
      />
      <EditModal
        clinicRooms={clinicRooms}
        schedule={selectedSchedule}
        open={editModalOpen}
        onChange={setSelectedSchedule}
        onClose={() => {
          setEditModalOpen(false);
        }}
        onSave={() => {
          loadData();
        }}
      />
      <CreateModal
        open={createModalOpen}
        clinicRooms={clinicRooms}
        onClose={() => {
          setCreateModalOpen(false);
        }}
        onSave={() => {
          loadData();
        }}
      />
    </>
  );
}
