const chat__container = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  minWidth: "100%",
  justifyContent: "space-between",
};

const avatar__container = {
  width: "44px",
  height: "44px",
  background: "none",
};

const avatar__img = {
  borderRadius: "50%",
  width: "44px",
  height: "44px",
};

const chat__img__wrapper = {
  width: "calc(100% - 88px)",
  background: "none",
};

const chat__img__container = {
  pt: 0.5,
  px: 1,
  display: "flex",
  justifyContent: "start",
};

const chat__img__bubble = {
  width: "fit-content",
  maxWidth: "50%",
  px: 1,
  pt: 1,
  background: "#EFF1F9",
  borderRadius: "0px 8px 8px 8px",
};

const img__container = {
  maxHeight: "250px",
  position: "relative",
  overflow: "hidden",
  borderRadius: "10px",
  mb: 0.5,
};

const img = {
  display: "block",
  width: "100%",
  height: "105%",
  objectFit: "cover",
};

const footer__container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignContent: "center",
  alignItems: "center",
  gap: "2px",
  pl: 0.5,
};

const footer__time = {
  fontSize: "10px",
  fontWeight: 400,
  textAlign: "right",
};

export {
  chat__container,
  avatar__container,
  avatar__img,
  chat__img__wrapper,
  chat__img__container,
  chat__img__bubble,
  img__container,
  img,
  footer__container,
  footer__time,
};
